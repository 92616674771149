import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Overlay } from '@ifca-root/react-component/src/components/Overlay/Overlay';
import ShareIcon from '@material-ui/icons/Share';
import ShareDialog from 'components/Dialog/ShareDialog';
import { MainHeaderCustomer } from 'components/Layout/MainHeaderCustomer';
import {
	OrderDiscountStatus,
	PaymentClass,
	TableStatus,
	useCreateReceiptAttachmentMutation,
	useGetBillQuery,
	useGetMenuXDebtorListLazyQuery,
	useGetMenuXInhouseListLazyQuery,
	useGetOfficerLazyQuery,
	useGetStaffLazyQuery,
	useGetTableQuery,
} from 'generated/graphql';
import { sortDescByKey } from 'helpers/hooks/stringNumberFunction/sortByKey';
import { amtStr } from 'helpers/numFormatter';
import * as htmlToImage from 'html-to-image';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
	billReceiptDisplay,
	printReceipt,
} from './ReceiptFunctions/ReceiptFunctions';

export const Receipt = () => {
	useEffect(() => {
		console.log('Receipt Customer App');
	}, []);
	const history = useHistory();
	// const { orderID }: any = useParams();
	// const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	// const { zoomIn, zoomOut, zoomReset, styles } = useMobilePDFZoom();
	const { menuID, orderID, tableID, outletID, billID } = useParams<
		Record<string, any>
	>();

	const [hotelGuest, setHotelGuest] = useState({} as any);
	const [hotelDebtor, setHotelDebtor] = useState({} as any);
	const [officer, setOfficer] = useState({} as any);
	const [staff, setStaff] = useState({} as any);
	const [latestPayment, setLatestPayment] = useState({} as any);
	const [outlet, setOutlet] = useState({} as any);
	const [negOrderItem, setNegOrderItem] = useState({});
	const [receiptDataURL, setReceiptDataURL] = useState(null);

	const [subtotalFooter, setSubTotal] = useState(0);
	const [serviceChargeFooter, setServiceChargeFooter] = useState(0);
	const [taxFooter, setTaxFooter] = useState(0);
	const [orderDiscountFooter, setOrderDiscount] = useState(0);
	const [totalFooter, setTotalFooter] = useState(0);

	const handleSort = arr => {
		let temp = arr?.slice();
		return sortDescByKey(temp, 'createdTs');
	};

	const {
		loading: billLoading,
		called: billCalled,
		data: { getBill } = { getBill: [] },
	} = useGetBillQuery({
		fetchPolicy: 'network-only',
		variables: {
			ID: billID,
		},
		onCompleted: ({ getBill }) => {
			const latestPayment = handleSort(getBill[0]?.payments)[0];
			const outlet = getBill[0]?.outlet;
			setLatestPayment(latestPayment);
			setOutlet(outlet);
			let temp = {};
			getBill[0]?.orderItem?.map(el => {
				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID] += el?.quantity * -1;
					} else {
						temp[el?.refID] = el?.quantity * -1;
					}
				}
			});
			setNegOrderItem({ ...temp });

			if (
				outlet?.outletChannel?.hotelID &&
				latestPayment?.registrationID &&
				latestPayment?.paymentClass === PaymentClass.Debtor
			) {
				loadMenuXDebtor({
					variables: {
						hotelID: outlet?.outletChannel?.hotelID,
						outletID: outletID,
					},
				});
			} else if (
				outlet?.outletChannel?.hotelID &&
				latestPayment?.registrationID &&
				latestPayment?.paymentClass === PaymentClass.Room
			) {
				loadMenuXInhouse({
					variables: {
						hotelID: outlet?.outletChannel?.hotelID,
						outletID: outletID,
					},
				});
			} else if (latestPayment?.paymentClass === PaymentClass.Officer) {
				loadOfficer({
					variables: { ID: latestPayment?.patronAccountID },
				});
			} else if (latestPayment?.paymentClass === PaymentClass.Staff) {
				loadStaff({
					variables: { ID: latestPayment?.patronAccountID },
				});
			}
		},
	});

	const status = getBill[0]?.order?.table?.status;

	useEffect(() => {
		if (status === TableStatus?.Available) {
			history.push(`/menu/patron-app/invalid-qr`);
		}
	}, [status]);

	const [
		loadMenuXInhouse,
		{
			loading: MenuXInhouseListLoading,
			called: MenuXInhouseListCalled,
			data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
		},
	] = useGetMenuXInhouseListLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getMenuXInhouseList }) => {
			setHotelGuest(
				getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
					guest => guest?.RegistrationID === latestPayment?.registrationID,
				)[0],
			);
		},
	});

	const [
		loadMenuXDebtor,
		{
			loading: MenuXDebtorListLoading,
			called: MenuXDebtorListCalled,
			data: { getMenuXDebtorList } = { getMenuXDebtorList: [] },
		},
	] = useGetMenuXDebtorListLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getMenuXDebtorList }) => {
			setHotelDebtor(
				getMenuXDebtorList?.data?.MenuXDebtorList?.filter(
					guest => guest?.RegistrationID === latestPayment?.registrationID,
				)[0],
			);
		},
	});

	const [
		loadOfficer,
		{
			loading: getOfficerLoading,
			called: getOfficerCalled,
			data: { getOfficer } = { getOfficer: {} as any },
		},
	] = useGetOfficerLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getOfficer }) => {
			setOfficer(getOfficer);
		},
	});

	const [
		loadStaff,
		{
			loading: getStaffLoading,
			called: getStaffCalled,
			data: { getStaff } = { getStaff: {} as any },
		},
	] = useGetStaffLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getStaff }) => {
			setStaff(getStaff);
		},
	});

	const {
		loading: getTableLoading,
		called: getTableCalled,
		data: { getTable } = { getTable: {} as any },
	} = useGetTableQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getTable }) => {},
	});

	// const orderItems = getBill[0]?.orderItem?.filter(el => el?.quantity > 0);
	const orderItems = getBill[0]?.orderItem
		?.map(el => {
			if (el?.quantity - negOrderItem[el?.ID] === 0 || el?.quantity < 0) {
				return { ...el, void: true };
			} else {
				return { ...el, void: false };
			}
		})
		?.filter(list => list.void === false);

	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);
	const [openPrinterDialog, setOpenPrinterDialog] = useState(false);

	const [
		createAttachment,
		{
			data: { createReceiptAttachment } = {
				createReceiptAttachment: {} as any,
			},
			called: createReceiptAttachmentCalled,
			loading: createReceiptAttachmentLoading,
		},
	] = useCreateReceiptAttachmentMutation({});

	const onSubmit = blob => {
		createAttachment({
			variables: {
				file: blob,
				billID: billID,
				outletID: outletID,
			},
		});
	};

	useEffect(() => {
		if (billCalled && !billLoading) {
			let attachment = document.getElementById('share-receipt') as HTMLElement;
			htmlToImage
				.toBlob(attachment, {
					style: { background: '#ffffff' },
				})
				.then(value => {
					onSubmit(value);
				});
		}
	}, [billCalled, !billLoading]);

	let ttl, subtotal, svc, serviceCharge, tx, tax, total;
	if (orderItems?.length > 0) {
		ttl = orderItems?.map(el => {
			return el?.totalInfo?.totalAmount;
		});

		subtotal = ttl.reduce((accumulator: any, currentValue: any) => {
			return accumulator + currentValue;
		}, 0);

		//service charge
		svc = orderItems?.map(el => {
			return el?.totalInfo?.totalServiceCharge;
		});

		serviceCharge = svc.reduce((accumulator: any, currentValue: any) => {
			return accumulator + currentValue;
		}, 0);

		//tax
		tx = orderItems?.map(el => {
			return el?.totalInfo?.totalTax;
		});

		tax = tx.reduce((accumulator: any, currentValue: any) => {
			return accumulator + currentValue;
		}, 0);

		//total
		total = subtotal + serviceCharge + tax;
	}

	const orderDiscount =
		getBill[0]?.order?.orderDiscount?.filter(
			disc => disc?.status === OrderDiscountStatus.Active,
		)[0]?.amount ?? 0;

	const [fs, setFS] = useState(null);
	const [sc, setSC] = useState(null);
	const [ta, setTa] = useState(null);
	const [fd, setFD] = useState(null);
	const [to, setTo] = useState(null);

	useEffect(() => {
		setFS(subtotal);
		setSC(serviceCharge);
		setTa(tax);
		setFD(orderDiscount);
		setTo(total);
	}, [subtotal, serviceCharge, tax, orderDiscount, total]);

	const filterOrder = data => {
		return data?.filter(
			v => v?.orderItem?.filter(e => e?.billID === null).length > 0,
		);
	};

	const { register, control, handleSubmit, errors } = useForm<any>({
		defaultValues: {},
		mode: 'onSubmit',
	});

	const handlePrinting = data => {
		printReceipt(receiptDataURL, data['printer'].name);
	};

	return (
		<>
			{billLoading && <Loading />}
			{createReceiptAttachmentLoading && <Loading />}
			<MainHeaderCustomer
				onClick={() =>
					history.push(
						`/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${menuID}/summary/confirm-order`,
					)
				}
				mainBtn="back"
				smTitle="Customer App"
				title={outlet?.name}
				routeSegments={[
					{ name: 'Customer App' },
					{ name: 'Table' },
					{ name: 'Payment', current: true },
					{ name: 'Receipt', current: true },
				]}
			/>
			<DynamicSubHeader
				noMainheader
				title={`${getTable?.prefix}${getTable?.tableNumber} ${getBill[0]?.order?.docNo}`} // title text
				rightText={`${outlet?.currencyCode ?? ''}${amtStr(totalFooter)}`} // right text
				infoLine={
					// infoline text
					<>
						<span className="desc">
							{/* {getBill[0]?.order?.patronAccount?.name} */}
						</span>
					</>
				}
			/>
			<ContentWrapper footer float>
				{!billLoading && (
					<>
						{billReceiptDisplay(
							outlet,
							outletID,
							getBill,
							orderItems,
							{
								discount: fd,
								subtotal: fs,
								serviceCharge: sc,
								tax: ta,
								total: to,
							},
							negOrderItem,
							false,
							'',
							hotelGuest,
							hotelDebtor,
							officer,
							staff,
							latestPayment,
						)}
						{billReceiptDisplay(
							outlet,
							outletID,
							getBill,
							orderItems,
							{
								discount: fd,
								subtotal: fs,
								serviceCharge: sc,
								tax: ta,
								total: to,
							},
							negOrderItem,
							true,
							'',
							hotelGuest,
							hotelDebtor,
							officer,
							staff,
							latestPayment,
						)}
						<Overlay />
					</>
				)}
			</ContentWrapper>
			{createReceiptAttachmentCalled &&
				!createReceiptAttachmentLoading &&
				ShareDialog({
					shareTitle: `Share Receipt - ${getBill[0]?.order?.docNo}`,
					title: `Share Receipt`,
					URL: createReceiptAttachment?.fileURL,
					setSimple: setOpenShareDialog,
					simple: openShareDialog,
					emailShare: openSendEmail,
					setSimpleEmail: setOpenSendEmail,
				})}
			<Footer
				overlap
				// isHidden={openShareDialog}
				options={[
					{
						name: 'Home',
						onClick: () => {
							history.push(
								`/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}`,
							);
						},
						color: 'primary',
						props: {
							type: 'submit',
						},
					},
					{
						name: 'Share',
						onClick: () => {
							setOpenShareDialog(true);
						},
						color: 'primary',
						props: {
							type: 'submit',
						},
						startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
					},
				]}
			/>
		</>
	);
};
