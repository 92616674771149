import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import { Divider, Grid } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import { OutletIcon } from 'assets';
import { BillFooterReceipt } from 'components/Custom/CustomComponent';
import { CreditCardType, PaymentClass } from 'generated/graphql';
import { convertDate, dateFormat } from 'helpers/formatDate';
import { amtStr } from 'helpers/numFormatter';
import React from 'react';
// import '../../../../BusinessInsights/BusinessAnalytics/bi.scss';
import '../billSettlement.scss';
import * as bxlCommon from '../BixolonJS/bxlcommon';
import * as bxlPOS from '../BixolonJS/bxlpos';

export const billReceiptDisplay = (
	outlet,
	outletID,
	getBill,
	orderItems,
	footerToken,
	negOrderItem,
	hidden?: Boolean,
	mode?: string,
	hotelGuest?: any,
	hotelDebtor?: any,
	officer?: any,
	staff?: any,
	latestPayment?: any,
	currencyCode?: string,
) => {
	const creditClass = creditClass => {
		let credClass;

		if (creditClass === CreditCardType.AmericanExpressCard) {
			credClass = 'American Express Card';
		} else if (creditClass === CreditCardType.DinersCard) {
			credClass = 'Diners Card';
		} else if (creditClass === CreditCardType.JcbCard) {
			credClass = 'JCB Card';
		} else if (creditClass === CreditCardType.Mastercard) {
			credClass = 'Master Card';
		} else if (creditClass === CreditCardType.UnionpayCard) {
			credClass = 'UNION PAY';
		} else if (creditClass === CreditCardType.VisaCard) {
			credClass = 'Visa Card';
		} else if (creditClass === CreditCardType.DebitCard) {
			credClass = 'Debit Card';
		}
		return credClass;
	};

	const chargeToInfo = paymentClass => {
		let info1, info2;
		if (paymentClass === PaymentClass.Credit) {
			info1 = creditClass(latestPayment?.creditCardType);
			info2 = `****${(latestPayment?.cardNo).slice(-4)}`;
		} else if (paymentClass === PaymentClass.Room) {
			info1 = hotelGuest?.RoomNo ? hotelGuest?.RoomNo : null;
			info2 = hotelGuest?.GuestName;
		} else if (paymentClass === PaymentClass.Debtor) {
			info1 = hotelDebtor?.GuestType;
			info2 = hotelDebtor?.GuestName;
		} else if (paymentClass === PaymentClass.Staff) {
			info1 = staff?.designation;
			info2 = staff?.name;
		} else if (paymentClass === PaymentClass.Officer) {
			info1 = officer?.designation;
			info2 = officer?.name;
		}
		if (info1 || info2) {
			return (
				<div className="session">
					<div className="flex session">
						<div className="mdDesc flex-space">
							<span>{`Charged to `}</span>
							{info1 && <span>{`${info1} | `}</span>}
							<span className="highlight-text" style={{ color: '#ff9800' }}>
								{`${info2}`}
							</span>
						</div>
					</div>
				</div>
			);
		}
	};

	return (
		<div
			style={
				hidden
					? {
							zIndex: -40,
							top: 0,
							left: 0,
							position: 'fixed',
							width: '300px',
					  }
					: {
							marginTop: '0px',
							zIndex: 40,
							position: 'relative',
					  }
			}
			id={hidden ? 'share-receipt' : null}
			// className={'example-screen'}
		>
			<CardContents>
				<Grid container>
					<Grid item xs={12} style={{ textAlign: 'center' }}>
						{outlet?.logo?.fileURL ? (
							<img
								className="icon-svg"
								style={{
									height: '90px',
									width: '150px',
									// borderRadius: '100px',
									marginTop: '20px',
									marginBottom: '5px',
								}}
								src={outlet?.logo?.fileURL}
							/>
						) : (
							<img
								className="icon-svg"
								style={{
									height: '90px',
									width: '150px',
									// borderRadius: '100px',
									marginTop: '20px',
									marginBottom: '5px',
								}}
								src={OutletIcon}
							/>
						)}
					</Grid>
				</Grid>
				<div
					className="content-wrap full"
					style={{
						textAlign: 'center',
						marginBottom: '-5px',
						marginTop: '-5px',
						fontSize: '12px',
					}}
				>
					<div>{outlet?.address?.address}</div>
				</div>
				<div
					className="content-wrap full"
					style={{
						textAlign: 'center',
						fontSize: '12px',
						marginBottom: '-5px',
					}}
				>
					<div>
						{outlet?.address?.postCode +
							' ' +
							outlet?.address?.city +
							' ' +
							outlet?.address?.state +
							'.'}
					</div>
				</div>

				<Grid
					container
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<Grid item xs={4} style={{ textAlign: 'center' }}></Grid>
					<Grid item xs={4} style={{ textAlign: 'center' }}>
						<IconText
							icon={
								<PhoneIcon
									style={{
										width: '17px',
										color: 'black',
									}}
								/>
							}
							font=" xxTitle"
						>
							{outlet?.contactNo}
						</IconText>
						{/* {outlet?.contactNo} */}
					</Grid>
					<Grid item xs={4} style={{ textAlign: 'center' }}></Grid>
				</Grid>

				<div className="dialog-dynamic-content">
					{mode == 'void-bill' ? (
						<div className="session">
							<div className="flex session">
								<div className="xsTitle flex-space desc color-red">
									Voided Bill
								</div>
							</div>
						</div>
					) : (
						<div className="session">
							<div className="flex session">
								<div className="xsTitle flex-space desc">
									{`${getBill[0]?.order?.patron?.patronClass}`}
								</div>
								<div className="xsTitle desc" style={{ marginRight: '5px' }}>
									Table:
								</div>
								<div className="mdDesc">
									{`${getBill[0]?.order?.table?.prefix}${getBill[0]?.order?.table?.tableNumber}`}
								</div>
							</div>
						</div>
					)}
				</div>
				{mode == 'void-bill' ? null : (
					<div className="dialog-dynamic-content">
						<div className="session">
							<div className="flex session">
								<div className="xsTitle desc" style={{ marginRight: '5px' }}>
									Outlet:
								</div>
								<div className="mdDesc flex-space">{`${outlet?.name}`}</div>
								<div className="xsTitle desc">{`Cover: ${getBill[0]?.order?.pax}`}</div>
							</div>
						</div>
					</div>
				)}
				{mode == 'void-bill' ? null : (
					<div className="dialog-dynamic-content">
						<div className="session">
							<div className="flex session">
								<div className="xsTitle desc" style={{ marginRight: '5px' }}>
									Date:
								</div>
								<div className="mdDesc">
									{`${convertDate(getBill[0]?.createdTs)}`}
								</div>
							</div>
						</div>
					</div>
				)}

				<div className="content-wrap full" style={{ marginTop: '5px' }}>
					<div className={'xsTitle'}>
						Bill No. &nbsp;
						{getBill[0]?.billNo}
					</div>
					<div className={'mdDesc'} style={{ paddingTop: '12px' }}>
						{dateFormat(getBill[0]?.createdTs)}
					</div>
				</div>

				<Divider style={{ display: 'flex', width: '100%', color: 'black' }} />
				{orderItems
					// ?.filter(list1 => list1.void === false)
					// ?.filter(list2 => list2.refID !== null)
					?.map((v, index) => (
						<div className="dialog-dynamic-content">
							<div className="session">
								<div className="flex session">
									<div
										className="xsTitle flex-space desc"
										style={{ alignSelf: 'center' }}
									>
										{`${v?.openItemName ?? v?.menuItem?.name} x${v?.quantity -
											(negOrderItem[v?.ID] ?? 0)}`}
									</div>
									<div className="xsTitle rightText">
										{/* {`$${amtStr(v?.totalInfo?.totalAmount)}`} */}
										{`$${amtStr(
											v?.totalInfo?.totalAmount *
												((v?.quantity - (negOrderItem[v?.ID] ?? 0)) /
													v?.quantity),
										)}`}
									</div>
								</div>
								{v?.orderItemOption?.length
									? v?.orderItemOption?.map(x => (
											<>
												{x?.orderItemOptionItem?.map(x => (
													<div className="flex session">
														<div
															className="xsTitle flex-space"
															style={{
																color: '#757575',
																fontWeight: 500,
																alignSelf: 'center',
															}}
														>
															{x?.optionItem?.name}
														</div>
														<div
															className="xsTitle rightText"
															style={{
																color: '#757575',
																fontWeight: 500,
															}}
														>
															{`$${amtStr(
																Math.abs(
																	x?.amount *
																		((x?.quantity -
																			(negOrderItem[x?.ID] ?? 0)) /
																			x?.quantity),
																),
															)}`}
														</div>
													</div>
												))}
											</>
									  ))
									: v?.orderItemSetMenuOption?.map(x => (
											<>
												{x?.orderItemSetMenuOptionItem?.map(e => (
													<div
														className="xsTitle"
														style={{
															color: '#757575',
															fontWeight: 500,
															paddingBottom: '2px',
														}}
													>
														{e?.setMenuOptionItem?.menuItem?.name}
													</div>
												))}
											</>
									  ))}
							</div>
						</div>
					))}

				<Divider style={{ display: 'flex', width: '100%', color: 'black' }} />

				{mode == 'void-bill' ? (
					<>
						<BillFooterReceipt
							discount={footerToken?.discount}
							subtotal={footerToken?.subtotal}
							serviceCharge={footerToken?.serviceCharge}
							tax={footerToken?.tax}
							total={footerToken?.total}
							mode="void-bill"
							query={getBill}
							latestPayment={latestPayment}
							orderID={getBill[0]?.order?.ID}
							taxSchemeInfo={getBill[0]?.order?.taxSchemeInfo}
              taxSchemeFooterInfo={getBill[0]?.order?.taxSchemeFooterInfo}
						/>
					</>
				) : mode == 'void-payment' ? (
					<>
						<BillFooterReceipt
							discount={footerToken?.discount}
							subtotal={footerToken?.subtotal}
							serviceCharge={footerToken?.serviceCharge}
							tax={footerToken?.tax}
							total={footerToken?.total}
							mode="void-payment"
							query={getBill}
							latestPayment={latestPayment}
							orderID={getBill[0]?.order?.ID}
							taxSchemeInfo={getBill[0]?.order?.taxSchemeInfo}
              taxSchemeFooterInfo={getBill[0]?.order?.taxSchemeFooterInfo}
						/>
						<div
							className="dialog-dynamic-content"
							style={{ paddingTop: '6px', paddingBottom: '18px' }}
						>
							{chargeToInfo(latestPayment?.paymentClass)}
						</div>
						{getBill[0]?.signature !== undefined &&
							getBill[0]?.signature !== null &&
							Object.keys(getBill[0]?.signature).length > 0 && (
								<img
									className="icon-svg"
									style={{
										height: '120px',
										width: '120px',
										margin: '0px 10px 10px',
									}}
									src={getBill[0]?.signature?.fileURL}
								/>
							)}
					</>
				) : (
					<>
						<BillFooterReceipt
							discount={footerToken?.discount}
							subtotal={footerToken?.subtotal}
							serviceCharge={footerToken?.serviceCharge}
							tax={footerToken?.tax}
							total={footerToken?.total}
							query={getBill}
							latestPayment={latestPayment}
							outletID={outletID}
							orderID={getBill[0]?.order?.ID}
							taxSchemeInfo={getBill[0]?.order?.taxSchemeInfo}
              taxSchemeFooterInfo={getBill[0]?.order?.taxSchemeFooterInfo}
						/>
						<div
							className="dialog-dynamic-content"
							style={{ paddingTop: '6px', paddingBottom: '18px' }}
						>
							{chargeToInfo(latestPayment?.paymentClass)}
						</div>
						{getBill[0]?.signature !== undefined &&
							getBill[0]?.signature !== null &&
							Object.keys(getBill[0]?.signature).length > 0 && (
								<img
									className="icon-svg"
									style={{
										height: '120px',
										width: '120px',
										margin: '0px 10px 10px',
									}}
									src={getBill[0]?.signature?.fileURL}
								/>
							)}
					</>
				)}
			</CardContents>
		</div>
	);
};

export const printReceipt = async (fileURL, printerName) => {
	let nonWhiteSpace = printerName.replace(/ /g, '');

	let posID = 1;

	const viewResult = x => {
		console.log(
			'%cPrinter Results',
			'color: #12850b;',
			JSON.stringify(
				{ posID: posID, printerName: nonWhiteSpace, result: x },
				null,
				'\t',
			),
		);
	};

	const printImage = async () => {
		//Set Printer ID
		await bxlPOS.setPosId(posID);

		//Print Attachment
		await bxlPOS.printBitmap(fileURL, 469, 1, false);

		await bxlPOS.cutPaper(1);

		let posData = bxlPOS.getPosData();

		await bxlCommon.requestPrint(nonWhiteSpace, posData, viewResult);

		posID++;
	};
	console.log(fileURL, 'printReceipt');

	bxlPOS.checkPrinterStatus();
	return await printImage();
};
