import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import {
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	ListItemText,
	MenuItem,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import AddOrderButtonIcon from 'assets/icons/add_order_button.svg';
import CancelOrderButtonIcon from 'assets/icons/cancel_order_button.svg';
import MakePaymentButtonIcon from 'assets/icons/make_payment_button.svg';
import OrangeDollarIcon from 'assets/icons/Money/dollar-orange.svg';
import PickupButtonIcon from 'assets/icons/pickup_button.svg';
import BigNumber from 'bignumber.js';
import { CreditCardType, PaymentClass } from 'generated/graphql';
import { sortDescByKey } from 'helpers/hooks/stringNumberFunction/sortByKey';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import { amtStr } from 'helpers/numFormatter';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router';
import SignatureCanvas from 'react-signature-canvas';
import './customComponent.scss';
import { TaxSchemeComponent } from 'components/Footer/TaxSchemeComponent';

export const TableDialog = (props: any) => {
	const {
		open,
		setOpen,
		title,
		subTitle,
		secSubTitle,
		simpleDescription,
		isForm,
		handleSubmit,
		onSubmit,
		formStructure,
		infoLine,
		infoLineRight,
		infoLineMiddle,
	} = props;

	return (
		<>
			<CommonDialogV2
				fullWidth={true}
				open={open}
				onClose={() => setOpen(false)}
				sections={{
					header: {
						children: (
							<>
								{/* <ListItem
									style={{ backgroundColor: '#c7d6ff' }}
									className="remove-padding"
								> */}
								<ListItemText
									primary={
										<>
											<div className="">
												<div className="dialog-dynamic-content">
													<div className="session">
														<div className="flex session">
															<div
																className="title highlight-text"
																style={{ color: '#ff9800' }}
															>
																<span>{title}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="infoline-content ">
													<div className="xsTitle flex-space infoline">
														{infoLineMiddle}
													</div>
													<div className="xsTitle infoline rightText">
														{infoLineRight}
													</div>
												</div>
												{/* <div className="session">
													<div className="flex session">
														<div
															className="xsTitle flex-space infoline-content"
															style={{ color: 'black' }}
														>
															{infoLine}&nbsp;
															<div className="flex-space">{infoLineMiddle}</div>
															<div className="xsTitle rightText">
																{infoLineRight}
															</div>
														</div>
													</div>
												</div> */}
											</div>
										</>
									}
									secondary={
										<>
											<span className="highlight-text flex-space">
												{subTitle}
											</span>
											{secSubTitle && (
												<span className="highlight-text">{secSubTitle}</span>
											)}
										</>
									}
								/>
								{/* </ListItem> */}
								{/* <hr className="line-subheader" /> */}
							</>
						),
					},
					body: () => (
						<>
							<div
								style={{
									padding: simpleDescription ? '18px' : isForm ? '0px' : '5px',
								}}
							>
								{simpleDescription && (
									<span className="smTitle">{simpleDescription}</span>
								)}
								{isForm && <form>{formStructure()}</form>}
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => setOpen(false),
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										if (isForm) {
											handleSubmit(onSubmit)();
										} else {
											alert('confirm');
											handleSubmit(onSubmit)();
										}
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export const DropDown = (props: any) => {
	const {
		register,
		control,
		data,
		isMultiline,
		name,
		label,
		menuValue,
		menuDisplay,
		isRequired,
	} = props;
	return (
		<>
			<Controller
				as={
					<TextField select>
						{data?.map((el: any, index: number) => (
							<MenuItem key={index} value={menuValue(el)}>
								{menuDisplay(el)}
							</MenuItem>
						))}
					</TextField>
				}
				label={label}
				name={name}
				autoComplete="off"
				control={control}
				multiline={isMultiline}
				fullWidth
				margin="normal"
				required={isRequired}
				// helperText={errors?.title?.message}
				// error={errors?.title ? true : false}
				ref={register}
			/>
		</>
	);
};

export const BillFooter = (props: any) => {
	const {
		total,
		discount,
		subtotal,
		serviceCharge,
		tax,
		totalAmount,
		style,
		isConfirmOrder,
		highlightText,
		currencyCode = '',
	} = props;
	const divChild = (info1: any, info2: any) => {
		return (
			<div
				style={isConfirmOrder ? { paddingBottom: '5px' } : null}
				className={isConfirmOrder ? 'mdDesc' : 'mdDesc bill-ftr-child'}
			>
				<span>{info1}</span>
				<span className="right-info">{info2}</span>
			</div>
		);
	};
	return (
		<>
			<div
				className={isConfirmOrder ? null : 'bill-footer'}
				style={{ ...style }}
			>
				{divChild('Discount', `($${amtStr(discount)})`)}
				{divChild('Subtotal', '$' + amtStr(subtotal))}
				{divChild('Service Charge', '$' + amtStr(serviceCharge))}
				{divChild('Tax', '$' + amtStr(tax))}
				<hr className="line-bill" />
				{/* <Divider
					orientation="horizontal"
					variant="fullWidth"
					style={{ color: 'orange', backgroundColor: 'orange' }}
				/> */}
				<div
					className={
						{ highlightText }
							? 'smTitle bill-total'
							: 'smTitle bill-total highlightText'
					}
				>
					{highlightText ? (
						<>
							<div style={{ display: 'flex', flex: 1 }}>
								<div className="flex-space">
									<span>Total</span>
								</div>
								<div className="right-info highlight-text">
									{/* <IconText
										icon={
											<img
												src={OrangeDollarIcon}
												style={{ width: '17px', paddingRight: '2px' }}
											/>
										}
									> */}
									{`${currencyCode}${amtStr(total)}`}
									{/* </IconText> */}
								</div>
							</div>
						</>
					) : (
						<>
							<span>Total</span>
							<span className="right-info">{`${currencyCode}${amtStr(
								total,
							)}`}</span>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export const BillFooterHanUnused = (props: any) => {
	const {
		total,
		discount,
		discountType,
		subtotal,
		serviceCharge,
		tax,
		totalAmount,
		setFunction,
	} = props;
	let sc = subtotal * serviceCharge;
	let govTax = (subtotal * tax) / 100;
	const divChild = (info1: any, info2: any) => {
		return (
			<div className="mdDesc bill-ftr-child">
				<span>
					{info1}
					{discountType == 'AMOUNT' ? null : discount + '%'}
				</span>
				<span className="right-info">${info2}</span>
			</div>
		);
	};

	useEffect(() => {
		setFunction(
			discountType === 'AMOUNT'
				? subtotal + sc / 100 + govTax - discount
				: ((subtotal + sc / 100 + govTax) * discount) / 100,
		);
	}, [discountType, subtotal, sc, govTax, discount]);

	return (
		<>
			{console.log(discountType, 'discountType')}
			<div className="bill-footer">
				{/* {divChild('Total', '$' + amtStr(total))} */}
				{divChild('Subtotal', '$' + amtStr(subtotal))}
				{divChild('Service Charge', '$' + amtStr(sc / 100))}
				{divChild('Tax', '$' + amtStr(govTax))}
				{divChild(
					'Discount',
					discountType === 'AMOUNT'
						? '$' + amtStr(discount)
						: '$' + amtStr(((subtotal + sc / 100 + govTax) * discount) / 100),
				)}
				<hr className="line-bill" />
				<div className="smTitle bill-total">
					<span>Total</span>
					<span className="right-info">
						{discountType === 'AMOUNT'
							? '$' + amtStr(subtotal + sc / 100 + govTax - discount)
							: '$' + amtStr(((subtotal + sc / 100 + govTax) * discount) / 100)}
					</span>
				</div>
			</div>
		</>
	);
};

export const BillFooterReceipt = (props: any) => {
	const {
		total,
		discount,
		subtotal,
		serviceCharge,
		tax,
		mode,
		query,
		latestPayment,
		currencyCode = '',
		outletID,
		orderID,
		taxSchemeInfo,
    taxSchemeFooterInfo
	} = props;
	// const {
	// 	loading: billLoading,
	// 	called: billCalled,
	// 	data: { getBill } = { getBill: [] },
	// } = useGetBillQuery({
	// 	fetchPolicy: 'network-only',
	// 	variables: { ID: billID },
	// });

	const handleSort = () => {
		if (query) {
			let temp = query[0]?.payments.slice();
			return sortDescByKey(temp, 'createdTs');
		}
	};
	const roundedSubtotal = Math.floor(Number(subtotal) * 100) / 100;

	return (
		<>
			<div className="dialog-dynamic-content" style={{ paddingTop: '5px' }}>
				<div className="session">
					<div className="flex session">
						<div className="flex-space mdDesc" style={{ alignSelf: 'center' }}>
							{'Discount'}
						</div>
						<div className="xsTitle rightText">{`($${amtStr(discount)})`}</div>
					</div>
				</div>
			</div>

			<div className="dialog-dynamic-content">
				<div className="session">
					<div className="flex session">
						<div className="flex-space mdDesc" style={{ alignSelf: 'center' }}>
							{'Subtotal'}
						</div>
						<div className="xsTitle rightText">
							{'$' + amtStr(roundedSubtotal)}
						</div>
					</div>
				</div>
			</div>
			{/* <div className="dialog-dynamic-content">
				<div className="session">
					<div className="flex session">
						<div className="flex-space mdDesc" style={{ alignSelf: 'center' }}>
							{'Service Charge'}
						</div>
						<div className="xsTitle rightText">
							{'$' + amtStr(serviceCharge)}
						</div>
					</div>
				</div>
			</div>
			<div className="dialog-dynamic-content">
				<div className="session">
					<div className="flex session">
						<div className="flex-space mdDesc" style={{ alignSelf: 'center' }}>
							{'Tax'}
						</div>
						<div className="xsTitle rightText">{'$' + amtStr(tax)}</div>
					</div>
				</div>
			</div> */}

			<TaxSchemeComponent
				outletID={outletID}
				taxSchemeID={null}
				currencyCode={currencyCode}
				type={'receipt'}
				orderID={orderID}
				taxSchemeInfo={taxSchemeInfo}
        taxSchemeFooterInfo={taxSchemeFooterInfo}
			/>

			<div className="dialog-dynamic-content">
				<div className="session">
					<div className="flex session">
						<div className="flex-space mdDesc" style={{ alignSelf: 'center' }}>
							{'Rounding'}
						</div>
						<div className="xsTitle rightText">
							{'$' + amtStr(latestPayment?.roundingAmount)}
						</div>
					</div>
				</div>
			</div>

			<Divider style={{ display: 'flex', width: '100%', color: '#ff9800' }} />

			<div
				className="dialog-dynamic-content"
				style={{ paddingTop: '5px', paddingBottom: '10px' }}
			>
				<div className="session">
					<div className="flex session">
						<div className="flex-space xsTitle" style={{ alignSelf: 'center' }}>
							{'Total'}
						</div>
						<div className="rightText" style={{ color: '#ff9800' }}>
							{/* <IconText
								icon={<img src={OrangeDollarIcon} style={{ width: '20px' }} />}
								// font="highlight-text smTitle"
							> */}
							<div className="highlight-text" style={{ fontSize: '15px' }}>
								{/* {amtStr(total)} */}
								{`${currencyCode}${amtStr(
									new BigNumber(total)
										.minus(latestPayment?.roundingAmpunt ?? 0)
										.toNumber(),
								)}`}
							</div>
							{/* </IconText> */}
						</div>
					</div>
				</div>
			</div>

			{mode == 'void-bill' ? null : (
				<>
					<Divider
						style={{ display: 'flex', width: '100%', color: '#ff9800' }}
					/>
					<div className="dialog-dynamic-content" style={{ paddingTop: '5px' }}>
						<div className="session">
							<div className="flex session">
								<div
									className="flex-space xsTitle"
									style={{ alignSelf: 'center' }}
								>
									{'Payment'}
								</div>
							</div>
						</div>
					</div>
					<div className="dialog-dynamic-content">
						<div className="session">
							<div className="flex session">
								<div
									className="flex-space mdDesc"
									style={{ alignSelf: 'center' }}
								>
									{/* {`${toProperCase(latestPayment?.paymentClass)}`} */}
									{latestPayment?.paymentClass === PaymentClass.UnsettledBill
										? 'Unsettled Bill'
										: toProperCase(latestPayment?.paymentClass)}
								</div>
								<div className="xsTitle rightText">
									{'$' + amtStr(latestPayment?.receivedAmount)}
								</div>
							</div>
						</div>
					</div>
					{latestPayment?.paymentClass === PaymentClass.Cash && (
						<div className="dialog-dynamic-content">
							<div className="session">
								<div className="flex session">
									<div
										className="flex-space mdDesc"
										style={{ alignSelf: 'center' }}
									>
										{'Change'}
									</div>
									<div className="xsTitle rightText">
										{'$' + amtStr(latestPayment?.changeAmount)}
									</div>
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};

export const MutliLineSubheader = (props: any) => {
	const { rightText, title, subtitle, rightSub, style } = props;
	return (
		<div className="billListing-dynamic-header" style={{ ...style }}>
			<div className="billListing-dynamic-content">
				<div className="session">
					<div className="flex-space">{title}</div>
					<div>{rightText}</div>
				</div>
			</div>
			<div className="billlisting-subtitle">
				<div className="flex-space">{subtitle}</div>
				<div>{rightSub}</div>
			</div>
		</div>
	);
};

export const TakeawayFooter = (props: any) => {
	const history = useHistory();
	const { pickupDialog, cancelDialog, paymentDialog, addOrderPath } = props;

	return (
		<div className="footer">
			<Grid container>
				<Grid item xs={3} style={{ textAlign: 'center' }}>
					<IconButton
						style={{ paddingBottom: '1px', paddingTop: '7px' }}
						onClick={() => {
							pickupDialog(true);
						}}
					>
						<img
							style={{
								padding: '4px',
								width: '26px',
								height: '26px',
							}}
							src={PickupButtonIcon}
						/>
					</IconButton>
					<div className="desc">Pick Up</div>
				</Grid>
				<Grid item xs={3} style={{ textAlign: 'center' }}>
					<IconButton
						style={{ paddingBottom: '1px', paddingTop: '7px' }}
						onClick={() => {
							history.push(addOrderPath);
						}}
					>
						<img
							style={{
								padding: '4px',
								width: '26px',
								height: '26px',
							}}
							src={AddOrderButtonIcon}
						/>
					</IconButton>
					<div className="desc">Add Order</div>
				</Grid>
				<Grid item xs={3} style={{ textAlign: 'center' }}>
					<IconButton
						style={{ padding: '5.5px 0px 0px 0px' }}
						onClick={() => {
							cancelDialog(true);
						}}
					>
						<img
							style={{
								width: '36px',
								height: '36px',
							}}
							src={CancelOrderButtonIcon}
						/>
					</IconButton>
					<div className="desc">Cancel Order</div>
				</Grid>
				<Grid item xs={3} style={{ textAlign: 'center' }}>
					<IconButton
						style={{ paddingBottom: '1px', paddingTop: '7px' }}
						onClick={() => {
							paymentDialog(true);
						}}
					>
						<img
							style={{
								padding: '4px',
								width: '26px',
								height: '26px',
							}}
							src={MakePaymentButtonIcon}
						/>
					</IconButton>
					<div className="desc">Make Payment</div>
				</Grid>
			</Grid>
		</div>
	);
};

export const PaymentDialog = (props: any) => {
	const {
		signature,
		setSignature,
		openPayment,
		setOpenPayment,
		setCardDropdown,
		setRadioError,
		selectedOrder,
		totalFooter,
		values,
		register,
		handleRadio,
		clearErrors,
		getDefaultPayment,
		setCashTextField,
		cashTextField,
		control,
		errors,
		isDesktop,
		watch,
		cardDropdown,
		radioError,
		handleSubmit,
		memberHotelRadioID,
		onSubmit,
		isTakeaway,
	} = props;

	const [cardRadioID, setCardRadioID] = useState(null);
	const [forPatronAcc, setForPatronAcc] = useState({
		room: null,
		member: null,
	});

	const creditClass = creditClass => {
		let credClass;

		if (creditClass === CreditCardType.AmericanExpressCard) {
			credClass = 'American Express Card';
		} else if (creditClass === CreditCardType.DinersCard) {
			credClass = 'Diners Card';
		} else if (creditClass === CreditCardType.JcbCard) {
			credClass = 'JCB Card';
		} else if (creditClass === CreditCardType.Mastercard) {
			credClass = 'Master Card';
		} else if (creditClass === CreditCardType.UnionpayCard) {
			credClass = 'UNION PAY';
		} else if (creditClass === CreditCardType.VisaCard) {
			credClass = 'Visa Card';
		} else if (creditClass === CreditCardType.DebitCard) {
			credClass = 'Debit Card';
		}

		return credClass;
	};
	const creditCardTypeEnum = Object.values(CreditCardType);

	const paymentClass = paymentClass => {
		let pymtClass;

		if (paymentClass === PaymentClass.Member) {
			pymtClass = 'Member';
		} else if (paymentClass === PaymentClass.Online) {
			pymtClass = 'Online';
		} else if (paymentClass === PaymentClass.Cash) {
			pymtClass = 'Cash';
		} else if (paymentClass === PaymentClass.Credit) {
			pymtClass = 'Credit Card';
		} else if (paymentClass === PaymentClass.Room) {
			pymtClass = 'Room';
		} else if (paymentClass === PaymentClass.Skipper) {
			pymtClass = 'Skipper';
		} else if (paymentClass === PaymentClass.Officer) {
			pymtClass = 'Officer';
		} else if (paymentClass === PaymentClass.Staff) {
			pymtClass = 'Staff';
		} else if (paymentClass === PaymentClass.Voucher) {
			pymtClass = 'Voucher';
		} else if (paymentClass === PaymentClass.Others) {
			pymtClass = 'Others';
		}

		return pymtClass;
	};
	useEffect(() => {
		setCardRadioID(
			getDefaultPayment?.filter(x => x?.paymentClass == PaymentClass.Credit)[0]
				?.ID,
		);

		setForPatronAcc({
			room: getDefaultPayment?.filter(
				x => x?.paymentClass == PaymentClass.Room,
			)[0]?.ID,
			member: getDefaultPayment?.filter(
				x => x?.paymentClass == PaymentClass.Member,
			)[0]?.ID,
		});
	}, [openPayment]);

	return (
		<CommonDialogV2
			hidden={signature}
			fullWidth={true}
			open={openPayment}
			onClose={() => {
				setOpenPayment(false);
				setCardDropdown(false);
				setRadioError(false);
			}}
			sections={{
				header: {
					dynamic: (
						<>
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="flex session">
											<div
												className="title highlight-text flex-space"
												style={{ color: '#ff9800' }}
											>
												Payment
											</div>
										</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="xsTitle infoline flex-space">
										{isTakeaway
											? `#${selectedOrder?.docNo} | ${selectedOrder?.patronName}`
											: `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
									</div>
									<div className="xsTitle infoline rightText">
										{`$${amtStr(totalFooter)}`}
									</div>
								</div>
							</div>
						</>
					),
					// 			title: `Payment`,
					// 			subtitle: isTakeaway
					// 				? `#${selectedOrder?.docNo} |
					//   ${selectedOrder?.patronName}`
					// 				: `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`,
					// 			totalAmount: `$${amtStr(totalFooter)}`,
				},

				body: () => (
					<>
						<span className="mdDesc" style={{ paddingLeft: '15px' }}>
							Payment Method{' '}
						</span>
						<RadioGroup
							aria-label="paymentMethod"
							name="paymentMethodRadio"
							value={values}
							onChange={e => {
								register('defaultPaymentID');
								handleRadio(e);
								clearErrors();
							}}
						>
							{getDefaultPayment
								?.filter(x => {
									return x.isActive === true;
								})
								?.map((el, index) => {
									return (
										<>
											<FormControlLabel
												value={el?.ID}
												control={
													<Radio color="primary" name="defaultPaymentID" />
												}
												ref={register}
												onClick={() => {
													if (el?.paymentClass === PaymentClass.Credit) {
														setCardDropdown(true);
														setCashTextField(false);
													} else if (el?.paymentClass === PaymentClass.Cash) {
														setCashTextField(true);
														setCardDropdown(false);
													} else {
														setCardDropdown(false);
														setCashTextField(false);
													}
												}}
												className={
													el?.paymentClass === PaymentClass.Cash
														? 'cash-radio'
														: null
												}
												style={{ paddingLeft: '15px' }}
												label={
													<>
														<Grid container>
															<Grid
																item
																xs={
																	el?.paymentClass !== PaymentClass.Cash
																		? 12
																		: 8
																}
																style={{
																	alignSelf: 'center',
																	paddingBottom: '0px',
																	paddingTop: '4px',
																}}
															>
																<span>
																	{' '}
																	{toProperCase(paymentClass(el?.paymentClass))}
																</span>
															</Grid>

															{el?.paymentClass === PaymentClass.Cash &&
															cashTextField === true ? (
																<>
																	<Grid item xs={4}>
																		<Controller
																			as={
																				<NumberFormat
																					customInput={TextField}
																					fullWidth
																					autoComplete="off"
																					isNumericString
																					decimalScale={2}
																					fixedDecimalScale
																					style={{
																						marginBottom: '0px',
																						marginTop: '0px',
																					}}
																					margin="dense"
																					defaultValue={totalFooter}
																				/>
																			}
																			style={{
																				paddingBottom: '0px',
																				float: 'right',
																			}}
																			name="receivedAmount"
																			label="Amount"
																			control={control}
																			ref={register}
																			helperText={
																				(errors?.receivedAmount as any)?.message
																			}
																			error={
																				errors?.receivedAmount ? true : false
																			}
																		/>
																	</Grid>
																</>
															) : null}
														</Grid>
													</>
												}
											/>
											<Grid container>
												<Grid
													item
													xs={1}
													style={{
														maxWidth: isDesktop ? 'calc(5%)' : 'calc(10%)',
													}}
												></Grid>
												<Grid
													item
													xs={
														el?.paymentClass === PaymentClass.Member ||
														el?.paymentClass === PaymentClass.Room
															? 4
															: null
													}
													style={{ paddingLeft: isDesktop ? '2px' : '20px' }}
												>
													{watch(`defaultPaymentID`) === forPatronAcc.room &&
													el?.paymentClass == PaymentClass.Room ? (
														<span className="highlight-text">P5051P</span>
													) : (
														watch(`defaultPaymentID`) === forPatronAcc.member &&
														el?.paymentClass == PaymentClass.Member && (
															<span className="highlight-text">P5051P</span>
														)
													)}
												</Grid>
											</Grid>
											{el?.paymentClass === PaymentClass.Credit &&
											cardDropdown === true ? (
												<Grid container>
													<Grid
														item
														xs={1}
														style={{
															maxWidth: isDesktop ? 'calc(5%)' : 'calc(10%)',
														}}
													></Grid>

													<Grid
														item
														xs={5}
														style={{
															marginRight: '4px',
														}}
													>
														<Controller
															as={
																<TextField select>
																	{creditCardTypeEnum?.map(
																		(el: any, index: number) => (
																			<MenuItem key={index} value={el}>
																				{creditClass(el)}
																			</MenuItem>
																		),
																	)}
																</TextField>
															}
															label={'Card Type'}
															name={'cardType'}
															style={{
																marginTop: '0px',
																marginBottom: '0px',
															}}
															autoComplete="off"
															control={control}
															multiline
															fullWidth
															margin="dense"
															ref={register}
															helperText={
																radioError && watch('cardType') === undefined
																	? 'Must select at least one option'
																	: null
															}
															error={
																radioError && watch('cardType') === undefined
															}
														/>
													</Grid>
													<Grid item xs={5} style={{ marginLeft: '4px' }}>
														<Controller
															as={
																<NumberFormat
																	format="#### #### #### ####"
																	mask="_"
																	allowEmptyFormating
																	customInput={TextField}
																	fullWidth
																	autoComplete="off"
																	// isNumericString
																	style={{
																		marginBottom: '0px',
																		marginTop: '0px',
																	}}
																	margin="dense"
																/>
															}
															name="cardReference"
															label={'Card Ref.'}
															fullWidth
															autoComplete="off"
															multiline={false}
															margin="dense"
															// style={{
															// 	marginTop: '3px',
															// 	marginBottom: '3px',
															// }}
															ref={register}
															control={control}
															className="cardReference"
															helperText={
																radioError &&
																watch('cardReference') === undefined
																	? 'Card Ref. must not be empty'
																	: null
															}
															error={
																radioError &&
																watch('cardReference') === undefined
															}
														/>
													</Grid>
												</Grid>
											) : null}
										</>
									);
								})}
						</RadioGroup>
					</>
				),
				footer: {
					actions: [
						{
							displayText: 'Cancel',
							props: {
								onClick: () => {
									setCardDropdown(false);
									setCashTextField(false);
									setOpenPayment(false);
									setRadioError(false);
								},
								variant: 'contained',
								color: 'primary',
							},
						},
						{
							displayText: 'Confirm',

							props: {
								onClick: () => {
									if (watch('defaultPaymentID') === cardRadioID) {
										if (
											watch('cardType') === undefined ||
											watch('cardReference') === undefined ||
											new String(watch('cardReference'))?.match(/\d{4,16}/g)
												?.length !== 4
										) {
											setRadioError(true);
										} else {
											handleSubmit(onSubmit)();
										}
									} else if (memberHotelRadioID(watch(`defaultPaymentID`))) {
										handleSubmit(() => {
											setSignature(true);
										})();
									} else {
										handleSubmit(onSubmit)();
									}
								},
								variant: 'contained',

								color:
									watch('defaultPaymentID') !== undefined || null
										? 'primary'
										: 'inherit',
								disabled: watch('defaultPaymentID') === undefined || null,
							},
						},
					],
				},
			}}
		/>
	);
};

export const SignatureDialog = (props: any) => {
	const {
		signature,
		setSignature,
		setOpenPayment,
		isTakeaway,
		selectedOrder,
		totalFooter,
		handleSubmit,
		onSubmit,
	} = props;

	const [signatureImage, setSignatureImage] = useState(null);

	let sigCanvas = {};

	const clear = () => {
		const signature: any = sigCanvas;
		signature.clear();
	};

	const saveSigCanvas = () => {
		const signature: any = sigCanvas;
		signature.getTrimmedCanvas().toBlob(blob => {
			setSignatureImage(blob);
		});
	};

	const {
		files,
		handleUploadChange,
		previewFiles,
		remove: removeFile,
		handleEditUpload,
		setFiles,
	} = useUploadAttachment();

	useEffect(() => {
		if (signatureImage) {
			signatureImage['name'] = 'sigName';
			const newFiles = [signatureImage];
			setFiles([...newFiles]);
			handleSubmit(data => onSubmit(data, newFiles))();
			// console.log(signatureImage, 'sigIma');
		}
	}, [signatureImage]);

	return (
		<CommonDialogV2
			fullWidth={true}
			open={signature}
			onClose={() => setSignature(false)}
			sections={{
				header: {
					dynamic: (
						<>
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="flex session">
											<div
												className="title highlight-text flex-space"
												style={{ color: '#ff9800' }}
											>
												Member Signature
											</div>
										</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="xsTitle infoline flex-space">
										{isTakeaway
											? `#${selectedOrder?.docNo} | ${selectedOrder?.patronName}`
											: `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
									</div>
									<div className="xsTitle infoline rightText">
										{`$${amtStr(totalFooter)}`}
									</div>
								</div>
							</div>
						</>
					),
					// 			title: `Member Signature`,
					// 			subtitle: isTakeaway
					// 				? `#${selectedOrder?.docNo} |
					//   ${selectedOrder?.patronName}`
					// 				: `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`,
					// 			totalAmount: `$${amtStr(totalFooter)}`,
				},
				body: () => (
					<>
						<Grid container style={{ paddingTop: '10px' }}>
							<Grid item xs={8}>
								<span className="sigTitleText"> Sign Here </span>
							</Grid>
							<Grid item xs={4} style={{ textAlign: 'right' }}>
								<span onClick={clear} className="highlight-text">
									{' '}
									Reset
								</span>
							</Grid>
						</Grid>

						<SignatureCanvas
							ref={ref => {
								sigCanvas = ref;
							}}
							penColor="black"
							canvasProps={{
								className: 'sigCanvas',
							}}
						/>
					</>
				),
				footer: {
					actions: [
						{
							displayText: 'Cancel',
							props: {
								onClick: () => {
									setSignature(false);
								},

								variant: 'contained',
								color: 'primary',
							},
						},
						{
							displayText: 'Save',
							props: {
								onClick: () => {
									saveSigCanvas();
									// handleSubmit(onSubmit)();
									setSignature(false);
									setOpenPayment(false);
								},
								variant: 'contained',
								color: 'primary',
								//disabled: isExceed,
							},
						},
					],
				},
			}}
		/>
	);
};
