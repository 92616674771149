import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
// import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import {
	Grid,
	List,
	ListItem,
	ListItemText,
	useMediaQuery,
} from '@material-ui/core';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import BigNumber from 'bignumber.js';
import {
	PaymentDialogComponent,
	SignatureDialog,
} from 'components/Dialog/PaymentDialogComponent';
import { BillFooter } from 'components/Footer/BillFooter';
import { MainHeaderCustomer } from 'components/Layout/MainHeaderCustomer';
import { OrderItemList } from 'components/Order/OrderItemList';
import { OrderSubheader } from 'components/Subheader/OrderSubheader';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	CreditCardType,
	GetOrderItemDocument,
	GetAllOrderItemDocument,
	OrderItemStatus,
	PaymentClass,
	useCreateOnlinePaymentMutation,
	useCreatePaymentByQuantityMutation,
	useGetBillSettlementOrderItemQuery,
	useGetCurrentMealPeriodLazyQuery,
	useGetDailyMenuCategoryLazyQuery,
	useGetDefaultPaymentQuery,
	useGetMenuXInhouseListLazyQuery,
	useGetOrderQuery,
	useGetOutletQuery,
	TableStatus,
	OrderItemDiscountStatus,
	useGetRoundingPolicyQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { amtStr } from 'helpers/numFormatter';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, {
	Reducer,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import './billSettlement.scss';

let paymentUrl;
switch (process.env.REACT_APP_ENV) {
	case 'promenade-staging':
		paymentUrl = 'https://promenade-uat.menux.asia';
		break;
	case 'promenade':
		paymentUrl = 'https://promenade.menux.asia';
		break;
	case 'imperial-staging':
		paymentUrl = 'https://imperial-uat.menux.asia';
		break;
	case 'imperial':
		paymentUrl = 'https://imperial.menux.asia';
		break;
	case 'local':
		paymentUrl = `http://localhost:3250`;
		break;
	case 'dev':
		paymentUrl = `https://posx-dev-outlet.ifca.io`;
		break;
	case 'uat':
		paymentUrl = `https://menux-uat-outlet.menux.asia`;
		break;
	default:
		paymentUrl = `https://outlet.menux.asia`;
		break;
}

interface PaymentItemProps {
	key?: typeof String;
	receivedAmount: any;
	cardType: String;
	cardNo: Number;
}

interface FooterProps {
	discount: number;
	subtotal: number;
	serviceCharge: number;
	tax: number;
	total: number;
	rounding: number;
	totalPayment: number;
}

interface FooterAction {
	type: string;
	info?: any;
	maxQuantity?: number;
}

export const Payment = () => {
	useEffect(() => {
		console.log('Payment Customer App');
	}, []);
	const history = useHistory();
	const { pathname } = useLocation();
	const { [`tableID`]: tableID, outletID, orderID, billID, menuID } = useParams<
		Record<string, any>
	>();

	const [openDialog, setOpenDialog] = useState(false);
	const [openSignDialog, setOpenSignDialog] = useState(false);
	const [cardDropdown, setCardDropdown] = useState(false);
	const [cashTextField, setCashTextField] = useState(false);
	const [checkedID, setCheckedID] = useState([]);
	const [values, setValues] = useState();
	const [cardRadioID, setCardRadioID] = useState(null);
	const [forPatronAcc, setForPatronAcc] = useState({
		room: null,
		member: null,
	});
	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);

	const { globalState }: any = useContext(AppContext as any);

	useEffect(() => {
		setCardRadioID(
			getDefaultPayment?.filter(x => x?.paymentClass == PaymentClass.Credit)[0]
				?.ID,
		);
		setForPatronAcc({
			room: getDefaultPayment?.filter(
				x => x?.paymentClass == PaymentClass.Room,
			)[0]?.ID,
			member: getDefaultPayment?.filter(
				x => x?.paymentClass == PaymentClass.Member,
			)[0]?.ID,
		});
	}, [openDialog]);

	//GET CURRENT MEAL PERIOD
	//GET CURRENT MEAL PERIOD

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const [openPayment, setOpenPayment] = useState<boolean>(false);
	const [openSignature, setOpenSignature] = useState<boolean>(false);
	const [openChange, setOpenChange] = useState<boolean>(false);

	const [billItem, setBillItem] = useState<Set<any>>(new Set());

	const [selectedOrderItems, setSelectedOrderItems] = useState({});
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [negOrderItem, setNegOrderItem] = useState({});
	const [radioError, setRadioError] = useState(false);
	const [mode, setMode] = useState('payment');

	const [billInput, setBillInput] = useState({} as any);
	const [paymentInput, setPaymentInput] = useState({} as any);
	const [selectedRoom, setSelectedRoom] = useState({} as any);
	const [selectedOfficer, setSelectedOfficer] = useState({} as any);
	const [selectedStaff, setSelectedStaff] = useState({} as any);
	const [selectedDebtor, setSelectedDebtor] = useState({} as any);
	const [defaultRoom, setDefaultRoom] = useState({} as any);
	const [defaultDebtor, setDefaultDebtor] = useState({} as any);
	const [defaultOfficer, setDefaultOfficer] = useState({} as any);
	const [defaultStaff, setDefaultStaff] = useState({} as any);
	// const [defaultDebtorCC, setDefaultDebtorCC] = useState({} as any);

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
			loadOutletDailyMenuCategory({
				variables: {
					outletID: outletID,
					mealPeriodID: getCurrentMealPeriod?.ID,
				},
			});
		},
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	const {
		called: orderCalled,
		loading: orderLoading,
		error: orderError,
		data: { getOrder } = { getOrder: [] },
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: {
			ID: orderID,
		},
	});

	const {
		data: { getRoundingPolicy } = { getRoundingPolicy: [] },
		loading: getRoundingPolicyLoading,
		called: getRoundingPolicyCalled,
	} = useGetRoundingPolicyQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});

	const status = getOrder[0]?.table?.status;

	useEffect(() => {
		if (status === TableStatus?.Available) {
			history.push(`/menu/patron-app/invalid-qr`);
		}
	}, [status]);

	const {
		called: paymentCalled,
		loading: paymentLoading,
		error: paymentError,
		data: { getDefaultPayment } = { getDefaultPayment: [] },
	} = useGetDefaultPaymentQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, orderByAsc: 'paymentClass' },
	});

	//getBillSettlementOrderItem
	const [itemArray, setItemArray] = useState([]);

	const {
		data: { getBillSettlementOrderItem } = { getBillSettlementOrderItem: [] },
		loading: getBillSettlementOrderItemLoading,
	} = useGetBillSettlementOrderItemQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderID: orderID,
		},
		onCompleted: ({ getBillSettlementOrderItem }) => {
			let unpaidOrderItem = getBillSettlementOrderItem?.filter(
				item => item?.billID === null,
			);
			let temp = {};
			unpaidOrderItem?.map(el => {
				let totalTax = 0;
				el?.orderItemTax?.map(x => {
					totalTax += x?.taxAmount;
				});

				const activeOID = el?.orderItemDiscount?.filter(
					i => i?.status === OrderItemDiscountStatus?.Active,
				)[0];

				let totalOIDTax = 0;
				el?.orderItemDiscountTax?.map(v => {
					totalOIDTax += v?.taxAmount;
				});

				let totalODITax = 0;
				el?.orderDiscountItemTax?.map(v => {
					totalODITax += v?.taxAmount;
				});

				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID].quantity += el?.quantity * -1;
						temp[el?.refID].amount += el?.amount;
						temp[el?.refID].taxAmount += totalTax;

						temp[el?.refID].orderItemDiscBaseAmount += activeOID?.baseAmount;
						temp[el?.refID].orderItemDiscTaxAmount += totalOIDTax;

						temp[el?.refID].orderDiscountItemBaseAmount +=
							el?.orderDiscountItem?.baseAmount;
						temp[el?.refID].orderDiscountItemTaxAmount += totalODITax;
					} else {
						temp[el?.refID] = {
							quantity: el?.quantity * -1,
							amount: el?.amount * -1,
							taxAmount: totalTax * -1,

							orderItemDiscBaseAmount:
								isNaN(activeOID?.baseAmount * -1) === true
									? 0
									: activeOID?.baseAmount * -1,
							orderItemDiscTaxAmount:
								isNaN(totalOIDTax * -1) === true ? 0 : totalOIDTax * -1,

							orderDiscountItemBaseAmount:
								isNaN(el?.orderDiscountItem?.baseAmount * -1) === true
									? 0
									: el?.orderDiscountItem?.baseAmount * -1,
							orderDiscountItemTaxAmount:
								isNaN(totalODITax * -1) === true ? 0 : totalODITax * -1,

							orderItemOptions: [],
						};

						// Loop through the orderItemOptionArray
						for (let i = 0; i < el?.orderItemOption?.length; i++) {
							const option = el?.orderItemOption[i];
							const optionItem = el?.orderItemOption[i]?.orderItemOptionItem;
							let optionData;
							if (optionItem) {
								optionItem?.map(x => {
									let totalTax = 0;
									x?.orderItemOptionItemTax?.map(v => {
										totalTax += v?.taxAmount;
									});

									let totalOptItemOIDTax = 0;
									x?.orderItemOptionItemDiscountTax?.map(v => {
										totalOptItemOIDTax += v?.taxAmount;
									});

									let totalOptItemODITax = 0;
									x?.orderDiscountItemOptionTax?.map(v => {
										totalOptItemODITax += v?.taxAmount;
									});

									optionData = {
										optionID: option?.optionID,
										orderItemOptionItem: {
											basePrice: x?.basePrice * -1,
											taxAmount: totalTax * -1,
											discountBaseAmount: x?.discountBaseAmount * -1,
											itemDiscountTaxAmount: totalOptItemOIDTax * -1,
											discountItemTaxAmount: totalOptItemODITax * -1,
										},
									};
								});
								temp[el?.refID].orderItemOptions.push(optionData);
							}
						}
					}
				}
			});

			setNegOrderItem({ ...temp });

			handleCheckboxAll(
				true,
				unpaidOrderItem,
				// unpaidOrderItem?.filter(v => {
				// 	return v?.quantity - (temp[v?.ID] ?? 0) > 0;
				// }),
				temp,
			);

			setItemArray(unpaidOrderItem?.filter(list2 => list2.quantity > 0));
		},
	});

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [
		createPaymentByQuantity,
		{ loading: paymentQuantityLoading },
	] = useCreatePaymentByQuantityMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: ({ paymentByQuantity }) => {
			history.push(
				`/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${menuID}/summary/confirm-order/payment/${paymentByQuantity?.ID}/receipt`,
			);
			setRadioError(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const [
		createOnlinePayment,
		{ loading: createOnlinePaymentLoading },
	] = useCreateOnlinePaymentMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: ({ createOnlinePayment }) => {},
	});

	const initialState: FooterProps = {
		discount: 0,
		subtotal: 0,
		serviceCharge: 0,
		tax: 0,
		total: 0,
		rounding: 0,
		totalPayment: 0,
	};

	const reducer: Reducer<FooterProps, FooterAction> = (state, action) => {
		const subtotalAmount = action?.info?.totalAmount / action?.maxQuantity;

		const serviceChargeAmount =
			action?.info?.totalServiceCharge / action?.maxQuantity;

		const taxAmount = action?.info?.totalTax / action?.maxQuantity;

		const totalAmount = action?.info?.totalAmount / action?.maxQuantity;

		switch (action.type) {
			case 'remove':
				return {
					...state,
					subtotal: new BigNumber(state?.subtotal)
						.minus(subtotalAmount)
						.decimalPlaces(2)
						.toNumber(),

					serviceCharge: new BigNumber(state?.serviceCharge)
						.minus(serviceChargeAmount)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.minus(taxAmount)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.minus(totalAmount)
						.minus(serviceChargeAmount)
						.minus(taxAmount)
						.decimalPlaces(2)
						.toNumber(),
				};

			case 'add':
				return {
					...state,
					subtotal: new BigNumber(state?.subtotal)
						.plus(subtotalAmount)
						.decimalPlaces(2)
						.toNumber(),

					serviceCharge: new BigNumber(state?.serviceCharge)
						.plus(serviceChargeAmount)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.plus(taxAmount)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.plus(totalAmount)
						.plus(serviceChargeAmount)
						.plus(taxAmount)
						.decimalPlaces(2)
						.toNumber(),
				};
			case 'all':
				return {
					...state,
					discount: new BigNumber(state?.discount)
						.plus(action?.info?.discount)
						.decimalPlaces(2)
						.toNumber(),
					subtotal: new BigNumber(state?.subtotal)
						.plus(action?.info?.subtotal)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.plus(action?.info?.tax)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.plus(action?.info?.total)
						.decimalPlaces(2)
						.toNumber(),

					rounding: new BigNumber(state?.rounding)
						.plus(action?.info?.rounding)
						.decimalPlaces(2)
						.toNumber(),

					totalPayment: new BigNumber(state?.totalPayment)
						.plus(action?.info?.totalPayment)
						.decimalPlaces(2)
						.toNumber(),
				};

			//Handling for when a single item is unchecked

			case 'addItem':
				return {
					...state,
					subtotal: new BigNumber(state?.subtotal)
						.plus(action?.info?.subtotal)
						.decimalPlaces(2)
						.toNumber(),
					serviceCharge: new BigNumber(state?.serviceCharge)
						.plus(action?.info?.serviceCharge)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.plus(action?.info?.tax)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.plus(action?.info?.total)
						.decimalPlaces(2)
						.toNumber(),
				};

			case 'removeItem':
				return {
					...state,
					subtotal: new BigNumber(state?.subtotal)
						.minus(action?.info?.subtotal)
						//multiply with ratio
						.decimalPlaces(2)
						.toNumber(),
					serviceCharge: new BigNumber(state?.serviceCharge)
						.minus(action?.info?.serviceCharge)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.minus(action?.info?.tax)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.minus(action?.info?.total)
						.decimalPlaces(2)
						.toNumber(),
				};

			default:
				return initialState;
		}
	};

	const [footerState, dispatch] = useReducer(reducer, initialState);

	//yupppp
	const yupSchema = yup.object().shape({
		receivedAmount: yup
			.number()
			// .integer()
			.min(footerState?.total, `Amount cannot be less than bill amount`)
			.typeError('Amount cannot be empty')
			.nullable(),
	});

	const {
		register,
		control,
		handleSubmit,
		errors,
		clearErrors,
		watch,
		setValue,
	} = useForm<PaymentItemProps>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	useEffect(() => {
		setSelectedOrder(getOrder?.filter(order => order?.ID === orderID)[0]);
	}, [getOrder, orderID]);

	const handleChangeOrder = (e, obj) => {
		history.push({
			pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${obj?.ID}/bill-settlement`,
		});
	};

	//Check if voided order item exists

	const checkVoidedItem = (stateVariable, directVariable, ID) => {
		if (stateVariable[ID]) {
			return stateVariable[ID];
		} else {
			return directVariable[ID];
		}
	};

	const handleCheckboxAll = (e: any, nArr: any[], tempVariable?: any) => {
		//calculations
		let subtotalFooter = 0,
			baseAmount = 0,
			serviceChargeFooter = 0,
			serviceCharge = 0,
			oidSC = 0,
			odiSC = 0,
			taxFooter = 0,
			tax = 0,
			oidTax = 0,
			odiTax = 0,
			totalFooter = 0,
			discount = 0,
			oid = 0,
			odi = 0,
			roundingAmount = 0,
			totalOptItm = 0,
			discOptItm = 0,
			optTaxAmount = 0,
			optServiceCharge = 0,
			discOptTaxAmount = 0,
			discOptServiceCharge = 0;

		let tempSet = billItem;

		function generateRoundingAmount(
			totalFooter: number,
			roundingPolicyAmount: number,
		): number {
			const tempCents = totalFooter - Math.floor(totalFooter); // 16.24 - 16.00 = 0.24

			const cents = Math.round((tempCents + Number.EPSILON) * 100) / 100; // 0.24

			const roundingMulti = Math.round(
				(cents * 100) / (roundingPolicyAmount! * 100), // (0.24 * 100) / (0.05 * 100) = 24 / 5 = Math.round(4.8) = 5
			);

			const roundedAmount =
				getRoundingPolicy[0]?.inUse === true
					? Math.floor(totalFooter) + roundingPolicyAmount! * roundingMulti
					: totalFooter; // 16.00 + 0.05 * 5 = 16.

			const roundingAmount = (totalFooter - roundedAmount) * -1; // 16.24 - 16.25 = 0.01

			return Number(roundingAmount.toFixed(2));
		}

		if (e?.target?.checked || e === true) {
			nArr?.map(v => {
				//Checkbox logic
				selectedOrderItems[v.ID] = {
					showQuantity: true,
					paymentQuantity:
						v?.quantity -
						(negOrderItem?.[v?.ID]?.quantity ??
							tempVariable[v?.ID]?.quantity ??
							0),
					maxQuantity:
						v?.quantity -
						(negOrderItem?.[v?.ID]?.quantity ??
							tempVariable[v?.ID]?.quantity ??
							0),
				};

				//Calculations

				//overall option item important field to sum up
				const sumOptionItemOverallField = (
					items,
				): {
					basePrice: number;
					taxAmount: number;
					discountBaseAmount: number;
					itemDiscountTaxAmount: number;
					discountItemTaxAmount: number;
				} => {
					let totalBasePriceOpt = 0;
					let totalTaxAmountOpt = 0;
					let totalDiscBaseAmountOpt = 0;
					let totalItemDiscTaxAmountOpt = 0;
					let totalDiscItemTaxAmountOpt = 0;
					for (const item of items) {
						if (item.orderItemOption) {
							for (const option of item.orderItemOption) {
								if (option.orderItemOptionItem) {
									for (const optionItem of option.orderItemOptionItem) {
										let totalTax = 0;
										optionItem?.orderItemOptionItemTax?.map(x => {
											totalTax += x?.taxAmount;
										});

										let totalOptItemOIDTax = 0;
										optionItem?.orderItemOptionItemDiscountTax?.map(x => {
											totalOptItemOIDTax += x?.taxAmount;
										});

										let totalOptItemODITax = 0;
										optionItem?.orderDiscountItemOptionTax?.map(v => {
											totalOptItemODITax += v?.taxAmount;
										});

										totalBasePriceOpt += optionItem.price;
										totalTaxAmountOpt += totalTax;
										totalDiscBaseAmountOpt += optionItem.discountBaseAmount;
										totalItemDiscTaxAmountOpt += totalOptItemOIDTax;
										totalDiscItemTaxAmountOpt += totalOptItemODITax;
									}
								}
							}
						}
					}
					return {
						basePrice: totalBasePriceOpt,
						taxAmount: parseFloat(totalTaxAmountOpt.toFixed(2)),
						discountBaseAmount: totalDiscBaseAmountOpt,
						itemDiscountTaxAmount: parseFloat(
							totalItemDiscTaxAmountOpt.toFixed(2),
						),
						discountItemTaxAmount: parseFloat(
							totalDiscItemTaxAmountOpt.toFixed(2),
						),
					};
				};

				const totalOptItmOverallAmount = sumOptionItemOverallField(nArr);

				optTaxAmount = totalOptItmOverallAmount?.taxAmount;

				discOptTaxAmount =
					totalOptItmOverallAmount?.itemDiscountTaxAmount +
					totalOptItmOverallAmount?.discountItemTaxAmount;

				totalOptItm =
					totalOptItmOverallAmount?.basePrice +
					totalOptItmOverallAmount?.taxAmount;

				discOptItm =
					totalOptItmOverallAmount?.discountBaseAmount +
					totalOptItmOverallAmount?.itemDiscountTaxAmount +
					totalOptItmOverallAmount?.discountItemTaxAmount;

				//Discount
				const orderItemDiscount = v?.orderItemDiscount?.filter(
					x => x?.status === OrderItemDiscountStatus.Active,
				)[0];
				oid += orderItemDiscount?.baseAmount || 0;
				odi += v?.orderDiscountItem?.baseAmount || 0;

				//Subtotal
				// subtotalFooter += v?.totalInfo?.totalAmount;
				// console.log(v, 'V');

				baseAmount += v?.amount;

				// * ((v?.quantity - (tempVariable[v?.ID] ?? 0)) / v?.quantity) ||
				// 	v?.totalInfo?.totalAmount,

				//Tax footer
				// taxFooter += v?.totalInfo?.totalTax;

				let totalTax = 0;
				v?.orderItemTax?.map(x => {
					totalTax += x?.taxAmount;
				});

				let totalOIDTax = 0;
				v?.orderItemDiscountTax?.map(x => {
					totalOIDTax += x?.taxAmount;
				});

				let totalODITax = 0;
				v?.orderDiscountItemTax?.map(x => {
					totalODITax += x?.taxAmount;
				});

				tax += totalTax;
				oidTax += totalOIDTax || 0;
				odiTax += totalODITax || 0;
				taxFooter = tax + optTaxAmount - oidTax - odiTax - discOptTaxAmount;

				discount =
					oid > 0
						? oid + oidTax + discOptItm
						: odi + odiTax + odiSC + discOptItm;
				subtotalFooter = baseAmount + tax + totalOptItm;

				console.log('apa', baseAmount, tax, totalOptItm, subtotalFooter);

				// * ((v?.quantity - (tempVariable[v?.ID] ?? 0)) / v?.quantity) ||
				// 	v?.totalInfo?.totalTax,
				tempSet.add(v.ID);
			});

			totalFooter = subtotalFooter - discount;
			roundingAmount =
				getRoundingPolicy[0]?.inUse === true
					? generateRoundingAmount(
							totalFooter,
							getRoundingPolicy[0]?.smallestDenomination,
					  )
					: 0;

			setBillItem(new Set(Array.from(tempSet)));

			dispatch({
				type: 'all',
				info: {
					discount: discount,
					subtotal: subtotalFooter,
					tax: taxFooter,
					total: totalFooter + roundingAmount,
					rounding: roundingAmount,
					totalPayment: totalFooter,
					serviceCharge: serviceChargeFooter,
				},
			});
		} else {
			setSelectedOrderItems({});
			setBillItem(new Set());

			dispatch({
				type: 'reset',
			});
		}
	};

	//Signature Canvas

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	//discount
	const orderDiscount = selectedOrder?.orderDiscount?.amount;

	const [
		loadOutletDailyMenuCategory,
		{
			loading: outletDailyMenuCategoryLoading,
			called: outletDailyMenuCategoryCalled,
			data: { getOutletDailyMenuCategory } = { getOutletDailyMenuCategory: [] },
		},
	] = useGetDailyMenuCategoryLazyQuery({
		fetchPolicy: 'network-only',
	});

	const handleQuantityChange = (
		mode: string,
		ID: any,
		maxQuantity: number, //max quantity after deducting negative items
		totalInfo: any,
	) => {
		let tempSet = new Set();
		tempSet = billItem;

		if (mode === 'add') {
			if (selectedOrderItems[ID]?.paymentQuantity < maxQuantity) {
				dispatch({
					type: 'add',
					info: totalInfo,
					maxQuantity: maxQuantity,
				});
				setSelectedOrderItems({
					...selectedOrderItems,
					[ID]: {
						showQuantity: true,
						paymentQuantity: selectedOrderItems[ID].paymentQuantity + 1,
						maxQuantity: maxQuantity,
					},
				});
			}
		} else {
			if (selectedOrderItems[ID].paymentQuantity === 0) {
				setSelectedOrderItems({
					...selectedOrderItems,
					[ID]: {
						showQuantity: false,
						paymentQuantity: 0,
						maxQuantity: maxQuantity,
					},
				});
				tempSet.delete(ID);
			} else {
				dispatch({
					type: 'remove',
					info: totalInfo,
					maxQuantity: maxQuantity,
				});
				setSelectedOrderItems({
					...selectedOrderItems,
					[ID]: {
						showQuantity: true,
						paymentQuantity: selectedOrderItems[ID].paymentQuantity - 1,
						maxQuantity: maxQuantity,
					},
				});
			}
			setBillItem(tempSet);
		}
	};

	//Calculations for payment input
	let cardType: any = watch('cardType');
	let cardNo: any = watch('cardReference');
	let receivedAmount: any = parseFloat(
		watch('receivedAmount') || footerState?.total,
	);

	let changeAmount = new BigNumber(receivedAmount - footerState?.total)
		.decimalPlaces(2)
		.toNumber();

	const selectedPaymentClass = () => {
		const name = getDefaultPayment?.filter(
			x => x?.ID === watch('defaultPaymentID'),
		)[0]?.paymentClass;
		return name;
	};
	//Payment by quantity input

	const getSelectedOrderItemIDs = () => {
		let temp = Object.keys(selectedOrderItems).filter(
			v => selectedOrderItems[v]?.showQuantity === true,
		);

		return temp?.map(x => {
			return {
				orderItemID: x,
				paymentQuantity: selectedOrderItems[x].paymentQuantity,
			};
		});
	};

	const creditCardEnum = data => {
		let credClass;
		if (data === 'American Express Card') {
			credClass = CreditCardType.AmericanExpressCard;
		} else if (data === 'Diners Card') {
			credClass = CreditCardType.DinersCard;
		} else if (data === 'JCB Card') {
			credClass = CreditCardType.JcbCard;
		} else if (data === 'Master Card') {
			credClass = CreditCardType.Mastercard;
		} else if (data === 'UNION PAY') {
			credClass = CreditCardType.UnionpayCard;
		} else if (data === 'Visa Card') {
			credClass = CreditCardType.VisaCard;
		} else if (data === 'Debit Card') {
			credClass = CreditCardType.DebitCard;
		}
		return credClass;
	};

	const [hotelGuestInfo, setHotelGuestInfo] = useState({} as any);

	useEffect(() => {
		if (selectedRoom?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedRoom);
		} else if (selectedDebtor?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedDebtor);
		} else if (defaultRoom?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultRoom);
		} else if (defaultDebtor?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultDebtor);
		}
	}, [selectedRoom, defaultRoom, defaultDebtor, selectedDebtor]);

	const onSubmit = async (data: any, files?) => {
		const billInput = {
			orderID: orderID,
			outletID: outletID,
			...(selectedPaymentClass() === PaymentClass.Room ||
			selectedPaymentClass() === PaymentClass.Member ||
			selectedPaymentClass() === PaymentClass.Debtor
				? { file: files }
				: null),
		};

		const paymentInput = {
			outletID: outletID,
			amount: footerState?.total,
			creditCardType:
				cardType?.GuestType !== null || cardType?.GuestType !== undefined
					? creditCardEnum(cardType?.GuestType)
					: cardType,
			cardNo: cardNo,
			changeAmount: changeAmount,
			receivedAmount: receivedAmount,
			defaultPaymentID: data?.defaultPaymentID,
			paymentClass: selectedPaymentClass(),
			registrationID:
				selectedRoom?.RegistrationID ??
				defaultRoom?.RegistrationID ??
				defaultDebtor?.RegistrationID ??
				selectedDebtor?.RegistrationID ??
				cardType?.RegistrationID,
			hotelGuestInfo: {
				BookingNo: hotelGuestInfo?.BookingNo,
				FullName: hotelGuestInfo?.GuestName,
				RoomNo: hotelGuestInfo?.RoomNo,
				GuestType: hotelGuestInfo?.GuestType,
				ArrivalDate: hotelGuestInfo?.ArrivalDate,
				DepartureDate: hotelGuestInfo?.DepartureDate,
			},
		};

		const ref = selectedOrder?.docNo;

		const billLedgerInput = {
			HotelID: getOutlet[0]?.outletChannel?.hotelID,
			RegistrationID:
				selectedRoom?.RegistrationID ?? defaultRoom?.RegistrationID,
			OutletCode: getOutlet[0]?.outletCode,
			IncidentalChargeID: '',
			Reason: 'MenuX - Charge To Room',
			TrxDate: new Date(),
			Qty: 1,
			UnitPrice: footerState?.subtotal,
			TrxAmount: footerState?.total,
			BaseAmount: footerState?.subtotal,
			ServiceCharge: footerState?.serviceCharge,
			TaxAmount: footerState?.tax,
			DiscountAmount: 0,
			IsChargeToRoom: true,
			ReferenceNo: ref,
			CreatedBy: user?.ID,
		};

		const scheduleBillingInput = {
			HotelID: getOutlet[0]?.outletChannel?.hotelID,
			RegistrationID:
				defaultDebtor?.RegistrationID ??
				selectedDebtor?.RegistrationID ??
				cardType?.RegistrationID,
			CardNo: cardNo,
			// BillNo: "",
			BillDate: new Date(),
			BillAmount: footerState?.total,
			// BaseAmount: footerState?.subtotal,
			// DebtorID: "",
			CreatedBy: user?.ID,
		};

		setBillInput(billInput);
		setPaymentInput(paymentInput);

		if (selectedPaymentClass() === PaymentClass.Online) {
			createOnlinePayment({
				variables: {
					redirectUrl: `${paymentUrl}/menu/outlet-app/bill-settlement/online-payment/redirect`,
					payAmount: footerState?.total,
					detail: 'Detail',
					title: `Online Payment`,
					outletID: outletID,
				},
			}).then(i => {
				if (i?.data?.createOnlinePayment?.code === 'SUCCESS') {
					localStorage.setItem(
						'checkoutId',
						i?.data?.createOnlinePayment?.item?.checkoutId,
					);
					localStorage.setItem(
						'paymentInfo',
						JSON.stringify({
							paymentByQuantityInput: getSelectedOrderItemIDs(),
							billInput: billInput,
							paymentInput: paymentInput,
							tableID: tableID,
						}),
					);
					window.location.assign(i?.data?.createOnlinePayment?.item?.url);
					setOpenSnackBar(true);
					setSnackBarMsg(SystemMsgs.gatewayRedirect());
				} else {
					i?.data?.createOnlinePayment?.error?.code !== ''
						? setSnackBarMsg(i.data.createOnlinePayment?.error.message)
						: setSnackBarMsg(SystemMsgs.paymentFailed());
					setOpenSnackBar(true);
				}
			});
		} else {
			createPaymentByQuantity({
				variables: {
					paymentByQuantityInput: getSelectedOrderItemIDs(),
					billInput,
					paymentInput,
					billLedgerInput,
					scheduleBillingInput,
				},
				refetchQueries: [
					{
						query: GetOrderItemDocument,
						variables: { orderID, status: OrderItemStatus.Confirmed },
					},
					{
						query: GetAllOrderItemDocument,
						variables: { orderID },
					},
				],
			});
		}
	};

	// const [memberSearchField, setMemberSearchField] = useState(false);
	// const [hotelSearchField, setHotelSearchField] = useState(false);

	// const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	// const [tempState, setTempState] = useState('');
	// const [hotelTempState, setHotelTempState] = useState('');

	// const [selectedMember, setSelectedMember] = useState({
	// 	selectedAccountNo: getOrder[0]?.patronAccountID,
	// });

	const divChild = (info1, info2) => {
		return (
			<div className="mdDesc bill-ftr-child">
				<span>{info1}</span>
				<span className="right-info">{info2}</span>
			</div>
		);
	};

	console.log('footerState', footerState);

	return (
		<>
			{outletLoading && <Loading />}
			{orderLoading && <Loading />}
			{getBillSettlementOrderItemLoading && <Loading />}
			{outletDailyMenuCategoryLoading && <Loading />}
			{paymentLoading && <Loading />}
			{paymentQuantityLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			{createOnlinePaymentLoading && <Loading />}

			<MainHeaderCustomer
				onClick={() => {
					history.push({
						pathname: `/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${menuID}/summary/confirm-order`,
					});
				}}
				mainBtn="back"
				smTitle="Customer App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Table 03 (HARDCODE)' },
					{ name: 'Bill Settlement', current: true },
				]}
			/>
			<OrderSubheader
				getOrderLoading={orderLoading}
				getOrder={getOrder}
				selectedOrder={selectedOrder}
				orderID={selectedOrder?.ID}
				outletID={outletID}
			/>
			<ContentWrapper customerDynamicInfo>
				{!outletLoading &&
				!orderLoading &&
				!getBillSettlementOrderItemLoading &&
				!getCurrentMealPeriodLoading &&
				!outletDailyMenuCategoryLoading &&
				(itemArray === undefined || itemArray?.length === 0) ? (
					<EmptyList
						title="No confirmed order item found"
						subtitle="Add a order item now."
					/>
				) : itemArray?.length !== 0 ? (
					<>
						<OrderItemList
							outletID={outletID}
							itemArray={itemArray}
							negOrderItem={negOrderItem}
							page="bill-settlement"
							currencyCode={getOutlet[0]?.currencyCode}
						/>
					</>
				) : null

				// (
				// 	<>
				// 		<MsgAlert
				// 			icon={
				// 				<TouchAppIcon style={{ color: '#FFB11F', fontSize: '20px' }} />
				// 			}
				// 			message={'Tick Menu Item To Pay'}
				// 		/>
				// 		<List style={{ backgroundColor: 'white', color: 'black' }}>
				// 			{/* <ListItem style={{ fontSize: '10px', width: '37%' }}>
				// 				<FormControlLabel
				// 					className="bill-checkbox"
				// 					control={
				// 						<Checkbox
				// 							color="primary"
				// 							onChange={e => {
				// 								handleCheckboxAll(e, itemArray, negOrderItem);
				// 							}}
				// 							checked={
				// 								Object.values(selectedOrderItems)?.filter(
				// 									(item: any) => item.paymentQuantity > 0,
				// 								)?.length === itemArray?.length
				// 							}
				// 						/>
				// 					}
				// 					label="Select All"
				// 				/>
				// 			</ListItem> */}

				// 			{itemArray?.map((el, index) => {
				// 				const newEl = {
				// 					totalAmount:
				// 						el?.totalInfo?.totalAmount *
				// 						((el?.quantity - (negOrderItem[el?.ID] ?? 0)) /
				// 							el?.quantity),
				// 					totalBaseAmount:
				// 						el?.totalInfo?.totalBaseAmount *
				// 						((el?.quantity - (negOrderItem[el?.ID] ?? 0)) /
				// 							el?.quantity),
				// 					totalServiceCharge:
				// 						el?.totalInfo?.totalServiceCharge *
				// 						((el?.quantity - (negOrderItem[el?.ID] ?? 0)) /
				// 							el?.quantity),
				// 					totalTax:
				// 						el?.totalInfo?.totalTax *
				// 						((el?.quantity - (negOrderItem[el?.ID] ?? 0)) /
				// 							el?.quantity),
				// 					maxQuantity: el?.quantity - (negOrderItem[el?.ID] ?? 0),
				// 				};
				// 				return (
				// 					<>
				// 						<ListItem>
				// 							<ListItemText
				// 								primary={
				// 									<>
				// 										<Grid container className="mdDesc" wrap="nowrap">
				// 											{/* <Grid item xs={1}>
				// 												<Controller
				// 													as={
				// 														<FormControlLabel
				// 															control={
				// 																<Checkbox
				// 																	style={{
				// 																		marginTop: '-12px',
				// 																	}}
				// 																	name={`paymentItem[${index}].paymentItemID`}
				// 																	value={el?.ID}
				// 																	onChange={e => {
				// 																		handleCheckBox(
				// 																			e,
				// 																			el,
				// 																			newEl.maxQuantity,
				// 																		);
				// 																	}}
				// 																	checked={
				// 																		selectedOrderItems[el?.ID]
				// 																			?.paymentQuantity > 0 ??
				// 																		billItem?.has(el.ID)
				// 																	}
				// 																	color="primary"
				// 																	inputProps={{
				// 																		'aria-label': 'secondary checkbox',
				// 																	}}
				// 																/>
				// 															}
				// 															label={<></>}
				// 														/>
				// 													}
				// 													name={`paymentItem[${index}].paymentItemID`}
				// 													inputRef={register(
				// 														`paymentItem[${index}].paymentItemID`,
				// 													)}
				// 													control={control}
				// 												/>
				// 											</Grid> */}

				// 											<Grid item xs={1}>
				// 												{index + 1 + '.'}
				// 											</Grid>

				// 											<Grid item xs={6}>
				// 												{el?.openItemName ?? el?.menuItem?.name}&nbsp;
				// 												&nbsp;
				// 												{'x'}
				// 												{el?.quantity - negOrderItem[el?.ID] ||
				// 													el?.quantity}
				// 											</Grid>

				// 											{/* <Grid item xs={2}>
				// 												{selectedOrderItems[el?.ID]?.showQuantity ===
				// 												true ? (
				// 													<>
				// 														<IndeterminateCheckBox
				// 															style={{ fontSize: '25px' }}
				// 															className="order-minus"
				// 															onClick={() =>
				// 																handleQuantityChange(
				// 																	'reduce',
				// 																	el?.ID,
				// 																	newEl.maxQuantity,
				// 																	newEl,
				// 																)
				// 															}
				// 														/>
				// 														<span
				// 															className="order-quantity"
				// 															style={{
				// 																fontSize: '20px',
				// 																position: 'relative',
				// 																bottom: '5px',
				// 															}}
				// 														>
				// 															{
				// 																selectedOrderItems[el?.ID]
				// 																	?.paymentQuantity
				// 															}
				// 														</span>

				// 														<AddBox
				// 															style={{
				// 																fontSize: '25px',
				// 																color: '#ff9800',
				// 															}}
				// 															className="order-add"
				// 															onClick={() => {
				// 																handleQuantityChange(
				// 																	'add',
				// 																	el?.ID,
				// 																	newEl.maxQuantity,
				// 																	newEl,
				// 																);
				// 															}}
				// 														/>
				// 													</>
				// 												) : null}
				// 											</Grid> */}

				// 											<Grid
				// 												item
				// 												xs={5}
				// 												style={{
				// 													textAlign: 'right',
				// 												}}
				// 											>
				// 												{/* {`$${amtStr(
				// 													el?.totalInfo?.totalAmount,

				// 													// * ((el?.quantity - negOrderItem[el?.ID]) /
				// 													// 	el?.quantity) ||
				// 													//el?.totalInfo?.totalAmount
				// 												)}`} */}
				// 												{selectedOrderItems[el?.ID]?.showQuantity ===
				// 													true &&
				// 													`$${amtStr(
				// 														Math.abs(
				// 															el?.amount *
				// 																((el?.quantity -
				// 																	(negOrderItem[el?.ID] ?? 0)) /
				// 																	el?.quantity),
				// 														) *
				// 															(selectedOrderItems[el?.ID]
				// 																?.paymentQuantity /
				// 																selectedOrderItems[el?.ID]
				// 																	?.maxQuantity),
				// 													)}`}
				// 											</Grid>
				// 										</Grid>
				// 									</>
				// 								}
				// 								secondary={
				// 									el?.orderItemOption?.length
				// 										? el?.orderItemOption?.map(v => (
				// 												<>
				// 													{v?.orderItemOptionItem?.map(e => (
				// 														<Grid
				// 															container
				// 															className="mdDesc"
				// 															wrap="nowrap"
				// 															style={{
				// 																textDecoration:
				// 																	el?.quantity < 0
				// 																		? 'line-through'
				// 																		: null,
				// 															}}
				// 														>
				// 															<Grid item xs={1}></Grid>
				// 															<Grid item xs={7} className="flex-space">
				// 																{e?.optionItem?.name}
				// 															</Grid>
				// 															<Grid
				// 																item
				// 																xs={4}
				// 																style={{
				// 																	textAlign: 'right',
				// 																}}
				// 															>
				// 																<div>
				// 																	{/* {`$${amtStr(
				// 																		Math.abs(
				// 																			e?.price *
				// 																				((el?.quantity -
				// 																					negOrderItem[el?.ID]) /
				// 																					el?.quantity) || e?.price,
				// 																		),
				// 																	)}`} */}
				// 																	{selectedOrderItems[el?.ID]
				// 																		?.showQuantity === true &&
				// 																		`$${amtStr(
				// 																			Math.abs(
				// 																				e?.price *
				// 																					((el?.quantity -
				// 																						(negOrderItem[el?.ID] ??
				// 																							0)) /
				// 																						el?.quantity),
				// 																			) *
				// 																				(selectedOrderItems[el?.ID]
				// 																					?.paymentQuantity /
				// 																					selectedOrderItems[el?.ID]
				// 																						?.maxQuantity),
				// 																		)}`}
				// 																</div>
				// 															</Grid>
				// 														</Grid>
				// 													))}
				// 												</>
				// 										  ))
				// 										: el?.orderItemSetMenuOption?.map(v => (
				// 												<>
				// 													{v?.orderItemSetMenuOptionItem?.map(e => (
				// 														<Grid
				// 															container
				// 															className="mdDesc"
				// 															style={{
				// 																textDecoration: el?.refID
				// 																	? 'line-through'
				// 																	: null,
				// 															}}
				// 														>
				// 															<Grid item xs={1} />
				// 															<Grid item xs={7} className="flex-space">
				// 																{e?.setMenuOptionItem?.menuItem?.name}
				// 															</Grid>
				// 															<Grid
				// 																item
				// 																xs={4}
				// 																style={{
				// 																	textAlign: 'right',
				// 																	paddingRight: '10px',
				// 																}}
				// 															>
				// 																{/* {amtStr(e?.price)} */}
				// 															</Grid>
				// 														</Grid>
				// 													))}
				// 												</>
				// 										  ))
				// 								}
				// 							/>
				// 						</ListItem>
				// 					</>
				// 				);
				// 			})}
				// 		</List>
				// 	</>
				// )
				}

				<BillFooter
					style={{
						width: isDesktop ? 'calc(100% - 68px)' : '91%',
						left: !isDesktop ? '6px' : null,
						zIndex: 40,
					}}
					subtotal={footerState?.subtotal}
					serviceCharge={footerState?.serviceCharge}
					discount={footerState?.discount}
					tax={footerState?.tax}
					total={footerState?.total}
					outletID={outletID}
					currencyCode={getOutlet[0]?.currencyCode}
					taxSchemeInfo={getOrder[0]?.taxSchemeInfo}
					taxSchemeFooterInfo={getOrder[0]?.taxSchemeFooterInfo}
					rounding={footerState?.rounding}
					orderID={getOrder[0]?.ID}
				/>
			</ContentWrapper>
			<div style={{ paddingTop: '200px' }}></div>

			<PaymentDialogComponent
				paymentType="payment"
				errors={errors}
				setValue={setValue}
				control={control}
				openDialog={openDialog}
				setOpenDialog={setOpenDialog}
				register={register}
				watch={watch}
				getDefaultPayment={getDefaultPayment}
				total={footerState?.total}
				defaultRoom={defaultRoom}
				setDefaultRoom={setDefaultRoom}
				selectedRoom={selectedRoom}
				setSelectedRoom={setSelectedRoom}
				hotelID={getOutlet[0]?.outletChannel?.hotelID}
				outletID={outletID}
				selectedOrder={selectedOrder}
				setDefaultDebtor={setDefaultDebtor}
				defaultOfficer={defaultOfficer}
				defaultStaff={defaultStaff}
				openSignature={openSignature}
				setOpenSignature={setOpenSignature}
				defaultDebtor={defaultDebtor}
				radioError={radioError}
				setRadioError={setRadioError}
				selectedOfficer={selectedOfficer}
				selectedStaff={selectedStaff}
				setDefaultOfficer={setDefaultOfficer}
				setDefaultStaff={setDefaultStaff}
				setSelectedStaff={setSelectedStaff}
				setSelectedOfficer={setSelectedOfficer}
				setHotelGuestInfo={setHotelGuestInfo}
				selectedPaymentClass={selectedPaymentClass}
				handleSubmit={handleSubmit}
				onSubmit={onSubmit}
				selectedDebtor={selectedDebtor}
				setSelectedDebtor={setSelectedDebtor}
			/>

			<Footer
				options={[
					{
						name: 'Make Payment',
						onClick: () => {
							if (footerState?.total !== 0) {
								setMode(mode);
								setOpenDialog(true);
								// setOpenChangeDialog(true);
							}
						},
						color: footerState?.total ? 'primary' : '#faf2e8',
						disabled: footerState?.total === 0,
						props: {
							type: 'submit',
						},
					},
				]}
			/>

			<SignatureDialog
				setOpenDialog={setOpenPayment}
				total={footerState?.total}
				handleSubmit={handleSubmit}
				onSubmit={onSubmit}
				openSignature={openSignature}
				setOpenSignature={setOpenSignature}
				selectedOrder={selectedOrder}
			/>
			{/* 
			<ChangeDialog
				handleSubmit={handleSubmit}
				mode={mode}
				onSubmit={onSubmit}
				setOpenChange={setOpenChange}
				openChange={openChange}
				selectedOrder={selectedOrder}
				total={footerState?.total}
			/> */}
		</>
	);
};
