import React, { useContext } from 'react';
import { Switch } from 'react-router-dom';
import AppContext from '../Store/AppContext';
import { PrivateRoute } from './PrivateRoute';
import RouteSuspense from './RouteSuspense';

const Routes = () => {
	const { globalState } = useContext(AppContext as any);
	return (
		<Switch>
			{globalState.routes.map((el, index) => {
				return (
					<PrivateRoute key={index} {...el.props}>
						<RouteSuspense>{el.component}</RouteSuspense>
					</PrivateRoute>
				);
			})}
		</Switch>
	);
};

export default Routes;
