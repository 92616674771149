import { AppBar, AppBarProps } from '@material-ui/core';
import { Avatar, Breadcrumbs, useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import React from 'react';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import '../../assets/styles/headerCustomer.scss';
import { SwapHoriz } from '@material-ui/icons';

interface IHeader {
	onClick?: any;
	onClickAction?: any;
	routeSegments?: any;
	mainBtn?: string;
	sideBtn?: string;
	title?: string;
	smTitle?: string;
	currency?: any;
	rightRouteSegments?: any;
	customIcon?: string;
	rightLabel?: any;
	position?: AppBarProps['position'];
	dropDownOptions?: any;
	fixMainHeader?: boolean;
}

export const MainHeaderCustomer = (props: IHeader) => {
	const {
		mainBtn,
		routeSegments,
		currency,
		customIcon,
		dropDownOptions,
		fixMainHeader,
		onClick,
		onClickAction,
		position,
		rightLabel,
		rightRouteSegments,
		sideBtn,
		smTitle,
		title,
	} = props;

	const handleButtonType = type => {
		let icon;
		switch (type) {
			case 'menu':
				icon = <MenuIcon />;
				break;
			case 'back':
				icon = <KeyboardArrowLeftIcon />;
				break;
			case 'close':
				icon = <CloseIcon />;
				break;
			case 'switch':
				icon = <SwapHoriz />;
				break;
			case 'exit':
				icon = <ExitToAppIcon className="transform-180" />;
				break;
			case 'customIcon':
				icon = <img src={customIcon} />;
				break;
			case 'image':
				icon = (
					<Avatar
						variant="square"
						alt="ifca"
						src={customIcon}
						style={{ width: '30px', height: '30px' }}
						imgProps={{
							loading: 'lazy',
						}}
					/>
				);
				break;

			default:
				icon = null;
				break;
		}
		return icon;
	};

	return (
		<AppBar
			position="fixed"
			className="desktop-header-customer"
			style={{ backgroundColor: '#ff9800' }}
		>
			<Toolbar>
				<div className="container">
					<IconButton
						className="first-btn"
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={onClick}
					>
						{handleButtonType(mainBtn)}
					</IconButton>
					<div className="header-text">
						<div className="authorizer">{smTitle}</div>

						<div className="domain">{title}</div>
					</div>
				</div>
				<div style={{ width: '100%', display: 'flex' }}>
					<Breadcrumbs
						className="breadcrumbs"
						separator="/"
						aria-label="breadcrumb"
					>
						{routeSegments.map((el, index) => (
							<span
								className="breadcrumb-title"
								key={index}
								style={{
									fontWeight: el.current ? 'bolder' : 'normal',
									lineHeight: '1rem',
								}}
							>
								{el.current ? el.name : '...'}
							</span>
						))}
					</Breadcrumbs>
					{rightRouteSegments && (
						<Breadcrumbs
							className="breadcrumbs-right"
							separator="/"
							aria-label="breadcrumb"
						>
							{rightRouteSegments?.map((el, index) => {
								return (
									<span
										className="breadcrumb-title-right"
										key={index}
										style={{ fontWeight: el.current ? 'bolder' : 'normal' }}
									>
										{' '}
										{el.name}
									</span>
								);
							})}
						</Breadcrumbs>
					)}
				</div>
				{dropDownOptions}
			</Toolbar>
		</AppBar>
	);
};
