import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { convertDate24Hrs } from 'helpers/hooks/formatDate';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import React from 'react';
import './orderItemReceipt.scss';
import { Grid } from '@material-ui/core';

interface orderItemReceiptProps {
	outletName: string;
	data: any;
	printerName?: any;
	orderItemData?: any;
	voidItemData?: any;
	voidQuantity?: any;
	reasonCode?: any;
	remark?: any;
	hidden?: boolean;
	mode?: string;
}

export const OrderItemReceipt = (props: orderItemReceiptProps) => {
	const {
		outletName,
		data,
		printerName,
		orderItemData,
		voidItemData,
		voidQuantity,
		reasonCode,
		remark,
		hidden,
		mode,
	} = props;

	const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

	return (
		<div
			id={printerName}
			style={
				hidden
					? {
							zIndex: -40,
							top: 0,
							left: 0,
							position: 'fixed',
							width: '300px',
							fontSize: '18px',
					  }
					: {
							zIndex: 40,
							position: 'relative',
					  }
			}
		>
			<CardContents>
				{mode !== 'void' ? (
					<div className="content-wrap full" style={{ marginRight: '5px' }}>
						<div className={'mdTitle-bold'} style={{ paddingBottom: '5px' }}>
							Outlet: &nbsp;
							<span className="lgDesc">{outletName}</span>
						</div>

						<div
							className="mdTitle-bold flex-space"
							style={{ paddingBottom: '5px' }}
						>
							{`Order No: ${data?.docNo}`}
							<div className="mdTitle-bold right-info">{`Cover: ${data?.pax}`}</div>
						</div>

						<div
							className="mdTitle-bold right-info"
							style={{ paddingBottom: '5px' }}
						>
							{`Table: ${data?.table?.prefix + data?.table?.tableNumber}`}
						</div>

						<div className={'mdTitle-bold'} style={{ paddingBottom: '5px' }}>
							{Array.from(
								new Set(
									orderItemData?.map(x => x?.menuItem?.kitchenPrinter?.name),
								),
							)}
						</div>

						<div className={'mdTitle-bold'} style={{ paddingBottom: '5px' }}>
							{loggedInUser?.name}
							<div className="mdTitle-bold right-info">
								<span className="mdDesc">
									{convertDate24Hrs(data?.createdTs)}
								</span>
							</div>
						</div>
						<hr className="receipt-divider" />

						{orderItemData?.map((v, index) => (
							<>
								<span
									className="desc"
									style={{
										alignSelf: 'center',
										fontSize: '16px',
										marginRight: '10px',
									}}
								>
									{`${v?.menuItem?.name}`}
								</span>

								<span
									className="desc"
									style={{
										marginTop: '10px',
										alignSelf: 'center',
										fontSize: '16px',
									}}
								>{`x${v?.quantity}`}</span>

								<div
									className="flex-space desc text-noflow"
									style={{ alignSelf: 'center', fontSize: '16px' }}
								>
									{v?.specialRequest}
								</div>

								{v?.orderItemOption?.length
									? v?.orderItemOption?.map(x => (
											<>
												{x?.orderItemOptionItem?.map(x => (
													<div
														className="desc flex-space"
														style={{
															color: '#757575',
															fontWeight: 500,
															alignSelf: 'center',
															fontSize: '14px',
														}}
													>
														{x?.optionItem?.name}
													</div>
												))}
											</>
									  ))
									: v?.orderItemSetMenuOption?.map(x => (
											<>
												{x?.orderItemSetMenuOptionItem?.map(e => (
													<div
														className="desc"
														style={{
															color: '#757575',
															fontWeight: 500,
															paddingBottom: '2px',
															fontSize: '14px',
														}}
													>
														{e?.setMenuOptionItem?.menuItem?.name}
													</div>
												))}
											</>
									  ))}
							</>
						))}

						<hr className="receipt-divider" style={{ marginTop: '10px' }} />
					</div>
				) : (
					<div className="content-wrap full" style={{ marginRight: '5px' }}>
						<div
							className={'mdTitle-bold'}
							style={{
								marginBlock: '15px',
								textAlign: 'center',
								color: '#000000',
							}}
						>
							Void Item
						</div>
						<div className={'mdTitle-bold'} style={{ paddingBottom: '5px' }}>
							Outlet: &nbsp;
							<span className="lgDesc">{outletName}</span>
						</div>

						<div
							className="mdTitle-bold flex-space"
							style={{ paddingBottom: '5px' }}
						>
							{`Order No: ${data?.docNo}`}
							<div className="mdTitle-bold right-info">{`Cover: ${data?.pax}`}</div>
						</div>

						<div
							className="mdTitle-bold right-info"
							style={{ paddingBottom: '5px' }}
						>
							{`Table: ${data?.table?.prefix + data?.table?.tableNumber}`}
						</div>

						{voidItemData?.menuItem?.kitchenPrinter !== null && (
							<div className={'mdTitle-bold'} style={{ paddingBottom: '5px' }}>
								Printer: &nbsp;
								{voidItemData?.menuItem?.kitchenPrinter?.name}
							</div>
						)}

						<div className={'mdTitle-bold'} style={{ paddingBottom: '5px' }}>
							{loggedInUser?.name}
							<div className="mdTitle-bold right-info">
								<span className="mdDesc">
									{convertDate24Hrs(new Date().toISOString())}
									{/* {new Date().toISOString()} */}
								</span>
							</div>
						</div>
						<hr className="receipt-divider" />

						<div
							className="desc flex-space"
							style={{
								// paddingBottom: '5px',
								alignSelf: 'center',
								fontSize: '16px',
							}}
						>
							{`${voidItemData?.menuItem?.name}`}
							<div
								className="desc right-info"
								style={{
									// paddingBottom: '5px',
									alignSelf: 'center',
									fontSize: '16px',
								}}
							>{`x ${voidQuantity}`}</div>
						</div>

						{voidItemData?.orderItemOption?.length
							? voidItemData?.orderItemOption?.map(x => (
									<>
										{x?.orderItemOptionItem?.map(x => (
											<div
												className="desc flex-space"
												style={{
													color: '#000000',
													fontWeight: 500,
													alignSelf: 'center',
													fontSize: '14px',
												}}
											>
												{x?.optionItem?.name}
											</div>
										))}
									</>
							  ))
							: voidItemData?.orderItemSetMenuOption?.map(x => (
									<>
										{x?.orderItemSetMenuOptionItem?.map(e => (
											<div
												className="desc"
												style={{
													color: '#000000',
													fontWeight: 500,
													paddingBottom: '2px',
													fontSize: '14px',
												}}
											>
												{e?.setMenuOptionItem?.menuItem?.name}
											</div>
										))}
									</>
							  ))}

						<div className="session" style={{ color: '#000000' }}>
							<div className="flex session">
								<div
									className="flex-space desc"
									style={{ alignSelf: 'center' }}
								>
									Reason :
									<span style={{ fontSize: '18px' }}>
										{reasonCode?.toUpperCase()}
									</span>
								</div>
							</div>
						</div>
						{remark && (
							<div
								className="session"
								style={{ color: '#000000', marginTop: '2px' }}
							>
								<div className="flex session">
									<div
										className=" flex-space desc"
										style={{ alignSelf: 'center' }}
									>
										<span>{toProperCase(remark)}</span>
									</div>
								</div>
							</div>
						)}

						<hr className="receipt-divider" style={{ marginTop: '10px' }} />
					</div>
				)}
			</CardContents>
		</div>
	);
};
