import { getAccessToken } from 'AccessToken';
import React, { useContext } from 'react';
import { Route, useHistory, useParams } from 'react-router-dom';
import AppContext from '../Store/AppContext';

export const PrivateRoute = ({ children, ...rest }) => {
	const { globalState, dispatch } = useContext(AppContext as any);
	let history = useHistory();
	const AccessToken = getAccessToken();
	const { token }: any = useParams();

	return (
		<Route
			{...rest}
			render={
				({ location }) => children
				// AccessToken ? (
				// 	children
				// ) : (
				// 	<Redirect
				// 		to={{
				// 			pathname: '/login',
				// 			state: { from: location },
				// 		}}
				// 	/>
				// )
			}
		/>
	);
};
