import React, { useState, MouseEvent } from 'react';
/**
 * @param MenuOption
 * handle moreoption icon on listing page
 */
export const useMenuOption = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [menu, setMenu] = useState({ ID: '', index: 0, obj: null });
	const handleClick = (
		event: MouseEvent<HTMLButtonElement>,
		ID: string,
		index: number,
		obj?: any,
	) => {
		setAnchorEl(event.currentTarget);
		setMenu({ ID, index, obj });
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return {
		anchorEl,
		menu,
		handleClick,
		handleClose,
	};
};
