import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MultipleItemsCarousel from '@ifca-root/react-component/src/components/Carousel/MultipleItemsCarousel';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import { FooterWithTotal } from '@ifca-root/react-component/src/components/Footer/FooterWithTotal';
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/VoiceTextField';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { useMultiPurposeFileUpload } from '@ifca-root/react-component/src/utils/hooks/useMultiPurposeFileUpload';
import {
	Checkbox,
	FormControl,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Radio,
	RadioGroup,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { NoImage } from 'assets';
import BigNumber from 'bignumber.js';
import { MainHeaderCustomer } from 'components/Layout/MainHeaderCustomer';
import { OrderSubheader } from 'components/Subheader/OrderSubheader';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { SystemMsgs } from 'containers/helper/SystemMsg';
import {
	GetOrderDocument,
	GetOrderSummaryDocument,
	OrderItemStatus,
	useConfirmOrderItemMutation,
	useGetCurrentMealPeriodLazyQuery,
	useGetOrderQuery,
	useGetOrderSummaryQuery,
	useGetOutletQuery,
	useGetUomQuery,
	usePostPrintDataMutation,
	useUpdateOrderItemWithOptionsMutation,
	useUpdateOrderItemWithSetMenuOptionsMutation,
	TableStatus,
} from 'generated/graphql';
import { formatTimeto12h } from 'helpers/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { amtStr } from 'helpers/numFormatter';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import './order.scss';
import * as htmlToImage from 'html-to-image';
import { OrderItemReceipt } from './OrderReceipt/OrderItemReceipt';
import { Overlay } from '@ifca-root/react-component/src/components/Overlay/Overlay';
import { printReceipt } from './ReceiptFunctions/ReceiptFunctions';
interface OptionItem {
	remark: string;
	name: string;
	optionItem: String;
	openPrice: number;
	specialRequest: String;
	option: any[];
}

export const OrderSummary = () => {
	useEffect(() => {
		console.log('OrderSummary Customer App');
	}, []);
	const history = useHistory();
	const { globalState, dispatch }: any = useContext(AppContext as any);

	const { menuID, outletID, tableID, orderID } = useParams<
		Record<string, any>
	>();

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const [currMenu, setCurrMenu] = useState<any>();
	const [quantity, setQuantity] = useState(1); // this should be changed accordingly
	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);

	const editData = useState();
	//const editData: any = location?.state;
	const [text, setText] = useState();
	const [record, setRecord] = useState(false);
	const [audioData, setAudioData] = useState(null);
	const [audioInput, setAudioInput] = useState('');
	const { files, handleSingleRemark } = useMultiPurposeFileUpload();

	const [openDialog, setOpenDialog] = useState(false);
	const [editSetMenuDialog, setEditSetMenuDialog] = useState(false);
	const [takeAway, setTakeAway] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		if (currMenu?.specialRequest) {
			setValue('specialRequest', currMenu?.specialRequest);
		}
	}, [currMenu]);

	const yupSchema = yup.object().shape({
		openItem: currMenu?.isOpenItem
			? yup
					.string()
					.required('Open item name is required')
					.trim()
			: null,
		openPrice: currMenu?.isOpenItem
			? yup.number().required('Open item price is required')
			: null,
	});

	const {
		control,
		register,
		unregister,
		handleSubmit,
		setValue,
		watch,
		reset,
		formState: { dirtyFields },
		clearErrors,
		errors,
	} = useForm<OptionItem>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});
	// const [user] = useAtom(userAtom);
	// const [posOutlet] = useAtom(posActiveOutletAtom);

	const clickButton = (mode: string) => {
		if (mode === 'add') {
			if (
				quantity >=
				currMenu?.menuItem?.dailyAvailability?.outstandingQty +
					currMenu?.quantity
			) {
				setQuantity(quantity);
			} else {
				setQuantity(quantity + 1);
			}
		} else {
			if (quantity === 0) {
				setQuantity(0);
			} else {
				setQuantity(quantity - 1);
			}
		}
	};

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const check = ID => {
		const defaultOrderItemOptionItems = currMenu?.orderItemOption
			?.map((orderItemOption, optionIndex) =>
				orderItemOption?.orderItemOptionItem?.map(
					optionItem => optionItem?.optionItemID,
				),
			)
			.flat();

		return defaultOrderItemOptionItems?.includes(ID);
	};

	useEffect(() => {
		if (openDialog) {
			currMenu?.menuItem?.menuItemOptions?.map(
				(menuItemOption, optionIndex) => {
					let maxSelections = menuItemOption?.maxSelections;
					menuItemOption?.menuItemOptionItem?.map(
						(optionItem, optionItemIndex) => {
							if (check(optionItem?.optionItemID)) {
								if (maxSelections === 1) {
									register(
										`option[${optionIndex}].${menuItemOption?.optionID}[${0}]`,
									);
									setValue(
										`option[${optionIndex}].${menuItemOption?.optionID}[${0}]`,
										JSON.stringify({
											ItemID: optionItem?.optionItemID,
											price: optionItem?.price,
										}),
									);
								} else {
									register(
										`option[${optionIndex}].${menuItemOption?.optionID}[${optionItem?.optionItemID}]`,
									);
									setValue(
										`option[${optionIndex}].${menuItemOption?.optionID}[${optionItem?.optionItemID}]`,
										optionItem?.price,
									);
								}
							}
						},
					);
				},
			);
		} else {
			unregister(`option`);
			reset();
		}
	}, [currMenu, openDialog]);

	const crossCheckSetMenuOptionItem = ID => {
		const defaultOrderItemSetMenuOptionItems = currMenu?.orderItemSetMenuOption
			?.map((orderItemSetMenuOption, orderItemSetMenuOptionIndex) =>
				orderItemSetMenuOption?.orderItemSetMenuOptionItem?.map(
					orderItemSetMenuOptionItem =>
						orderItemSetMenuOptionItem?.setMenuOptionItemID,
				),
			)
			.flat();
		return defaultOrderItemSetMenuOptionItems?.includes(ID);
	};

	useEffect(() => {
		if (openDialog) {
			currMenu?.menuItem?.setMenuOption?.map(
				(setMenuOption, setMenuOptionIndex) => {
					let maxSelections = setMenuOption?.maxSelections;
					setMenuOption?.setMenuOptionItem?.map(setMenuOptionItem => {
						if (crossCheckSetMenuOptionItem(setMenuOptionItem?.ID)) {
							if (maxSelections === 0) {
								register(
									`option[0].${setMenuOption?.ID}[${setMenuOptionItem?.ID}]`,
								);
								setValue(
									`option[0].${setMenuOption?.ID}[${setMenuOptionItem?.ID}]`,
									{
										quantity: setMenuOptionItem?.quantity || 1,
										menuItemID: setMenuOptionItem?.menuItem?.ID,
									},
								);
							} else if (maxSelections === 1) {
								register(
									`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${0}]`,
								);
								setValue(
									`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${0}]`,
									JSON.stringify({
										itemID: setMenuOptionItem?.ID,
										quantity: setMenuOptionItem?.quantity || 1,
										menuItemID: setMenuOptionItem?.menuItem?.ID,
									}),
								);
							} else if (maxSelections > 1) {
								register(
									`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${setMenuOptionItem?.ID}]`,
								);
								setValue(
									`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${setMenuOptionItem?.ID}]`,
									{
										quantity: setMenuOptionItem?.quantity || 1,
										menuItemID: setMenuOptionItem?.menuItem?.ID,
									},
								);
							}
						}
					});
				},
			);
		} else {
			unregister(`option`);
			reset();
		}
	}, [openDialog]);

	//GET ORDER
	//GET ORDER

	const {
		data: { getOrder } = { getOrder: [] },
		called: getOrderCalled,
		loading: getOrderLoading,
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: { ID: orderID },
	});

	const status = getOrder[0]?.table?.status;

	useEffect(() => {
		if (status === TableStatus?.Available) {
			history.push(`/menu/patron-app/invalid-qr`);
		}
	}, [status]);

	const {
		data: { getOrderItem } = { getOrderItem: [] },
		loading: getOrderSummaryLoading,
	} = useGetOrderSummaryQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderID: orderID,
			status: OrderItemStatus.Pending,
		},
	});

	//GETUOM
	//GETUOM
	//GETUOM

	const {
		data: { getUOM } = { getUOM: [] },
		loading: getUOMLoading,
	} = useGetUomQuery({
		fetchPolicy: 'network-only',
		// variables: {
		// 	outletID: outletID,
		// },
	});

	const [weightName, setWeightName] = useState(null);

	const [selectedOrder, setSelectedOrder] = useState(null);

	// useEffect(() => {
	// 	currMenu?.menuItem?.menuItemOptions?.map((menuItemOption, optionIndex) => {
	// 		menuItemOption?.menuItemOptionItem?.map((optionItem, optionItemIndex) => {
	// 			unregister(
	// 				`option[${optionIndex}].${menuItemOption?.optionID}[${optionItem?.optionItemID}]`,
	// 			);
	// 		});
	// 	});
	// }, [MenuDialog]);

	useEffect(() => {
		setWeightName(
			getUOM?.filter(id => id?.ID === currMenu?.menuItem?.uomID)[0],
		);
	}, [currMenu]);

	useEffect(() => {
		setSelectedOrder(getOrder?.filter(id => id?.ID === orderID)[0]);
	}, [getOrder, orderID]);

	//GET OUTLET
	//GET OUTLET

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
		},
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});
	// const outletArray: any[] = getOutlet;
	const selfOrder = getOutlet[0]?.customerPolicy?.selfOrder;
	const selfPayment = getOutlet[0]?.customerPolicy?.selfPayment;

	//CONFIRM ORDER
	//CONFIRM ORDER

	const [confirmOrderItem] = useConfirmOrderItemMutation({
		onError: error => {
			console.log(error, ' errr');
		},
		onCompleted: data => {
			history.push(
				`/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${menuID}/summary/confirm-order`,
			);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.confirmRecord());
			setIsSubmitting(false);
		},
	});

	//UPDATE ORDER ITEM
	//UPDATE ORDER ITEM
	const [optionErr, setOptionErr] = useState(false);

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [
		updateOrderItemWithSetMenuOptions,
	] = useUpdateOrderItemWithSetMenuOptionsMutation({
		onError: error => {
			setOptionErr(true);
		},
		onCompleted: ({ updateOrderItemWithSetMenuOptions }) => {
			setEditSetMenuDialog(false);
			setOptionErr(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setIsSubmitting(false);
		},
	});

	let totalAmountFooter = amtStr(selectedOrder?.outstandingAmount);

	const [updateOrderItemWithOptions] = useUpdateOrderItemWithOptionsMutation({
		onError: error => {
			setOptionErr(true);
		},
		onCompleted: data => {
			setOpenDialog(false);
			setOptionErr(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setIsSubmitting(false);
		},
	});

	//Printing
	//Printing
	//Printing

	const [uniqueKpArray, setUniqueKpArray] = useState([]);
	const [kpObj, setKpObject] = useState({});
	const [printerIndex, setPrinterIndex] = useState(null);
	const [dataURL, setDataURL] = useState(null);
	const [printerName, setPrinterName] = useState(null);
	const [kitchenPrinterObj, setKitchenPrinterObj] = useState([]);

	// Rerender receipt data after update
	useEffect(() => {
		if (!getOrderSummaryLoading && getOrderItem?.length > 0) {
			generateReceiptData();
		}
	}, [getOrderItem]);

	const generateReceiptData = () => {
		let uniqueprinterName = new Set(
			getOrderItem
				?.filter(oi => oi?.menuItem?.kitchenPrinterID !== (null && undefined))
				?.map(oi => oi?.menuItem?.kitchenPrinter?.name),
		);
		setUniqueKpArray(Array.from(uniqueprinterName));

		let temporaryObject = {};

		getOrderItem?.map(oi => {
			if (!(oi?.menuItem?.kitchenPrinter?.name in temporaryObject)) {
				temporaryObject[oi?.menuItem?.kitchenPrinter?.name] = [oi];
			} else {
				temporaryObject[oi?.menuItem?.kitchenPrinter?.name].push(oi);
			}
		});
		setKpObject(temporaryObject);
	};

	const handlePrinting = async () => {
		if (uniqueKpArray?.length > 0) {
			await uniqueKpArray?.map(async (printerName, index) => {
				let receiptElement = document?.getElementById(printerName);
				setKitchenPrinterObj(kpObj[printerName]);
				await htmlToImage
					.toPng(receiptElement, { style: { background: '#ffffff' } })
					.then(async x => {
						console.log('x', x);
						setDataURL(x);
						setPrinterIndex(index);
						setPrinterName(printerName);
					});
			});
		} else {
			!isSubmitting && handleSubmit(onSubmit)();
		}
		return true;
	};

	//ON SUBMIIIIIIIIIIIIIIIIIIIT
	//ON SUBMIIIIIIIIIIIIIIIIIIIT
	//ON SUBMIIIIIIIIIIIIIIIIIIIT

	const onSubmit = (data: any) => {
		confirmOrderItem({
			variables: {
				orderItemIDs: getOrderItem?.map(e => {
					return e?.ID;
				}),
				orderID: orderID,
			},
			refetchQueries: [
				{
					query: GetOrderSummaryDocument,
					variables: {
						orderID: orderID,
						status: OrderItemStatus.Pending,
						// menuCategoryID: menuCategoryID,
					},
				},
			],
		});
	};

	let amount: number = 0;

	const updateItem = (data: any) => {
		if (data?.getWeight) {
			amount = parseFloat(data?.getWeight) * currMenu?.unitPrice;
		} else if (data?.openPrice) {
			amount = parseFloat(data?.openPrice);
		} else {
			amount = currMenu?.unitPrice * quantity;
		}
		updateOrderItemWithOptions({
			variables: {
				orderItemInput: {
					ID: currMenu?.ID,
					outletID: outletID,
					amount: amount,
					baseAmount: amount,
					quantity: data?.getWeight ? 1 : quantity,
					unitPrice: parseFloat(data?.openPrice) || currMenu?.unitPrice,
					openItemName: data?.openItem || currMenu?.openItemName,
					menuItemID: currMenu?.menuItemID,
					orderID: orderID,
					mealPeriodID: currentMealPeriod?.ID,
					status: OrderItemStatus.Pending,
					specialRequest: audioInput,
					isTakeaway: takeAway,
					weight: data?.getWeight || currMenu?.weight,
				},
				orderItemOptionInput: data?.option?.map(orderItemOption => {
					let optionID = Object.keys(orderItemOption)[0];
					return {
						outletID,
						optionID: optionID,
						orderItemOptionItems:
							typeof orderItemOption[optionID][0] === 'string'
								? [
										{
											outletID,
											optionItemID: JSON.parse(orderItemOption[optionID][0])
												?.ItemID,
											price:
												JSON.parse(orderItemOption[optionID][0])?.price *
												quantity,
											basePrice:
												JSON.parse(orderItemOption[optionID][0])?.price *
												quantity,
										},
								  ]
								: Object.keys(orderItemOption[optionID])
										?.filter(item => item !== null)
										?.map(optionItem => {
											return {
												outletID,
												optionItemID: optionItem,
												price: orderItemOption[optionID][optionItem] * quantity,
												basePrice:
													orderItemOption[optionID][optionItem] * quantity,
											};
										}),
					};
				}),
			},
			refetchQueries: [
				{
					query: GetOrderSummaryDocument,
					variables: {
						orderID: orderID,
						status: OrderItemStatus.Pending,
					},
				},
				{
					query: GetOrderDocument,
					variables: {
						ID: orderID,
					},
				},
			],
		});
		setQuantity(currMenu?.quantity);
		setTakeAway(false);
	};

	const updateSetMenuItem = (data: any) => {
		if (data?.getWeight) {
			amount = parseFloat(data?.getWeight) * currMenu?.unitPrice;
		} else if (data?.openPrice) {
			amount = parseFloat(data?.openPrice);
		} else {
			amount = currMenu?.unitPrice * quantity;
		}
		updateOrderItemWithSetMenuOptions({
			variables: {
				orderItemInput: {
					ID: currMenu?.ID,
					outletID: outletID,
					amount: amount,
					baseAmount: amount,
					quantity: data?.getWeight ? 1 : quantity,
					unitPrice: parseFloat(data?.openPrice) || currMenu?.unitPrice,
					menuItemID: currMenu?.menuItemID,
					orderID: orderID,
					mealPeriodID: currentMealPeriod?.ID,
					status: OrderItemStatus.Pending,
					specialRequest: audioInput,
					isTakeaway: takeAway,
					openItemName: data?.openItem || currMenu?.openItemName,
					weight: parseFloat(data?.getWeight) || currMenu?.weight,
				},
				orderItemSetMenuOptionInput: data?.option?.map(setMenuOption => {
					let setMenuOptionID = Object.keys(setMenuOption)[0];
					return {
						outletID,
						setMenuOptionID: setMenuOptionID,
						orderItemSetMenuOptionItemInput:
							typeof setMenuOption[setMenuOptionID][0] === 'string'
								? [
										{
											outletID,
											menuItemID: JSON.parse(setMenuOption[setMenuOptionID][0])
												?.menuItemID,
											setMenuOptionItemID: JSON.parse(
												setMenuOption[setMenuOptionID][0],
											)?.itemID,
											quantity: JSON.parse(setMenuOption[setMenuOptionID][0])
												?.quantity,
										},
								  ]
								: Object.keys(setMenuOption[setMenuOptionID])
										?.filter(setMenuOptionItemID => setMenuOptionItemID != null)
										?.map(setMenuOptionItemID => {
											return {
												outletID,
												setMenuOptionItemID: setMenuOptionItemID,
												menuItemID:
													setMenuOption[setMenuOptionID][setMenuOptionItemID]
														.menuItemID,
												quantity:
													setMenuOption[setMenuOptionID][setMenuOptionItemID]
														?.quantity,
											};
										}),
					};
				}),
			},
			refetchQueries: [
				{
					query: GetOrderSummaryDocument,
					variables: {
						orderID: orderID,
						status: OrderItemStatus.Pending,
					},
				},
				{
					query: GetOrderDocument,
					variables: {
						tableID: tableID,
					},
				},
			],
		});
		setQuantity(currMenu?.quantity);
		setTakeAway(false);
	};

	const MenuDialog = () => {
		return (
			<>
				<CommonDialogV2
					fullWidth={true}
					open={openDialog}
					onClose={() => {
						setOpenDialog(false);
						setQuantity(currMenu?.quantity);
						setTakeAway(false);
						setIsSubmitting(false);
					}}
					sections={{
						header: {
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="title">{`${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}</div>
										</div>
									</div>
									<div className="infoline-content ">
										<div className="title flex-space infoline">
											{currMenu?.name}
										</div>
										<div className="title rightText infoline">
											{`${getOutlet[0]?.currencyCode ?? ''}${amtStr(
												currMenu?.unitPrice,
											)}`}
										</div>
									</div>
								</div>
							),
							// children: (
							// 	<>
							// 		<ListItem
							// 			style={{ backgroundColor: '#c7d6ff' }}
							// 			className="remove-padding"
							// 		>
							// 			<ListItemText
							// 				primary={
							// 					<>
							// 						<div
							// 							className="smTitle highlight-text"
							// 							style={{ color: '#ff9800' }}
							// 						>
							// 							<span style={{ marginRight: '15px' }}>
							// 								{`${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
							// 							</span>
							// 						</div>
							// 					</>
							// 				}
							// 				secondary={
							// 					<>
							// 						<span className="highlight-text flex-space">
							// 							{currMenu?.name}
							// 						</span>
							// 						<span className="highlight-text">
							// 							{`$${amtStr(currMenu?.unitPrice)}`}
							// 						</span>
							// 					</>
							// 				}
							// 			/>
							// 		</ListItem>
							// 		<hr className="line-subheader" />
							// 	</>
							// ),
						},
						body: () => (
							<>
								<MultipleItemsCarousel>
									<div className="img-container">
										<img
											src={currMenu?.menuItem?.attachments?.fileURL}
											alt=""
										/>
									</div>
								</MultipleItemsCarousel>
								<div className="orig-padding" style={{ marginTop: '10px' }}>
									<span className="mdDesc text-noflow">
										{currMenu?.description}
									</span>
									<hr className="line-description" />
									<div>
										{/* OPEN ITEM */}
										{/* OPEN ITEM */}

										{currMenu?.menuItem?.isOpenItem && selfOrder && (
											<div className="" style={{ marginBottom: '10px' }}>
												<span>
													<Controller
														as={TextField}
														fullWidth
														label="we will try our best to fulfill your request..."
														variant="standard"
														size="small"
														autoComplete="off"
														multiline={true}
														name="openItem"
														defaultValue={currMenu?.openItemName}
														control={control}
														ref={register}
													/>
												</span>
											</div>
										)}

										{/* OPEN PRICE */}
										{/* OPEN PRICE */}

										{(currMenu?.menuItem?.isOpenItem ||
											currMenu?.menuItem?.isOpenPrice) &&
											selfOrder && (
												<div className="" style={{ marginBottom: '20px' }}>
													<span>
														<Controller
															as={
																<NumberFormat
																	customInput={TextField}
																	fullWidth
																	label="Price"
																	autoComplete="off"
																	isNumericString
																	decimalScale={2}
																	fixedDecimalScale
																	style={{
																		marginBottom: '0px',
																		marginTop: '0px',
																	}}
																	margin="dense"
																	// ref={register}
																	// control={control}
																	allowNegative={false}
																	defaultValue={currMenu?.unitPrice}
																/>
															}
															name="openPrice"
															control={control}
															ref={register}
														/>
													</span>
												</div>
											)}

										{/* WIEGHT */}
										{/* WIEGHT */}
										{/* WIEGHT */}

										{currMenu?.menuItem?.isByWeight && selfOrder && (
											<Grid container style={{ marginBottom: '15px' }}>
												<Grid item xs={2}>
													<span className="mdDesc">
														<span>Weight</span>
													</span>
												</Grid>
												<Grid item xs={4}>
													<span className="dialog-span">
														<Controller
															as={
																<NumberFormat
																	customInput={TextField}
																	autoComplete="off"
																	isNumericString
																	decimalScale={2}
																	fixedDecimalScale
																	style={{
																		marginBottom: '0px',
																		marginTop: '0px',
																	}}
																	margin="dense"
																	// onInputCapture={(e) => {
																	// 	setQuantity();
																	// }}
																	defaultValue={currMenu?.quantity}
																	// ref={register}
																	// control={control}
																	allowNegative
																/>
															}
															name="getWeight"
															fullWidth={false}
															// label="Weight"
															variant="outlined"
															control={control}
															ref={register}
														/>
													</span>
												</Grid>
												<Grid item xs={2}>
													<span
														className="mdDesc"
														style={{ paddingLeft: '10px' }}
													>
														{`(${weightName?.name})`}
													</span>
												</Grid>
											</Grid>
										)}

										{/* QUANTITY */}
										{/* QUANTITY */}

										{(!currMenu?.menuItem?.isByWeight ||
											(!currMenu?.menuItem?.isByWeight &&
												currMenu?.menuItem?.isOpenItem)) &&
											selfOrder && (
												<span>
													<IndeterminateCheckBoxIcon
														className="order-minus"
														onClick={() => clickButton('reduce')}
													/>
													<span className="order-quantity"> {quantity} </span>
													<AddBox
														className="order-add"
														onClick={() => clickButton('add')}
													/>
												</span>
											)}

										{/* <span>
											<IndeterminateCheckBoxIcon
												className="order-minus"
												onClick={() => clickButton('minus')}
											/>
											<span className="order-quantity"> {quantity} </span>
											<AddBox
												className="order-add"
												onClick={() => clickButton('add')}
											/>
										</span> */}
										{selfOrder && (
											<span
												style={{
													float: 'right',
													position: 'relative',
													paddingTop: '10px',
												}}
											>
												<Checkbox
													defaultChecked={currMenu?.isTakeaway}
													onChange={e => {
														e.target.checked
															? setTakeAway(true)
															: setTakeAway(false);
													}}
													color="primary"
													inputProps={{ 'aria-label': 'secondary checkbox' }}
													style={{ padding: '5px' }}
													ref={register}
												/>
												<span className="mdDesc">{'Take Away'}</span>
											</span>
										)}
										{currMenu?.menuItem?.menuItemOptions?.map(
											(v, optionIndex) => (
												<>
													<div className="option-radio">
														<div className="mdDesc" key={currMenu?.ID}>
															{v?.maxSelections === 1
																? `${v?.option?.name}* (Require 1 option)`
																: v?.maxSelections === 1
																? `${v?.option?.name}* (Choose at most ${v?.maxSelections} option)`
																: `${v?.option?.name}`}
														</div>
														{v?.maxSelections === 1
															? customRadio(
																	v?.option?.optionItem,
																	v?.option,
																	optionIndex,
																	v?.menuItemOptionItem,
															  )
															: customCheckBox(
																	v?.option?.optionItem,
																	v?.option,
																	optionIndex,
																	v?.menuItemOptionItem,
																	optionErr,
																	register,
																	setValue,
																	unregister,
																	watch,
																	clearErrors,
															  )}
													</div>
													<div>
														<TextField
															InputProps={{ disableUnderline: true }}
															disabled
															style={{ marginTop: '-35px' }}
															helperText={
																optionErr
																	? 'Must Select atleast 1 option'
																	: null
															}
															error={optionErr}
														/>
													</div>
												</>
											),
										)}
										{selfOrder && (
											<div className="mdDesc" style={{ padding: '10px 0px' }}>
												{'Special Request'}
											</div>
										)}
										{selfOrder && specialRequestFunction()}
									</div>
								</div>
							</>
						),
						footer: {
							actions: !selfOrder
								? [
										{
											displayText: 'Close',
											props: {
												onClick: () => {
													setOpenDialog(false);
													setQuantity(currMenu?.quantity);
													setTakeAway(false);
												},
												variant: 'contained',
												color: 'primary',
											},
										},
								  ]
								: [
										{
											displayText: 'Cancel',
											props: {
												onClick: () => {
													setOpenDialog(false);
													setQuantity(currMenu?.quantity);
													setTakeAway(false);
												},
												variant: 'contained',
												color: 'primary',
											},
										},
										{
											displayText: 'Save',
											props: {
												onClick: e => {
													setIsSubmitting(true);
													!isSubmitting && handleSubmit(updateItem)();
													e.stopPropagation();
												},
												variant: 'contained',
												color: 'primary',
											},
										},
								  ],
						},
					}}
				/>
			</>
		);
	};

	const customLabel = (data: any, checked: boolean) => {
		return (
			<>
				{checked ? (
					<div className="xsTitle custom-label-checked">{data}</div>
				) : (
					<div className="xsTitle custom-label">{data}</div>
				)}
			</>
		);
	};

	const customRadio = (
		data: any,
		option: any,
		optionIndex: number,
		menuItemOptionItem: any,
	) => {
		return (
			<span>
				{menuItemOptionItem?.map((optionItem: any, optionItemIndex: number) => (
					<Controller
						as={
							<RadioGroup>
								<Radio
									checked={checkedRadioCondiWithDefaultVal(
										{
											optionIndex: optionIndex,
											option: option,
											optionItem: optionItem,
										},
										watch,
										currMenu,
										option,
										optionItem,
										`option[${optionIndex}].${option?.ID}[0]`,
									)}
									value={JSON.stringify({
										ItemID: optionItem?.optionItemID,
										price: optionItem?.price,
									})}
									ref={register}
									name={
										data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
											?.name
									}
									checkedIcon={customLabel(
										data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
											?.name,
										true,
									)}
									icon={customLabel(
										data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
											?.name,
										false,
									)}
									disabled={!selfOrder}
									// defaultChecked={
									// 	currMenu?.orderItemOption
									// 		?.filter((id) => id?.optionID === option?.ID)[0]
									// 		?.orderItemOptionItem?.filter(
									// 			(itemid) =>
									// 				itemid?.optionItemID === optionItem?.optionItemID,
									// 		).length > 0
									// }
								/>
							</RadioGroup>
						}
						name={`option[${optionIndex}].${option?.ID}[0]`}
						control={control}
						ref={register}
						// helperText={errors?.calcType?.message}
						// error={errors?.calcType ? true : false}
					/>
				))}
			</span>
		);
	};
	const checkedRadioCondition = (
		watch: any,
		optionIndex: number,
		option: any,
		optionItemID: string,
	) => {
		let optionItemIDandPrice: any = watch(
			`option[${optionIndex}].${option?.ID}[${0}]`,
		);
		if (optionItemIDandPrice && JSON.parse(optionItemIDandPrice)?.ItemID) {
			return optionItemID === JSON.parse(optionItemIDandPrice)?.ItemID;
		} else return false;
	};

	const checkedRadioCondiWithDefaultVal = (
		currCheck,
		watch,
		currData,
		option,
		optionItem,
		fieldName,
	) => {
		if (watch(fieldName)) {
			return checkedRadioCondition(
				watch,
				currCheck.optionIndex,
				currCheck.option,
				currCheck.optionItem.optionItemID,
			);
		} else {
			return (
				currData?.orderItemOption
					?.filter(id => id?.optionID === option?.ID)[0]
					?.orderItemOptionItem?.filter(
						itemid => itemid?.optionItemID === optionItem?.optionItemID,
					).length > 0
			);
		}
	};

	const customCheckBox = (
		data: any,
		option: any,
		optionIndex: number,
		menuItemOptionItem: any,
		optionErr: any,
		register: any,
		setValue: any,
		unregister: any,
		watch: any,
		clearErrors: any,
	) => {
		return (
			<div>
				{menuItemOptionItem?.map((optionItem: any, optionItemIndex: number) => (
					<FormControl component="fieldset">
						<Checkbox
							checkedIcon={customLabel(
								data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
									?.name,
								true,
							)}
							icon={customLabel(
								data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
									?.name,
								false,
							)}
							onChange={event => {
								register(
									`option[${optionIndex}].${option?.ID}[${event.target.value}]`,
								);
								if (event.target.checked) {
									setValue(
										`option[${optionIndex}].${option?.ID}[${event.target.value}]`,
										optionItem?.price,
									);
								} else {
									unregister(
										`option[${optionIndex}].${option?.ID}[${event.target.value}]`,
									);
								}
							}}
							defaultChecked={
								currMenu?.orderItemOption
									?.filter(id => id?.optionID === option?.ID)[0]
									?.orderItemOptionItem?.filter(
										itemid => itemid?.optionItemID === optionItem?.optionItemID,
									).length > 0
									? true
									: false
							}
							color="primary"
							inputProps={{ 'aria-label': 'secondary checkbox' }}
							ref={register}
							value={optionItem?.optionItemID}
							name={`option[${optionIndex}].${option?.ID}[${optionItemIndex}]`}
							disabled={!selfOrder}
						/>
					</FormControl>
				))}
			</div>
		);
	};

	const specialRequestFunction = () => {
		return (
			<VoiceTextField
				name="specialRequest"
				mounted={true}
				label="we will try our best to fulfill your request..."
				required
				value={audioInput}
				record={record}
				setRecord={setRecord}
				setValue={setValue}
				audioInput={audioInput}
				setAudioInput={setAudioInput}
				openDialog={openDialog}
				customOnchange
				register={register}
				control={control}
				clearErrors={clearErrors}
				handleCustomOnChange={e => {
					setAudioInput(e?.target?.value);
				}}
			/>
		);
	};

	const SetMenuDialog = () => {
		return (
			<>
				<CommonDialogV2
					fullWidth={true}
					open={editSetMenuDialog}
					onClose={() => {
						setEditSetMenuDialog(false);
						setQuantity(currMenu?.quantity);
						setTakeAway(false);
						setIsSubmitting(false);
					}}
					sections={{
						header: {
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="title">{`${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}</div>
										</div>
									</div>
									<div className="infoline-content ">
										<div className="title flex-space infoline">
											{currMenu?.menuItem?.name}
										</div>
										<div className="title rightText infoline">
											{`${getOutlet[0]?.currencyCode ?? ''}${amtStr(
												currMenu?.unitPrice,
											)}`}
										</div>
									</div>
								</div>
							),
							// children: (
							// 	<>
							// 		<ListItem
							// 			style={{ backgroundColor: '#c7d6ff' }}
							// 			className="remove-padding"
							// 		>
							// 			<ListItemText
							// 				primary={
							// 					<>
							// 						<div
							// 							className="smTitle highlight-text"
							// 							style={{ color: '#ff9800' }}
							// 						>
							// 							<span
							// 								style={{ marginRight: '15px' }}
							// 							>{`${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}</span>
							// 						</div>
							// 					</>
							// 				}
							// 				secondary={
							// 					<>
							// 						<span className="highlight-text flex-space">
							// 							{currMenu?.menuItem?.name}
							// 						</span>
							// 						<span className="highlight-text">
							// 							{`$${amtStr(currMenu?.unitPrice)}`}
							// 						</span>
							// 					</>
							// 				}
							// 			/>
							// 		</ListItem>
							// 		<hr className="line-subheader" />
							// 	</>
							// ),
						},
						body: () => (
							<>
								<MultipleItemsCarousel>
									<div className="img-container">
										<img
											src={currMenu?.menuItem?.attachments?.fileURL}
											alt=""
										/>
									</div>
								</MultipleItemsCarousel>
								<div className="orig-padding" style={{ marginTop: '10px' }}>
									<span className="mdDesc text-noflow">
										{currMenu?.menuItem?.description}
									</span>
									<hr className="line-description" />

									<div>
										{/* QUANTITY */}
										{/* QUANTITY */}

										{(!currMenu?.menuItem?.isByWeight ||
											(!currMenu?.menuItem?.isByWeight &&
												currMenu?.menuItem?.isOpenItem)) &&
											selfOrder && (
												<span>
													<IndeterminateCheckBoxIcon
														className="order-minus"
														onClick={() => clickButton('reduce')}
													/>
													<span className="order-quantity">{quantity}</span>
													<AddBox
														className="order-add"
														onClick={() => clickButton('add')}
													/>
												</span>
											)}
										{selfOrder && (
											<span
												style={{
													float: 'right',
													position: 'relative',
													paddingTop: '10px',
												}}
											>
												<Checkbox
													value={currMenu?.menuItem?.ID}
													onChange={e =>
														e.target.checked
															? setTakeAway(true)
															: setTakeAway(false)
													}
													color="primary"
													style={{ padding: '5px' }}
													inputProps={{
														'aria-label': 'secondary checkbox',
													}}
													ref={register}
												/>

												<span className="mdDesc">Take Away</span>
											</span>
										)}

										<div style={{ padding: '10px 0px' }}>
											<div
												style={{ paddingBottom: '5px' }}
												className="desc"
											>{`Included in ${currMenu?.menuItem?.name}`}</div>
											{currMenu?.menuItem?.setMenuOption
												?.filter(option => option?.maxSelections === 0)[0]
												?.setMenuOptionItem?.map((optionItem, i) => (
													<div
														style={{ paddingBottom: '5px' }}
														className="xsTitle"
													>{`${optionItem?.menuItem?.name} x${optionItem?.quantity}`}</div>
												))}
										</div>
										{currMenu?.menuItem?.setMenuOption
											?.filter(
												setMenuOption => setMenuOption?.maxSelections !== 0,
											)
											?.map((setMenuOption, i) => (
												<div className="option-radio">
													<div className="mdDesc" key={setMenuOption?.ID}>
														{setMenuOption?.maxSelections === 1
															? `${setMenuOption?.name}* (Require 1 option)`
															: setMenuOption?.maxSelections === 1
															? `${setMenuOption?.name}* (Choose at most ${setMenuOption?.maxSelections} option)`
															: `${setMenuOption?.name}`}
													</div>
													{setMenuOption?.maxSelections === 1
														? customSetMenuRadio(
																setMenuOption?.setMenuOptionItem,
																setMenuOption,
																i + 1,
														  )
														: setMenuOption?.maxSelections > 1
														? customSetMenuCheckBox(
																setMenuOption?.setMenuOptionItem,
																setMenuOption,
																i + 1,
																optionErr,
																register,
																setValue,
																unregister,
																watch,
																clearErrors,
														  )
														: null}
												</div>
											))}
									</div>
								</div>
							</>
						),
						footer: {
							actions: !selfOrder
								? [
										{
											displayText: 'Close',
											props: {
												onClick: () => {
													setEditSetMenuDialog(false);
													setQuantity(currMenu?.quantity);
													setTakeAway(false);
												},
												variant: 'contained',
												color: 'secondary',
											},
										},
								  ]
								: [
										{
											displayText: 'Cancel',
											props: {
												onClick: () => {
													setEditSetMenuDialog(false);
													setQuantity(currMenu?.quantity);
													setTakeAway(false);
												},
												variant: 'contained',
												color: 'secondary',
											},
										},
										{
											displayText: 'Save',
											props: {
												onClick: e => {
													// if (quantity !== 0) {
													setIsSubmitting(true);
													!isSubmitting && handleSubmit(updateSetMenuItem)();
													// }
													e.stopPropagation();
												},
												// color: quantity !== 0 ? 'primary' : 'inherit',
												color: 'primary',
												variant: 'contained',
												type: 'submit',
											},
										},
								  ],
						},
					}}
				/>
			</>
		);
	};

	const customSetMenuCheckBox = (
		setMenuOptionItem: any,
		setMenuOption: any,
		setMenuOptionIndex: number,
		optionErr: any,
		register: any,
		setValue: any,
		unregister: any,
		watch: any,
		clearErrors: any,
	) => {
		return (
			<div>
				{setMenuOptionItem?.map(
					(setMenuOptionItem: any, setMenuOptionItemIndex: number) => (
						<FormControl component="fieldset">
							<Checkbox
								checkedIcon={customLabel(
									setMenuOptionItem?.menuItem?.name,
									true,
								)}
								icon={customLabel(setMenuOptionItem?.menuItem?.name, false)}
								onChange={e => {
									register(
										`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
									);
									if (e.target.checked) {
										setValue(
											`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
											{
												quantity: setMenuOptionItem?.quantity || 1,
												menuItemID: setMenuOptionItem?.menuItem?.ID,
											},
										);
									} else {
										unregister(
											`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
										);
									}
								}}
								defaultChecked={
									currMenu?.orderItemSetMenuOption
										?.filter(
											orderItemSetMenuOption =>
												orderItemSetMenuOption?.setMenuOptionID ===
												setMenuOption?.ID,
										)[0]
										?.orderItemSetMenuOptionItem?.filter(
											orderItemSetMenuOptionItem =>
												orderItemSetMenuOptionItem?.setMenuOptionItemID ===
												setMenuOptionItem?.ID,
										).length > 0
										? true
										: false
								}
								color="primary"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
								ref={register}
								value={setMenuOptionItem?.ID}
								name={`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${setMenuOptionItemIndex}]`}
								disabled={!selfOrder}
							/>
						</FormControl>
					),
				)}
			</div>
		);
	};

	const customSetMenuRadio = (
		setMenuOptionItem: any,
		setMenuOption: any,
		setMenuOptionIndex: number,
	) => {
		return (
			<span>
				{setMenuOptionItem?.map(
					(setMenuOptionItem: any, setMenuOptionItemIndex: number) => (
						<Controller
							as={
								<RadioGroup>
									<Radio
										checked={checkedRadioCondition(
											watch,
											setMenuOptionIndex,
											setMenuOption,
											setMenuOptionItem.ID,
										)}
										value={JSON.stringify({
											itemID: setMenuOptionItem?.ID,
											quantity: setMenuOptionItem?.quantity || 1,
											menuItemID: setMenuOptionItem?.menuItem?.ID,
										})}
										name={setMenuOptionItem?.menuItem?.name}
										checkedIcon={customLabel(
											setMenuOptionItem?.menuItem?.name,
											true,
										)}
										icon={customLabel(setMenuOptionItem?.menuItem?.name, false)}
										ref={register}
										disabled={!selfOrder}
									/>
								</RadioGroup>
							}
							name={`option[${setMenuOptionIndex}].${setMenuOption?.ID}[0]`}
							control={control}
							ref={register}
							// helperText={errors?.calcType?.message}
							// error={errors?.calcType ? true : false}
						/>
					),
				)}
			</span>
		);
	};

	const checkItem = item => {
		if (item?.menuItem?.isSetMenu && item?.status === 'PENDING') {
			setEditSetMenuDialog(true);
		} else if (!item?.menuItem?.isSetMenu && item?.status === 'PENDING') {
			setOpenDialog(true);
		}
		setAudioInput(item?.specialRequest);
		setValue('specialRequest', item?.specialRequest);
	};

	//Printing

	const [postPrintData] = usePostPrintDataMutation({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		const orderItemIDs = kitchenPrinterObj?.map(x => x?.ID);
		if (uniqueKpArray.length > 0 && printerName !== null) {
			handleSubmit(onSubmit)();
			// printReceipt(dataURL, printerName)
			postPrintData({
				variables: {
					printData: {
						dataURL: JSON.stringify(dataURL),
						outletID,
						printerName,
						outletName: getOutlet[0]?.name,
						orderID: selectedOrder?.ID,
						orderItemIDs: orderItemIDs,
						orderMode: 'confirm',
					},
				},
			});
		}
	}, [uniqueKpArray?.length, printerName]);

	return (
		<>
			{getOrderLoading && <Loading />}
			{getOrderSummaryLoading && <Loading />}
			{getUOMLoading && <Loading />}
			{outletLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			<MainHeaderCustomer
				onClick={() =>
					history.push({
						pathname: `/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${menuID}`,
					})
				}
				smTitle="Customer App"
				title={getOutlet[0]?.name}
				mainBtn="back"
				// customIcon={IFCAGlobeIcon}
				// currency={
				// 	<span className="xxTitle flex-space" style={{ color: 'white' }}>
				// 		Table
				// 		<span className="title flex-space" style={{ color: 'orange' }}>
				// 			{' ' + selectedOrder?.table?.tableNumber}
				// 		</span>
				// 	</span>
				// }
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Order Summary', current: true },
				]}
				rightRouteSegments={[
					{
						name: currentMealPeriod
							? `${currentMealPeriod?.name} ${formatTimeto12h(
									currentMealPeriod?.startTime,
							  )} - ${formatTimeto12h(currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
					},
				]}
			/>

			<OrderSubheader
				getOrderLoading={getOrderLoading}
				getOrder={getOrder}
				selectedOrder={selectedOrder}
				orderID={selectedOrder?.ID}
				outletID={outletID}
			/>
			<ContentWrapper customerDynamicInfo footer float>
				<List className="core-list" style={{ zIndex: 40 }}>
					{!getOrderLoading &&
						!getOrderSummaryLoading &&
						!getUOMLoading &&
						!outletLoading &&
						!getCurrentMealPeriodLoading &&
						(getOrderItem === undefined || getOrderItem?.length === 0 ? (
							<EmptyList
								title="There are no orders found"
								subtitle="Please make a new order in the Menu "
							/>
						) : (
							getOrderItem?.map((v, i) => (
								<ListItem style={{ padding: '0px 10px 7px' }}>
									{/* <div style={{ paddingRight: '6px' }}>
										<img
											src={v?.menuItem?.attachments?.fileURL}
											className="img-owned"
											loading="lazy"
											// alt={}
										/>
									</div> */}
									<ListItemIcon>
										{v?.menuItem?.attachments?.fileURL ? (
											<img
												className="img-owned"
												src={v?.menuItem?.attachments?.fileURL}
											/>
										) : (
											<img
												className="img-owned"
												style={{ border: 'none' }}
												src={NoImage}
											/>
										)}
									</ListItemIcon>
									<ListItemText
										primary={
											<>
												<span className="xsTitle flex-space">
													{v?.menuItem?.name} x{v.quantity}
												</span>
												<span className="xsTitle highlight-text">
													{`${getOutlet[0]?.currencyCode ?? ''}${amtStr(
														new BigNumber(v?.totalInfo?.totalBaseAmount),
													)}`}
												</span>
											</>
										}
										secondary={
											<>
												<span className="flex-space desc">
													<div>{v?.menuItem?.description}</div>
													<div>{v.specialRequest}</div>
												</span>

												<EditIcon
													style={
														v?.status === 'PENDING'
															? {
																	fontSize: '23px',
																	color: 'white',
																	marginTop: '15px',
																	backgroundColor: '#ff9800',
																	borderRadius: '3px',
															  }
															: {
																	fontSize: '23px',
																	color: 'white',
																	marginTop: '15px',
																	backgroundColor: 'grey',
																	borderRadius: '3px',
															  }
													}
													onClick={() => {
														v?.status === 'PENDING'
															? setOpenDialog(true)
															: setOpenDialog(false);
														setCurrMenu({ ...v });
														setQuantity(v?.quantity);
														setTakeAway(v?.isTakeaway);
														checkItem(v);
													}}
												/>
											</>
										}
									/>
								</ListItem>
							))
						))}
				</List>

				<Overlay />
				{uniqueKpArray?.map((x, i) => {
					return (
						<OrderItemReceipt
							outletName={getOutlet[0]?.name}
							data={selectedOrder}
							orderItemData={kpObj[x]}
							printerName={x}
							hidden
						/>
					);
				})}
			</ContentWrapper>
			{MenuDialog()}
			{SetMenuDialog()}

			<FooterWithTotal
				label="Total"
				value={`${getOutlet[0]?.currencyCode ?? ''}${totalAmountFooter || 0}`}
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(100% - 240px)' : '100%',
				}}
				option={{
					name: !selfOrder
						? 'Confirm Order'
						: getOrderItem?.length > 0 && selfOrder
						? 'Confirm Order'
						: 'Add Item',
					onClick: e => {
						setIsSubmitting(true);
						if (!selfOrder) {
							history.push(
								`/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}/menu-category/${menuID}/summary/confirm-order`,
							);
						} else if (selfOrder && getOrderItem?.length > 0) {
							// !isSubmitting && handleSubmit(onSubmit)();
							!isSubmitting && handlePrinting();
						} else if (selfOrder && getOrderItem?.length === 0) {
							history.push({
								pathname: `/menu/patron-app/outlet/${outletID}/table/${tableID}/order/${orderID}`,
							});
						}
						e.stopPropagation();
					},
					color: 'primary',
					disabled: isSubmitting,
				}}
			/>
		</>
	);
};
