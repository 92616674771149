import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import { Divider } from '@material-ui/core';
import OrangeDollarIcon from 'assets/icons/Money/dollar-orange.svg';
import { useGetBillQuery } from 'generated/graphql';
import { amtStr } from 'helpers/numFormatter';
import React from 'react';
import { useParams } from 'react-router';
import './BillFooter.scss';
import { TaxSchemeComponent } from './TaxSchemeComponent';

export const BillFooter = (props: any) => {
	const {
		total,
		discount,
		subtotal,
		serviceCharge,
		tax,
		totalAmount,
		style,
		isConfirmOrder,
		highlightText,
		taxSchemeInfo,
    taxSchemeFooterInfo,
		currencyCode = '',
		rounding,
		outletID,
		orderID,
	} = props;
	const divChild = (info1: any, info2: any) => {
		return (
			<div
				style={isConfirmOrder ? { paddingBottom: '5px' } : null}
				className={isConfirmOrder ? 'mdDesc' : 'mdDesc bill-ftr-child'}
			>
				<span>{info1}</span>
				<span className="right-info">{info2}</span>
			</div>
		);
	};
	// const roundedSubtotal = Math.floor(Number(subtotal) * 100) / 100;

	return (
		<>
			<div
				className={isConfirmOrder ? null : 'bill-footer'}
				style={{ ...style }}
			>
				{divChild('Sub-Total', `${currencyCode}` + amtStr(subtotal))}
				{divChild('Discount', `(${currencyCode}${amtStr(discount)})`)}
				{divChild(
					'Rounding',
					rounding < 0
						? `(${currencyCode} ${Math.abs(rounding).toFixed(2)})`
						: currencyCode + amtStr(rounding),
				)}
				<div
					className={
						{ highlightText }
							? 'smTitle bill-total'
							: 'smTitle bill-total highlightText'
					}
				>
					{highlightText ? (
						<>
							<div style={{ display: 'flex', flex: 1 }}>
								<div className="flex-space">
									<span>Total</span>
								</div>
								<div className="right-info highlight-text">
									<IconText
										icon={
											<img
												src={OrangeDollarIcon}
												style={{ width: '17px', paddingRight: '2px' }}
											/>
										}
									>
										{amtStr(total)}
									</IconText>
								</div>
							</div>
						</>
					) : (
						<>
							<span>Total</span>
							<span className="right-info">
								{`${currencyCode}` + amtStr(total)}
							</span>
						</>
					)}
				</div>

				<hr className="line-bill" />

				<TaxSchemeComponent
					outletID={outletID}
					taxSchemeID={null}
					currencyCode={currencyCode}
					taxSchemeInfo={taxSchemeInfo}
          taxSchemeFooterInfo={taxSchemeFooterInfo}
					type={'detail'}
					orderID={orderID}
				/>
			</div>
		</>
	);
};

export const BillFooterReceipt = (props: any) => {
	const {
		total,
		discount,
		subtotal,
		serviceCharge,
		tax,
		totalAmount,
		currencyCode = '',
	} = props;
	const { billID } = useParams<Record<string, any>>();
	const {
		loading: billLoading,
		called: billCalled,
		data: { getBill } = { getBill: [] },
	} = useGetBillQuery({
		fetchPolicy: 'network-only',
		variables: { ID: billID },
	});

	return (
		<>
			<div className="dialog-dynamic-content" style={{ paddingTop: '5px' }}>
				<div className="session">
					<div className="flex session">
						<div className="flex-space mdDesc" style={{ alignSelf: 'center' }}>
							{'Discount'}
						</div>
						<div className="xsTitle rightText">{`(${currencyCode}${amtStr(
							discount,
						)})`}</div>
					</div>
				</div>
			</div>

			<div className="dialog-dynamic-content">
				<div className="session">
					<div className="flex session">
						<div className="flex-space mdDesc" style={{ alignSelf: 'center' }}>
							{'Subtotal'}
						</div>
						<div className="xsTitle rightText">
							{`${currencyCode}` + amtStr(subtotal)}
						</div>
					</div>
				</div>
			</div>
			<div className="dialog-dynamic-content">
				<div className="session">
					<div className="flex session">
						<div className="flex-space mdDesc" style={{ alignSelf: 'center' }}>
							{'Service Charge'}
						</div>
						<div className="xsTitle rightText">
							{`${currencyCode}` + amtStr(serviceCharge)}
						</div>
					</div>
				</div>
			</div>
			<div className="dialog-dynamic-content">
				<div className="session">
					<div className="flex session">
						<div className="flex-space mdDesc" style={{ alignSelf: 'center' }}>
							{'Tax'}
						</div>
						<div className="xsTitle rightText">
							{`${currencyCode}` + amtStr(tax)}
						</div>
					</div>
				</div>
			</div>

			<Divider style={{ display: 'flex', width: '100%', color: '#ff9800' }} />

			<div className="dialog-dynamic-content" style={{ paddingTop: '5px' }}>
				<div className="session">
					<div className="flex session">
						<div className="flex-space xsTitle" style={{ alignSelf: 'center' }}>
							{'Total'}
						</div>
						<div className="rightText" style={{ color: '#ff9800' }}>
							{/* <IconText
								icon={<img src={OrangeDollarIcon} style={{ width: '20px' }} />}
								// font="highlight-text smTitle"
							> */}
							<div className="highlight-text" style={{ fontSize: '15px' }}>
								{`${currencyCode}${amtStr(total)}`}
							</div>
							{/* </IconText> */}
						</div>
					</div>
				</div>
			</div>

			<Divider style={{ display: 'flex', width: '100%', color: '#ff9800' }} />
			<div className="dialog-dynamic-content" style={{ paddingTop: '5px' }}>
				<div className="session">
					<div className="flex session">
						<div className="flex-space xsTitle" style={{ alignSelf: 'center' }}>
							{'Payment'}
						</div>
						{/* <div className="highlight-text rightText" style={{ color: '#ff9800' }}>
							{'$' + amtStr(total)}
						</div> */}
					</div>
				</div>
			</div>
			<div className="dialog-dynamic-content">
				<div className="session">
					<div className="flex session">
						<div className="flex-space mdDesc" style={{ alignSelf: 'center' }}>
							{`${getBill[0]?.payments[0]?.defaultPayment?.paymentTypeInfo?.description}`}
						</div>
						<div className="xsTitle rightText">
							{`${currencyCode}` + amtStr(total)}
						</div>
					</div>
				</div>
			</div>
			<div className="dialog-dynamic-content">
				<div className="session">
					<div className="flex session">
						<div className="flex-space mdDesc" style={{ alignSelf: 'center' }}>
							{'Change'}
						</div>
						<div className="xsTitle rightText">
							{`${currencyCode}` +
								amtStr(getBill[0]?.payments[0]?.changeAmount)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
