import { ApolloProvider } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import '@ifca-root/react-component/src/assets/styles/app.scss';
import theme from '@ifca-root/react-component/src/assets/theme';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { setAccessToken } from 'AccessToken';
import { createBrowserHistory } from 'history';
import React, { Suspense, useEffect, useReducer, useState } from 'react';
import { Router } from 'react-router-dom';
import { Layout } from '../../components/Layout/Layout';
import { POSClient, posNodeRefreshUrl } from '../../POSClient';
import '../AccountModule/layout.scss';
import Routes from './Router/Routes';
import AppContext from './Store/AppContext';
import { GlobalInitialState, RootReducer } from './Store/RootReducer';
import SnackBarContext from './Store/SnackBarContext';

export const history = createBrowserHistory();

interface AppProps {
	children?: any;
}
export const App = (props: AppProps) => {
	const { children } = props;
	const [globalState, dispatch] = useReducer(RootReducer, GlobalInitialState);
	const [loading, setLoading] = useState(true);
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const key = sessionStorage?.getItem('key');
	const routing: any = history?.location?.pathname;

	const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
	const [snackBarMsg, setSnackBarMsg] = useState<string>('');

	useEffect(() => {
		fetch(posNodeRefreshUrl, {
			method: 'POST',
			credentials: 'include',
		}).then(async x => {
			const { accessToken } = await x.json();
			setAccessToken(accessToken);
			setLoading(false);
		});
	}, []);

	// const MINUTE_MS = 60000;
	// useEffect(() => {
	// 	const interval = setInterval(() => {
	// 		const currTs = new Date();
	// 		const currHour = currTs.getHours();
	// 		const currMinute = currTs.getMinutes();
	// 		const currSecond = currTs.getSeconds();

	// 		const currTime = `${currHour < 10 ? `0${currHour}` : currHour}:${
	// 			currMinute < 10 ? `0${currMinute}` : currMinute
	// 		}:${currSecond < 10 ? `0${currSecond}` : currSecond}`;

	// 		const allMealPeriodObj = localStorage.getItem('allMealPeriod');
	// 		if (allMealPeriodObj) {
	// 			const allMealPeriod = JSON.parse(allMealPeriodObj);
	// 			let currentMealPeriod = {};
	// 			var outletIDs = Object.keys(allMealPeriod);
	// 			outletIDs?.map(outletID => {
	// 				let currMealPeriod = allMealPeriod[outletID]?.filter(
	// 					mealPeriod =>
	// 						mealPeriod.startTime < currTime && mealPeriod.endTime > currTime,
	// 				);
	// 				currentMealPeriod[outletID] = currMealPeriod;
	// 			});
	// 			localStorage.setItem(
	// 				'currentMealPeriod',
	// 				JSON.stringify(currentMealPeriod),
	// 			);
	// 		}
	// 	}, MINUTE_MS);
	// 	return () => clearInterval(interval);
	// }, []);

	// useEffect(() => {
	// 	if (!!!key && !!!user && isNotMainContent()) {
	// 		history.push('/login');
	// 	}
	// }, [key, user]);

	if (loading) {
		return <Loading />;
	}

	return (
		<AppContext.Provider value={{ globalState, dispatch }}>
			<SnackBarContext.Provider value={{ setOpenSnackBar, setSnackBarMsg }}>
				<ThemeProvider theme={theme}>
					<ApolloProvider client={POSClient as any}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Router history={history}>
								<Layout>
									<Suspense fallback={<Loading />}>
										<Routes />
										<SnackBarMsg
											open={openSnackBar}
											setOpen={setOpenSnackBar}
											message={snackBarMsg}
										/>
									</Suspense>
								</Layout>
							</Router>
						</MuiPickersUtilsProvider>
					</ApolloProvider>
				</ThemeProvider>
			</SnackBarContext.Provider>
		</AppContext.Provider>
	);
};
export default App;
