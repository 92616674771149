import orderRoutes from 'containers/OrderModule/OrderRoutes';
import React from 'react';
import { Redirect } from 'react-router-dom';

const redirectRoute = [
	{
		props: { exact: true, path: '/' },
		component: <Redirect to="/" />,
	},
];

const errorRoute = [
	{
		// props: { exact: true, path: '/' },
		component: <Redirect to="/authentication/404" />,
	},
];

//error route always at the bottom//
const routes = [...orderRoutes];

export default routes;
