import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AccountJson = {
  __typename?: 'AccountJson';
  revenueDiv?: Maybe<Scalars['String']>;
  revenueDept?: Maybe<Scalars['String']>;
  revenueDebit?: Maybe<Scalars['String']>;
  revenueCredit?: Maybe<Scalars['String']>;
  reverseDiv?: Maybe<Scalars['String']>;
  reverseDept?: Maybe<Scalars['String']>;
  reverseDebit?: Maybe<Scalars['String']>;
  reverseCredit?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AccountJsonInput = {
  revenueDiv?: Maybe<Scalars['String']>;
  revenueDept?: Maybe<Scalars['String']>;
  revenueDebit?: Maybe<Scalars['String']>;
  revenueCredit?: Maybe<Scalars['String']>;
  reverseDiv?: Maybe<Scalars['String']>;
  reverseDept?: Maybe<Scalars['String']>;
  reverseDebit?: Maybe<Scalars['String']>;
  reverseCredit?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AccountMappingGlInput = {
  bill?: Maybe<Array<GlInput>>;
};

export type AccountMappingResponse = {
  __typename?: 'AccountMappingResponse';
  description?: Maybe<Scalars['String']>;
  glAccount?: Maybe<Scalars['JSON']>;
};

export type AccountMappingTaxGlInput = {
  TaxGL?: Maybe<Array<TaxGlInput>>;
};

export type ActionLogEntity = AuditEntity & {
  __typename?: 'ActionLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  userID: Scalars['String'];
  softwareName?: Maybe<Scalars['String']>;
  actionName: Scalars['String'];
  IPAddress?: Maybe<Scalars['String']>;
  loginDateTime: Scalars['DateTime'];
  logoutDateTime: Scalars['DateTime'];
  socketID?: Maybe<Scalars['String']>;
};

export type ActionlogInput = {
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  softwareName?: Maybe<Scalars['String']>;
  actionName?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  loginDateTime?: Maybe<Scalars['DateTime']>;
  logoutDateTime?: Maybe<Scalars['DateTime']>;
  socketID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput = {
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AttachmentEntity = AuditEntity & {
  __typename?: 'AttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  mediaType: Scalars['String'];
  refID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  fileURL: Scalars['String'];
  bucketFileName: Scalars['String'];
  fileSize: Scalars['Float'];
  isPrivate: Scalars['Boolean'];
};

/** JsonType */
export type AttachmentInfo = {
  __typename?: 'AttachmentInfo';
  fileURL: Scalars['String'];
  mediaType: Scalars['String'];
};

export type AttachmentInput = {
  isPrivate?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
};

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AzureStorageOutput = {
  __typename?: 'AzureStorageOutput';
  bucketFileName: Scalars['String'];
  fileName: Scalars['String'];
  url: Scalars['String'];
  mediaType: Scalars['String'];
  isPrivate?: Maybe<Scalars['Boolean']>;
  privateUrl?: Maybe<Scalars['String']>;
};

export type BankAccountContactEntity = AuditEntity & {
  __typename?: 'BankAccountContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  mobileNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  bankAccount?: Maybe<BankAccountEntity>;
};

export type BankAccountContactInput = {
  name?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  mobileNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type BankAccountEntity = AuditEntity & {
  __typename?: 'BankAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  bankName: Scalars['String'];
  accountName: Scalars['String'];
  accountNo: Scalars['String'];
  SWIFTCode: Scalars['String'];
  branch: Scalars['String'];
  contactNo: Scalars['String'];
  address: Scalars['JSON'];
  isDefault?: Maybe<Scalars['Boolean']>;
  outletID?: Maybe<Scalars['String']>;
  bankAccountContact?: Maybe<Array<BankAccountContactEntity>>;
};

export type BankAccountInput = {
  ID?: Maybe<Scalars['String']>;
  bankName: Scalars['String'];
  accountName: Scalars['String'];
  accountNo: Scalars['String'];
  SWIFTCode: Scalars['String'];
  branch: Scalars['String'];
  contactNo: Scalars['String'];
  address: AddressInput;
  isDefault?: Maybe<Scalars['Boolean']>;
  outletID?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BillDiscountEntity = AuditEntity & {
  __typename?: 'BillDiscountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  billID: Scalars['String'];
  discountID: Scalars['String'];
  amount: Scalars['Float'];
  businessDate: Scalars['DateTime'];
};

export type BillDiscountInput = {
  outletID: Scalars['String'];
  billID: Scalars['String'];
  discountID: Scalars['String'];
  amount: Scalars['Float'];
  businessDate: Scalars['DateTime'];
};

export type BillEntity = AuditEntity & {
  __typename?: 'BillEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  billNo?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  outlet?: Maybe<OutletEntity>;
  businessDate?: Maybe<Scalars['DateTime']>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  status: BillStatus;
  orderID: Scalars['String'];
  order?: Maybe<OrderEntity>;
  payments?: Maybe<Array<PaymentEntity>>;
  orderItem?: Maybe<Array<OrderItemEntity>>;
  paymentItem?: Maybe<Array<PaymentItemEntity>>;
  isGLPosted?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  signature?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  billDiscountType?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  billPaymentType?: Maybe<Scalars['JSON']>;
};

export type BillInput = {
  billNo?: Maybe<Scalars['String']>;
  orderID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  businessDate?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export type BillInputOffset = {
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
};

export type BillLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ReversedBillLedgerID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Reason: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  Qty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsDelivered?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  OutletCode?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['String']>;
  RefBillLedgerID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum BillStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Void = 'VOID'
}

export type CbExportEntity = AuditEntity & {
  __typename?: 'CBExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  batchNo?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  sourceID?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  cbDate?: Maybe<Scalars['DateTime']>;
  trxDate?: Maybe<Scalars['DateTime']>;
  cbType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  referenceNo?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  accountCode?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
};

export enum CalcType {
  Amount = 'AMOUNT',
  Percentage = 'PERCENTAGE'
}

export type CalculateOrderDiscountInput = {
  outletID?: Maybe<Scalars['String']>;
  orderID?: Maybe<Scalars['String']>;
};

export type CheckOut = {
  __typename?: 'CheckOut';
  item?: Maybe<Item>;
  code?: Maybe<Scalars['String']>;
  error?: Maybe<Errors>;
  paySessionNo?: Maybe<Scalars['Float']>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export enum ComputeMethod {
  TaxOnGross = 'TAX_ON_GROSS',
  TaxOnTaxedPrice = 'TAX_ON_TAXED_PRICE'
}

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ConversionLog = {
  __typename?: 'ConversionLog';
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ConversionLogInput = {
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type Coordinate = {
  __typename?: 'Coordinate';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type CoordinateInput = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export enum CreditCardType {
  VisaCard = 'VISA_CARD',
  Mastercard = 'MASTERCARD',
  AmericanExpressCard = 'AMERICAN_EXPRESS_CARD',
  DinersCard = 'DINERS_CARD',
  JcbCard = 'JCB_CARD',
  UnionpayCard = 'UNIONPAY_CARD',
  DebitCard = 'DEBIT_CARD',
  Ewallet = 'EWALLET'
}

export type CustomerAccessResponse = {
  __typename?: 'CustomerAccessResponse';
  accessToken: Scalars['String'];
  customer: CustomerEntity;
};

export type CustomerEntity = AuditEntity & {
  __typename?: 'CustomerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID?: Maybe<Scalars['String']>;
  orderID?: Maybe<Scalars['String']>;
  softwareCode: Scalars['String'];
  superUser: Scalars['Boolean'];
};

export type DailyMenuItemAvailabilityEntity = AuditEntity & {
  __typename?: 'DailyMenuItemAvailabilityEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  menuItemID: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  dayTs: Scalars['DateTime'];
  businessDate?: Maybe<Scalars['DateTime']>;
  menuItem?: Maybe<MenuItemEntity>;
  /** CustomFieldResolver */
  outstandingQty?: Maybe<Scalars['Float']>;
};

export type DailyMenuItemAvailabilityInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  menuItemID: Scalars['String'];
  quantity: Scalars['Float'];
  dayTs: Scalars['DateTime'];
};

export type DailyMenuPriceEntity = AuditEntity & {
  __typename?: 'DailyMenuPriceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  promoMenuID: Scalars['String'];
  menuItemID: Scalars['String'];
  patronTypeID: Scalars['String'];
  outletID: Scalars['String'];
  promoMenu?: Maybe<PromoMenuEntity>;
  menuItem?: Maybe<MenuItemEntity>;
  patronType?: Maybe<PatronTypeEntity>;
  patronCustomType: Scalars['String'];
  unitPrice: Scalars['Float'];
  startTs: Scalars['DateTime'];
  endTs: Scalars['DateTime'];
};

export type DailyMenuPriceInput = {
  promotionMenuID: Scalars['String'];
  menuItemID: Scalars['String'];
  startTs: Scalars['DateTime'];
  endTs: Scalars['DateTime'];
};


export type DefaultPaymentEntity = AuditEntity & {
  __typename?: 'DefaultPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  paymentTypeID: Scalars['String'];
  payment?: Maybe<Array<PaymentEntity>>;
  isDefault: Scalars['Boolean'];
  paymentClass: PaymentClass;
  outletGLAccount?: Maybe<AccountJson>;
  /** CustomFieldResolver */
  paymentTypeInfo?: Maybe<Scalars['JSON']>;
};

export type DefaultPaymentGlEntity = AuditEntity & {
  __typename?: 'DefaultPaymentGLEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  outletID: Scalars['String'];
  paymentClass?: Maybe<Scalars['String']>;
  discountType?: Maybe<Scalars['String']>;
  outletGLAccount?: Maybe<AccountJson>;
};

export type DefaultPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  paymentTypeID: Scalars['String'];
  isDefault: Scalars['Boolean'];
  paymentClass: PaymentClass;
};

export type DigitalMenuCategoryEntity = AuditEntity & {
  __typename?: 'DigitalMenuCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  digitalMenuID: Scalars['String'];
  outletID: Scalars['String'];
  name: Scalars['String'];
  priority: Scalars['Float'];
  digitalMenu?: Maybe<DigitalMenuEntity>;
  digitalMenuItem?: Maybe<Array<DigitalMenuItemEntity>>;
};

export type DigitalMenuCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  name: Scalars['String'];
  priority: Scalars['Float'];
  digitalMenuID: Scalars['String'];
};

export type DigitalMenuEntity = AuditEntity & {
  __typename?: 'DigitalMenuEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  outletID: Scalars['String'];
  description: Scalars['String'];
  digitalMenuCategory?: Maybe<Array<DigitalMenuCategoryEntity>>;
};

export type DigitalMenuInput = {
  accountID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type DigitalMenuItemEntity = AuditEntity & {
  __typename?: 'DigitalMenuItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  digitalMenuCategoryID: Scalars['String'];
  digitalMenuCategory?: Maybe<DigitalMenuCategoryEntity>;
  menuItemID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
  digitalMenuItemPrice?: Maybe<Array<DigitalMenuItemPriceEntity>>;
};

export type DigitalMenuItemInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  digitalMenuCategoryID?: Maybe<Scalars['String']>;
  menuItemID: Scalars['String'];
  digitalMenuItemPrice: Array<DigitalMenuItemPriceInput>;
};

export type DigitalMenuItemPriceEntity = AuditEntity & {
  __typename?: 'DigitalMenuItemPriceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  digitalMenuItemID: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  digitalMenuItem?: Maybe<DigitalMenuItemEntity>;
  patronID: Scalars['String'];
  patron?: Maybe<PatronEntity>;
};

export type DigitalMenuItemPriceInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  digitalMenuItemID?: Maybe<Scalars['String']>;
  patronID: Scalars['String'];
  price: Scalars['Float'];
};

/** JsonType */
export type DiscountCheckingInUseInfo = {
  __typename?: 'DiscountCheckingInUseInfo';
  discountInUse: Scalars['String'];
};

export type DiscountEntity = AuditEntity & {
  __typename?: 'DiscountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  amount: Scalars['Float'];
  calcType: CalcType;
  discountType: DiscountType;
  outletGLAccount?: Maybe<AccountJson>;
  outletTaxGLAccount?: Maybe<AccountJson>;
  outletServiceChargeGLAccount?: Maybe<AccountJson>;
  /** CustomFieldResolver */
  discountCheckingInUseInfo?: Maybe<Scalars['JSON']>;
};

export type DiscountInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  amount: Scalars['Float'];
  calcType: CalcType;
  discountType: DiscountType;
};

export enum DiscountType {
  Discount = 'DISCOUNT',
  OfficerCheck = 'OFFICER_CHECK',
  Entertainment = 'ENTERTAINMENT',
  Promo = 'PROMO'
}

export enum DocType {
  Bill = 'BILL',
  Order = 'ORDER',
  Table = 'TABLE',
  Payment = 'PAYMENT',
  Reservation = 'RESERVATION',
  OnlinePayment = 'ONLINE_PAYMENT'
}

export type EditTaxSchemeInput = {
  createdBy: Scalars['String'];
  modBy: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  taxSchemeDetail?: Maybe<Array<TaxSchemeDetailInput>>;
  id?: Maybe<Scalars['String']>;
};

export type EditTaxSetupInput = {
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeTypeId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  taxDetail: Array<TaxDetailInput>;
  taxId: Scalars['String'];
  deletedId: Array<Scalars['String']>;
};

export type EntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  outletID: Scalars['String'];
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type Errors = {
  __typename?: 'Errors';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  debug?: Maybe<Scalars['String']>;
};

export type FamilyEntity = AuditEntity & {
  __typename?: 'FamilyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  description: Scalars['String'];
  majorID: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
  major?: Maybe<MajorEntity>;
  menuItem?: Maybe<Array<MenuItemEntity>>;
};

export type FamilyInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  majorID: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export enum FrequencyType {
  Once = 'ONCE',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type GlExportEntity = AuditEntity & {
  __typename?: 'GLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  batchNo?: Maybe<Scalars['Float']>;
  outletID?: Maybe<Scalars['String']>;
  sourceID?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  glDate: Scalars['DateTime'];
  trxDate: Scalars['DateTime'];
  glType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  accountCode?: Maybe<Scalars['String']>;
  debit?: Maybe<Scalars['Float']>;
  credit?: Maybe<Scalars['Float']>;
  displaySeq?: Maybe<Scalars['Float']>;
};

export type GlInput = {
  description?: Maybe<Scalars['String']>;
  descriptionType?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  outletGLAccount?: Maybe<AccountJsonInput>;
  outletOCGLAccount?: Maybe<AccountJsonInput>;
  outletENTGLAccount?: Maybe<AccountJsonInput>;
  outletTaxGLAccount?: Maybe<AccountJsonInput>;
  outletServiceChargeGLAccount?: Maybe<AccountJsonInput>;
  outletRoundingGLAccount?: Maybe<AccountJsonInput>;
  outletDiscountGLAcct?: Maybe<AccountJsonInput>;
  outletOCDiscountGLAcct?: Maybe<AccountJsonInput>;
  outletENTDiscountGLAcct?: Maybe<AccountJsonInput>;
};

export type GuestLedgerAcctInput = {
  div?: Maybe<Scalars['String']>;
  dept?: Maybe<Scalars['String']>;
  acct?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type HotelGuestInfo = {
  __typename?: 'HotelGuestInfo';
  BookingNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  GuestType?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
};

export type HotelGuestInfoInput = {
  BookingNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  GuestType?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
};

export type HotelRoomEntity = AuditEntity & {
  __typename?: 'HotelRoomEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  roomNo?: Maybe<Scalars['String']>;
  patronAccount?: Maybe<Array<PatronAccountEntity>>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type InterfaceBaseEntity = AuditEntity & {
  __typename?: 'InterfaceBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
};

export type InterfaceTrxEntity = AuditEntity & {
  __typename?: 'InterfaceTrxEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};


export type KitchenOrder = AuditEntity & {
  __typename?: 'KitchenOrder';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  registrationID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  serviceMode: ServiceMode;
  openedByID: Scalars['String'];
  servedByIDs: Array<Scalars['String']>;
  paidByID?: Maybe<Scalars['String']>;
  businessDate: Scalars['DateTime'];
  pax?: Maybe<Scalars['Float']>;
  patronName?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  pickupTime?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  status: OrderStatus;
  refOrderID?: Maybe<Scalars['String']>;
  orderItem?: Maybe<Array<OrderItemEntity>>;
  bill?: Maybe<Array<BillEntity>>;
  tableID?: Maybe<Scalars['String']>;
  table?: Maybe<TableEntity>;
  qrID: Scalars['String'];
  mealPeriodID: Scalars['String'];
  mealPeriod?: Maybe<MealPeriodEntity>;
  orderDiscount?: Maybe<Array<OrderDiscountEntity>>;
  patronID: Scalars['String'];
  patron?: Maybe<PatronEntity>;
  patronAccountID?: Maybe<Scalars['String']>;
  paymentItem?: Maybe<Array<PaymentItemEntity>>;
  /** CustomFieldResolver */
  qr?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAmount?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingAmount?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalDiscountAmount?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  taxSchemeInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  taxSchemeFooterInfo?: Maybe<Scalars['JSON']>;
  kitchenOrderItems?: Maybe<Array<OrderItemEntity>>;
};

export type KitchenPrinterEntity = AuditEntity & {
  __typename?: 'KitchenPrinterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  description: Scalars['String'];
  address: Scalars['String'];
  outletID: Scalars['String'];
  menuItem?: Maybe<Array<MenuItemEntity>>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type KitchenPrinterInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  address?: Maybe<Scalars['String']>;
};

export enum KitchenStatus {
  Pending = 'PENDING',
  Preparing = 'PREPARING',
  Prepared = 'PREPARED',
  Completed = 'COMPLETED'
}

export type ListEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  outletID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type MajorDiscountGlEntity = AuditEntity & {
  __typename?: 'MajorDiscountGLEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  outletID: Scalars['String'];
  majorName?: Maybe<Scalars['String']>;
  discountType?: Maybe<Scalars['String']>;
  outletGLAccount?: Maybe<AccountJson>;
};

export type MajorEntity = AuditEntity & {
  __typename?: 'MajorEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  priority?: Maybe<Scalars['Float']>;
  outletID: Scalars['String'];
  description: Scalars['String'];
  family?: Maybe<Array<FamilyEntity>>;
  menuItem?: Maybe<Array<MenuItemEntity>>;
  outletGLAccount?: Maybe<AccountJson>;
  outletOCGLAccount?: Maybe<AccountJson>;
  outletENTGLAccount?: Maybe<AccountJson>;
};

export type MajorGlEntity = AuditEntity & {
  __typename?: 'MajorGLEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  outletID: Scalars['String'];
  majorName?: Maybe<Scalars['String']>;
  discountType?: Maybe<Scalars['String']>;
  outletGLAccount?: Maybe<AccountJson>;
};

export type MajorInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
};

export type MealPeriodEntity = AuditEntity & {
  __typename?: 'MealPeriodEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  outletID: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  validFrom?: Maybe<Scalars['DateTime']>;
  validTill?: Maybe<Scalars['DateTime']>;
  paymentItem?: Maybe<Array<PaymentItemEntity>>;
  orderItem?: Maybe<Array<OrderItemEntity>>;
  order?: Maybe<Array<OrderEntity>>;
};

export type MealPeriodInput = {
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  scheduledMenuID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  validFrom?: Maybe<Scalars['DateTime']>;
  validTill?: Maybe<Scalars['DateTime']>;
};

export type MembershipEntity = AuditEntity & {
  __typename?: 'MembershipEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  memberNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  patronAccount?: Maybe<Array<PatronAccountEntity>>;
};

export type MenuItemEntity = AuditEntity & {
  __typename?: 'MenuItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  nativeName: Scalars['String'];
  kitchenName?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  defaultQuantity?: Maybe<Scalars['Float']>;
  isOpenItem: Scalars['Boolean'];
  isOpenPrice: Scalars['Boolean'];
  isChefRecommended: Scalars['Boolean'];
  isQuantityControlled: Scalars['Boolean'];
  isTaxable: Scalars['Boolean'];
  isDiscountable: Scalars['Boolean'];
  isServiceChargeable: Scalars['Boolean'];
  isByWeight: Scalars['Boolean'];
  isSetMenu: Scalars['Boolean'];
  promoMenuIDs: Array<Scalars['String']>;
  promoMenu: Array<PromoMenuEntity>;
  promoMenuItem?: Maybe<Array<PromoMenuItemEntity>>;
  dailyAvailability?: Maybe<DailyMenuItemAvailabilityEntity>;
  menuItemOptions?: Maybe<Array<MenuItemOptionEntity>>;
  paymentItem?: Maybe<Array<PaymentItemEntity>>;
  orderItem?: Maybe<Array<OrderItemEntity>>;
  orderItemSetMenuOptionItem?: Maybe<Array<OrderItemSetMenuOptionItemEntity>>;
  paymentItemSetMenuOptionItem?: Maybe<Array<PaymentItemSetMenuOptionItemEntity>>;
  outletDailyMenuItem?: Maybe<Array<OutletDailyMenuItemEntity>>;
  stopSales?: Maybe<StopSalesEntity>;
  dailyMenuPrice?: Maybe<DailyMenuPriceEntity>;
  familyID?: Maybe<Scalars['String']>;
  family?: Maybe<FamilyEntity>;
  majorID?: Maybe<Scalars['String']>;
  major?: Maybe<MajorEntity>;
  outletID: Scalars['String'];
  outlet?: Maybe<OutletEntity>;
  kitchenPrinterID?: Maybe<Scalars['String']>;
  kitchenPrinter2ID?: Maybe<Scalars['String']>;
  kitchenPrinter?: Maybe<KitchenPrinterEntity>;
  digitalMenuItem?: Maybe<Array<DigitalMenuItemEntity>>;
  setMenuOptionItem?: Maybe<Array<SetMenuOptionItemEntity>>;
  setMenuOption?: Maybe<Array<SetMenuOptionEntity>>;
  uomID?: Maybe<Scalars['String']>;
  uom?: Maybe<UomEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  attachments?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  setMenuInfo?: Maybe<Array<SetMenuInfo>>;
};

export type MenuItemInput = {
  isActive?: Maybe<Scalars['Boolean']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nativeName: Scalars['String'];
  kitchenName?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  familyID?: Maybe<Scalars['String']>;
  majorID?: Maybe<Scalars['String']>;
  kitchenPrinterID?: Maybe<Scalars['String']>;
  kitchenPrinter2ID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  isOpenItem?: Maybe<Scalars['Boolean']>;
  isOpenPrice?: Maybe<Scalars['Boolean']>;
  isChefRecommended?: Maybe<Scalars['Boolean']>;
  isQuantityControlled?: Maybe<Scalars['Boolean']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  isServiceChargeable?: Maybe<Scalars['Boolean']>;
  isDiscountable?: Maybe<Scalars['Boolean']>;
  isSetMenu?: Maybe<Scalars['Boolean']>;
  defaultQuantity?: Maybe<Scalars['Float']>;
  isByWeight?: Maybe<Scalars['Boolean']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  taxSchemeID?: Maybe<Scalars['String']>;
};

export type MenuItemOptionEntity = AuditEntity & {
  __typename?: 'MenuItemOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  menuItemID: Scalars['String'];
  outletID: Scalars['String'];
  maxSelections: Scalars['Float'];
  menuItem?: Maybe<MenuItemEntity>;
  optionID: Scalars['String'];
  option?: Maybe<OptionEntity>;
  menuItemOptionItem?: Maybe<Array<MenuItemOptionItemEntity>>;
};

export type MenuItemOptionInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
  optionID: Scalars['String'];
  maxSelections: Scalars['Float'];
  menuItemOptionItems: Array<MenuItemOptionItemInput>;
};

export type MenuItemOptionItemEntity = AuditEntity & {
  __typename?: 'MenuItemOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  price: Scalars['Float'];
  optionItemID: Scalars['String'];
  optionItem?: Maybe<OptionItemEntity>;
  menuItemOptionID: Scalars['String'];
  menuItemOption?: Maybe<MenuItemOptionEntity>;
};

export type MenuItemOptionItemInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  optionItemID: Scalars['String'];
  menuItemOptionID?: Maybe<Scalars['String']>;
};

export enum MethodType {
  Up = 'UP',
  Down = 'DOWN',
  Off = 'OFF'
}

export type Mutation = {
  __typename?: 'Mutation';
  submitOutletGLInterface: Scalars['Boolean'];
  AccountMappingTaxGLInsert: Scalars['Boolean'];
  updateBankAccount: Scalars['Boolean'];
  createBankAccount: Scalars['Boolean'];
  deleteBankAccount: Scalars['Boolean'];
  createBankAccountContact: BankAccountContactEntity;
  updateBankAccountContact: BankAccountContactEntity;
  deleteBankAccountContact: Scalars['Boolean'];
  postCBExport: Scalars['Boolean'];
  postGLExport: Scalars['Boolean'];
  createAttachment: AttachmentEntity;
  updateAttachment: AttachmentEntity;
  deleteAttachment: Scalars['Boolean'];
  shareAttachment?: Maybe<ShareAttachmentEntity>;
  transactionExecMutation: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  DuplicateRolePermission: Scalars['Boolean'];
  createOfficer: OfficerEntity;
  updateOfficer: OfficerEntity;
  deleteOfficer: Scalars['Boolean'];
  createRoundingPolicy: RoundingPolicyEntity;
  updateRoundingPolicy: RoundingPolicyEntity;
  deleteRoundingPolicy: Scalars['Boolean'];
  submitRoundingPolicy: Scalars['Boolean'];
  createStaff: StaffEntity;
  updateStaff: StaffEntity;
  deleteStaff: Scalars['Boolean'];
  taxSchemeInsert: Scalars['Boolean'];
  taxSchemeUpdate: Scalars['Boolean'];
  taxSchemeDelete: Scalars['Boolean'];
  setDefaultTaxScheme: Scalars['Boolean'];
  taxInsert: Scalars['Boolean'];
  taxUpdate: Scalars['Boolean'];
  taxDelete: Scalars['Boolean'];
  createUser: UserEntity;
  resendInvitation: Scalars['Boolean'];
  updateUser: UserEntity;
  createPassword: Scalars['Boolean'];
  createPasswordTest: Scalars['Boolean'];
  activateUser: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  uploadProfilePic: AttachmentEntity;
  forgotPassword: Scalars['Boolean'];
  unblockUser: Scalars['Boolean'];
  customerAccess: CustomerAccessResponse;
  createDailyMenuItemAvailability: DailyMenuItemAvailabilityEntity;
  updateDailyMenuItemAvailability: DailyMenuItemAvailabilityEntity;
  deleteDailyMenuItemAvailability: Scalars['Boolean'];
  submitDailyMenuItemAvailability: Scalars['Boolean'];
  createDailyMenuPrice: DailyMenuPriceEntity;
  updateDailyMenuPrice: DailyMenuPriceEntity;
  deleteDailyMenuPrice: Scalars['Boolean'];
  createDefaultPayment: DefaultPaymentEntity;
  updateDefaultPayment: DefaultPaymentEntity;
  deleteDefaultPayment: Scalars['Boolean'];
  submitDefaultPayment: Scalars['Boolean'];
  createDigitalMenu: DigitalMenuEntity;
  updateDigitalMenu: DigitalMenuEntity;
  deleteDigitalMenu: Scalars['Boolean'];
  duplicateDigitalMenu: Scalars['Boolean'];
  createDigitalMenuCategory: DigitalMenuCategoryEntity;
  updateDigitalMenuCategory: DigitalMenuCategoryEntity;
  deleteDigitalMenuCategory: Scalars['Boolean'];
  createDigitalMenuItem: DigitalMenuItemEntity;
  updateDigitalMenuItem: DigitalMenuItemEntity;
  deleteDigitalMenuItem: Scalars['Boolean'];
  deleteDigitalMenuItemPrice: Scalars['Boolean'];
  createDigitalMenuItemPrice: Scalars['Boolean'];
  updateDigitalMenuItemPrice: Scalars['Boolean'];
  deleteDigitalMenuItemAndPrice: Scalars['Boolean'];
  createDiscount: DiscountEntity;
  updateDiscount: DiscountEntity;
  deleteDiscount: Scalars['Boolean'];
  createFamily: FamilyEntity;
  updateFamily: FamilyEntity;
  deleteFamily: Scalars['Boolean'];
  createKitchenPrinter: KitchenPrinterEntity;
  updateKitchenPrinter: KitchenPrinterEntity;
  deleteKitchenPrinter: Scalars['Boolean'];
  createMajor: MajorEntity;
  updateMajor: MajorEntity;
  deleteMajor: Scalars['Boolean'];
  createMajorWithGL: Scalars['Boolean'];
  updateMajorWithGL: Scalars['Boolean'];
  deleteMajorWithGL: Scalars['Boolean'];
  dragMajor: Scalars['Boolean'];
  createMealPeriod: MealPeriodEntity;
  updateMealPeriod: MealPeriodEntity;
  deleteMealPeriod: Scalars['Boolean'];
  duplicateOutletMenu: Scalars['Boolean'];
  createMenuItem: MenuItemEntity;
  updateMenuItem: MenuItemEntity;
  createMenuItemWithOptions: Scalars['Boolean'];
  updateMenuItemWithOptions: Scalars['Boolean'];
  deleteMenuItem: Scalars['Boolean'];
  createMenuItemOption: MenuItemOptionEntity;
  updateMenuItemOption: MenuItemOptionEntity;
  deleteMenuItemOption: Scalars['Boolean'];
  createOperatingHours: OperatingHoursEntity;
  updateOperatingHours: OperatingHoursEntity;
  deleteOperatingHours: Scalars['Boolean'];
  createOption: OptionEntity;
  updateOption: OptionEntity;
  deleteOption: Scalars['Boolean'];
  deleteOptionItem: Scalars['Boolean'];
  createOptionItem: OptionItemEntity;
  updateOptionItem: OptionItemEntity;
  dragOptionItem: Scalars['Boolean'];
  deleteOutlet: Scalars['Boolean'];
  createOutlet: Scalars['Boolean'];
  updateOutlet: Scalars['Boolean'];
  updateOutletTaxSetupByTaxScheme: Scalars['Boolean'];
  createOutletCustomerPolicy: OutletCustomerPolicyEntity;
  updateOutletCustomerPolicy: OutletCustomerPolicyEntity;
  deleteOutletCustomerPolicy: Scalars['Boolean'];
  submitOutletCustomerPolicy: Scalars['Boolean'];
  createOutletTaxPolicy: OutletTaxPolicyEntity;
  updateOutletTaxPolicy: OutletTaxPolicyEntity;
  deleteOutletTaxPolicy: Scalars['Boolean'];
  updateOutletGovTaxPolicy: Scalars['Boolean'];
  createPatron: PatronEntity;
  updatePatron: PatronEntity;
  deletePatron: Scalars['Boolean'];
  submitOutletPatronSetup: Scalars['Boolean'];
  createPromoCode: PromoCodeEntity;
  updatePromoCode: PromoCodeEntity;
  deletePromoCode: Scalars['Boolean'];
  createPromoMenu: PromoMenuEntity;
  updatePromoMenu: PromoMenuEntity;
  deletePromoMenu: Scalars['Boolean'];
  createPromoMenuItem: PromoMenuItemEntity;
  updatePromoMenuItem: PromoMenuItemEntity;
  deletePromoMenuItem: Scalars['Boolean'];
  deletePromoMenuItemPrice: Scalars['Boolean'];
  createPromoMenuItemPrice: Scalars['Boolean'];
  updatePromoMenuItemPrice: Scalars['Boolean'];
  deletePromoMenuItemAndPrice: Scalars['Boolean'];
  createQR: QrEntity;
  updateQR: QrEntity;
  deleteQR: Scalars['Boolean'];
  createReason: ReasonEntity;
  updateReason: ReasonEntity;
  deleteReason: Scalars['Boolean'];
  deleteReceiptPrinter: Scalars['Boolean'];
  createReceiptPrinter: Scalars['Boolean'];
  updateReceiptPrinter: Scalars['Boolean'];
  createScheduledMenu: ScheduledMenuEntity;
  updateScheduledMenu: ScheduledMenuEntity;
  deleteScheduledMenu: Scalars['Boolean'];
  createScheduledMenuAndPeriods: Scalars['Boolean'];
  updateScheduledMenuMealPeriods: Scalars['Boolean'];
  deleteScheduledMenuMealPeriods: Scalars['Boolean'];
  createSetMenuOption: SetMenuOptionEntity;
  updateSetMenuOption: SetMenuOptionEntity;
  deleteSetMenuOption: Scalars['Boolean'];
  createSetMenuOptionItem: SetMenuOptionItemEntity;
  updateSetMenuOptionItem: SetMenuOptionItemEntity;
  deleteSetMenuOptionItem: Scalars['Boolean'];
  createSetMenuStandardOptionItem: Scalars['Boolean'];
  createSetMenuOptionOptionItem: Scalars['Boolean'];
  createStopSales: StopSalesEntity;
  updateStopSales: StopSalesEntity;
  deleteStopSales: Scalars['Boolean'];
  submitStopSales: Scalars['Boolean'];
  createTable: TableEntity;
  updateTable: TableEntity;
  deleteTable: Scalars['Boolean'];
  submitTable: Scalars['Boolean'];
  transferTable: Scalars['Boolean'];
  closeTableStatus: Scalars['Boolean'];
  dragTable: Scalars['Boolean'];
  createBill: BillEntity;
  updateBill: BillEntity;
  deleteBill: Scalars['Boolean'];
  createBillAndPayment: BillEntity;
  createReceiptAttachment?: Maybe<AttachmentEntity>;
  voidBill: Scalars['Boolean'];
  createBillDiscount: BillDiscountEntity;
  updateBillDiscount: BillDiscountEntity;
  deleteBillDiscount: Scalars['Boolean'];
  deleteOrder: Scalars['Boolean'];
  createOrder: OrderEntity;
  updateOrder: Scalars['Boolean'];
  pickupTakeawayOrder: Scalars['Boolean'];
  cancelTakeawayOrder: Scalars['Boolean'];
  updateOrderDiscount: OrderDiscountEntity;
  createOrderDiscount: Scalars['Boolean'];
  deleteOrderDiscount: Scalars['Boolean'];
  createOrderItem: OrderItemEntity;
  updateOrderItem: OrderItemEntity;
  createOrderItemWithOptions: Scalars['Boolean'];
  updateOrderItemWithOptions: Scalars['Boolean'];
  createOrderItemWithSetMenuOptions: Scalars['Boolean'];
  updateOrderItemWithSetMenuOptions: Scalars['Boolean'];
  confirmOrderItem: Scalars['Boolean'];
  voidOrderItemWithOptions: Scalars['Boolean'];
  voidOrderItemWithSetMenuOptions: Scalars['Boolean'];
  transferItemByQuantity: Scalars['Boolean'];
  postPrintData: Scalars['Boolean'];
  createOrderItemPatronApp: Scalars['Boolean'];
  updateOrderItemDiscount: OrderItemDiscountEntity;
  createOrderItemDiscount: Scalars['Boolean'];
  deleteOrderItemDiscount: Scalars['Boolean'];
  updateKitchenStatus: Scalars['Boolean'];
  revertKitchenStatus: Scalars['Boolean'];
  confirmKitchenStatus: Scalars['Boolean'];
  createOrderItemOption: OrderItemOptionEntity;
  updateOrderItemOption: OrderItemOptionEntity;
  deleteOrderItemOption: Scalars['Boolean'];
  createOrderItemOptionItem: OrderItemOptionItemEntity;
  updateOrderItemOptionItem: OrderItemOptionItemEntity;
  deleteOrderItemOptionItem: Scalars['Boolean'];
  createOrderItemSetMenuOption: OrderItemSetMenuOptionEntity;
  updateOrderItemSetMenuOption: OrderItemSetMenuOptionEntity;
  deleteOrderItemSetMenuOption: Scalars['Boolean'];
  createOrderItemSetMenuOptionItem: OrderItemSetMenuOptionItemEntity;
  updateOrderItemSetMenuOptionItem: OrderItemSetMenuOptionItemEntity;
  deleteOrderItemSetMenuOptionItem: Scalars['Boolean'];
  updateReservation: ReservationEntity;
  deleteReservation: Scalars['Boolean'];
  createReservation: Scalars['Boolean'];
  assignReservationTable: Scalars['Boolean'];
  cancelReservation: Scalars['Boolean'];
  reinstateReservation: Scalars['Boolean'];
  createTableSession: TableSessionEntity;
  updateTableSession: TableSessionEntity;
  deleteAddress: Scalars['Boolean'];
  createOnlinePayment?: Maybe<CheckOut>;
  refundPayment?: Maybe<CheckOut>;
  createPayment: PaymentEntity;
  updatePayment: PaymentEntity;
  deletePayment: Scalars['Boolean'];
  voidPayment: Scalars['Boolean'];
  paymentByQuantity: BillEntity;
  createPatronType: PatronTypeEntity;
  updatePatronType: PatronTypeEntity;
  deletePatronType: Scalars['Boolean'];
  createPaymentType: PaymentTypeEntity;
  updatePaymentType: PaymentTypeEntity;
  deletePaymentType: Scalars['Boolean'];
  createPlatformTax: PlatformTaxEntity;
  updatePlatformTax: PlatformTaxEntity;
  deletePlatformTax: Scalars['Boolean'];
  createUOM: UomEntity;
  updateUOM: UomEntity;
  deleteUOM: Scalars['Boolean'];
  createUOMConversion: Scalars['Boolean'];
  updateUOMConversion: Scalars['Boolean'];
  deleteUOMConversion: Scalars['Boolean'];
  createMissingPatron: Scalars['Boolean'];
  createMissingPaymentType: Scalars['Boolean'];
  createMissingNextNumber: Scalars['Boolean'];
  updateOutletBusinessDate: Scalars['Boolean'];
  setupNewSubscription: Scalars['Boolean'];
  createSuperUser: UserEntity;
  resetRolePermission: Scalars['Boolean'];
  createMajorDiscountGL: Scalars['Boolean'];
  createDefaultPaymentGL: Scalars['Boolean'];
  createOutletTaxPolicyGL: Scalars['Boolean'];
  createOutletDailyMenuCategory: OutletDailyMenuCategoryEntity;
  updateOutletDailyMenuCategory: OutletDailyMenuCategoryEntity;
  deleteOutletDailyMenuCategory: Scalars['Boolean'];
  syncOutletDailyMenu: Scalars['Boolean'];
  createOutletDailyMenuItem: OutletDailyMenuItemEntity;
  updateOutletDailyMenuItem: OutletDailyMenuItemEntity;
  deleteOutletDailyMenuItem: Scalars['Boolean'];
  createOutletDailyMenuItemOption: OutletDailyMenuItemOptionEntity;
  updateOutletDailyMenuItemOption: OutletDailyMenuItemOptionEntity;
  deleteOutletDailyMenuItemOption: Scalars['Boolean'];
  createOutletDailyMenuItemOptionItem: OutletDailyMenuItemOptionItemEntity;
  updateOutletDailyMenuItemOptionItem: OutletDailyMenuItemOptionItemEntity;
  deleteOutletDailyMenuItemOptionItem: Scalars['Boolean'];
  createOutletDailyMenuItemPatronPrice: OutletDailyMenuItemPatronPriceEntity;
  updateOutletDailyMenuItemPatronPrice: OutletDailyMenuItemPatronPriceEntity;
  deleteOutletDailyMenuItemPatronPrice: Scalars['Boolean'];
  createOutletDailyPromoMenuItem: OutletDailyPromoMenuItemEntity;
  updateOutletDailyPromoMenuItem: OutletDailyPromoMenuItemEntity;
  deleteOutletDailyPromoMenuItem: Scalars['Boolean'];
  createOutletDailyPromoMenuItemPrice: OutletDailyPromoMenuItemPriceEntity;
  updateOutletDailyPromoMenuItemPrice: OutletDailyPromoMenuItemPriceEntity;
  deleteOutletDailyPromoMenuItemPrice: Scalars['Boolean'];
  createOutletDailySetMenuOption: OutletDailySetMenuOptionEntity;
  updateOutletDailySetMenuOption: OutletDailySetMenuOptionEntity;
  deleteOutletDailySetMenuOption: Scalars['Boolean'];
  createOutletDailySetMenuOptionItem: OutletDailySetMenuOptionItemEntity;
  updateOutletDailySetMenuOptionItem: OutletDailySetMenuOptionItemEntity;
  deleteOutletDailySetMenuOptionItem: Scalars['Boolean'];
  batchDeleteOrderItem: Scalars['Boolean'];
  dailyMenuRevenueInsert: Scalars['Boolean'];
};


export type MutationSubmitOutletGlInterfaceArgs = {
  glInput: AccountMappingGlInput;
  outletID: Scalars['String'];
};


export type MutationAccountMappingTaxGlInsertArgs = {
  TaxGLInput: AccountMappingTaxGlInput;
  accountID: Scalars['String'];
  outletID: Scalars['String'];
};


export type MutationUpdateBankAccountArgs = {
  contactInput: Array<BankAccountContactInput>;
  bankAccountInput: BankAccountInput;
};


export type MutationCreateBankAccountArgs = {
  contactInput: Array<BankAccountContactInput>;
  bankAccountInput: BankAccountInput;
};


export type MutationDeleteBankAccountArgs = {
  ID: Scalars['String'];
};


export type MutationCreateBankAccountContactArgs = {
  input: BankAccountContactInput;
};


export type MutationUpdateBankAccountContactArgs = {
  input: BankAccountContactInput;
};


export type MutationDeleteBankAccountContactArgs = {
  ID: Scalars['String'];
};


export type MutationPostCbExportArgs = {
  cbDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationPostGlExportArgs = {
  glDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationCreateAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationUpdateAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationDeleteAttachmentArgs = {
  ID: Scalars['String'];
};


export type MutationShareAttachmentArgs = {
  file: Scalars['Upload'];
  outletID: Scalars['String'];
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<PosPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<PosPermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  outletID: Scalars['String'];
};


export type MutationDuplicateRolePermissionArgs = {
  name: Scalars['String'];
  roleID: Scalars['String'];
};


export type MutationCreateOfficerArgs = {
  input: OfficerInput;
};


export type MutationUpdateOfficerArgs = {
  input: OfficerInput;
};


export type MutationDeleteOfficerArgs = {
  ID: Scalars['String'];
};


export type MutationCreateRoundingPolicyArgs = {
  input: RoundingPolicyInput;
};


export type MutationUpdateRoundingPolicyArgs = {
  input: RoundingPolicyInput;
};


export type MutationDeleteRoundingPolicyArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitRoundingPolicyArgs = {
  input: RoundingPolicyInput;
};


export type MutationCreateStaffArgs = {
  input: StaffInput;
};


export type MutationUpdateStaffArgs = {
  input: StaffInput;
};


export type MutationDeleteStaffArgs = {
  ID: Scalars['String'];
};


export type MutationTaxSchemeInsertArgs = {
  taxSchemeInput: TaxSchemeInput;
  accountID: Scalars['String'];
};


export type MutationTaxSchemeUpdateArgs = {
  editTaxSchemeInput: EditTaxSchemeInput;
  accountID: Scalars['String'];
};


export type MutationTaxSchemeDeleteArgs = {
  TaxSchemeID: Scalars['String'];
};


export type MutationSetDefaultTaxSchemeArgs = {
  taxSchemeID: Scalars['String'];
};


export type MutationTaxInsertArgs = {
  taxInsertInput: TaxSetupInput;
  accountID: Scalars['String'];
};


export type MutationTaxUpdateArgs = {
  taxEditInput: EditTaxSetupInput;
  accountID: Scalars['String'];
};


export type MutationTaxDeleteArgs = {
  taxDeleteInput: Array<TaxDetailInput>;
  taxID: Scalars['String'];
};


export type MutationCreateUserArgs = {
  OutletID: Scalars['String'];
  input: UserInput;
};


export type MutationResendInvitationArgs = {
  userID?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  input: PasswordInfo;
};


export type MutationCreatePasswordTestArgs = {
  input: PasswordInfo;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  loginID: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationLogoutArgs = {
  outletID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationUploadProfilePicArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationUnblockUserArgs = {
  input: PasswordInfo;
};


export type MutationCustomerAccessArgs = {
  qrID: Scalars['String'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationCreateDailyMenuItemAvailabilityArgs = {
  input: DailyMenuItemAvailabilityInput;
};


export type MutationUpdateDailyMenuItemAvailabilityArgs = {
  input: DailyMenuItemAvailabilityInput;
};


export type MutationDeleteDailyMenuItemAvailabilityArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitDailyMenuItemAvailabilityArgs = {
  outletID: Scalars['String'];
  input: Array<DailyMenuItemAvailabilityInput>;
};


export type MutationCreateDailyMenuPriceArgs = {
  input: DailyMenuPriceInput;
};


export type MutationUpdateDailyMenuPriceArgs = {
  input: DailyMenuPriceInput;
};


export type MutationDeleteDailyMenuPriceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDefaultPaymentArgs = {
  input: DefaultPaymentInput;
};


export type MutationUpdateDefaultPaymentArgs = {
  input: DefaultPaymentInput;
};


export type MutationDeleteDefaultPaymentArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitDefaultPaymentArgs = {
  outletID: Scalars['String'];
  defaultPaymentIDs: Array<Scalars['String']>;
};


export type MutationCreateDigitalMenuArgs = {
  input: DigitalMenuInput;
};


export type MutationUpdateDigitalMenuArgs = {
  input: DigitalMenuInput;
};


export type MutationDeleteDigitalMenuArgs = {
  ID: Scalars['String'];
};


export type MutationDuplicateDigitalMenuArgs = {
  outletID: Scalars['String'];
  digitalMenuID: Scalars['String'];
};


export type MutationCreateDigitalMenuCategoryArgs = {
  input: DigitalMenuCategoryInput;
};


export type MutationUpdateDigitalMenuCategoryArgs = {
  input: DigitalMenuCategoryInput;
};


export type MutationDeleteDigitalMenuCategoryArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDigitalMenuItemArgs = {
  input: DigitalMenuItemInput;
};


export type MutationUpdateDigitalMenuItemArgs = {
  input: DigitalMenuItemInput;
};


export type MutationDeleteDigitalMenuItemArgs = {
  ID: Scalars['String'];
};


export type MutationDeleteDigitalMenuItemPriceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDigitalMenuItemPriceArgs = {
  digitalMenuItemInput: Array<DigitalMenuItemInput>;
  digitalMenuCategoryID: Scalars['String'];
  outletID: Scalars['String'];
};


export type MutationUpdateDigitalMenuItemPriceArgs = {
  digitalMenuItemPriceInput: Array<DigitalMenuItemPriceInput>;
  outletID: Scalars['String'];
  digitalMenuItemID: Scalars['String'];
};


export type MutationDeleteDigitalMenuItemAndPriceArgs = {
  digitalMenuItemID: Scalars['String'];
};


export type MutationCreateDiscountArgs = {
  input: DiscountInput;
};


export type MutationUpdateDiscountArgs = {
  input: DiscountInput;
};


export type MutationDeleteDiscountArgs = {
  ID: Scalars['String'];
};


export type MutationCreateFamilyArgs = {
  input: FamilyInput;
};


export type MutationUpdateFamilyArgs = {
  input: FamilyInput;
};


export type MutationDeleteFamilyArgs = {
  ID: Scalars['String'];
};


export type MutationCreateKitchenPrinterArgs = {
  input: KitchenPrinterInput;
};


export type MutationUpdateKitchenPrinterArgs = {
  input: KitchenPrinterInput;
};


export type MutationDeleteKitchenPrinterArgs = {
  ID: Scalars['String'];
};


export type MutationCreateMajorArgs = {
  input: MajorInput;
};


export type MutationUpdateMajorArgs = {
  input: MajorInput;
};


export type MutationDeleteMajorArgs = {
  ID: Scalars['String'];
};


export type MutationCreateMajorWithGlArgs = {
  input: MajorInput;
};


export type MutationUpdateMajorWithGlArgs = {
  input: MajorInput;
};


export type MutationDeleteMajorWithGlArgs = {
  ID: Scalars['String'];
};


export type MutationDragMajorArgs = {
  outletID: Scalars['String'];
  input: Array<MajorInput>;
};


export type MutationCreateMealPeriodArgs = {
  input: MealPeriodInput;
};


export type MutationUpdateMealPeriodArgs = {
  input: MealPeriodInput;
};


export type MutationDeleteMealPeriodArgs = {
  ID: Scalars['String'];
};


export type MutationDuplicateOutletMenuArgs = {
  targetOutletID: Scalars['String'];
  sourceOutletID: Scalars['String'];
};


export type MutationCreateMenuItemArgs = {
  input: MenuItemInput;
};


export type MutationUpdateMenuItemArgs = {
  input: MenuItemInput;
};


export type MutationCreateMenuItemWithOptionsArgs = {
  menuItemOptionInput?: Maybe<Array<MenuItemOptionInput>>;
  menuItemInput: MenuItemInput;
};


export type MutationUpdateMenuItemWithOptionsArgs = {
  menuItemOptionInput?: Maybe<Array<MenuItemOptionInput>>;
  menuItemInput: MenuItemInput;
};


export type MutationDeleteMenuItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateMenuItemOptionArgs = {
  input: MenuItemOptionInput;
};


export type MutationUpdateMenuItemOptionArgs = {
  input: MenuItemOptionInput;
};


export type MutationDeleteMenuItemOptionArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOperatingHoursArgs = {
  input: OperatingHoursInput;
};


export type MutationUpdateOperatingHoursArgs = {
  input: OperatingHoursInput;
};


export type MutationDeleteOperatingHoursArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOptionArgs = {
  input: OptionInput;
};


export type MutationUpdateOptionArgs = {
  input: OptionInput;
};


export type MutationDeleteOptionArgs = {
  ID: Scalars['String'];
};


export type MutationDeleteOptionItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOptionItemArgs = {
  input: OptionItemInput;
};


export type MutationUpdateOptionItemArgs = {
  input: OptionItemInput;
};


export type MutationDragOptionItemArgs = {
  optionID: Scalars['String'];
  input: Array<OptionItemInput>;
};


export type MutationDeleteOutletArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletArgs = {
  outletInput: OutletInput;
};


export type MutationUpdateOutletArgs = {
  opHourInput: Array<OperatingHoursInput>;
  outletInput: OutletInput;
};


export type MutationUpdateOutletTaxSetupByTaxSchemeArgs = {
  taxSchemeID: Scalars['String'];
  outletID: Scalars['String'];
};


export type MutationCreateOutletCustomerPolicyArgs = {
  input: OutletCustomerPolicyInput;
};


export type MutationUpdateOutletCustomerPolicyArgs = {
  input: OutletCustomerPolicyInput;
};


export type MutationDeleteOutletCustomerPolicyArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitOutletCustomerPolicyArgs = {
  outletCustomerPolicyInput: OutletCustomerPolicyInput;
};


export type MutationCreateOutletTaxPolicyArgs = {
  input: OutletTaxPolicyInput;
};


export type MutationUpdateOutletTaxPolicyArgs = {
  input: OutletTaxPolicyInput;
};


export type MutationDeleteOutletTaxPolicyArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateOutletGovTaxPolicyArgs = {
  isInclusive: Scalars['Boolean'];
  outletID: Scalars['String'];
};


export type MutationCreatePatronArgs = {
  input: PatronInput;
};


export type MutationUpdatePatronArgs = {
  input: PatronInput;
};


export type MutationDeletePatronArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitOutletPatronSetupArgs = {
  outletID: Scalars['String'];
  patronIDs: Array<Scalars['String']>;
};


export type MutationCreatePromoCodeArgs = {
  input: PromoCodeInput;
};


export type MutationUpdatePromoCodeArgs = {
  input: PromoCodeInput;
};


export type MutationDeletePromoCodeArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePromoMenuArgs = {
  input: PromoMenuInput;
};


export type MutationUpdatePromoMenuArgs = {
  input: PromoMenuInput;
};


export type MutationDeletePromoMenuArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePromoMenuItemArgs = {
  input: PromoMenuItemInput;
};


export type MutationUpdatePromoMenuItemArgs = {
  input: PromoMenuItemInput;
};


export type MutationDeletePromoMenuItemArgs = {
  ID: Scalars['String'];
};


export type MutationDeletePromoMenuItemPriceArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePromoMenuItemPriceArgs = {
  promoMenuItemInput: Array<PromoMenuItemInput>;
  promoMenuID: Scalars['String'];
  outletID: Scalars['String'];
};


export type MutationUpdatePromoMenuItemPriceArgs = {
  promoMenuItemPriceInput: Array<PromoMenuItemPriceInput>;
  outletID: Scalars['String'];
  promoMenuItemID: Scalars['String'];
};


export type MutationDeletePromoMenuItemAndPriceArgs = {
  promoMenuItemID: Scalars['String'];
};


export type MutationCreateQrArgs = {
  input: QrInput;
};


export type MutationUpdateQrArgs = {
  input: QrInput;
};


export type MutationDeleteQrArgs = {
  ID: Scalars['String'];
};


export type MutationCreateReasonArgs = {
  input: ReasonInput;
};


export type MutationUpdateReasonArgs = {
  input: ReasonInput;
};


export type MutationDeleteReasonArgs = {
  ID: Scalars['String'];
};


export type MutationDeleteReceiptPrinterArgs = {
  ID: Scalars['String'];
};


export type MutationCreateReceiptPrinterArgs = {
  input: ReceiptPrinterInput;
};


export type MutationUpdateReceiptPrinterArgs = {
  input: ReceiptPrinterInput;
};


export type MutationCreateScheduledMenuArgs = {
  input: ScheduledMenuInput;
};


export type MutationUpdateScheduledMenuArgs = {
  input: ScheduledMenuInput;
};


export type MutationDeleteScheduledMenuArgs = {
  ID: Scalars['String'];
};


export type MutationCreateScheduledMenuAndPeriodsArgs = {
  scheduledMenuMealPeriodInput: Array<ScheduledMenuMealPeriodInput>;
  scheduledMenuInput: ScheduledMenuInput;
};


export type MutationUpdateScheduledMenuMealPeriodsArgs = {
  scheduledMenuMealPeriodInput: Array<ScheduledMenuMealPeriodInput>;
  scheduledMenuInput: ScheduledMenuInput;
};


export type MutationDeleteScheduledMenuMealPeriodsArgs = {
  scheduledMenuID: Scalars['String'];
};


export type MutationCreateSetMenuOptionArgs = {
  input: SetMenuOptionInput;
};


export type MutationUpdateSetMenuOptionArgs = {
  input: SetMenuOptionInput;
};


export type MutationDeleteSetMenuOptionArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSetMenuOptionItemArgs = {
  input: SetMenuOptionItemInput;
};


export type MutationUpdateSetMenuOptionItemArgs = {
  input: SetMenuOptionItemInput;
};


export type MutationDeleteSetMenuOptionItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSetMenuStandardOptionItemArgs = {
  outletID: Scalars['String'];
  setMenuID: Scalars['String'];
  setMenuOptionItemInput: Array<SetMenuOptionItemInput>;
};


export type MutationCreateSetMenuOptionOptionItemArgs = {
  setMenuOptionItemInput: Array<SetMenuOptionItemInput>;
};


export type MutationCreateStopSalesArgs = {
  input: StopSalesInput;
};


export type MutationUpdateStopSalesArgs = {
  input: StopSalesInput;
};


export type MutationDeleteStopSalesArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitStopSalesArgs = {
  outletID: Scalars['String'];
  input: Array<StopSalesInput>;
};


export type MutationCreateTableArgs = {
  input: TableInput;
};


export type MutationUpdateTableArgs = {
  input: TableInput;
};


export type MutationDeleteTableArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitTableArgs = {
  outletID: Scalars['String'];
  input?: Maybe<Array<TableInput>>;
};


export type MutationTransferTableArgs = {
  orderID: Scalars['String'];
  newTableID: Scalars['String'];
  oldTableID: Scalars['String'];
};


export type MutationCloseTableStatusArgs = {
  paymentByQuantityInput: Array<PaymentByQuantityInput>;
  billInput?: Maybe<BillInput>;
  tableID: Scalars['String'];
};


export type MutationDragTableArgs = {
  input: Array<TableInput>;
};


export type MutationCreateBillArgs = {
  input: BillInput;
};


export type MutationUpdateBillArgs = {
  input: BillInput;
};


export type MutationDeleteBillArgs = {
  ID: Scalars['String'];
};


export type MutationCreateBillAndPaymentArgs = {
  isTakeaway?: Maybe<Scalars['Boolean']>;
  paymentInput: PaymentInput;
  billInput: BillInput;
  orderItemIDs: Array<Scalars['String']>;
};


export type MutationCreateReceiptAttachmentArgs = {
  file: Scalars['Upload'];
  outletID: Scalars['String'];
  billID: Scalars['String'];
};


export type MutationVoidBillArgs = {
  scheduleBillingInput?: Maybe<ScheduleBillingInput>;
  billLedgerInput?: Maybe<BillLedgerInput>;
  billInput?: Maybe<BillInput>;
  paymentInput: PaymentInput;
};


export type MutationCreateBillDiscountArgs = {
  input: BillDiscountInput;
};


export type MutationUpdateBillDiscountArgs = {
  input: BillDiscountInput;
};


export type MutationDeleteBillDiscountArgs = {
  ID: Scalars['String'];
};


export type MutationDeleteOrderArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOrderArgs = {
  input: OrderInput;
};


export type MutationUpdateOrderArgs = {
  input: OrderInput;
};


export type MutationPickupTakeawayOrderArgs = {
  orderID: Scalars['String'];
};


export type MutationCancelTakeawayOrderArgs = {
  orderID: Scalars['String'];
};


export type MutationUpdateOrderDiscountArgs = {
  input: OrderDiscountInput;
};


export type MutationCreateOrderDiscountArgs = {
  input: OrderDiscountInput;
};


export type MutationDeleteOrderDiscountArgs = {
  input: OrderDiscountInput;
};


export type MutationCreateOrderItemArgs = {
  input: OrderItemInput;
};


export type MutationUpdateOrderItemArgs = {
  input: OrderItemInput;
};


export type MutationCreateOrderItemWithOptionsArgs = {
  orderItemOptionInput?: Maybe<Array<OrderItemOptionInput>>;
  orderItemInput: OrderItemInput;
};


export type MutationUpdateOrderItemWithOptionsArgs = {
  orderItemOptionInput?: Maybe<Array<OrderItemOptionInput>>;
  orderItemInput: OrderItemInput;
};


export type MutationCreateOrderItemWithSetMenuOptionsArgs = {
  orderItemSetMenuOptionInput?: Maybe<Array<OrderItemSetMenuOptionInput>>;
  orderItemInput: OrderItemInput;
};


export type MutationUpdateOrderItemWithSetMenuOptionsArgs = {
  orderItemSetMenuOptionInput?: Maybe<Array<OrderItemSetMenuOptionInput>>;
  orderItemInput: OrderItemInput;
};


export type MutationConfirmOrderItemArgs = {
  orderID: Scalars['String'];
  orderItemIDs: Array<Scalars['String']>;
};


export type MutationVoidOrderItemWithOptionsArgs = {
  file?: Maybe<Scalars['String']>;
  voidOrderItemInput: VoidOrderItemInput;
};


export type MutationVoidOrderItemWithSetMenuOptionsArgs = {
  voidOrderItemInput: VoidOrderItemInput;
};


export type MutationTransferItemByQuantityArgs = {
  sourceOrderID?: Maybe<Scalars['String']>;
  targetOrderID: Scalars['String'];
  transferInput: Array<TransferInput>;
};


export type MutationPostPrintDataArgs = {
  printData: PrintDataPayload;
};


export type MutationCreateOrderItemPatronAppArgs = {
  optionItemInput?: Maybe<Array<OrderItemOptionInput>>;
  orderInput: OrderItemInput;
};


export type MutationUpdateOrderItemDiscountArgs = {
  input: OrderItemDiscountInput;
};


export type MutationCreateOrderItemDiscountArgs = {
  input: OrderItemDiscountInput;
  orderID: Scalars['String'];
};


export type MutationDeleteOrderItemDiscountArgs = {
  input: OrderItemDiscountInput;
  orderID: Scalars['String'];
};


export type MutationUpdateKitchenStatusArgs = {
  InputItemID: Scalars['String'];
};


export type MutationRevertKitchenStatusArgs = {
  InputItemID: Scalars['String'];
};


export type MutationConfirmKitchenStatusArgs = {
  InputItemID: Array<Scalars['String']>;
};


export type MutationCreateOrderItemOptionArgs = {
  input: OrderItemOptionInput;
};


export type MutationUpdateOrderItemOptionArgs = {
  input: OrderItemOptionInput;
};


export type MutationDeleteOrderItemOptionArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOrderItemOptionItemArgs = {
  input: OrderItemOptionItemInput;
};


export type MutationUpdateOrderItemOptionItemArgs = {
  input: OrderItemOptionItemInput;
};


export type MutationDeleteOrderItemOptionItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOrderItemSetMenuOptionArgs = {
  input: OrderItemSetMenuOptionInput;
};


export type MutationUpdateOrderItemSetMenuOptionArgs = {
  input: OrderItemSetMenuOptionInput;
};


export type MutationDeleteOrderItemSetMenuOptionArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOrderItemSetMenuOptionItemArgs = {
  input: OrderItemSetMenuOptionItemInput;
};


export type MutationUpdateOrderItemSetMenuOptionItemArgs = {
  input: OrderItemSetMenuOptionItemInput;
};


export type MutationDeleteOrderItemSetMenuOptionItemArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateReservationArgs = {
  input: ReservationInput;
};


export type MutationDeleteReservationArgs = {
  ID: Scalars['String'];
};


export type MutationCreateReservationArgs = {
  input: ReservationInput;
};


export type MutationAssignReservationTableArgs = {
  reservationID: Scalars['String'];
  tableID: Scalars['String'];
};


export type MutationCancelReservationArgs = {
  reservationID: Scalars['String'];
};


export type MutationReinstateReservationArgs = {
  reservationID: Scalars['String'];
};


export type MutationCreateTableSessionArgs = {
  input: TableSessionInput;
};


export type MutationUpdateTableSessionArgs = {
  input: TableSessionInput;
};


export type MutationDeleteAddressArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOnlinePaymentArgs = {
  redirectUrl: Scalars['String'];
  payAmount: Scalars['Float'];
  detail: Scalars['String'];
  title: Scalars['String'];
  outletID: Scalars['String'];
};


export type MutationRefundPaymentArgs = {
  billID: Scalars['String'];
  outletID: Scalars['String'];
};


export type MutationCreatePaymentArgs = {
  input: PaymentInput;
};


export type MutationUpdatePaymentArgs = {
  input: PaymentInput;
};


export type MutationDeletePaymentArgs = {
  ID: Scalars['String'];
};


export type MutationVoidPaymentArgs = {
  scheduleBillingInput?: Maybe<ScheduleBillingInput>;
  billLedgerInput?: Maybe<BillLedgerInput>;
  billInput?: Maybe<BillInput>;
  paymentInput: PaymentInput;
};


export type MutationPaymentByQuantityArgs = {
  billLedgerInput?: Maybe<BillLedgerInput>;
  scheduleBillingInput?: Maybe<ScheduleBillingInput>;
  billInput: BillInput;
  paymentInput: PaymentInput;
  paymentByQuantityInput: Array<PaymentByQuantityInput>;
};


export type MutationCreatePatronTypeArgs = {
  input: PatronTypeInput;
};


export type MutationUpdatePatronTypeArgs = {
  input: PatronTypeInput;
};


export type MutationDeletePatronTypeArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePaymentTypeArgs = {
  input: PaymentTypeInput;
};


export type MutationUpdatePaymentTypeArgs = {
  input: PaymentTypeInput;
};


export type MutationDeletePaymentTypeArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePlatformTaxArgs = {
  input: PlatformTaxInput;
};


export type MutationUpdatePlatformTaxArgs = {
  input: PlatformTaxInput;
};


export type MutationDeletePlatformTaxArgs = {
  ID: Scalars['String'];
};


export type MutationCreateUomArgs = {
  input: UomInput;
};


export type MutationUpdateUomArgs = {
  input: UomInput;
};


export type MutationDeleteUomArgs = {
  ID: Scalars['String'];
};


export type MutationCreateUomConversionArgs = {
  input: UomConversionInput;
};


export type MutationUpdateUomConversionArgs = {
  input: UomConversionInput;
};


export type MutationDeleteUomConversionArgs = {
  ID: Scalars['String'];
};


export type MutationCreateMissingPatronArgs = {
  outletID: Scalars['String'];
};


export type MutationCreateMissingPaymentTypeArgs = {
  outletIDs: Array<Scalars['String']>;
};


export type MutationCreateMissingNextNumberArgs = {
  outletIDs: Array<Scalars['String']>;
};


export type MutationUpdateOutletBusinessDateArgs = {
  outletIDs: Array<Scalars['String']>;
};


export type MutationSetupNewSubscriptionArgs = {
  outletIDs: Scalars['String'];
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationResetRolePermissionArgs = {
  accountID: Scalars['String'];
};


export type MutationCreateOutletDailyMenuCategoryArgs = {
  input: OutletDailyMenuCategoryInput;
};


export type MutationUpdateOutletDailyMenuCategoryArgs = {
  input: OutletDailyMenuCategoryInput;
};


export type MutationDeleteOutletDailyMenuCategoryArgs = {
  ID: Scalars['String'];
};


export type MutationSyncOutletDailyMenuArgs = {
  manual?: Maybe<Scalars['Boolean']>;
  outletID: Scalars['String'];
};


export type MutationCreateOutletDailyMenuItemArgs = {
  input: OutletDailyMenuItemInput;
};


export type MutationUpdateOutletDailyMenuItemArgs = {
  input: OutletDailyMenuItemInput;
};


export type MutationDeleteOutletDailyMenuItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletDailyMenuItemOptionArgs = {
  input: OutletDailyMenuItemOptionInput;
};


export type MutationUpdateOutletDailyMenuItemOptionArgs = {
  input: OutletDailyMenuItemOptionInput;
};


export type MutationDeleteOutletDailyMenuItemOptionArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletDailyMenuItemOptionItemArgs = {
  input: OutletDailyMenuItemOptionItemInput;
};


export type MutationUpdateOutletDailyMenuItemOptionItemArgs = {
  input: OutletDailyMenuItemOptionItemInput;
};


export type MutationDeleteOutletDailyMenuItemOptionItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletDailyMenuItemPatronPriceArgs = {
  input: OutletDailyMenuItemPatronPriceInput;
};


export type MutationUpdateOutletDailyMenuItemPatronPriceArgs = {
  input: OutletDailyMenuItemPatronPriceInput;
};


export type MutationDeleteOutletDailyMenuItemPatronPriceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletDailyPromoMenuItemArgs = {
  input: OutletDailyPromoMenuItemInput;
};


export type MutationUpdateOutletDailyPromoMenuItemArgs = {
  input: OutletDailyPromoMenuItemInput;
};


export type MutationDeleteOutletDailyPromoMenuItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletDailyPromoMenuItemPriceArgs = {
  input: OutletDailyPromoMenuItemPriceInput;
};


export type MutationUpdateOutletDailyPromoMenuItemPriceArgs = {
  input: OutletDailyPromoMenuItemPriceInput;
};


export type MutationDeleteOutletDailyPromoMenuItemPriceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletDailySetMenuOptionArgs = {
  input: OutletDailySetMenuOptionInput;
};


export type MutationUpdateOutletDailySetMenuOptionArgs = {
  input: OutletDailySetMenuOptionInput;
};


export type MutationDeleteOutletDailySetMenuOptionArgs = {
  ID: Scalars['String'];
};


export type MutationCreateOutletDailySetMenuOptionItemArgs = {
  input: OutletDailySetMenuOptionItemInput;
};


export type MutationUpdateOutletDailySetMenuOptionItemArgs = {
  input: OutletDailySetMenuOptionItemInput;
};


export type MutationDeleteOutletDailySetMenuOptionItemArgs = {
  ID: Scalars['String'];
};


export type MutationBatchDeleteOrderItemArgs = {
  orderItemID: Array<Scalars['String']>;
};


export type MutationDailyMenuRevenueInsertArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  accountID?: Maybe<Scalars['String']>;
  hotelID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};

export type NextNumberEntity = {
  __typename?: 'NextNumberEntity';
  ID: Scalars['String'];
  accountID: Scalars['String'];
  outletID: Scalars['String'];
  docType: DocType;
  prefix: Scalars['String'];
  fill: Scalars['Float'];
  startNumber: Scalars['Float'];
  nextNumber: Scalars['Float'];
};

export type OfficerEntity = AuditEntity & {
  __typename?: 'OfficerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  designation: Scalars['String'];
  mobileNo: Scalars['String'];
  email: Scalars['String'];
};

export type OfficerInput = {
  name: Scalars['String'];
  designation: Scalars['String'];
  mobileNo: Scalars['String'];
  email: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  ID?: Maybe<Scalars['String']>;
};

export type OperatingHoursEntity = AuditEntity & {
  __typename?: 'OperatingHoursEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  outlet?: Maybe<OutletEntity>;
  day: RecurrenceDay;
  openingTime: Scalars['String'];
  closingTime: Scalars['String'];
};

export type OperatingHoursInput = {
  outletID?: Maybe<Scalars['String']>;
  openingTime: Scalars['String'];
  closingTime: Scalars['String'];
  day: RecurrenceDay;
};

export type OptionEntity = AuditEntity & {
  __typename?: 'OptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  outletID: Scalars['String'];
  optionItem?: Maybe<Array<OptionItemEntity>>;
  menuItemOption?: Maybe<Array<MenuItemOptionEntity>>;
  orderItemOption?: Maybe<Array<OrderItemOptionEntity>>;
  paymentItemOption?: Maybe<Array<PaymentItemOptionEntity>>;
};

export type OptionInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  name: Scalars['String'];
};

/** JsonType */
export type OptionItemDiscountTaxInfo = {
  __typename?: 'OptionItemDiscountTaxInfo';
  ID: Scalars['String'];
  orderItemDiscountID: Scalars['String'];
  orderItemOptionItemID: Scalars['String'];
  taxID: Scalars['String'];
  taxSchemeDetailID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxAmount: Scalars['Float'];
  taxRate: Scalars['Float'];
};

export type OptionItemEntity = AuditEntity & {
  __typename?: 'OptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  priority?: Maybe<Scalars['Float']>;
  outletID?: Maybe<Scalars['String']>;
  optionID: Scalars['String'];
  option?: Maybe<OptionEntity>;
  menuItemOptionItem?: Maybe<MenuItemOptionItemEntity>;
  orderItemOptionItem?: Maybe<OrderItemOptionItemEntity>;
  paymentItemOptionItem?: Maybe<PaymentItemOptionItemEntity>;
};

export type OptionItemInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
  outletID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  optionID: Scalars['String'];
};

export type OrderDiscountEntity = AuditEntity & {
  __typename?: 'OrderDiscountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  discountID: Scalars['String'];
  outletID: Scalars['String'];
  businessDate: Scalars['DateTime'];
  status: OrderDiscountStatus;
  amount?: Maybe<Scalars['Float']>;
  discountedBy?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<Scalars['String']>;
  orderID: Scalars['String'];
  order?: Maybe<OrderEntity>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  isGLPosted?: Maybe<Scalars['Boolean']>;
  orderDiscountItem?: Maybe<Array<OrderDiscountItemEntity>>;
};

export type OrderDiscountInput = {
  outletID: Scalars['String'];
  orderID: Scalars['String'];
  discountID?: Maybe<Scalars['String']>;
  businessDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderDiscountStatus>;
  amount?: Maybe<Scalars['Float']>;
  discountedBy?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  reasonCode?: Maybe<Scalars['String']>;
};

export type OrderDiscountItemEntity = AuditEntity & {
  __typename?: 'OrderDiscountItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  orderDiscountID?: Maybe<Scalars['String']>;
  orderItemID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  orderDiscount?: Maybe<OrderDiscountEntity>;
  orderItem?: Maybe<OrderItemEntity>;
  orderDiscountItemTax?: Maybe<Array<OrderDiscountItemTaxV1Entity>>;
  orderDiscountItemOptionTax?: Maybe<Array<OrderDiscountItemOptionTaxEntity>>;
};

export type OrderDiscountItemOptionTaxEntity = AuditEntity & {
  __typename?: 'OrderDiscountItemOptionTaxEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  orderItemOptionItemID: Scalars['String'];
  orderDiscountItemID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID: Scalars['String'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  orderDiscountItem?: Maybe<OrderDiscountItemEntity>;
  orderItemOptionItem?: Maybe<OrderItemOptionItemEntity>;
};

export type OrderDiscountItemTaxV1Entity = AuditEntity & {
  __typename?: 'OrderDiscountItemTaxV1Entity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  orderItemID: Scalars['String'];
  orderDiscountItemID: Scalars['String'];
  orderDiscountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID: Scalars['String'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  orderItem?: Maybe<OrderItemEntity>;
  orderDiscountItem?: Maybe<OrderDiscountItemEntity>;
};

export enum OrderDiscountStatus {
  Active = 'ACTIVE',
  Void = 'VOID'
}

export type OrderEntity = AuditEntity & {
  __typename?: 'OrderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  registrationID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  serviceMode: ServiceMode;
  openedByID: Scalars['String'];
  servedByIDs: Array<Scalars['String']>;
  paidByID?: Maybe<Scalars['String']>;
  businessDate: Scalars['DateTime'];
  pax?: Maybe<Scalars['Float']>;
  patronName?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  pickupTime?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  status: OrderStatus;
  refOrderID?: Maybe<Scalars['String']>;
  orderItem?: Maybe<Array<OrderItemEntity>>;
  bill?: Maybe<Array<BillEntity>>;
  tableID?: Maybe<Scalars['String']>;
  table?: Maybe<TableEntity>;
  qrID: Scalars['String'];
  mealPeriodID: Scalars['String'];
  mealPeriod?: Maybe<MealPeriodEntity>;
  orderDiscount?: Maybe<Array<OrderDiscountEntity>>;
  patronID: Scalars['String'];
  patron?: Maybe<PatronEntity>;
  patronAccountID?: Maybe<Scalars['String']>;
  paymentItem?: Maybe<Array<PaymentItemEntity>>;
  /** CustomFieldResolver */
  qr?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAmount?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingAmount?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalDiscountAmount?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  taxSchemeInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  taxSchemeFooterInfo?: Maybe<Scalars['JSON']>;
};

/** JsonType */
export type OrderInfo = {
  __typename?: 'OrderInfo';
  totalAmount: Scalars['Float'];
  totalPax: Scalars['Float'];
};

export type OrderInput = {
  ID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  tableID?: Maybe<Scalars['String']>;
  qrID: Scalars['String'];
  serviceMode?: Maybe<ServiceMode>;
  openedByID?: Maybe<Scalars['String']>;
  servedByIDs?: Maybe<Array<Scalars['String']>>;
  promoIDs?: Maybe<Array<Scalars['String']>>;
  paidByID?: Maybe<Scalars['String']>;
  businessDate?: Maybe<Scalars['DateTime']>;
  pax?: Maybe<Scalars['Float']>;
  mealPeriodID?: Maybe<Scalars['String']>;
  patronID: Scalars['String'];
  patronAccountID?: Maybe<Scalars['String']>;
  patronName?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  pickupTime?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  registrationID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
};

export type OrderItemDiscountEntity = AuditEntity & {
  __typename?: 'OrderItemDiscountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  discountID: Scalars['String'];
  orderItemID: Scalars['String'];
  orderItem?: Maybe<OrderItemEntity>;
  status: OrderItemDiscountStatus;
  amount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  discountedBy?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<Scalars['String']>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  isGLPosted?: Maybe<Scalars['Boolean']>;
  optAmount?: Maybe<Scalars['Float']>;
  optBaseAmount?: Maybe<Scalars['Float']>;
  optTaxAmount?: Maybe<Scalars['Float']>;
  optServiceCharge?: Maybe<Scalars['Float']>;
  orderItemDiscountTax?: Maybe<Array<OrderItemDiscountTaxEntity>>;
  orderItemOptionItemDiscountTax?: Maybe<Array<OrderItemOptionItemDiscountTaxEntity>>;
};

export type OrderItemDiscountInput = {
  outletID: Scalars['String'];
  orderItemID: Scalars['String'];
  status?: Maybe<OrderItemDiscountStatus>;
  discountID?: Maybe<Scalars['String']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  discountedBy?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  reasonCode?: Maybe<Scalars['String']>;
  optAmount?: Maybe<Scalars['Float']>;
  optBaseAmount?: Maybe<Scalars['Float']>;
  optTaxAmount?: Maybe<Scalars['Float']>;
  optServiceCharge?: Maybe<Scalars['Float']>;
};

export enum OrderItemDiscountStatus {
  Active = 'ACTIVE',
  Void = 'VOID'
}

export type OrderItemDiscountTaxEntity = AuditEntity & {
  __typename?: 'OrderItemDiscountTaxEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  orderItemID: Scalars['String'];
  orderItemDiscountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID: Scalars['String'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  orderItemDiscount?: Maybe<OrderItemDiscountEntity>;
  orderItem?: Maybe<OrderItemEntity>;
};

export type OrderItemEntity = AuditEntity & {
  __typename?: 'OrderItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  openItemName?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  refID?: Maybe<Scalars['String']>;
  specialRequest?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  isTakeaway?: Maybe<Scalars['Boolean']>;
  isPrintToKitchen?: Maybe<Scalars['Boolean']>;
  businessDate: Scalars['DateTime'];
  status: OrderItemStatus;
  kitchenStatus?: Maybe<KitchenStatus>;
  unitPrice: Scalars['Float'];
  quantity: Scalars['Float'];
  name: Scalars['String'];
  description: Scalars['String'];
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  promoID?: Maybe<Scalars['String']>;
  orderID: Scalars['String'];
  order?: Maybe<OrderEntity>;
  orderItemDiscount?: Maybe<Array<OrderItemDiscountEntity>>;
  paymentItem?: Maybe<Array<PaymentItemEntity>>;
  menuItemID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
  mealPeriodID: Scalars['String'];
  mealPeriod?: Maybe<MealPeriodEntity>;
  billID?: Maybe<Scalars['String']>;
  bill?: Maybe<BillEntity>;
  orderItemOption?: Maybe<Array<OrderItemOptionEntity>>;
  orderItemSetMenuOption?: Maybe<Array<OrderItemSetMenuOptionEntity>>;
  isGLPosted?: Maybe<Scalars['Boolean']>;
  orderDiscountItem?: Maybe<OrderDiscountItemEntity>;
  orderItemTax?: Maybe<Array<OrderItemTaxEntity>>;
  orderItemDiscountTax?: Maybe<Array<OrderItemDiscountTaxEntity>>;
  orderDiscountItemTax?: Maybe<Array<OrderDiscountItemTaxV1Entity>>;
  /** CustomFieldResolver */
  totalInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  unitPriceInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  orderDiscount?: Maybe<Scalars['Float']>;
};

export type OrderItemInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  openItemName?: Maybe<Scalars['String']>;
  billID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  specialRequest?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  isTakeaway?: Maybe<Scalars['Boolean']>;
  isPrintToKitchen?: Maybe<Scalars['Boolean']>;
  businessDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unitPrice: Scalars['Float'];
  menuItemID: Scalars['String'];
  outletID: Scalars['String'];
  orderID: Scalars['String'];
  mealPeriodID: Scalars['String'];
  status: OrderItemStatus;
  kitchenStatus?: Maybe<KitchenStatus>;
};

export type OrderItemOptionEntity = AuditEntity & {
  __typename?: 'OrderItemOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  orderItemID: Scalars['String'];
  orderItem?: Maybe<OrderItemEntity>;
  optionID: Scalars['String'];
  option?: Maybe<OptionEntity>;
  orderItemOptionItem?: Maybe<Array<OrderItemOptionItemEntity>>;
};

export type OrderItemOptionInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderItemID?: Maybe<Scalars['String']>;
  optionID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  orderItemOptionItems: Array<OrderItemOptionItemInput>;
};

export type OrderItemOptionItemDiscountTaxEntity = AuditEntity & {
  __typename?: 'OrderItemOptionItemDiscountTaxEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  orderItemOptionItemID: Scalars['String'];
  orderItemDiscountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID: Scalars['String'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  orderItemDiscount?: Maybe<OrderItemDiscountEntity>;
  orderItemOptionItem?: Maybe<OrderItemOptionItemEntity>;
};

export type OrderItemOptionItemEntity = AuditEntity & {
  __typename?: 'OrderItemOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  basePrice?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  optionItemID: Scalars['String'];
  optionItem?: Maybe<OptionItemEntity>;
  orderItemOptionID: Scalars['String'];
  orderItemOption?: Maybe<OrderItemOptionEntity>;
  isGLPosted?: Maybe<Scalars['Boolean']>;
  discountAmount?: Maybe<Scalars['Float']>;
  discountBaseAmount?: Maybe<Scalars['Float']>;
  discountTaxAmount?: Maybe<Scalars['Float']>;
  discountServiceCharge?: Maybe<Scalars['Float']>;
  orderItemOptionItemTax?: Maybe<Array<OrderItemOptionItemTaxEntity>>;
  orderItemOptionItemDiscountTax?: Maybe<Array<OrderItemOptionItemDiscountTaxEntity>>;
  orderDiscountItemOptionTax?: Maybe<Array<OrderDiscountItemOptionTaxEntity>>;
};

export type OrderItemOptionItemInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  basePrice?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  optionItemID: Scalars['String'];
  orderItemOptionID?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['Float']>;
  discountBaseAmount?: Maybe<Scalars['Float']>;
  discountTaxAmount?: Maybe<Scalars['Float']>;
  discountServiceCharge?: Maybe<Scalars['Float']>;
};

export type OrderItemOptionItemTaxEntity = AuditEntity & {
  __typename?: 'OrderItemOptionItemTaxEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  orderItemID: Scalars['String'];
  orderItemOptionItemID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID: Scalars['String'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  orderItemOptionItem?: Maybe<OrderItemOptionItemEntity>;
};

export type OrderItemSetMenuOptionEntity = AuditEntity & {
  __typename?: 'OrderItemSetMenuOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  orderItemID: Scalars['String'];
  orderItem?: Maybe<OrderItemEntity>;
  setMenuOptionID: Scalars['String'];
  setMenuOption?: Maybe<SetMenuOptionEntity>;
  orderItemSetMenuOptionItem?: Maybe<Array<OrderItemSetMenuOptionItemEntity>>;
};

export type OrderItemSetMenuOptionInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  setMenuOptionID: Scalars['String'];
  orderItemID?: Maybe<Scalars['String']>;
  orderItemSetMenuOptionItemInput: Array<OrderItemSetMenuOptionItemInput>;
};

export type OrderItemSetMenuOptionItemEntity = AuditEntity & {
  __typename?: 'OrderItemSetMenuOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  setMenuOptionItemID: Scalars['String'];
  setMenuOptionItem?: Maybe<SetMenuOptionItemEntity>;
  orderItemSetMenuOptionID: Scalars['String'];
  orderItemSetMenuOption?: Maybe<OrderItemSetMenuOptionEntity>;
  menuItemID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
};

export type OrderItemSetMenuOptionItemInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  menuItemID: Scalars['String'];
  setMenuOptionItemID: Scalars['String'];
  orderItemSetMenuOptionID?: Maybe<Scalars['String']>;
};

export enum OrderItemStatus {
  Ordered = 'ORDERED',
  Served = 'SERVED',
  Paid = 'PAID',
  Preparing = 'PREPARING',
  Prepared = 'PREPARED',
  Pending = 'PENDING',
  Voided = 'VOIDED',
  Confirmed = 'CONFIRMED',
  PaidIn = 'PAID_IN',
  PaidOut = 'PAID_OUT',
  TransferIn = 'TRANSFER_IN',
  TransferOut = 'TRANSFER_OUT'
}

export type OrderItemTaxEntity = AuditEntity & {
  __typename?: 'OrderItemTaxEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  orderItemID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  taxSchemeDetailID?: Maybe<Scalars['String']>;
  taxID: Scalars['String'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  orderItem?: Maybe<OrderItemEntity>;
  /** CustomFieldResolver */
  taxSchemeType?: Maybe<Scalars['JSON']>;
};

export enum OrderStatus {
  Delivered = 'DELIVERED',
  InTransit = 'IN_TRANSIT',
  PaymentDue = 'PAYMENT_DUE',
  PaymentComplete = 'PAYMENT_COMPLETE',
  PickupAvailable = 'PICKUP_AVAILABLE',
  Problem = 'PROBLEM',
  Processing = 'PROCESSING',
  Returned = 'RETURNED',
  Open = 'OPEN',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  PickedUp = 'PICKED_UP',
  Splitted = 'SPLITTED'
}

export type OutletChannelEntity = AuditEntity & {
  __typename?: 'OutletChannelEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name: Scalars['String'];
  secret: Scalars['String'];
  user: Scalars['String'];
  url: Scalars['String'];
  hotelID: Scalars['String'];
  outlet?: Maybe<OutletEntity>;
};

export type OutletCustomerPolicyEntity = AuditEntity & {
  __typename?: 'OutletCustomerPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  selfOrder?: Maybe<Scalars['Boolean']>;
  selfPayment?: Maybe<Scalars['Boolean']>;
  qrCodeGenerate?: Maybe<Scalars['Boolean']>;
  outlet?: Maybe<OutletEntity>;
};

export type OutletCustomerPolicyInput = {
  outletID: Scalars['String'];
  selfOrder?: Maybe<Scalars['Boolean']>;
  selfPayment?: Maybe<Scalars['Boolean']>;
  qrCodeGenerate?: Maybe<Scalars['Boolean']>;
};

export type OutletDailyMenuCategoryEntity = AuditEntity & {
  __typename?: 'OutletDailyMenuCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name: Scalars['String'];
  priority: Scalars['Float'];
  businessDate: Scalars['DateTime'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  mealPeriodID: Scalars['String'];
  digitalMenuCategoryID: Scalars['String'];
  outletDailyMenuItem?: Maybe<Array<OutletDailyMenuItemEntity>>;
};

export type OutletDailyMenuCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  priority: Scalars['Float'];
  businessDate: Scalars['DateTime'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  mealPeriodID: Scalars['String'];
  digitalMenuCategoryID: Scalars['String'];
  isByWeight: Scalars['Boolean'];
  outletID: Scalars['String'];
};

export type OutletDailyMenuItemEntity = AuditEntity & {
  __typename?: 'OutletDailyMenuItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  defaultQuantity?: Maybe<Scalars['Float']>;
  uomID?: Maybe<Scalars['String']>;
  isOpenItem: Scalars['Boolean'];
  isOpenPrice: Scalars['Boolean'];
  isChefRecommended: Scalars['Boolean'];
  isQuantityControlled: Scalars['Boolean'];
  isTaxable: Scalars['Boolean'];
  isDiscountable: Scalars['Boolean'];
  isSetMenu: Scalars['Boolean'];
  isServiceChargeable: Scalars['Boolean'];
  isByWeight: Scalars['Boolean'];
  outletDailyMenuCategoryID: Scalars['String'];
  outletDailyMenuCategory?: Maybe<OutletDailyMenuCategoryEntity>;
  digitalMenuItemID: Scalars['String'];
  menuItemID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
  outletDailyPromoMenuItem?: Maybe<Array<OutletDailyPromoMenuItemEntity>>;
  outletDailyMenuItemOption?: Maybe<Array<OutletDailyMenuItemOptionEntity>>;
  outletDailySetMenuOption?: Maybe<Array<OutletDailySetMenuOptionEntity>>;
  outletDailySetMenuOptionItem?: Maybe<Array<OutletDailySetMenuOptionItemEntity>>;
  outletDailyMenuItemPatronPrice?: Maybe<Array<OutletDailyMenuItemPatronPriceEntity>>;
  /** CustomFieldResolver */
  attachments?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  promoChecking?: Maybe<Scalars['Boolean']>;
};

export type OutletDailyMenuItemInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletDailyMenuCategoryID: Scalars['String'];
  menuItemID: Scalars['String'];
  digitalMenuItemID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isOpenItem: Scalars['Boolean'];
  isOpenPrice: Scalars['Boolean'];
  isChefRecommended: Scalars['Boolean'];
  isQuantityControlled: Scalars['Boolean'];
  defaultQuantity: Scalars['Float'];
  isTaxable: Scalars['Boolean'];
  isDiscountable: Scalars['Boolean'];
  isSetMenu: Scalars['Boolean'];
  uomID: Scalars['String'];
  isByWeight: Scalars['Boolean'];
  isServiceChargeable: Scalars['Boolean'];
};

export type OutletDailyMenuItemOptionEntity = AuditEntity & {
  __typename?: 'OutletDailyMenuItemOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name: Scalars['String'];
  maxSelections: Scalars['Float'];
  outletDailyMenuItemID: Scalars['String'];
  outletDailyMenuItem?: Maybe<OutletDailyMenuItemEntity>;
  optionID: Scalars['String'];
  menuItemOptionID: Scalars['String'];
  outletDailyMenuItemOptionItem?: Maybe<Array<OutletDailyMenuItemOptionItemEntity>>;
};

export type OutletDailyMenuItemOptionInput = {
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  outletDailyMenuItemID: Scalars['String'];
  optionID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  maxSelections?: Maybe<Scalars['Float']>;
};

export type OutletDailyMenuItemOptionItemEntity = AuditEntity & {
  __typename?: 'OutletDailyMenuItemOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  optionItemID: Scalars['String'];
  outletDailyMenuItemOptionID: Scalars['String'];
  outletDailyMenuItemOption?: Maybe<OutletDailyMenuItemOptionEntity>;
};

export type OutletDailyMenuItemOptionItemInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  outletDailyMenuItemOptionID?: Maybe<Scalars['String']>;
  optionItemID?: Maybe<Scalars['String']>;
};

export type OutletDailyMenuItemPatronPriceEntity = AuditEntity & {
  __typename?: 'OutletDailyMenuItemPatronPriceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  price: Scalars['Float'];
  name: Scalars['String'];
  description: Scalars['String'];
  patronClass: Scalars['String'];
  outletDailyMenuItemID: Scalars['String'];
  outletDailyMenuItem?: Maybe<OutletDailyMenuItemEntity>;
  patronID: Scalars['String'];
};

export type OutletDailyMenuItemPatronPriceInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  patronClass: PatronClass;
  outletDailyMenuItemID?: Maybe<Scalars['String']>;
};

export type OutletDailyPromoMenuItemEntity = AuditEntity & {
  __typename?: 'OutletDailyPromoMenuItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  businessDate: Scalars['DateTime'];
  promoMenuItemID: Scalars['String'];
  outletDailyMenuItemID: Scalars['String'];
  outletDailyMenuItem?: Maybe<OutletDailyMenuItemEntity>;
  outletDailyPromoMenuItemPrice?: Maybe<Array<OutletDailyPromoMenuItemPriceEntity>>;
};

export type OutletDailyPromoMenuItemInput = {
  ID: Scalars['String'];
  outletDailyMenuItemID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  startTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
};

export type OutletDailyPromoMenuItemPriceEntity = AuditEntity & {
  __typename?: 'OutletDailyPromoMenuItemPriceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  price: Scalars['Float'];
  name: Scalars['String'];
  description: Scalars['String'];
  patronClass: Scalars['String'];
  outletDailyPromoMenuItemID: Scalars['String'];
  outletDailyPromoMenuItem?: Maybe<OutletDailyPromoMenuItemEntity>;
  patronID: Scalars['String'];
};

export type OutletDailyPromoMenuItemPriceInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  patronClass: PatronClass;
  outletDailyPromoMenuItemID?: Maybe<Scalars['String']>;
};

export type OutletDailySetMenuOptionEntity = AuditEntity & {
  __typename?: 'OutletDailySetMenuOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  maxSelections: Scalars['Float'];
  setMenuOptionID: Scalars['String'];
  outletDailyMenuItemID: Scalars['String'];
  outletDailyMenuItem?: Maybe<OutletDailyMenuItemEntity>;
  outletDailySetMenuOptionItem?: Maybe<Array<OutletDailySetMenuOptionItemEntity>>;
};

export type OutletDailySetMenuOptionInput = {
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  outletDailyMenuItemID?: Maybe<Scalars['String']>;
  setMenuOptionID?: Maybe<Scalars['String']>;
  maxSelections?: Maybe<Scalars['Float']>;
};

export type OutletDailySetMenuOptionItemEntity = AuditEntity & {
  __typename?: 'OutletDailySetMenuOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  outletDailySetMenuOptionID: Scalars['String'];
  outletDailySetMenuOption?: Maybe<OutletDailySetMenuOptionEntity>;
  outletDailyMenuItemID: Scalars['String'];
  outletDailyMenuItem?: Maybe<OutletDailyMenuItemEntity>;
};

export type OutletDailySetMenuOptionItemInput = {
  createdTs: Scalars['DateTime'];
  createBy: Scalars['String'];
  modTs: Scalars['DateTime'];
  modBy: Scalars['String'];
  isActive: Scalars['Boolean'];
  ID: Scalars['String'];
  accountID: Scalars['String'];
  outletDailySetMenuOptionID: Scalars['String'];
  outletDailyMenuItemID: Scalars['String'];
  quantity: Scalars['Float'];
};

export type OutletEntity = AuditEntity & {
  __typename?: 'OutletEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  companyID: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  tables?: Maybe<Array<TableEntity>>;
  outletCode?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  isInclusive: Scalars['Boolean'];
  integrateCityLedger: Scalars['Boolean'];
  capacity: Scalars['Float'];
  registrationNo: Scalars['String'];
  taxRegistrationNo: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  address: Scalars['JSON'];
  coordinate?: Maybe<Scalars['JSON']>;
  closingTime?: Maybe<Scalars['String']>;
  openingTime?: Maybe<Scalars['String']>;
  autoDayEndTime?: Maybe<Scalars['String']>;
  operatingHours?: Maybe<Array<OperatingHoursEntity>>;
  menuItem?: Maybe<Array<MenuItemEntity>>;
  bill?: Maybe<Array<BillEntity>>;
  promoMenu?: Maybe<Array<PromoMenuEntity>>;
  businessDate: Scalars['DateTime'];
  paymentGateway?: Maybe<PaymentGatewayEntity>;
  onlinePaymentStoreID?: Maybe<Scalars['String']>;
  outletChannel?: Maybe<OutletChannelEntity>;
  customerPolicy?: Maybe<OutletCustomerPolicyEntity>;
  outletGLAccount?: Maybe<AccountJson>;
  outletRoundingGLAccount?: Maybe<AccountJson>;
  outletDiscountGLAcct?: Maybe<AccountJson>;
  outletOCDiscountGLAcct?: Maybe<AccountJson>;
  outletENTDiscountGLAcct?: Maybe<AccountJson>;
  taxSchemeID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  tablesInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  logo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  gallery?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  govTax?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  serviceCharge?: Maybe<Scalars['Float']>;
};

export type OutletInput = {
  ID?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  hotelID?: Maybe<Scalars['String']>;
  address: AddressInput;
  coordinate: CoordinateInput;
  registrationNo: Scalars['String'];
  taxRegistrationNo: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  closingTime: Scalars['String'];
  openingTime: Scalars['String'];
  autoDayEndTime: Scalars['String'];
  outletCode?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  govTax?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Scalars['Float']>;
  integrateCityLedger?: Maybe<Scalars['Boolean']>;
  galleryFiles?: Maybe<Array<Scalars['Upload']>>;
  logoFiles?: Maybe<Array<Scalars['Upload']>>;
  taxSchemeID?: Maybe<Scalars['String']>;
};

export type OutletTaxOutput = {
  __typename?: 'OutletTaxOutput';
  taxScheme?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
  isTaxable?: Maybe<Scalars['Boolean']>;
};

export type OutletTaxPolicyEntity = AuditEntity & {
  __typename?: 'OutletTaxPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  taxType: TaxType;
  isTaxable?: Maybe<Scalars['Boolean']>;
  effectiveDate: Scalars['DateTime'];
  value: Scalars['Float'];
  outletGLAccount?: Maybe<AccountJson>;
  outletOCGLAccount?: Maybe<AccountJson>;
  outletENTGLAccount?: Maybe<AccountJson>;
  outletDiscountGLAcct?: Maybe<AccountJson>;
  outletOCDiscountGLAcct?: Maybe<AccountJson>;
  outletENTDiscountGLAcct?: Maybe<AccountJson>;
};

export type OutletTaxPolicyGlEntity = AuditEntity & {
  __typename?: 'OutletTaxPolicyGLEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  outletID: Scalars['String'];
  taxType?: Maybe<Scalars['String']>;
  discountType?: Maybe<Scalars['String']>;
  outletGLAccount?: Maybe<AccountJson>;
};

export type OutletTaxPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  taxType: TaxType;
  isTaxable?: Maybe<Scalars['Boolean']>;
  effectiveDate: Scalars['DateTime'];
  value: Scalars['Float'];
};

export enum PosPermission {
  RestaurantIcon = 'RESTAURANT__________ICON',
  KitchenIcon = 'KITCHEN__________ICON',
  PrinterServerIcon = 'PRINTER_SERVER__________ICON',
  HomeTableLayoutView = 'HOME_TABLE_LAYOUT_______VIEW',
  HomeTableLayoutNewOrderCreate = 'HOME_TABLE_LAYOUT_NEW_ORDER____CREATE',
  HomeTableLayoutAddEditOrderItemView = 'HOME_TABLE_LAYOUT_ADD_EDIT_ORDER_ITEM____VIEW',
  HomeTableLayoutAddEditOrderItemVoidOrderItemVoid = 'HOME_TABLE_LAYOUT_ADD_EDIT_ORDER_ITEM_VOID_ORDER_ITEM_VOID',
  HomeTableLayoutEditOrderInfoView = 'HOME_TABLE_LAYOUT_EDIT_ORDER_INFO____VIEW',
  HomeTableLayoutChangeTransferTableView = 'HOME_TABLE_LAYOUT_CHANGE_TRANSFER_TABLE____VIEW',
  HomeTableLayoutItemTransferView = 'HOME_TABLE_LAYOUT_ITEM_TRANSFER____VIEW',
  HomeTableLayoutBillItemDiscountView = 'HOME_TABLE_LAYOUT_BILL_ITEM_DISCOUNT____VIEW',
  HomeTableLayoutBillItemDiscountCreateOrderDiscountCreate = 'HOME_TABLE_LAYOUT_BILL_ITEM_DISCOUNT_CREATE_ORDER_DISCOUNT_CREATE',
  HomeTableLayoutBillItemDiscountCreateOrderItemDiscountCreate = 'HOME_TABLE_LAYOUT_BILL_ITEM_DISCOUNT_CREATE_ORDER_ITEM_DISCOUNT_CREATE',
  HomeTableLayoutBillSettlementView = 'HOME_TABLE_LAYOUT_BILL_SETTLEMENT____VIEW',
  HomeTableLayoutCloseTableView = 'HOME_TABLE_LAYOUT_CLOSE_TABLE____VIEW',
  TakeawayShow = 'TAKEAWAY____________SHOW',
  TakeawayCreate = 'TAKEAWAY____________CREATE',
  TakeawayTakeawayVoidOrderItemVoid = 'TAKEAWAY_TAKEAWAY_VOID_ORDER_ITEM_________VOID',
  TakeawayPickUpUpdate = 'TAKEAWAY_PICK_UP_________UPDATE',
  TakeawayAddOrderCreate = 'TAKEAWAY_ADD_ORDER_________CREATE',
  TakeawayCancelOrderUpdate = 'TAKEAWAY_CANCEL_ORDER_________UPDATE',
  TakeawayMakePaymentCreate = 'TAKEAWAY_MAKE_PAYMENT_________CREATE',
  GeneralSettingShow = 'GENERAL_SETTING____________SHOW',
  GeneralSettingAppointedOfficerCreate = 'GENERAL_SETTING_APPOINTED_OFFICER_________CREATE',
  GeneralSettingAppointedOfficerShow = 'GENERAL_SETTING_APPOINTED_OFFICER_________SHOW',
  GeneralSettingAppointedOfficerUpdate = 'GENERAL_SETTING_APPOINTED_OFFICER_________UPDATE',
  GeneralSettingAppointedOfficerDelete = 'GENERAL_SETTING_APPOINTED_OFFICER_________DELETE',
  GeneralSettingAuthorisedStaffCreate = 'GENERAL_SETTING_AUTHORISED_STAFF_________CREATE',
  GeneralSettingAuthorisedStaffShow = 'GENERAL_SETTING_AUTHORISED_STAFF_________SHOW',
  GeneralSettingAuthorisedStaffUpdate = 'GENERAL_SETTING_AUTHORISED_STAFF_________UPDATE',
  GeneralSettingAuthorisedStaffDelete = 'GENERAL_SETTING_AUTHORISED_STAFF_________DELETE',
  GeneralSettingAccessSecurityShow = 'GENERAL_SETTING_ACCESS_SECURITY_________SHOW',
  GeneralSettingAccessSecurityUsersCreate = 'GENERAL_SETTING_ACCESS_SECURITY_USERS______CREATE',
  GeneralSettingAccessSecurityUsersShow = 'GENERAL_SETTING_ACCESS_SECURITY_USERS______SHOW',
  GeneralSettingAccessSecurityUsersUpdate = 'GENERAL_SETTING_ACCESS_SECURITY_USERS______UPDATE',
  GeneralSettingAccessSecurityRolesCreate = 'GENERAL_SETTING_ACCESS_SECURITY_ROLES______CREATE',
  GeneralSettingAccessSecurityRolesShow = 'GENERAL_SETTING_ACCESS_SECURITY_ROLES______SHOW',
  GeneralSettingAccessSecurityRolesUpdate = 'GENERAL_SETTING_ACCESS_SECURITY_ROLES______UPDATE',
  GeneralSettingAccessSecurityRolesDelete = 'GENERAL_SETTING_ACCESS_SECURITY_ROLES______DELETE',
  GeneralSettingRoundingPolicyShow = 'GENERAL_SETTING_ROUNDING_POLICY_________SHOW',
  GeneralSettingRoundingPolicyUpdate = 'GENERAL_SETTING_ROUNDING_POLICY_________UPDATE',
  GeneralSettingUomShow = 'GENERAL_SETTING_UOM_________SHOW',
  GeneralSettingUomCreate = 'GENERAL_SETTING_UOM_________CREATE',
  GeneralSettingUomUpdate = 'GENERAL_SETTING_UOM_________UPDATE',
  GeneralSettingUomDelete = 'GENERAL_SETTING_UOM_________DELETE',
  GeneralSettingUomConversionShow = 'GENERAL_SETTING_UOM_CONVERSION_________SHOW',
  GeneralSettingUomConversionCreate = 'GENERAL_SETTING_UOM_CONVERSION_________CREATE',
  GeneralSettingUomConversionUpdate = 'GENERAL_SETTING_UOM_CONVERSION_________UPDATE',
  GeneralSettingUomConversionDelete = 'GENERAL_SETTING_UOM_CONVERSION_________DELETE',
  OutletSettingShow = 'OUTLET_SETTING____________SHOW',
  OutletSettingCreate = 'OUTLET_SETTING____________CREATE',
  OutletSettingOutletProfileUpdate = 'OUTLET_SETTING_OUTLET_PROFILE_________UPDATE',
  OutletSettingOutletPolicyShow = 'OUTLET_SETTING_OUTLET_POLICY_________SHOW',
  OutletSettingOutletPolicyPatronSetupShow = 'OUTLET_SETTING_OUTLET_POLICY_PATRON_SETUP______SHOW',
  OutletSettingOutletPolicyTaxSetupShow = 'OUTLET_SETTING_OUTLET_POLICY_TAX_SETUP______SHOW',
  OutletSettingOutletPolicyTaxSetupTaxShow = 'OUTLET_SETTING_OUTLET_POLICY_TAX_SETUP___TAX_SHOW',
  OutletSettingOutletPolicyTaxSetupServiceChargeShow = 'OUTLET_SETTING_OUTLET_POLICY_TAX_SETUP___SERVICE_CHARGE_SHOW',
  OutletSettingOutletPolicyReasonSetupShow = 'OUTLET_SETTING_OUTLET_POLICY_REASON_SETUP______SHOW',
  OutletSettingTableShow = 'OUTLET_SETTING_TABLE_________SHOW',
  OutletSettingQrCodeShow = 'OUTLET_SETTING_QR_CODE_________SHOW',
  OutletSettingKitchenPrinterShow = 'OUTLET_SETTING_KITCHEN_PRINTER_________SHOW',
  OutletSettingReceiptPrinterShow = 'OUTLET_SETTING_RECEIPT_PRINTER_________SHOW',
  OutletSettingMealPeriodShow = 'OUTLET_SETTING_MEAL_PERIOD_________SHOW',
  OutletSettingMajorFamilyShow = 'OUTLET_SETTING_MAJOR_FAMILY_________SHOW',
  OutletSettingStandardMenuOptionShow = 'OUTLET_SETTING_STANDARD_MENU_OPTION_________SHOW',
  OutletSettingMenuItemsShow = 'OUTLET_SETTING_MENU_ITEMS_________SHOW',
  OutletSettingSetMenuShow = 'OUTLET_SETTING_SET_MENU_________SHOW',
  OutletSettingDigitalMenuPricingShow = 'OUTLET_SETTING_DIGITAL_MENU_PRICING_________SHOW',
  OutletSettingMenuSchedulingShow = 'OUTLET_SETTING_MENU_SCHEDULING_________SHOW',
  OutletSettingPromotionalMenuPricingShow = 'OUTLET_SETTING_PROMOTIONAL_MENU_PRICING_________SHOW',
  OutletSettingDailyMenuItemAvailabilityShow = 'OUTLET_SETTING_DAILY_MENU_ITEM_AVAILABILITY_________SHOW',
  OutletSettingPromoCodeShow = 'OUTLET_SETTING_PROMO_CODE_________SHOW',
  OutletSettingDiscountTypeShow = 'OUTLET_SETTING_DISCOUNT_TYPE_________SHOW',
  OutletSettingStopSalesItemsShow = 'OUTLET_SETTING_STOP_SALES_ITEMS_________SHOW',
  OutletSettingOutletRoleAssignmentShow = 'OUTLET_SETTING_OUTLET_ROLE_ASSIGNMENT_________SHOW',
  OutletSettingPaymentTypeShow = 'OUTLET_SETTING_PAYMENT_TYPE_________SHOW',
  OutletSettingThirdPartyInterfaceSetupShow = 'OUTLET_SETTING_THIRD_PARTY_INTERFACE_SETUP_________SHOW',
  BillListingShow = 'BILL_LISTING____________SHOW',
  BillListingVoidPayment = 'BILL_LISTING____________VOID_PAYMENT',
  BillListingVoidBill = 'BILL_LISTING____________VOID_BILL',
  BillListingPrint = 'BILL_LISTING____________PRINT',
  UtilityShow = 'UTILITY____________SHOW',
  UtilityDuplicateMenuDuplicate = 'UTILITY_DUPLICATE_MENU_________DUPLICATE',
  UtilityUpdateDailyMenuItemUpdate = 'UTILITY_UPDATE_DAILY_MENU_ITEM_________UPDATE',
  BizAnalyticsShow = 'BIZ_ANALYTICS____________SHOW',
  BizAnalyticsOscAnalysisShow = 'BIZ_ANALYTICS_OSC_ANALYSIS_________SHOW',
  BizAnalyticsMpsAnalysisShow = 'BIZ_ANALYTICS_MPS_ANALYSIS_________SHOW',
  BizAnalyticsWscAnalysisShow = 'BIZ_ANALYTICS_WSC_ANALYSIS_________SHOW',
  BizAnalyticsFmviAnalysisShow = 'BIZ_ANALYTICS_FMVI_ANALYSIS_________SHOW',
  BizAnalyticsSmviAnalysisShow = 'BIZ_ANALYTICS_SMVI_ANALYSIS_________SHOW',
  BizAnalyticsPtAnalysisShow = 'BIZ_ANALYTICS_PT_ANALYSIS_________SHOW',
  BizAnalyticsDtAnalysisShow = 'BIZ_ANALYTICS_DT_ANALYSIS_________SHOW',
  BizAnalyticsAmtAnalysisShow = 'BIZ_ANALYTICS_AMT_ANALYSIS_________SHOW',
  DigitalReportingShow = 'DIGITAL_REPORTING____________SHOW',
  DigitalReportingOutletSalesRevenueShow = 'DIGITAL_REPORTING_OUTLET_SALES_REVENUE_________SHOW',
  DigitalReportingMealPeriodCollectionShow = 'DIGITAL_REPORTING_MEAL_PERIOD_COLLECTION_________SHOW',
  DigitalReportingProductionAnalysisSummaryShow = 'DIGITAL_REPORTING_PRODUCTION_ANALYSIS_SUMMARY_________SHOW',
  DigitalReportingProductionAnalysisByOutletShow = 'DIGITAL_REPORTING_PRODUCTION_ANALYSIS_BY_OUTLET_________SHOW',
  DigitalReportingDailyEarningReportShow = 'DIGITAL_REPORTING_DAILY_EARNING_REPORT_________SHOW',
  DigitalReportingCashierCollectionReportShow = 'DIGITAL_REPORTING_CASHIER_COLLECTION_REPORT_________SHOW',
  DigitalReportingDayEndReportShow = 'DIGITAL_REPORTING_DAY_END_REPORT_________SHOW',
  DigitalReportingBillTransactionListingShow = 'DIGITAL_REPORTING_BILL_TRANSACTION_LISTING_________SHOW',
  DigitalReportingDiscountListingShow = 'DIGITAL_REPORTING_DISCOUNT_LISTING_________SHOW',
  DigitalReportingAuditUserLoggingSystemShow = 'DIGITAL_REPORTING_AUDIT_USER_LOGGING_SYSTEM_________SHOW',
  DigitalReportingVoidedTransactionListingShow = 'DIGITAL_REPORTING_VOIDED_TRANSACTION_LISTING_________SHOW',
  DigitalReportingMealPeriodSalesShow = 'DIGITAL_REPORTING_MEAL_PERIOD_SALES_________SHOW',
  DigitalDocumentShow = 'DIGITAL_DOCUMENT____________SHOW',
  DigitalDocumentPrint = 'DIGITAL_DOCUMENT____________PRINT',
  DigitalDocumentShare = 'DIGITAL_DOCUMENT____________SHARE',
  AccountJournalsShow = 'ACCOUNT_JOURNALS____________SHOW',
  AccountJournalsAccountsMappingShow = 'ACCOUNT_JOURNALS_ACCOUNTS_MAPPING_________SHOW',
  AccountJournalsAccountsMappingOutletGlInterfaceShow = 'ACCOUNT_JOURNALS_ACCOUNTS_MAPPING_OUTLET_GL_INTERFACE______SHOW',
  AccountJournalsAccountsMappingOutletGlInterfaceUpdate = 'ACCOUNT_JOURNALS_ACCOUNTS_MAPPING_OUTLET_GL_INTERFACE______UPDATE',
  AccountJournalsAccountsMappingBankAccountShow = 'ACCOUNT_JOURNALS_ACCOUNTS_MAPPING_BANK_ACCOUNT______SHOW',
  AccountJournalsAccountsMappingBankAccountCreate = 'ACCOUNT_JOURNALS_ACCOUNTS_MAPPING_BANK_ACCOUNT______CREATE',
  AccountJournalsAccountsMappingBankAccountUpdate = 'ACCOUNT_JOURNALS_ACCOUNTS_MAPPING_BANK_ACCOUNT______UPDATE',
  AccountJournalsAccountsMappingBankAccountDelete = 'ACCOUNT_JOURNALS_ACCOUNTS_MAPPING_BANK_ACCOUNT______DELETE',
  AccountJournalsGlJournalShow = 'ACCOUNT_JOURNALS_GL_JOURNAL_________SHOW',
  AccountJournalsCbTransactionShow = 'ACCOUNT_JOURNALS_CB_TRANSACTION_________SHOW',
  KitchenUpdateOrderItemUpdate = 'KITCHEN_UPDATE_ORDER_ITEM_________UPDATE',
  KitchenCloseOrderUpdate = 'KITCHEN_CLOSE_ORDER_________UPDATE'
}

export type PosRoleEntity = AuditEntity & {
  __typename?: 'POSRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type PatronAccountEntity = AuditEntity & {
  __typename?: 'PatronAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  creditBalance?: Maybe<Scalars['Float']>;
  hotelRoomID?: Maybe<Scalars['String']>;
  hotelRoom?: Maybe<HotelRoomEntity>;
  membershipID?: Maybe<Scalars['String']>;
  membership?: Maybe<MembershipEntity>;
};

export enum PatronClass {
  Member = 'MEMBER',
  Staff = 'STAFF',
  Officer = 'OFFICER',
  Visitor = 'VISITOR',
  HotelGuest = 'HOTEL_GUEST',
  Hotel = 'HOTEL',
  Guest = 'GUEST'
}

export type PatronEntity = AuditEntity & {
  __typename?: 'PatronEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  patronTypeID: Scalars['String'];
  patronType?: Maybe<PatronTypeEntity>;
  reservation?: Maybe<ReservationEntity>;
  promoMenuItemPrice?: Maybe<Array<PromoMenuItemPriceEntity>>;
  digitalMenuItemPrice?: Maybe<Array<DigitalMenuItemPriceEntity>>;
  order?: Maybe<Array<OrderEntity>>;
  /** CustomFieldResolver */
  patronClass?: Maybe<Scalars['JSON']>;
};

export type PatronInput = {
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  patronTypeID: Scalars['String'];
};

export type PatronTypeEntity = AuditEntity & {
  __typename?: 'PatronTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  description: Scalars['String'];
  patronClass: PatronClass;
  dailyMenuPrice?: Maybe<Array<DailyMenuPriceEntity>>;
  patron?: Maybe<Array<PatronEntity>>;
};

export type PatronTypeInput = {
  name: Scalars['String'];
  description: Scalars['String'];
};

export type PaymentByQuantityInput = {
  orderItemID?: Maybe<Scalars['String']>;
  paymentQuantity?: Maybe<Scalars['Float']>;
};

export enum PaymentClass {
  Member = 'MEMBER',
  Online = 'ONLINE',
  Cash = 'CASH',
  Credit = 'CREDIT',
  Room = 'ROOM',
  NonGuest = 'NON_GUEST',
  Debtor = 'DEBTOR',
  Skipper = 'SKIPPER',
  Officer = 'OFFICER',
  Staff = 'STAFF',
  Voucher = 'VOUCHER',
  Others = 'OTHERS',
  UnsettledBill = 'UNSETTLED_BILL',
  Folio = 'FOLIO',
  SuspendFolio = 'SUSPEND_FOLIO',
  BankTt = 'BANK_TT',
  Cheque = 'CHEQUE'
}

export type PaymentEntity = AuditEntity & {
  __typename?: 'PaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  registrationID?: Maybe<Scalars['String']>;
  billID?: Maybe<Scalars['String']>;
  bill?: Maybe<BillEntity>;
  creditCardType?: Maybe<CreditCardType>;
  defaultPaymentID: Scalars['String'];
  patronAccountID?: Maybe<Scalars['String']>;
  defaultPayment?: Maybe<DefaultPaymentEntity>;
  paymentStatus?: Maybe<PaymentStatus>;
  referenceNo?: Maybe<Scalars['String']>;
  debtorAccount?: Maybe<Scalars['String']>;
  paySessionNo?: Maybe<Scalars['Float']>;
  checkoutID?: Maybe<Scalars['String']>;
  cardNo?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  receivedAmount?: Maybe<Scalars['Float']>;
  changeAmount?: Maybe<Scalars['Float']>;
  roundingAmount?: Maybe<Scalars['Float']>;
  businessDate?: Maybe<Scalars['DateTime']>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  status: PaymentStatus;
  paymentClass: PaymentClass;
  isGLPosted?: Maybe<Scalars['Boolean']>;
  isCBPosted?: Maybe<Scalars['Boolean']>;
  hotelGuestInfo?: Maybe<Scalars['JSON']>;
};

export type PaymentGatewayEntity = AuditEntity & {
  __typename?: 'PaymentGatewayEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  store?: Maybe<Scalars['String']>;
  privateKey?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  outlet?: Maybe<OutletEntity>;
};

export type PaymentGatewayInput = {
  ID: Scalars['String'];
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  store?: Maybe<Scalars['String']>;
  privateKey?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
};

export type PaymentInput = {
  ID?: Maybe<Scalars['String']>;
  defaultPaymentID?: Maybe<Scalars['String']>;
  billID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  cardNo?: Maybe<Scalars['String']>;
  registrationID?: Maybe<Scalars['String']>;
  creditCardType?: Maybe<CreditCardType>;
  paymentStatus?: Maybe<PaymentStatus>;
  amount: Scalars['Float'];
  receivedAmount?: Maybe<Scalars['Float']>;
  changeAmount?: Maybe<Scalars['Float']>;
  roundingAmount?: Maybe<Scalars['Float']>;
  paySessionNo?: Maybe<Scalars['Float']>;
  checkoutID?: Maybe<Scalars['String']>;
  businessDate?: Maybe<Scalars['DateTime']>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  paymentClass?: Maybe<PaymentClass>;
  prevPaymentClass?: Maybe<PaymentClass>;
  patronAccountID?: Maybe<Scalars['String']>;
  referenceNo?: Maybe<Scalars['String']>;
  hotelGuestInfo?: Maybe<HotelGuestInfoInput>;
};

export type PaymentItemDiscountEntity = AuditEntity & {
  __typename?: 'PaymentItemDiscountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  discountID: Scalars['String'];
  paymentItemID: Scalars['String'];
  paymentItem?: Maybe<PaymentItemEntity>;
  status: OrderItemDiscountStatus;
  amount?: Maybe<Scalars['Float']>;
  discountedBy?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<Scalars['String']>;
};

export type PaymentItemEntity = AuditEntity & {
  __typename?: 'PaymentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  openItemName?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  refID?: Maybe<Scalars['String']>;
  specialRequest?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  isTakeaway?: Maybe<Scalars['Boolean']>;
  isPrintToKitchen?: Maybe<Scalars['Boolean']>;
  businessDate: Scalars['DateTime'];
  status: OrderItemStatus;
  kitchenStatus?: Maybe<KitchenStatus>;
  unitPrice: Scalars['Float'];
  quantity: Scalars['Float'];
  name: Scalars['String'];
  description: Scalars['String'];
  promoID?: Maybe<Scalars['String']>;
  orderID: Scalars['String'];
  order?: Maybe<OrderEntity>;
  paymentItemDiscount?: Maybe<PaymentItemDiscountEntity>;
  menuItemID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
  mealPeriodID: Scalars['String'];
  mealPeriod?: Maybe<MealPeriodEntity>;
  billID?: Maybe<Scalars['String']>;
  bill?: Maybe<BillEntity>;
  orderItemID?: Maybe<Scalars['String']>;
  orderItem?: Maybe<OrderItemEntity>;
  paymentItemOption?: Maybe<Array<PaymentItemOptionEntity>>;
  paymentItemSetMenuOption?: Maybe<Array<PaymentItemSetMenuOptionEntity>>;
};

export type PaymentItemOptionEntity = AuditEntity & {
  __typename?: 'PaymentItemOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  paymentItemID: Scalars['String'];
  paymentItem?: Maybe<PaymentItemEntity>;
  optionID: Scalars['String'];
  option?: Maybe<OptionEntity>;
  paymentItemOptionItem?: Maybe<Array<PaymentItemOptionItemEntity>>;
};

export type PaymentItemOptionItemEntity = AuditEntity & {
  __typename?: 'PaymentItemOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  basePrice?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  optionItemID: Scalars['String'];
  optionItem?: Maybe<OptionItemEntity>;
  paymentItemOptionID: Scalars['String'];
  paymentItemOption?: Maybe<PaymentItemOptionEntity>;
};

export type PaymentItemSetMenuOptionEntity = AuditEntity & {
  __typename?: 'PaymentItemSetMenuOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  paymentItemID: Scalars['String'];
  paymentItem?: Maybe<PaymentItemEntity>;
  setMenuOptionID: Scalars['String'];
  setMenuOption?: Maybe<SetMenuOptionEntity>;
  paymentItemSetMenuOptionItem?: Maybe<Array<PaymentItemSetMenuOptionItemEntity>>;
};

export type PaymentItemSetMenuOptionItemEntity = AuditEntity & {
  __typename?: 'PaymentItemSetMenuOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  setMenuOptionItemID: Scalars['String'];
  setMenuOptionItem?: Maybe<SetMenuOptionItemEntity>;
  paymentItemSetMenuOptionID: Scalars['String'];
  paymentItemSetMenuOption?: Maybe<PaymentItemSetMenuOptionEntity>;
  menuItemID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
};

export enum PaymentStatus {
  Open = 'OPEN',
  Pending = 'PENDING',
  Void = 'VOID'
}

export type PaymentTypeEntity = AuditEntity & {
  __typename?: 'PaymentTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  description: Scalars['String'];
  paymentClass: PaymentClass;
};

export type PaymentTypeInput = {
  ID?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  paymentClass: PaymentClass;
};

export type PlatformTaxEntity = AuditEntity & {
  __typename?: 'PlatformTaxEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  effectiveDate: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type PlatformTaxInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
};

export type PosCoreEntity = AuditEntity & {
  __typename?: 'PosCoreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type PrintData = {
  __typename?: 'PrintData';
  printerName: Scalars['String'];
  outletName: Scalars['String'];
  orderID: Scalars['String'];
  orderItemIDs: Array<Scalars['String']>;
  orderMode: Scalars['String'];
  voidQuantity?: Maybe<Scalars['Float']>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
};

export type PrintDataPayload = {
  outletID: Scalars['String'];
  dataURL: Scalars['String'];
  printerName: Scalars['String'];
  outletName: Scalars['String'];
  orderID: Scalars['String'];
  orderItemIDs: Array<Scalars['String']>;
  orderMode: Scalars['String'];
  voidQuantity?: Maybe<Scalars['Float']>;
  reasonCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
};

export type PromoCodeEntity = AuditEntity & {
  __typename?: 'PromoCodeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  validFrom: Scalars['DateTime'];
  validTill: Scalars['DateTime'];
  calcType: CalcType;
  amount: Scalars['Float'];
};

export type PromoCodeInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  validFrom: Scalars['DateTime'];
  validTill: Scalars['DateTime'];
  calcType: CalcType;
  amount: Scalars['Float'];
};

export type PromoMenuEntity = AuditEntity & {
  __typename?: 'PromoMenuEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  description: Scalars['String'];
  outletID: Scalars['String'];
  outlet?: Maybe<OutletEntity>;
  frequencyType?: Maybe<FrequencyType>;
  validityStartTs: Scalars['DateTime'];
  validityEndTs: Scalars['DateTime'];
  recurStartTime: Scalars['String'];
  recurEndTime: Scalars['String'];
  recurOnceAt?: Maybe<Scalars['DateTime']>;
  recurEveryXDays?: Maybe<Scalars['Float']>;
  recurEveryXWeeks?: Maybe<Scalars['Float']>;
  recurEveryXMonths?: Maybe<Scalars['Float']>;
  recurAtDayXOfMonth?: Maybe<Scalars['Float']>;
  recurWeekBitmap?: Maybe<Scalars['Float']>;
  recurDayBitmap?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
  dailyMenuPrice?: Maybe<Array<DailyMenuPriceEntity>>;
  menuItem: Array<MenuItemEntity>;
  menuItemIDs: Array<Scalars['Float']>;
  promoMenuItem?: Maybe<Array<PromoMenuItemEntity>>;
};

export type PromoMenuInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  outletID: Scalars['String'];
  frequencyType: FrequencyType;
  validityStartTs: Scalars['DateTime'];
  validityEndTs: Scalars['DateTime'];
  recurStartTime?: Maybe<Scalars['String']>;
  recurEndTime?: Maybe<Scalars['String']>;
  recurOnceAt?: Maybe<Scalars['DateTime']>;
  recurEveryXDays?: Maybe<Scalars['Float']>;
  recurEveryXWeeks?: Maybe<Scalars['Float']>;
  recurEveryXMonths?: Maybe<Scalars['Float']>;
  recurAtDayXOfMonth?: Maybe<Scalars['Float']>;
  recurWeekBitmap?: Maybe<Scalars['Float']>;
  recurDayBitmap?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['Float']>;
};

export type PromoMenuItemEntity = AuditEntity & {
  __typename?: 'PromoMenuItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  promoMenuID: Scalars['String'];
  promoMenu?: Maybe<PromoMenuEntity>;
  menuItemID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
  promoMenuItemPrice?: Maybe<Array<PromoMenuItemPriceEntity>>;
};

export type PromoMenuItemInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  promoMenuID?: Maybe<Scalars['String']>;
  menuItemID: Scalars['String'];
  promoMenuItemPriceInput: Array<PromoMenuItemPriceInput>;
};

export type PromoMenuItemPriceEntity = AuditEntity & {
  __typename?: 'PromoMenuItemPriceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  promoMenuItemID: Scalars['String'];
  patronID: Scalars['String'];
  outletID: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  promoMenuItem?: Maybe<PromoMenuItemEntity>;
  patron?: Maybe<PatronEntity>;
  /** CustomFieldResolver */
  patronClass?: Maybe<Scalars['JSON']>;
};

export type PromoMenuItemPriceInput = {
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  promoMenuItemID?: Maybe<Scalars['String']>;
  patronID: Scalars['String'];
  price: Scalars['Float'];
};

export type QrEntity = AuditEntity & {
  __typename?: 'QREntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  code: Scalars['String'];
  outletID: Scalars['String'];
  /** CustomFieldResolver */
  orderInfo?: Maybe<Scalars['JSON']>;
};

export type QrInput = {
  accountID?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type QrOrderInfo = {
  __typename?: 'QROrderInfo';
  orderID: Scalars['String'];
  tableID: Scalars['String'];
  orderItemCount: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  getOutletBillCharges: Scalars['JSON'];
  getOutletBillOCCharges: Scalars['JSON'];
  getOutletBillEntCharges: Scalars['JSON'];
  AccountMappingTaxGLList: Scalars['JSON'];
  getBankAccount: Array<BankAccountEntity>;
  getBankAccountContact: Array<BankAccountContactEntity>;
  getExportedCB: Scalars['JSON'];
  getExportedCBDetails: Scalars['JSON'];
  getCBExportData: Scalars['JSON'];
  getExportedCBDetailsByTrxType: Scalars['JSON'];
  getExportedGLListing: Scalars['JSON'];
  getExportedGLDetails: Array<Scalars['JSON']>;
  getGLExport: Scalars['JSON'];
  getGLExportDetailsByTrxType: Scalars['JSON'];
  getAttachment: Array<AttachmentEntity>;
  attachmentListing?: Maybe<Array<AttachmentEntity>>;
  auditLogDetails: Scalars['JSON'];
  averageMealTimeAnalysis: Scalars['JSON'];
  discountTypeAnalysis: Scalars['JSON'];
  fastMovingMenuItemAnalysis: Scalars['JSON'];
  mealPeriodSalesAnalysis: Scalars['JSON'];
  outletSalesCollectionAnalysis: Scalars['JSON'];
  patronTypeAnalysis: Scalars['JSON'];
  customerTypeAnalysis: Scalars['JSON'];
  slowMovingMenuItemAnalysis: Scalars['JSON'];
  waiterSalesCollectionAnalysis: Scalars['JSON'];
  getEntityRoleUserAssignment: Array<RoleUserEntAssignmentEntity>;
  getRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  getPOSPermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleContract: Array<UserEntity>;
  testPermission: Scalars['String'];
  logoView: AttachmentEntity;
  getOfficer: Array<OfficerEntity>;
  getRoundingPolicy: Array<RoundingPolicyEntity>;
  getStaff: Array<StaffEntity>;
  getTaxSchemeListing: Array<TaxSchemeEntity>;
  isTaxSameName: Scalars['Boolean'];
  getTaxSchemeDetailItemRate: Array<TaxSchemeDetailItemRateOutput>;
  getTaxSchemeDetail: Array<TaxSchemeDetailOutput>;
  isTaxSchemeInUse: Scalars['Boolean'];
  getTaxTypeListing: Scalars['JSON'];
  getTaxListing: Scalars['JSON'];
  isTaxSameCode: Scalars['Boolean'];
  isTaxInUse: Scalars['Boolean'];
  getTaxDetails: TaxEntity;
  getTaxListingDropdown: Array<TaxListingOutput>;
  testPath: Scalars['JSON'];
  loggedInUserProfile: UserEntity;
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIDs: Array<UserEntity>;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getUsersByAccountAndSoftware: Array<UserEntity>;
  getUsersWaiterRole: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  getDailyMenuItemAvailability: Array<DailyMenuItemAvailabilityEntity>;
  getDailyMenuPrice: Array<DailyMenuPriceEntity>;
  getDefaultPayment: Array<DefaultPaymentEntity>;
  getDigitalMenu: Array<DigitalMenuEntity>;
  getScheduledDigitalMenu: Array<OutletDailyMenuCategoryEntity>;
  isDigitalMenuInUse: Scalars['Boolean'];
  getDigitalMenuCategory: Array<DigitalMenuCategoryEntity>;
  getScheduledDigitalMenuCategory: Array<DigitalMenuCategoryEntity>;
  isDigitalMenuCategoryInUse: Scalars['Boolean'];
  getDigitalMenuItem: Array<DigitalMenuItemEntity>;
  getDigitalMenuItemPrice: Array<DigitalMenuItemPriceEntity>;
  getMenuItemForDigitalMenuItemPrice: Array<MenuItemEntity>;
  getDiscount: Array<DiscountEntity>;
  getFilteredDiscount: Array<DiscountEntity>;
  getSortedDiscount: Array<DiscountEntity>;
  getVoidedDiscount: Scalars['JSON'];
  getFamily: Array<FamilyEntity>;
  getKitchenPrinter: Array<KitchenPrinterEntity>;
  getMajor: Array<MajorEntity>;
  getMealPeriod: Array<MealPeriodEntity>;
  getCurrentMealPeriod: MealPeriodEntity;
  getAllMealPeriod: Scalars['JSON'];
  getMenuItem: Array<MenuItemEntity>;
  getMenuItemForSetMenuOptionItem: Array<MenuItemEntity>;
  getMenuItemOption: Array<MenuItemOptionEntity>;
  getOperatingHours: Array<OperatingHoursEntity>;
  getOption: Array<OptionEntity>;
  getOptionItem: Array<OptionItemEntity>;
  getOutlet: Array<OutletEntity>;
  getAssignedOutletByUser: Array<OutletEntity>;
  getOutletCustomerPolicy: Array<OutletCustomerPolicyEntity>;
  getOutletTaxPolicy: Array<OutletTaxPolicyEntity>;
  getPatron: Array<PatronEntity>;
  getOutletVisitorPatron: PatronEntity;
  getPromoCode: Array<PromoCodeEntity>;
  getPromoMenu: Array<PromoMenuEntity>;
  getPromoMenuItem: Array<PromoMenuItemEntity>;
  promoMenuItemChecking: Array<PromoMenuItemEntity>;
  getPromoMenuItemPrice: Array<PromoMenuItemPriceEntity>;
  getMenuItemForPromoMenuItemPrice: Array<MenuItemEntity>;
  getQR: Array<QrEntity>;
  openOrderQRChecking: QrEntity;
  getReason: Array<ReasonEntity>;
  getReceiptPrinter: Array<ReceiptPrinterEntity>;
  getScheduledMenuMealPeriod: Array<ScheduledMenuMealPeriodEntity>;
  getScheduledMenu: Array<ScheduledMenuEntity>;
  getSetMenuOption: Array<SetMenuOptionEntity>;
  getSetMenuOptionOption: Array<SetMenuOptionEntity>;
  getSetMenuOptionItem: Array<SetMenuOptionItemEntity>;
  getStopSales: Array<StopSalesEntity>;
  getMenuItemForStopSale: Array<MenuItemEntity>;
  getTable: Array<TableEntity>;
  getTableByAscOrder: Array<TableEntity>;
  getBill: Array<BillEntity>;
  getBillInfinite?: Maybe<Array<BillEntity>>;
  getBillWithDate?: Maybe<Array<BillEntity>>;
  getBillDiscount: Array<BillDiscountEntity>;
  getMenuXInhouseList: Scalars['JSON'];
  getMenuXInhouseStatus: Scalars['JSON'];
  getMenuXDebtorList: Scalars['JSON'];
  getHotelBySubscription: Scalars['JSON'];
  getOrder: Array<OrderEntity>;
  getTakeawayOrder: Array<OrderEntity>;
  getKitchenOrder: Array<KitchenOrder>;
  getOrderDiscount: Array<OrderDiscountEntity>;
  getOrderItem: Array<OrderItemEntity>;
  getAllOrderItem: Array<OrderItemEntity>;
  getPrinterOrderItem: Array<OrderItemEntity>;
  getBillSettlementOrderItem: Array<OrderItemEntity>;
  getOrderItemDiscount: Array<OrderItemDiscountEntity>;
  getOrderItemOption: Array<OrderItemOptionEntity>;
  getOrderItemOptionItem: Array<OrderItemOptionItemEntity>;
  getOrderItemSetMenuOption: Array<OrderItemSetMenuOptionEntity>;
  getOrderItemSetMenuOptionItem: Array<OrderItemSetMenuOptionItemEntity>;
  getPatronAccount: Array<PatronAccountEntity>;
  getReservation: Array<ReservationEntity>;
  noShowCheck: Scalars['Boolean'];
  getTableSession: Array<TableSessionEntity>;
  getPayment: Array<PaymentEntity>;
  getPatronType: Array<PatronTypeEntity>;
  getPaymentType: Array<PaymentTypeEntity>;
  getPlatformTax: Array<PlatformTaxEntity>;
  computeTaxbyOutlet: TaxOutput;
  getUOM: Array<UomEntity>;
  getUOMConversion: Array<UomConversionEntity>;
  getUomConversionData: Scalars['JSON'];
  getOutletDailyMenuCategory: Array<OutletDailyMenuCategoryEntity>;
  getOutletDailyMenuItem: Array<OutletDailyMenuItemEntity>;
  getOutletDailyMenuItemOption: Array<OutletDailyMenuItemOptionEntity>;
  getOutletDailyMenuItemOptionItem: Array<OutletDailyMenuItemOptionItemEntity>;
  getOutletDailyMenuItemPatronPrice: Array<OutletDailyMenuItemPatronPriceEntity>;
  getOutletDailyPromoMenuItem: Array<OutletDailyPromoMenuItemEntity>;
  getOutletDailyPromoMenuItemPrice: Array<OutletDailyPromoMenuItemPriceEntity>;
  getOutletDailySetMenuOption: Array<OutletDailySetMenuOptionEntity>;
  getOutletDailySetMenuOptionItem: Array<OutletDailySetMenuOptionItemEntity>;
  bankAccountDate?: Maybe<Scalars['DateTime']>;
  glJournalDate?: Maybe<Scalars['DateTime']>;
  cbTransactionDate?: Maybe<Scalars['DateTime']>;
  outletSalesDate?: Maybe<Scalars['DateTime']>;
  mealPeriodDate?: Maybe<Scalars['DateTime']>;
  waiterSalesDate?: Maybe<Scalars['DateTime']>;
  fastMovingMenuItemDate?: Maybe<Scalars['DateTime']>;
  slowMovingMenuItemDate?: Maybe<Scalars['DateTime']>;
  patronTypeDate?: Maybe<Scalars['DateTime']>;
  discountTypeDate?: Maybe<Scalars['DateTime']>;
  averageMealTimeDate?: Maybe<Scalars['DateTime']>;
  userDate?: Maybe<Scalars['DateTime']>;
  roleDate?: Maybe<Scalars['DateTime']>;
  officerCount?: Maybe<Scalars['JSON']>;
  staffCount?: Maybe<Scalars['JSON']>;
  userCount?: Maybe<Scalars['JSON']>;
  roundingPolicyDate?: Maybe<Scalars['DateTime']>;
  uomDate?: Maybe<Scalars['DateTime']>;
  uomConversionDate?: Maybe<Scalars['DateTime']>;
  taxDate?: Maybe<Scalars['DateTime']>;
  taxSchemeDate?: Maybe<Scalars['DateTime']>;
  orderCount?: Maybe<Scalars['JSON']>;
  orderItemCount?: Maybe<Scalars['JSON']>;
  editOrderInfo?: Maybe<Scalars['JSON']>;
  newestTable?: Maybe<Scalars['JSON']>;
  itemTransferCount?: Maybe<Scalars['JSON']>;
  billItemDiscount?: Maybe<Scalars['JSON']>;
  billSettlement?: Maybe<Scalars['JSON']>;
  close?: Maybe<Scalars['JSON']>;
  patronSetupDate?: Maybe<Scalars['DateTime']>;
  taxSetupDate?: Maybe<Scalars['DateTime']>;
  reasonSetupDate?: Maybe<Scalars['DateTime']>;
  customerSetupDate?: Maybe<Scalars['DateTime']>;
  outletProfileDate?: Maybe<Scalars['DateTime']>;
  outletPolicyDate?: Maybe<Scalars['DateTime']>;
  outletPolicyDateNew?: Maybe<Scalars['JSON']>;
  tableCount?: Maybe<Scalars['JSON']>;
  qrCount?: Maybe<Scalars['JSON']>;
  kitchenPrinterCount?: Maybe<Scalars['JSON']>;
  receiptPrinterCount?: Maybe<Scalars['JSON']>;
  mealPeriodCount?: Maybe<Scalars['JSON']>;
  majorFamilyCount?: Maybe<Scalars['JSON']>;
  standardMenuOptionCount?: Maybe<Scalars['JSON']>;
  menuItemsCount?: Maybe<Scalars['JSON']>;
  setMenuCount?: Maybe<Scalars['JSON']>;
  digitalMenuPricingCount?: Maybe<Scalars['JSON']>;
  menuSchedulingCount?: Maybe<Scalars['JSON']>;
  promotionalMenuPricingCount?: Maybe<Scalars['JSON']>;
  dailyMenuItemAvailabilityCount?: Maybe<Scalars['JSON']>;
  promoCodeCount?: Maybe<Scalars['JSON']>;
  discountTypeCount?: Maybe<Scalars['JSON']>;
  stopSalesItemCount?: Maybe<Scalars['JSON']>;
  paymentTypeDate?: Maybe<Scalars['DateTime']>;
  getItemTotal: Scalars['JSON'];
};


export type QueryGetOutletBillChargesArgs = {
  outletID: Scalars['String'];
};


export type QueryGetOutletBillOcChargesArgs = {
  outletID: Scalars['String'];
};


export type QueryGetOutletBillEntChargesArgs = {
  outletID: Scalars['String'];
};


export type QueryAccountMappingTaxGlListArgs = {
  accountID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryGetBankAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetBankAccountContactArgs = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetExportedCbArgs = {
  outletID: Scalars['String'];
};


export type QueryGetExportedCbDetailsArgs = {
  batchNo: Scalars['Float'];
  outletID: Scalars['String'];
};


export type QueryGetCbExportDataArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetExportedCbDetailsByTrxTypeArgs = {
  trxDate: Scalars['DateTime'];
  trxType: Scalars['String'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetExportedGlListingArgs = {
  outletID: Scalars['String'];
};


export type QueryGetExportedGlDetailsArgs = {
  batchNo: Scalars['Float'];
  outletID: Scalars['String'];
};


export type QueryGetGlExportArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetGlExportDetailsByTrxTypeArgs = {
  trxDate: Scalars['DateTime'];
  trxDesc: Scalars['String'];
  trxType: Scalars['String'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetAttachmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryAttachmentListingArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type QueryAuditLogDetailsArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  fieldID: Scalars['String'];
  tableName: Scalars['String'];
};


export type QueryAverageMealTimeAnalysisArgs = {
  outletID: Scalars['String'];
};


export type QueryDiscountTypeAnalysisArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
};


export type QueryFastMovingMenuItemAnalysisArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  mealPeriodID?: Maybe<Scalars['String']>;
  familyID?: Maybe<Scalars['String']>;
  majorID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryMealPeriodSalesAnalysisArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
};


export type QueryOutletSalesCollectionAnalysisArgs = {
  frequencyType?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
};


export type QueryPatronTypeAnalysisArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
};


export type QueryCustomerTypeAnalysisArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  outletID: Scalars['String'];
};


export type QuerySlowMovingMenuItemAnalysisArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  mealPeriodID?: Maybe<Scalars['String']>;
  familyID?: Maybe<Scalars['String']>;
  majorID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryWaiterSalesCollectionAnalysisArgs = {
  selectedDate?: Maybe<Scalars['DateTime']>;
  salesAmount?: Maybe<Scalars['Float']>;
  userID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetEntityRoleUserAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  outletID: Scalars['String'];
};


export type QueryGetUsersRoleContractArgs = {
  outletID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryTestPermissionArgs = {
  posPerm: PosPermission;
};


export type QueryLogoViewArgs = {
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryGetOfficerArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetRoundingPolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetStaffArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTaxSchemeListingArgs = {
  accountID: Scalars['String'];
};


export type QueryIsTaxSameNameArgs = {
  taxSchemeName: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetTaxSchemeDetailItemRateArgs = {
  effectiveDate: Scalars['DateTime'];
  taxSchemeID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetTaxSchemeDetailArgs = {
  orderID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryIsTaxSchemeInUseArgs = {
  taxSchemeID: Scalars['String'];
};


export type QueryGetTaxTypeListingArgs = {
  accountID: Scalars['String'];
};


export type QueryGetTaxListingArgs = {
  accountID: Scalars['String'];
};


export type QueryIsTaxSameCodeArgs = {
  taxCode: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryIsTaxInUseArgs = {
  taxID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetTaxDetailsArgs = {
  taxID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetTaxListingDropdownArgs = {
  accountID: Scalars['String'];
};


export type QueryGetUsersByAccountArgs = {
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIDsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetUsersByAccountAndSoftwareArgs = {
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
};


export type QueryGetUsersWaiterRoleArgs = {
  outletID: Scalars['String'];
};


export type QueryGetDailyMenuItemAvailabilityArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
};


export type QueryGetDailyMenuPriceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  promotionMenuID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
  startTs?: Maybe<Scalars['DateTime']>;
  endTs?: Maybe<Scalars['DateTime']>;
};


export type QueryGetDefaultPaymentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  paymentTypeID?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};


export type QueryGetDigitalMenuArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetScheduledDigitalMenuArgs = {
  mealPeriodID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryIsDigitalMenuInUseArgs = {
  digitalMenuID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryGetDigitalMenuCategoryArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  digitalMenuID?: Maybe<Scalars['String']>;
};


export type QueryGetScheduledDigitalMenuCategoryArgs = {
  mealPeriodID: Scalars['String'];
};


export type QueryIsDigitalMenuCategoryInUseArgs = {
  digitalMenuCategoryID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryGetDigitalMenuItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  digitalMenuCategoryID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
};


export type QueryGetDigitalMenuItemPriceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  digitalMenuItemID?: Maybe<Scalars['String']>;
  patronTypeID?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetMenuItemForDigitalMenuItemPriceArgs = {
  outletID: Scalars['String'];
  digitalMenuCategoryID: Scalars['String'];
};


export type QueryGetDiscountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};


export type QueryGetFilteredDiscountArgs = {
  isOrderItemDiscount: Scalars['Boolean'];
  amount: Scalars['Float'];
  outletID: Scalars['String'];
};


export type QueryGetSortedDiscountArgs = {
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetVoidedDiscountArgs = {
  orderID: Scalars['String'];
};


export type QueryGetFamilyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  majorID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetKitchenPrinterArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};


export type QueryGetMajorArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetMealPeriodArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
  validTill?: Maybe<Scalars['String']>;
  menus?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetCurrentMealPeriodArgs = {
  outletID: Scalars['String'];
};


export type QueryGetMenuItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  familyID?: Maybe<Scalars['String']>;
  majorID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  isSetMenu?: Maybe<Scalars['Boolean']>;
  isQuantityControlled?: Maybe<Scalars['Boolean']>;
};


export type QueryGetMenuItemForSetMenuOptionItemArgs = {
  isStandard?: Maybe<Scalars['Boolean']>;
  outletID: Scalars['String'];
  setMenuID: Scalars['String'];
};


export type QueryGetMenuItemOptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
  optionID?: Maybe<Scalars['String']>;
};


export type QueryGetOperatingHoursArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  day: RecurrenceDay;
};


export type QueryGetOptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetOptionItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  outletID?: Maybe<Scalars['String']>;
  optionID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletCustomerPolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  outletID: Scalars['String'];
  selfOrder?: Maybe<Scalars['Boolean']>;
  selfPayment?: Maybe<Scalars['Boolean']>;
  qrCodeGenerate?: Maybe<Scalars['Boolean']>;
};


export type QueryGetOutletTaxPolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  taxType?: Maybe<TaxType>;
  isTaxable?: Maybe<Scalars['Boolean']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetPatronArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  personID?: Maybe<Scalars['String']>;
  patronTypeID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryGetOutletVisitorPatronArgs = {
  outletID: Scalars['String'];
};


export type QueryGetPromoCodeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['DateTime']>;
  validTill?: Maybe<Scalars['DateTime']>;
  calcType?: Maybe<CalcType>;
  amount?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};


export type QueryGetPromoMenuArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetPromoMenuItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  promoMenuID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
};


export type QueryPromoMenuItemCheckingArgs = {
  promoMenuID: Scalars['String'];
  menuItemIDs: Array<Scalars['String']>;
};


export type QueryGetPromoMenuItemPriceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  promoMenuItemID?: Maybe<Scalars['String']>;
  patronTypeID?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};


export type QueryGetMenuItemForPromoMenuItemPriceArgs = {
  outletID: Scalars['String'];
  promoMenuID: Scalars['String'];
};


export type QueryGetQrArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryOpenOrderQrCheckingArgs = {
  qrID: Scalars['String'];
};


export type QueryGetReasonArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetReceiptPrinterArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};


export type QueryGetScheduledMenuMealPeriodArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  mealPeriodID?: Maybe<Scalars['String']>;
  scheduledMenuID?: Maybe<Scalars['String']>;
  digitalMenuID?: Maybe<Scalars['String']>;
};


export type QueryGetScheduledMenuArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  effectiveTs?: Maybe<Scalars['DateTime']>;
};


export type QueryGetSetMenuOptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  maxSelections?: Maybe<Scalars['Float']>;
  menuItemID?: Maybe<Scalars['String']>;
};


export type QueryGetSetMenuOptionOptionArgs = {
  outletID: Scalars['String'];
  menuItemID: Scalars['String'];
};


export type QueryGetSetMenuOptionItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  recommendedPriceAdjustment?: Maybe<Scalars['Float']>;
};


export type QueryGetStopSalesArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
};


export type QueryGetMenuItemForStopSaleArgs = {
  outletID: Scalars['String'];
};


export type QueryGetTableArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  tableNumber?: Maybe<Scalars['String']>;
  seatCount?: Maybe<Scalars['Float']>;
  outletID?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
};


export type QueryGetTableByAscOrderArgs = {
  isActive?: Maybe<Scalars['Boolean']>;
  outletID: Scalars['String'];
};


export type QueryGetBillArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
};


export type QueryGetBillInfiniteArgs = {
  limit?: Maybe<Scalars['Float']>;
  offSet?: Maybe<Scalars['Float']>;
  customerType?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  billNo?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  input: BillInputOffset;
};


export type QueryGetBillWithDateArgs = {
  limit: Scalars['Float'];
  offSet: Scalars['Float'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  billNo?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetBillDiscountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  billID?: Maybe<Scalars['String']>;
};


export type QueryGetMenuXInhouseListArgs = {
  outletID?: Maybe<Scalars['String']>;
  hotelID: Scalars['String'];
};


export type QueryGetMenuXInhouseStatusArgs = {
  outletID?: Maybe<Scalars['String']>;
  registrationID: Scalars['String'];
};


export type QueryGetMenuXDebtorListArgs = {
  outletID?: Maybe<Scalars['String']>;
  hotelID: Scalars['String'];
};


export type QueryGetOrderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  tableID?: Maybe<Scalars['String']>;
  mealPeriodID?: Maybe<Scalars['String']>;
  patronAccountID?: Maybe<Scalars['String']>;
  personID?: Maybe<Scalars['String']>;
  isSupervisor?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
  serviceMode?: Maybe<ServiceMode>;
};


export type QueryGetTakeawayOrderArgs = {
  orderID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
};


export type QueryGetKitchenOrderArgs = {
  kitchenPrinterID: Scalars['String'];
  outletID: Scalars['String'];
};


export type QueryGetOrderDiscountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  discountID?: Maybe<Scalars['String']>;
  orderID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderDiscountStatus>;
  amount?: Maybe<Scalars['String']>;
  discountedBy?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<Scalars['String']>;
};


export type QueryGetOrderItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
  orderID?: Maybe<Scalars['String']>;
  billID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderItemStatus>;
  kitchenStatus?: Maybe<KitchenStatus>;
};


export type QueryGetAllOrderItemArgs = {
  orderID: Scalars['String'];
};


export type QueryGetPrinterOrderItemArgs = {
  orderItemIDs: Array<Scalars['String']>;
  orderID: Scalars['String'];
};


export type QueryGetBillSettlementOrderItemArgs = {
  orderID: Scalars['String'];
};


export type QueryGetOrderItemDiscountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  discountID?: Maybe<Scalars['String']>;
  orderItemID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderItemDiscountStatus>;
  amount?: Maybe<Scalars['String']>;
  discountedBy?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<Scalars['String']>;
};


export type QueryGetOrderItemOptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  optionID?: Maybe<Scalars['String']>;
  orderItemID?: Maybe<Scalars['String']>;
};


export type QueryGetOrderItemOptionItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  optionItemID?: Maybe<Scalars['String']>;
};


export type QueryGetOrderItemSetMenuOptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  setMenuOptionID?: Maybe<Scalars['String']>;
  orderItemID?: Maybe<Scalars['String']>;
};


export type QueryGetOrderItemSetMenuOptionItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  setMenuOptionItemID?: Maybe<Scalars['String']>;
  orderItemSetMenuOptionItemID?: Maybe<Scalars['String']>;
};


export type QueryGetPatronAccountArgs = {
  isMember?: Maybe<Scalars['Boolean']>;
};


export type QueryGetReservationArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  patronID?: Maybe<Scalars['String']>;
  tableID?: Maybe<Scalars['String']>;
  pax?: Maybe<Scalars['Float']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryNoShowCheckArgs = {
  reservationID: Scalars['String'];
};


export type QueryGetTableSessionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  tableID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  billID?: Maybe<Scalars['String']>;
  amountPaid?: Maybe<Scalars['Float']>;
  roundingAdjustment?: Maybe<Scalars['Float']>;
  paidByID?: Maybe<Scalars['String']>;
};


export type QueryGetPatronTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  paymentClass?: Maybe<PaymentClass>;
};


export type QueryGetPlatformTaxArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
};


export type QueryComputeTaxbyOutletArgs = {
  input: TaxInput;
};


export type QueryGetUomArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomConversionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailyMenuCategoryArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  digitalMenuCategoryID?: Maybe<Scalars['String']>;
  businessDate?: Maybe<Scalars['DateTime']>;
  mealPeriodID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailyMenuItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletDailyMenuCategoryID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailyMenuItemOptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  outletDailyMenuItemID?: Maybe<Scalars['String']>;
  optionID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailyMenuItemOptionItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  outletDailyMenuItemOptionID?: Maybe<Scalars['String']>;
  optionItemID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailyMenuItemPatronPriceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletDailyMenuItemID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailyPromoMenuItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  outletDailyMenuItemID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailyPromoMenuItemPriceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletDailyPromoMenuItemID?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailySetMenuOptionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};


export type QueryGetOutletDailySetMenuOptionItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  outletDailySetMenuOptionID?: Maybe<Scalars['String']>;
  outletDailyMenuItemID?: Maybe<Scalars['String']>;
};


export type QueryOrderCountArgs = {
  tableID: Scalars['String'];
};


export type QueryOrderItemCountArgs = {
  tableID: Scalars['String'];
};


export type QueryEditOrderInfoArgs = {
  tableID: Scalars['String'];
};


export type QueryNewestTableArgs = {
  ID: Scalars['String'];
};


export type QueryItemTransferCountArgs = {
  tableID: Scalars['String'];
};


export type QueryBillItemDiscountArgs = {
  tableID: Scalars['String'];
};


export type QueryBillSettlementArgs = {
  tableID: Scalars['String'];
};


export type QueryCloseArgs = {
  tableID: Scalars['String'];
};


export type QueryPatronSetupDateArgs = {
  outletID: Scalars['String'];
};


export type QueryTaxSetupDateArgs = {
  outletID: Scalars['String'];
};


export type QueryReasonSetupDateArgs = {
  outletID: Scalars['String'];
};


export type QueryCustomerSetupDateArgs = {
  outletID: Scalars['String'];
};


export type QueryOutletProfileDateArgs = {
  ID: Scalars['String'];
};


export type QueryOutletPolicyDateArgs = {
  outletID: Scalars['String'];
};


export type QueryOutletPolicyDateNewArgs = {
  outletID: Scalars['String'];
};


export type QueryTableCountArgs = {
  outletID: Scalars['String'];
};


export type QueryQrCountArgs = {
  outletID: Scalars['String'];
};


export type QueryKitchenPrinterCountArgs = {
  outletID: Scalars['String'];
};


export type QueryReceiptPrinterCountArgs = {
  outletID: Scalars['String'];
};


export type QueryMealPeriodCountArgs = {
  outletID: Scalars['String'];
};


export type QueryMajorFamilyCountArgs = {
  outletID: Scalars['String'];
};


export type QueryStandardMenuOptionCountArgs = {
  outletID: Scalars['String'];
};


export type QueryMenuItemsCountArgs = {
  outletID: Scalars['String'];
};


export type QuerySetMenuCountArgs = {
  outletID: Scalars['String'];
};


export type QueryDigitalMenuPricingCountArgs = {
  outletID: Scalars['String'];
};


export type QueryMenuSchedulingCountArgs = {
  outletID: Scalars['String'];
};


export type QueryPromotionalMenuPricingCountArgs = {
  outletID: Scalars['String'];
};


export type QueryDailyMenuItemAvailabilityCountArgs = {
  outletID: Scalars['String'];
};


export type QueryPromoCodeCountArgs = {
  outletID: Scalars['String'];
};


export type QueryDiscountTypeCountArgs = {
  outletID: Scalars['String'];
};


export type QueryStopSalesItemCountArgs = {
  outletID: Scalars['String'];
};


export type QueryPaymentTypeDateArgs = {
  outletID: Scalars['String'];
};


export type QueryGetItemTotalArgs = {
  orderItemID: Scalars['String'];
};

export type ReasonEntity = AuditEntity & {
  __typename?: 'ReasonEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  description: Scalars['String'];
  outletID: Scalars['String'];
};

export type ReasonInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type ReceiptPrinterEntity = AuditEntity & {
  __typename?: 'ReceiptPrinterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  outletID: Scalars['String'];
  isDefault: Scalars['Boolean'];
};

export type ReceiptPrinterInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export enum RecurrenceDay {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type ReportDevEntity = {
  __typename?: 'ReportDevEntity';
  ID: Scalars['String'];
  ParentID: Scalars['String'];
  ReportName: Scalars['String'];
  FilePath?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Module?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type ReservationEntity = AuditEntity & {
  __typename?: 'ReservationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  contactNo: Scalars['String'];
  reservationDate: Scalars['DateTime'];
  reservationTime: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  noShow?: Maybe<Scalars['Boolean']>;
  pax: Scalars['Float'];
  status: ReservationStatus;
  tableID?: Maybe<Scalars['String']>;
  table?: Maybe<TableEntity>;
  patronID?: Maybe<Scalars['String']>;
  patron?: Maybe<PatronEntity>;
};

/** JsonType */
export type ReservationInfo = {
  __typename?: 'ReservationInfo';
  reserved: Scalars['Boolean'];
  pax: Scalars['Float'];
  name: Scalars['String'];
  time: Scalars['String'];
  noShow: Scalars['Boolean'];
};

export type ReservationInput = {
  noShow?: Maybe<Scalars['Boolean']>;
  patronID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  tableID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  contactNo: Scalars['String'];
  reservationDate: Scalars['DateTime'];
  reservationTime: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  pax: Scalars['Float'];
};

export enum ReservationStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  rolePerm: Array<RolePermissionAssignmentEntity>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type RolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'RolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  permName?: Maybe<Scalars['String']>;
};

export type RoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'RoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoundingPolicyEntity = AuditEntity & {
  __typename?: 'RoundingPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  inUse: Scalars['Boolean'];
  transactionType: TransactionType;
  methodType: MethodType;
  smallestDenomination: Scalars['Float'];
};

export type RoundingPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  inUse?: Maybe<Scalars['Boolean']>;
  transactionType?: Maybe<TransactionType>;
  methodType?: Maybe<MethodType>;
  smallestDenomination?: Maybe<Scalars['Float']>;
};

export type ScheduleBillingInput = {
  ID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BillNo?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  BillDate: Scalars['DateTime'];
  BillAmount?: Maybe<Scalars['Float']>;
  DebtorID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ScheduledDigitalMenuMealPeriod = {
  __typename?: 'ScheduledDigitalMenuMealPeriod';
  mealPeriodID: Scalars['String'];
  mealPeriodName: Scalars['String'];
  digitalMenuID: Scalars['String'];
  digitalMenuName: Scalars['String'];
};

export type ScheduledMenuEntity = AuditEntity & {
  __typename?: 'ScheduledMenuEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  effectiveTs: Scalars['DateTime'];
  /** CustomFieldResolver */
  scheduledDigitalMenuMealPeriod?: Maybe<Scalars['JSON']>;
};

export type ScheduledMenuInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  effectiveTs: Scalars['DateTime'];
};

export type ScheduledMenuMealPeriodEntity = AuditEntity & {
  __typename?: 'ScheduledMenuMealPeriodEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  mealPeriodID: Scalars['String'];
  scheduledMenuID: Scalars['String'];
  digitalMenuID: Scalars['String'];
};

export type ScheduledMenuMealPeriodInput = {
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  mealPeriodID: Scalars['String'];
  scheduledMenuID?: Maybe<Scalars['String']>;
  digitalMenuID: Scalars['String'];
};

export enum ServiceMode {
  DineIn = 'DINE_IN',
  Pickup = 'PICKUP',
  Delivery = 'DELIVERY'
}

/** JsonType */
export type SetMenuInfo = {
  __typename?: 'SetMenuInfo';
  menuItemID: Scalars['String'];
  setMenuID: Scalars['String'];
  setMenuName: Scalars['String'];
};

export type SetMenuOptionEntity = AuditEntity & {
  __typename?: 'SetMenuOptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  nativeName?: Maybe<Scalars['String']>;
  menuItemID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  maxSelections: Scalars['Float'];
  setMenuOptionItem?: Maybe<Array<SetMenuOptionItemEntity>>;
  menuItem?: Maybe<MenuItemEntity>;
  orderItemSetMenuOption?: Maybe<Array<OrderItemSetMenuOptionEntity>>;
  paymentItemSetMenuOption?: Maybe<Array<PaymentItemSetMenuOptionEntity>>;
};

export type SetMenuOptionInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nativeName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  maxSelections: Scalars['Float'];
  menuItemID: Scalars['String'];
};

export type SetMenuOptionItemEntity = AuditEntity & {
  __typename?: 'SetMenuOptionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  setMenuOptionID: Scalars['String'];
  menuItemID: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  setMenuOption?: Maybe<SetMenuOptionEntity>;
  menuItem?: Maybe<MenuItemEntity>;
  orderItemSetMenuOptionItem?: Maybe<Array<OrderItemSetMenuOptionItemEntity>>;
  paymentItemSetMenuOptionItem?: Maybe<Array<PaymentItemSetMenuOptionItemEntity>>;
};

export type SetMenuOptionItemInput = {
  outletID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  menuItemID: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  setMenuOptionID?: Maybe<Scalars['String']>;
};

export type ShareAttachmentEntity = AuditEntity & {
  __typename?: 'ShareAttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  outletID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type StaffEntity = AuditEntity & {
  __typename?: 'StaffEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  paymentID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  designation: Scalars['String'];
  mobileNo: Scalars['String'];
  email: Scalars['String'];
};

export type StaffInput = {
  name: Scalars['String'];
  designation: Scalars['String'];
  mobileNo: Scalars['String'];
  email: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  ID?: Maybe<Scalars['String']>;
};

export type StopSalesEntity = AuditEntity & {
  __typename?: 'StopSalesEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  menuItemID: Scalars['String'];
  outletID: Scalars['String'];
  menuItem?: Maybe<MenuItemEntity>;
  major: StopSalesEntity;
};

export type StopSalesInput = {
  menuItemID: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  refreshTable: TableEntity;
  refreshOrderItem: OrderItemEntity;
  getPrintData: PrintData;
};


export type SubscriptionRefreshTableArgs = {
  outletID: Scalars['String'];
};


export type SubscriptionRefreshOrderItemArgs = {
  outletID: Scalars['String'];
};


export type SubscriptionGetPrintDataArgs = {
  outletID: Scalars['String'];
};

export type TableEntity = AuditEntity & {
  __typename?: 'TableEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  sequence?: Maybe<Scalars['Float']>;
  outlet?: Maybe<OutletEntity>;
  seatCount: Scalars['Float'];
  prefix: Scalars['String'];
  tableNumber: Scalars['String'];
  status: TableStatus;
  reservation?: Maybe<ReservationEntity>;
  order?: Maybe<Array<OrderEntity>>;
  /** CustomFieldResolver */
  orderInfo?: Maybe<OrderInfo>;
  /** CustomFieldResolver */
  reservationInfo?: Maybe<ReservationInfo>;
  /** CustomFieldResolver */
  tablePrfixNumberInfo?: Maybe<Scalars['JSON']>;
};

/** JsonType */
export type TableInfo = {
  __typename?: 'TableInfo';
  count: Scalars['Float'];
  capacity: Scalars['Float'];
};

export type TableInput = {
  ID?: Maybe<Scalars['String']>;
  outletID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  seatCount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  prefix?: Maybe<Scalars['String']>;
  tableNumber?: Maybe<Scalars['String']>;
};

/** JsonType */
export type TablePrfixNumberInfo = {
  __typename?: 'TablePrfixNumberInfo';
  prefixNumber: Scalars['String'];
};

export type TableSessionEntity = AuditEntity & {
  __typename?: 'TableSessionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  tableID: Scalars['String'];
  tableSessionType: TableSessionType;
  billIDs: Array<Scalars['String']>;
  closedTs: Scalars['DateTime'];
  duration: Scalars['Float'];
};

export type TableSessionInput = {
  tableID: Scalars['String'];
  tableSessionType: TableSessionType;
  billIDs: Array<Scalars['String']>;
  closedTs: Scalars['DateTime'];
  duration: Scalars['Float'];
};

export enum TableSessionType {
  Preparation = 'PREPARATION',
  Dining = 'DINING',
  Closed = 'CLOSED',
  Opened = 'OPENED'
}

export enum TableStatus {
  Available = 'AVAILABLE',
  Occupied = 'OCCUPIED',
  Reserved = 'RESERVED'
}

export type TaxDetailInput = {
  id?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
};

/** JsonType */
export type TaxDetailtoInfo = {
  __typename?: 'TaxDetailtoInfo';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxId: Scalars['String'];
  commonStatus: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
};

export type TaxGlEntity = AuditEntity & {
  __typename?: 'TaxGLEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  outletID: Scalars['String'];
  accountID: Scalars['String'];
  taxID: Scalars['String'];
  schemetypeID: Scalars['String'];
  outletTaxGLAcct?: Maybe<AccountJson>;
  outletTaxDiscountGLAcct?: Maybe<AccountJson>;
};

export type TaxGlInput = {
  Description?: Maybe<Scalars['String']>;
  DescriptionType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  taxID?: Maybe<Scalars['String']>;
  GL?: Maybe<AccountJsonInput>;
  GLDisc?: Maybe<AccountJsonInput>;
};

export type TaxInput = {
  outletID?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['Boolean']>;
  serviceCharge?: Maybe<Scalars['Boolean']>;
  trxAmount?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type TaxListingOutput = {
  __typename?: 'TaxListingOutput';
  tax_id: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  class_type: Scalars['String'];
  tax_status: Scalars['String'];
  effective_date: Scalars['DateTime'];
  rate: Scalars['String'];
  rn?: Maybe<Scalars['String']>;
  tax_type?: Maybe<Scalars['String']>;
  scheme_type_id?: Maybe<Scalars['String']>;
};

export type TaxOnSeq = {
  __typename?: 'TaxOnSeq';
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

export type TaxOutput = {
  __typename?: 'TaxOutput';
  discountAmount?: Maybe<Scalars['Float']>;
  baseAmount?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
};

export type TaxSchemeDetailInput = {
  detailId?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  taxSchemeDetailItem: Array<TaxSchemeDetailItemInput>;
};

export type TaxSchemeDetailItemInput = {
  detailItemId?: Maybe<Scalars['String']>;
  detailId?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  classType: Scalars['String'];
  computeMethod: ComputeMethod;
  seqNo?: Maybe<Scalars['Float']>;
  taxOnSeq?: Maybe<Array<TaxOnSeqInput>>;
};

export type TaxSchemeDetailItemRateOutput = {
  __typename?: 'TaxSchemeDetailItemRateOutput';
  isExclusive: Scalars['Boolean'];
  taxId: Scalars['String'];
  taxRate: Scalars['Float'];
  computeMethod: Scalars['String'];
  seqNo: Scalars['String'];
  taxOnSeq?: Maybe<Array<TaxOnSeqObjects>>;
  taxSchemeDetailId: Scalars['String'];
};

export type TaxSchemeDetailOutput = {
  __typename?: 'TaxSchemeDetailOutput';
  taxId: Scalars['String'];
  taxRate: Scalars['Float'];
  taxCode: Scalars['String'];
  taxType: Scalars['String'];
};

/** JsonType */
export type TaxSchemeDetailsInfo = {
  __typename?: 'TaxSchemeDetailsInfo';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeId: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
};

/** JsonType */
export type TaxSchemeInfo = {
  __typename?: 'TaxSchemeInfo';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  isExclusive: Scalars['Boolean'];
  commonStatus: Scalars['String'];
};

export type TaxSchemeInput = {
  createdBy: Scalars['String'];
  modBy: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  taxSchemeDetail?: Maybe<Array<TaxSchemeDetailInput>>;
};

export type TaxSetupInput = {
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeTypeId: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  taxDetail: Array<TaxDetailInput>;
};

export enum TaxType {
  GovTax = 'GOV_TAX',
  ServiceCharge = 'SERVICE_CHARGE'
}

export type TestOutletInput = {
  name: Scalars['String'];
};

/** JsonType */
export type TotalInfo = {
  __typename?: 'TotalInfo';
  totalAmount: Scalars['Float'];
  totalBaseAmount: Scalars['Float'];
  totalTax: Scalars['Float'];
  totalServiceCharge: Scalars['Float'];
};

/** JsonType */
export type TotalTaxSchemeInfo = {
  __typename?: 'TotalTaxSchemeInfo';
  totalSST: Scalars['Float'];
  totalSC: Scalars['Float'];
  totalGST: Scalars['Float'];
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export enum TransactionType {
  Cash = 'CASH',
  Debit = 'DEBIT',
  Credit = 'CREDIT',
  Round = 'ROUND'
}

export type TransferInput = {
  orderItemID: Scalars['String'];
  removeQty: Scalars['Float'];
};

export type UomDeleteInput = {
  ID: Scalars['String'];
};

export type UomEntity = AuditEntity & {
  __typename?: 'UOMEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  code: Scalars['String'];
  commonStatus: CommonStatus;
  menuItem?: Maybe<Array<MenuItemEntity>>;
  fromUOMConversion?: Maybe<Array<UomConversionEntity>>;
  toUOMConversion?: Maybe<Array<UomConversionEntity>>;
};

export type UomInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

/** JsonType */
export type UnitPriceInfo = {
  __typename?: 'UnitPriceInfo';
  totalAmount: Scalars['Float'];
  totalBaseAmount: Scalars['Float'];
  totalTax: Scalars['Float'];
  totalServiceCharge: Scalars['Float'];
};

export type UomConversionEntity = AuditEntity & {
  __typename?: 'UomConversionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  fromAmt: Scalars['Float'];
  toAmt: Scalars['Float'];
  conversionRate: Scalars['Float'];
  uomLog?: Maybe<Array<Scalars['JSON']>>;
  fromUomID: Scalars['String'];
  fromUOM?: Maybe<UomEntity>;
  toUomID: Scalars['String'];
  toUOM?: Maybe<UomEntity>;
};

export type UomConversionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
  fromAmt?: Maybe<Scalars['Float']>;
  toAmt?: Maybe<Scalars['Float']>;
  conversionRate?: Maybe<Scalars['Float']>;
  uomLog?: Maybe<Array<ConversionLogInput>>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};


export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
};

export type UserOnlineEntity = AuditEntity & {
  __typename?: 'UserOnlineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  isActive: Scalars['Boolean'];
  outletID: Scalars['String'];
  userID: Scalars['String'];
  commonStatus: Scalars['String'];
  softwareCode?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  lastLogin: Scalars['DateTime'];
  lastLogout: Scalars['DateTime'];
  socketID?: Maybe<Scalars['String']>;
};

export type UserOnlineInput = {
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastLogout?: Maybe<Scalars['DateTime']>;
  socketID?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  password?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
};

export enum UserSelection {
  All = 'ALL',
  Superuser = 'SUPERUSER',
  Nonsuper = 'NONSUPER'
}

export type VoidOrderItemInput = {
  orderItemID: Scalars['String'];
  deductionQuantity: Scalars['Float'];
  reasonCode: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
};

export type Item = {
  __typename?: 'item';
  checkoutId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  qrCodeUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PasswordInfo = {
  ID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  confirmedPassword: Scalars['String'];
};

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type TaxDetailEntity = {
  __typename?: 'taxDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxId: Scalars['String'];
  commonStatus: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
  tax: TaxEntity;
};

export type TaxEntity = {
  __typename?: 'taxEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  schemeTypeId: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  taxDetail: Array<TaxDetailEntity>;
};

export type TaxOnSeqInput = {
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

/** JsonType */
export type TaxOnSeqObjects = {
  __typename?: 'taxOnSeqObjects';
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

export type TaxSchemeDetailEntity = {
  __typename?: 'taxSchemeDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeId: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
  taxScheme: TaxSchemeEntity;
  taxSchemeDetailItems?: Maybe<Array<TaxSchemeDetailItemEntity>>;
};

export type TaxSchemeDetailItemEntity = {
  __typename?: 'taxSchemeDetailItemEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeDetailId: Scalars['String'];
  computeMethod: Scalars['String'];
  taxId: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
  taxOnSeq?: Maybe<Array<TaxOnSeqObjects>>;
  commonStatus: Scalars['String'];
  taxSchemeDetail: TaxSchemeDetailEntity;
};

/** JsonType */
export type TaxSchemeDetailItemsInfo = {
  __typename?: 'taxSchemeDetailItemsInfo';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeDetailId: Scalars['String'];
  computeMethod: Scalars['String'];
  taxId: Scalars['String'];
  seqNo: Scalars['Float'];
  commonStatus: Scalars['String'];
};

export type TaxSchemeEntity = {
  __typename?: 'taxSchemeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  commonStatus: Scalars['String'];
  taxSchemeDetails?: Maybe<Array<TaxSchemeDetailEntity>>;
};

export type TaxSchemeTypeEntity = {
  __typename?: 'taxSchemeTypeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  commonStatus: Scalars['String'];
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'name' | 'email' | 'contactNo' | 'department' | 'superUser' | 'jobTitle'>
  ) }
);

export type LoginMutationVariables = Exact<{
  loginID: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'name'>
    ) }
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type CreatePasswordMutationVariables = Exact<{
  input: PasswordInfo;
}>;


export type CreatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPassword'>
);

export type GetScheduledDailyMenuCategoryQueryVariables = Exact<{
  outletID?: Maybe<Scalars['String']>;
  mealPeriodID?: Maybe<Scalars['String']>;
}>;


export type GetScheduledDailyMenuCategoryQuery = (
  { __typename?: 'Query' }
  & { getOutletDailyMenuCategory: Array<(
    { __typename?: 'OutletDailyMenuCategoryEntity' }
    & Pick<OutletDailyMenuCategoryEntity, 'ID' | 'name' | 'priority' | 'modTs' | 'createdTs' | 'outletID' | 'mealPeriodID' | 'digitalMenuCategoryID'>
    & { outletDailyMenuItem?: Maybe<Array<(
      { __typename?: 'OutletDailyMenuItemEntity' }
      & Pick<OutletDailyMenuItemEntity, 'ID' | 'name' | 'description' | 'menuItemID' | 'attachments' | 'isOpenItem' | 'isOpenPrice' | 'isByWeight' | 'isSetMenu' | 'isChefRecommended' | 'uomID' | 'promoChecking' | 'outletDailyMenuCategoryID'>
      & { outletDailyPromoMenuItem?: Maybe<Array<(
        { __typename?: 'OutletDailyPromoMenuItemEntity' }
        & Pick<OutletDailyPromoMenuItemEntity, 'ID' | 'promoMenuItemID' | 'startTime' | 'endTime'>
        & { outletDailyPromoMenuItemPrice?: Maybe<Array<(
          { __typename?: 'OutletDailyPromoMenuItemPriceEntity' }
          & Pick<OutletDailyPromoMenuItemPriceEntity, 'ID' | 'price' | 'name' | 'description' | 'patronID'>
        )>> }
      )>>, menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'isDiscountable'>
        & { dailyAvailability?: Maybe<(
          { __typename?: 'DailyMenuItemAvailabilityEntity' }
          & Pick<DailyMenuItemAvailabilityEntity, 'menuItemID' | 'ID' | 'outstandingQty'>
        )>, stopSales?: Maybe<(
          { __typename?: 'StopSalesEntity' }
          & Pick<StopSalesEntity, 'ID' | 'isActive' | 'menuItemID'>
        )>, menuItemOptions?: Maybe<Array<(
          { __typename?: 'MenuItemOptionEntity' }
          & Pick<MenuItemOptionEntity, 'maxSelections' | 'menuItemID' | 'optionID'>
          & { menuItemOptionItem?: Maybe<Array<(
            { __typename?: 'MenuItemOptionItemEntity' }
            & Pick<MenuItemOptionItemEntity, 'optionItemID' | 'menuItemOptionID' | 'price'>
            & { optionItem?: Maybe<(
              { __typename?: 'OptionItemEntity' }
              & Pick<OptionItemEntity, 'ID' | 'optionID' | 'name'>
            )> }
          )>>, option?: Maybe<(
            { __typename?: 'OptionEntity' }
            & Pick<OptionEntity, 'ID' | 'name'>
            & { optionItem?: Maybe<Array<(
              { __typename?: 'OptionItemEntity' }
              & Pick<OptionItemEntity, 'ID' | 'optionID' | 'name'>
            )>> }
          )> }
        )>>, setMenuOption?: Maybe<Array<(
          { __typename?: 'SetMenuOptionEntity' }
          & Pick<SetMenuOptionEntity, 'ID' | 'name' | 'maxSelections'>
          & { setMenuOptionItem?: Maybe<Array<(
            { __typename?: 'SetMenuOptionItemEntity' }
            & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity' | 'menuItemID'>
            & { menuItem?: Maybe<(
              { __typename?: 'MenuItemEntity' }
              & Pick<MenuItemEntity, 'ID' | 'name'>
            )> }
          )>> }
        )>> }
      )>, outletDailyMenuItemPatronPrice?: Maybe<Array<(
        { __typename?: 'OutletDailyMenuItemPatronPriceEntity' }
        & Pick<OutletDailyMenuItemPatronPriceEntity, 'ID' | 'outletID' | 'price' | 'name' | 'patronID' | 'description' | 'patronClass' | 'outletDailyMenuItemID'>
      )>> }
    )>> }
  )> }
);

export type GetDailyMenuItemQueryVariables = Exact<{
  outletDailyMenuCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetDailyMenuItemQuery = (
  { __typename?: 'Query' }
  & { getOutletDailyMenuItem: Array<(
    { __typename?: 'OutletDailyMenuItemEntity' }
    & Pick<OutletDailyMenuItemEntity, 'ID' | 'name' | 'description' | 'defaultQuantity' | 'menuItemID' | 'attachments'>
    & { outletDailyMenuItemOption?: Maybe<Array<(
      { __typename?: 'OutletDailyMenuItemOptionEntity' }
      & Pick<OutletDailyMenuItemOptionEntity, 'ID' | 'outletDailyMenuItemID' | 'maxSelections' | 'name' | 'optionID'>
      & { outletDailyMenuItemOptionItem?: Maybe<Array<(
        { __typename?: 'OutletDailyMenuItemOptionItemEntity' }
        & Pick<OutletDailyMenuItemOptionItemEntity, 'ID' | 'outletDailyMenuItemOptionID' | 'name' | 'price' | 'optionItemID'>
      )>> }
    )>> }
  )> }
);

export type GetDailyPatronPriceQueryVariables = Exact<{
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetDailyPatronPriceQuery = (
  { __typename?: 'Query' }
  & { getOutletDailyMenuItemPatronPrice: Array<(
    { __typename?: 'OutletDailyMenuItemPatronPriceEntity' }
    & Pick<OutletDailyMenuItemPatronPriceEntity, 'ID' | 'outletID' | 'price' | 'name' | 'description' | 'patronClass' | 'outletDailyMenuItemID'>
  )> }
);

export type CreateOrderItemPatronAppMutationVariables = Exact<{
  orderInput: OrderItemInput;
  optionItemInput?: Maybe<Array<OrderItemOptionInput> | OrderItemOptionInput>;
}>;


export type CreateOrderItemPatronAppMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrderItemPatronApp'>
);

export type GetAllMealPeriodQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllMealPeriodQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllMealPeriod'>
);

export type GetDailyMenuCategoryQueryVariables = Exact<{
  outletID?: Maybe<Scalars['String']>;
  mealPeriodID?: Maybe<Scalars['String']>;
}>;


export type GetDailyMenuCategoryQuery = (
  { __typename?: 'Query' }
  & { getOutletDailyMenuCategory: Array<(
    { __typename?: 'OutletDailyMenuCategoryEntity' }
    & Pick<OutletDailyMenuCategoryEntity, 'ID' | 'name' | 'outletID' | 'mealPeriodID' | 'priority' | 'digitalMenuCategoryID'>
    & { outletDailyMenuItem?: Maybe<Array<(
      { __typename?: 'OutletDailyMenuItemEntity' }
      & Pick<OutletDailyMenuItemEntity, 'ID' | 'outletDailyMenuCategoryID' | 'name'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID'>
        & { stopSales?: Maybe<(
          { __typename?: 'StopSalesEntity' }
          & Pick<StopSalesEntity, 'ID'>
        )> }
      )>, outletDailyMenuItemPatronPrice?: Maybe<Array<(
        { __typename?: 'OutletDailyMenuItemPatronPriceEntity' }
        & Pick<OutletDailyMenuItemPatronPriceEntity, 'patronID' | 'price'>
      )>>, outletDailyPromoMenuItem?: Maybe<Array<(
        { __typename?: 'OutletDailyPromoMenuItemEntity' }
        & Pick<OutletDailyPromoMenuItemEntity, 'ID' | 'startTime' | 'endTime'>
        & { outletDailyPromoMenuItemPrice?: Maybe<Array<(
          { __typename?: 'OutletDailyPromoMenuItemPriceEntity' }
          & Pick<OutletDailyPromoMenuItemPriceEntity, 'ID' | 'price' | 'patronID'>
        )>> }
      )>> }
    )>> }
  )> }
);

export type GetQrQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetQrQuery = (
  { __typename?: 'Query' }
  & { getQR: Array<(
    { __typename?: 'QREntity' }
    & Pick<QrEntity, 'ID' | 'code' | 'createdTs' | 'outletID' | 'orderInfo'>
  )> }
);

export type GetOrderQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  tableID?: Maybe<Scalars['String']>;
  mealPeriodID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
  outletID?: Maybe<Scalars['String']>;
  serviceMode?: Maybe<ServiceMode>;
}>;


export type GetOrderQuery = (
  { __typename?: 'Query' }
  & { getOrder: Array<(
    { __typename?: 'OrderEntity' }
    & Pick<OrderEntity, 'ID' | 'outletID' | 'tableID' | 'qrID' | 'docNo' | 'serviceMode' | 'openedByID' | 'servedByIDs' | 'paidByID' | 'businessDate' | 'pax' | 'mealPeriodID' | 'patronID' | 'createdTs' | 'totalAmount' | 'outstandingAmount' | 'registrationID' | 'patronAccountID' | 'taxSchemeInfo' | 'taxSchemeFooterInfo' | 'status' | 'patronName' | 'contactNo' | 'pickupTime' | 'qr'>
    & { orderDiscount?: Maybe<Array<(
      { __typename?: 'OrderDiscountEntity' }
      & Pick<OrderDiscountEntity, 'ID' | 'amount' | 'status' | 'reasonCode' | 'remark'>
    )>>, mealPeriod?: Maybe<(
      { __typename?: 'MealPeriodEntity' }
      & Pick<MealPeriodEntity, 'ID' | 'name' | 'startTime' | 'endTime'>
    )>, orderItem?: Maybe<Array<(
      { __typename?: 'OrderItemEntity' }
      & Pick<OrderItemEntity, 'ID' | 'name' | 'orderID' | 'amount' | 'specialRequest' | 'quantity' | 'status' | 'kitchenStatus' | 'isTakeaway' | 'weight' | 'menuItemID' | 'openItemName' | 'billID' | 'refID'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'name' | 'description' | 'attachments'>
      )>, orderItemOption?: Maybe<Array<(
        { __typename?: 'OrderItemOptionEntity' }
        & Pick<OrderItemOptionEntity, 'optionID' | 'name'>
        & { orderItemOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemEntity' }
          & Pick<OrderItemOptionItemEntity, 'optionItemID'>
          & { optionItem?: Maybe<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name'>
          )> }
        )>> }
      )>>, orderItemSetMenuOption?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionEntity' }
        & Pick<OrderItemSetMenuOptionEntity, 'setMenuOptionID' | 'name'>
        & { orderItemSetMenuOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemSetMenuOptionItemEntity' }
          & Pick<OrderItemSetMenuOptionItemEntity, 'setMenuOptionItemID'>
          & { setMenuOptionItem?: Maybe<(
            { __typename?: 'SetMenuOptionItemEntity' }
            & Pick<SetMenuOptionItemEntity, 'ID'>
            & { menuItem?: Maybe<(
              { __typename?: 'MenuItemEntity' }
              & Pick<MenuItemEntity, 'ID' | 'name'>
            )> }
          )> }
        )>> }
      )>> }
    )>>, bill?: Maybe<Array<(
      { __typename?: 'BillEntity' }
      & Pick<BillEntity, 'ID' | 'billNo' | 'businessDate'>
    )>>, table?: Maybe<(
      { __typename?: 'TableEntity' }
      & Pick<TableEntity, 'ID' | 'name' | 'seatCount' | 'prefix' | 'tableNumber' | 'status'>
    )>, patron?: Maybe<(
      { __typename?: 'PatronEntity' }
      & Pick<PatronEntity, 'patronClass'>
    )> }
  )> }
);

export type GetAllOrderItemQueryVariables = Exact<{
  orderID: Scalars['String'];
}>;


export type GetAllOrderItemQuery = (
  { __typename?: 'Query' }
  & { getAllOrderItem: Array<(
    { __typename?: 'OrderItemEntity' }
    & Pick<OrderItemEntity, 'ID' | 'quantity' | 'orderID' | 'menuItemID' | 'billID' | 'specialRequest' | 'unitPrice' | 'taxAmount' | 'serviceCharge' | 'amount' | 'baseAmount' | 'isTakeaway' | 'refID' | 'status' | 'weight' | 'openItemName' | 'orderDiscount' | 'totalInfo' | 'unitPriceInfo'>
    & { orderItemDiscount?: Maybe<Array<(
      { __typename?: 'OrderItemDiscountEntity' }
      & Pick<OrderItemDiscountEntity, 'amount' | 'status' | 'reasonCode' | 'remark'>
    )>>, orderDiscountItem?: Maybe<(
      { __typename?: 'OrderDiscountItemEntity' }
      & Pick<OrderDiscountItemEntity, 'amount'>
    )>, menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'name' | 'isSetMenu' | 'isByWeight' | 'isOpenItem' | 'isOpenPrice' | 'isDiscountable' | 'description' | 'attachments' | 'uomID'>
      & { menuItemOptions?: Maybe<Array<(
        { __typename?: 'MenuItemOptionEntity' }
        & Pick<MenuItemOptionEntity, 'ID' | 'optionID' | 'maxSelections'>
        & { menuItemOptionItem?: Maybe<Array<(
          { __typename?: 'MenuItemOptionItemEntity' }
          & Pick<MenuItemOptionItemEntity, 'ID' | 'menuItemOptionID' | 'optionItemID' | 'price'>
        )>>, option?: Maybe<(
          { __typename?: 'OptionEntity' }
          & Pick<OptionEntity, 'ID' | 'name'>
          & { optionItem?: Maybe<Array<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name' | 'ID'>
          )>> }
        )> }
      )>>, setMenuOption?: Maybe<Array<(
        { __typename?: 'SetMenuOptionEntity' }
        & Pick<SetMenuOptionEntity, 'ID' | 'name' | 'maxSelections'>
        & { setMenuOptionItem?: Maybe<Array<(
          { __typename?: 'SetMenuOptionItemEntity' }
          & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity' | 'menuItemID'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>, orderItemOption?: Maybe<Array<(
      { __typename?: 'OrderItemOptionEntity' }
      & Pick<OrderItemOptionEntity, 'optionID'>
      & { orderItemOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemOptionItemEntity' }
        & Pick<OrderItemOptionItemEntity, 'optionItemID' | 'price' | 'basePrice' | 'unitPrice' | 'taxAmount' | 'serviceCharge'>
        & { optionItem?: Maybe<(
          { __typename?: 'OptionItemEntity' }
          & Pick<OptionItemEntity, 'name'>
        )> }
      )>> }
    )>>, orderItemSetMenuOption?: Maybe<Array<(
      { __typename?: 'OrderItemSetMenuOptionEntity' }
      & Pick<OrderItemSetMenuOptionEntity, 'setMenuOptionID'>
      & { orderItemSetMenuOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionItemEntity' }
        & Pick<OrderItemSetMenuOptionItemEntity, 'setMenuOptionItemID' | 'quantity'>
        & { setMenuOptionItem?: Maybe<(
          { __typename?: 'SetMenuOptionItemEntity' }
          & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'ID' | 'name'>
          )> }
        )> }
      )>> }
    )>> }
  )> }
);

export type GetOutletQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
}>;


export type GetOutletQuery = (
  { __typename?: 'Query' }
  & { getOutlet: Array<(
    { __typename?: 'OutletEntity' }
    & Pick<OutletEntity, 'ID' | 'isActive' | 'name' | 'registrationNo' | 'taxRegistrationNo' | 'businessDate' | 'contactNo' | 'outletCode' | 'currencyCode' | 'email' | 'taxSchemeID' | 'logo' | 'isInclusive' | 'openingTime' | 'closingTime' | 'autoDayEndTime' | 'govTax' | 'serviceCharge' | 'tablesInfo' | 'address'>
    & { outletChannel?: Maybe<(
      { __typename?: 'OutletChannelEntity' }
      & Pick<OutletChannelEntity, 'ID' | 'url' | 'hotelID'>
    )>, operatingHours?: Maybe<Array<(
      { __typename?: 'OperatingHoursEntity' }
      & Pick<OperatingHoursEntity, 'openingTime' | 'closingTime'>
    )>>, customerPolicy?: Maybe<(
      { __typename?: 'OutletCustomerPolicyEntity' }
      & Pick<OutletCustomerPolicyEntity, 'selfOrder' | 'selfPayment'>
    )> }
  )> }
);

export type GetBillQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetBillQuery = (
  { __typename?: 'Query' }
  & { getBill: Array<(
    { __typename?: 'BillEntity' }
    & Pick<BillEntity, 'ID' | 'outletID' | 'createdTs' | 'billNo' | 'signature' | 'orderID' | 'status'>
    & { outlet?: Maybe<(
      { __typename?: 'OutletEntity' }
      & Pick<OutletEntity, 'name' | 'logo' | 'address' | 'contactNo' | 'currencyCode'>
      & { outletChannel?: Maybe<(
        { __typename?: 'OutletChannelEntity' }
        & Pick<OutletChannelEntity, 'ID' | 'hotelID' | 'name'>
      )> }
    )>, order?: Maybe<(
      { __typename?: 'OrderEntity' }
      & Pick<OrderEntity, 'ID' | 'docNo' | 'patronAccountID' | 'patronName' | 'patronID' | 'serviceMode'>
      & { orderDiscount?: Maybe<Array<(
        { __typename?: 'OrderDiscountEntity' }
        & Pick<OrderDiscountEntity, 'ID' | 'amount' | 'status' | 'reasonCode' | 'remark'>
      )>>, table?: Maybe<(
        { __typename?: 'TableEntity' }
        & Pick<TableEntity, 'ID' | 'tableNumber' | 'prefix' | 'status'>
      )>, patron?: Maybe<(
        { __typename?: 'PatronEntity' }
        & Pick<PatronEntity, 'patronClass'>
      )>, orderItem?: Maybe<Array<(
        { __typename?: 'OrderItemEntity' }
        & Pick<OrderItemEntity, 'ID' | 'billID'>
      )>> }
    )>, payments?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'ID' | 'amount' | 'registrationID' | 'receivedAmount' | 'changeAmount' | 'roundingAmount' | 'businessDate' | 'cardNo' | 'reasonCode' | 'creditCardType' | 'paymentClass' | 'patronAccountID' | 'hotelGuestInfo' | 'status' | 'remark' | 'createdTs'>
      & { defaultPayment?: Maybe<(
        { __typename?: 'DefaultPaymentEntity' }
        & Pick<DefaultPaymentEntity, 'ID' | 'paymentClass' | 'paymentTypeInfo'>
      )> }
    )>>, orderItem?: Maybe<Array<(
      { __typename?: 'OrderItemEntity' }
      & Pick<OrderItemEntity, 'ID' | 'amount' | 'refID' | 'serviceCharge' | 'taxAmount' | 'totalInfo' | 'quantity'>
      & { menuItem?: Maybe<(
        { __typename?: 'MenuItemEntity' }
        & Pick<MenuItemEntity, 'ID' | 'name'>
      )>, orderItemOption?: Maybe<Array<(
        { __typename?: 'OrderItemOptionEntity' }
        & Pick<OrderItemOptionEntity, 'ID' | 'name' | 'orderItemID'>
        & { orderItemOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemEntity' }
          & Pick<OrderItemOptionItemEntity, 'ID' | 'price' | 'unitPrice'>
          & { optionItem?: Maybe<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name'>
          )> }
        )>> }
      )>>, orderItemSetMenuOption?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionEntity' }
        & Pick<OrderItemSetMenuOptionEntity, 'ID'>
        & { orderItemSetMenuOptionItem?: Maybe<Array<(
          { __typename?: 'OrderItemSetMenuOptionItemEntity' }
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'name'>
          )>, setMenuOptionItem?: Maybe<(
            { __typename?: 'SetMenuOptionItemEntity' }
            & { menuItem?: Maybe<(
              { __typename?: 'MenuItemEntity' }
              & Pick<MenuItemEntity, 'name'>
            )> }
          )> }
        )>> }
      )>> }
    )>> }
  )> }
);

export type CreateReceiptAttachmentMutationVariables = Exact<{
  billID: Scalars['String'];
  outletID: Scalars['String'];
  file: Scalars['Upload'];
}>;


export type CreateReceiptAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { createReceiptAttachment?: Maybe<(
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'ID' | 'bucketFileName' | 'fileName' | 'fileURL'>
  )> }
);

export type CreateBillAndPaymentMutationVariables = Exact<{
  orderItemIDs: Array<Scalars['String']> | Scalars['String'];
  paymentInput: PaymentInput;
  billInput: BillInput;
  isTakeaway?: Maybe<Scalars['Boolean']>;
}>;


export type CreateBillAndPaymentMutation = (
  { __typename?: 'Mutation' }
  & { createBillAndPayment: (
    { __typename?: 'BillEntity' }
    & Pick<BillEntity, 'ID'>
  ) }
);

export type GetDefaultPaymentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetDefaultPaymentQuery = (
  { __typename?: 'Query' }
  & { getDefaultPayment: Array<(
    { __typename?: 'DefaultPaymentEntity' }
    & Pick<DefaultPaymentEntity, 'ID' | 'isActive' | 'outletID' | 'paymentTypeID' | 'isDefault' | 'paymentClass' | 'paymentTypeInfo'>
  )> }
);

export type GetPatronAccountQueryVariables = Exact<{
  isMember?: Maybe<Scalars['Boolean']>;
}>;


export type GetPatronAccountQuery = (
  { __typename?: 'Query' }
  & { getPatronAccount: Array<(
    { __typename?: 'PatronAccountEntity' }
    & Pick<PatronAccountEntity, 'ID' | 'name' | 'status' | 'creditBalance' | 'hotelRoomID' | 'membershipID'>
    & { hotelRoom?: Maybe<(
      { __typename?: 'HotelRoomEntity' }
      & Pick<HotelRoomEntity, 'ID' | 'roomNo'>
    )>, membership?: Maybe<(
      { __typename?: 'MembershipEntity' }
      & Pick<MembershipEntity, 'ID' | 'memberNo' | 'description'>
    )> }
  )> }
);

export type UpdateOrderItemWithSetMenuOptionsMutationVariables = Exact<{
  orderItemInput: OrderItemInput;
  orderItemSetMenuOptionInput?: Maybe<Array<OrderItemSetMenuOptionInput> | OrderItemSetMenuOptionInput>;
}>;


export type UpdateOrderItemWithSetMenuOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrderItemWithSetMenuOptions'>
);

export type UpdateOrderItemWithOptionsMutationVariables = Exact<{
  orderItemInput: OrderItemInput;
  orderItemOptionInput?: Maybe<Array<OrderItemOptionInput> | OrderItemOptionInput>;
}>;


export type UpdateOrderItemWithOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrderItemWithOptions'>
);

export type GetUomQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetUomQuery = (
  { __typename?: 'Query' }
  & { getUOM: Array<(
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'ID' | 'name' | 'code' | 'isActive'>
  )> }
);

export type GetOrderSummaryQueryVariables = Exact<{
  orderID?: Maybe<Scalars['String']>;
  menuItemID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderItemStatus>;
}>;


export type GetOrderSummaryQuery = (
  { __typename?: 'Query' }
  & { getOrderItem: Array<(
    { __typename?: 'OrderItemEntity' }
    & Pick<OrderItemEntity, 'ID' | 'quantity' | 'orderID' | 'menuItemID' | 'billID' | 'specialRequest' | 'unitPrice' | 'taxAmount' | 'serviceCharge' | 'amount' | 'baseAmount' | 'isTakeaway' | 'status' | 'weight' | 'openItemName' | 'totalInfo'>
    & { orderItemDiscount?: Maybe<Array<(
      { __typename?: 'OrderItemDiscountEntity' }
      & Pick<OrderItemDiscountEntity, 'amount' | 'status' | 'reasonCode' | 'remark'>
    )>>, menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'name' | 'isSetMenu' | 'isByWeight' | 'isOpenItem' | 'isOpenPrice' | 'description' | 'attachments' | 'uomID' | 'kitchenPrinterID'>
      & { kitchenPrinter?: Maybe<(
        { __typename?: 'KitchenPrinterEntity' }
        & Pick<KitchenPrinterEntity, 'ID' | 'name'>
      )>, menuItemOptions?: Maybe<Array<(
        { __typename?: 'MenuItemOptionEntity' }
        & Pick<MenuItemOptionEntity, 'ID' | 'optionID' | 'maxSelections'>
        & { menuItemOptionItem?: Maybe<Array<(
          { __typename?: 'MenuItemOptionItemEntity' }
          & Pick<MenuItemOptionItemEntity, 'ID' | 'menuItemOptionID' | 'optionItemID' | 'price'>
        )>>, option?: Maybe<(
          { __typename?: 'OptionEntity' }
          & Pick<OptionEntity, 'ID' | 'name'>
          & { optionItem?: Maybe<Array<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name' | 'ID'>
          )>> }
        )> }
      )>>, setMenuOption?: Maybe<Array<(
        { __typename?: 'SetMenuOptionEntity' }
        & Pick<SetMenuOptionEntity, 'ID' | 'name' | 'maxSelections'>
        & { setMenuOptionItem?: Maybe<Array<(
          { __typename?: 'SetMenuOptionItemEntity' }
          & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity' | 'menuItemID'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>, orderItemOption?: Maybe<Array<(
      { __typename?: 'OrderItemOptionEntity' }
      & Pick<OrderItemOptionEntity, 'optionID'>
      & { orderItemOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemOptionItemEntity' }
        & Pick<OrderItemOptionItemEntity, 'optionItemID' | 'price' | 'basePrice' | 'unitPrice' | 'taxAmount' | 'serviceCharge'>
        & { optionItem?: Maybe<(
          { __typename?: 'OptionItemEntity' }
          & Pick<OptionItemEntity, 'name'>
        )> }
      )>> }
    )>>, orderItemSetMenuOption?: Maybe<Array<(
      { __typename?: 'OrderItemSetMenuOptionEntity' }
      & Pick<OrderItemSetMenuOptionEntity, 'setMenuOptionID'>
      & { orderItemSetMenuOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionItemEntity' }
        & Pick<OrderItemSetMenuOptionItemEntity, 'setMenuOptionItemID' | 'quantity'>
        & { setMenuOptionItem?: Maybe<(
          { __typename?: 'SetMenuOptionItemEntity' }
          & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'ID' | 'name'>
          )> }
        )> }
      )>> }
    )>> }
  )> }
);

export type ConfirmOrderItemMutationVariables = Exact<{
  orderItemIDs: Array<Scalars['String']> | Scalars['String'];
  orderID: Scalars['String'];
}>;


export type ConfirmOrderItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmOrderItem'>
);

export type CreateOrderItemWithOptionsMutationVariables = Exact<{
  orderItemOptionInput?: Maybe<Array<OrderItemOptionInput> | OrderItemOptionInput>;
  orderItemInput: OrderItemInput;
}>;


export type CreateOrderItemWithOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrderItemWithOptions'>
);

export type CreateOrderItemWithSetMenuOptionsMutationVariables = Exact<{
  orderItemInput: OrderItemInput;
  orderItemSetMenuOptionInput?: Maybe<Array<OrderItemSetMenuOptionInput> | OrderItemSetMenuOptionInput>;
}>;


export type CreateOrderItemWithSetMenuOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrderItemWithSetMenuOptions'>
);

export type GrantCustomerAccessMutationVariables = Exact<{
  accountID: Scalars['String'];
  outletID: Scalars['String'];
  qrID: Scalars['String'];
}>;


export type GrantCustomerAccessMutation = (
  { __typename?: 'Mutation' }
  & { customerAccess: (
    { __typename?: 'CustomerAccessResponse' }
    & Pick<CustomerAccessResponse, 'accessToken'>
    & { customer: (
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'ID' | 'accountID' | 'isActive' | 'outletID' | 'orderID' | 'softwareCode' | 'superUser'>
    ) }
  ) }
);

export type GetMenuXInhouseListQueryVariables = Exact<{
  hotelID: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetMenuXInhouseListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMenuXInhouseList'>
);

export type GetMenuXDebtorListQueryVariables = Exact<{
  hotelID: Scalars['String'];
  outletID?: Maybe<Scalars['String']>;
}>;


export type GetMenuXDebtorListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMenuXDebtorList'>
);

export type CreatePaymentByQuantityMutationVariables = Exact<{
  paymentByQuantityInput: Array<PaymentByQuantityInput> | PaymentByQuantityInput;
  billInput: BillInput;
  paymentInput: PaymentInput;
  billLedgerInput?: Maybe<BillLedgerInput>;
  scheduleBillingInput?: Maybe<ScheduleBillingInput>;
}>;


export type CreatePaymentByQuantityMutation = (
  { __typename?: 'Mutation' }
  & { paymentByQuantity: (
    { __typename?: 'BillEntity' }
    & Pick<BillEntity, 'ID'>
  ) }
);

export type CreateOnlinePaymentMutationVariables = Exact<{
  outletID: Scalars['String'];
  title: Scalars['String'];
  detail: Scalars['String'];
  payAmount: Scalars['Float'];
  redirectUrl: Scalars['String'];
}>;


export type CreateOnlinePaymentMutation = (
  { __typename?: 'Mutation' }
  & { createOnlinePayment?: Maybe<(
    { __typename?: 'CheckOut' }
    & Pick<CheckOut, 'code' | 'paySessionNo'>
    & { item?: Maybe<(
      { __typename?: 'item' }
      & Pick<Item, 'checkoutId' | 'url' | 'qrCodeUrl' | 'status'>
    )>, error?: Maybe<(
      { __typename?: 'Errors' }
      & Pick<Errors, 'code' | 'message' | 'debug'>
    )> }
  )> }
);

export type PostPrintDataMutationVariables = Exact<{
  printData: PrintDataPayload;
}>;


export type PostPrintDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'postPrintData'>
);

export type GetOrderItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderID?: Maybe<Scalars['String']>;
  billID?: Maybe<Scalars['String']>;
  status?: Maybe<OrderItemStatus>;
}>;


export type GetOrderItemQuery = (
  { __typename?: 'Query' }
  & { getOrderItem: Array<(
    { __typename?: 'OrderItemEntity' }
    & Pick<OrderItemEntity, 'ID' | 'orderID' | 'billID' | 'openItemName' | 'quantity' | 'taxAmount' | 'serviceCharge' | 'amount' | 'baseAmount' | 'kitchenStatus' | 'status' | 'menuItemID' | 'orderDiscount' | 'totalInfo'>
    & { order?: Maybe<(
      { __typename?: 'OrderEntity' }
      & Pick<OrderEntity, 'ID'>
      & { bill?: Maybe<Array<(
        { __typename?: 'BillEntity' }
        & Pick<BillEntity, 'billNo'>
      )>> }
    )>, menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'ID' | 'name' | 'description' | 'isDiscountable'>
    )>, orderItemDiscount?: Maybe<Array<(
      { __typename?: 'OrderItemDiscountEntity' }
      & Pick<OrderItemDiscountEntity, 'ID' | 'amount' | 'status' | 'reasonCode' | 'remark'>
    )>>, orderItemOption?: Maybe<Array<(
      { __typename?: 'OrderItemOptionEntity' }
      & Pick<OrderItemOptionEntity, 'ID'>
      & { orderItemOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemOptionItemEntity' }
        & Pick<OrderItemOptionItemEntity, 'ID' | 'price' | 'basePrice' | 'unitPrice' | 'taxAmount' | 'serviceCharge'>
      )>> }
    )>> }
  )> }
);

export type GetBillSettlementOrderItemQueryVariables = Exact<{
  orderID: Scalars['String'];
}>;


export type GetBillSettlementOrderItemQuery = (
  { __typename?: 'Query' }
  & { getBillSettlementOrderItem: Array<(
    { __typename?: 'OrderItemEntity' }
    & Pick<OrderItemEntity, 'ID' | 'quantity' | 'orderID' | 'menuItemID' | 'billID' | 'specialRequest' | 'unitPrice' | 'taxAmount' | 'serviceCharge' | 'amount' | 'baseAmount' | 'isTakeaway' | 'refID' | 'status' | 'weight' | 'openItemName' | 'orderDiscount' | 'totalInfo'>
    & { orderItemTax?: Maybe<Array<(
      { __typename?: 'OrderItemTaxEntity' }
      & Pick<OrderItemTaxEntity, 'ID' | 'orderItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount' | 'taxSchemeType'>
    )>>, orderItemDiscountTax?: Maybe<Array<(
      { __typename?: 'OrderItemDiscountTaxEntity' }
      & Pick<OrderItemDiscountTaxEntity, 'ID' | 'orderItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
    )>>, orderDiscountItemTax?: Maybe<Array<(
      { __typename?: 'OrderDiscountItemTaxV1Entity' }
      & Pick<OrderDiscountItemTaxV1Entity, 'ID' | 'orderItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
    )>>, orderItemDiscount?: Maybe<Array<(
      { __typename?: 'OrderItemDiscountEntity' }
      & Pick<OrderItemDiscountEntity, 'orderItemID' | 'amount' | 'baseAmount' | 'serviceCharge' | 'taxAmount' | 'status' | 'reasonCode' | 'remark'>
    )>>, orderDiscountItem?: Maybe<(
      { __typename?: 'OrderDiscountItemEntity' }
      & Pick<OrderDiscountItemEntity, 'orderItemID' | 'amount' | 'baseAmount' | 'serviceCharge' | 'taxAmount'>
    )>, menuItem?: Maybe<(
      { __typename?: 'MenuItemEntity' }
      & Pick<MenuItemEntity, 'name' | 'isSetMenu' | 'isByWeight' | 'isOpenItem' | 'isOpenPrice' | 'isDiscountable' | 'description' | 'attachments' | 'uomID'>
      & { menuItemOptions?: Maybe<Array<(
        { __typename?: 'MenuItemOptionEntity' }
        & Pick<MenuItemOptionEntity, 'ID' | 'optionID' | 'maxSelections'>
        & { menuItemOptionItem?: Maybe<Array<(
          { __typename?: 'MenuItemOptionItemEntity' }
          & Pick<MenuItemOptionItemEntity, 'ID' | 'menuItemOptionID' | 'optionItemID' | 'price'>
        )>>, option?: Maybe<(
          { __typename?: 'OptionEntity' }
          & Pick<OptionEntity, 'ID' | 'name'>
          & { optionItem?: Maybe<Array<(
            { __typename?: 'OptionItemEntity' }
            & Pick<OptionItemEntity, 'name' | 'ID'>
          )>> }
        )> }
      )>>, setMenuOption?: Maybe<Array<(
        { __typename?: 'SetMenuOptionEntity' }
        & Pick<SetMenuOptionEntity, 'ID' | 'name' | 'maxSelections'>
        & { setMenuOptionItem?: Maybe<Array<(
          { __typename?: 'SetMenuOptionItemEntity' }
          & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity' | 'menuItemID'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'ID' | 'name'>
          )> }
        )>> }
      )>> }
    )>, orderItemOption?: Maybe<Array<(
      { __typename?: 'OrderItemOptionEntity' }
      & Pick<OrderItemOptionEntity, 'optionID'>
      & { orderItemOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemOptionItemEntity' }
        & Pick<OrderItemOptionItemEntity, 'optionItemID' | 'price' | 'basePrice' | 'unitPrice' | 'taxAmount' | 'serviceCharge' | 'discountAmount' | 'discountBaseAmount' | 'discountTaxAmount' | 'discountServiceCharge'>
        & { optionItem?: Maybe<(
          { __typename?: 'OptionItemEntity' }
          & Pick<OptionItemEntity, 'name'>
        )>, orderItemOptionItemTax?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemTaxEntity' }
          & Pick<OrderItemOptionItemTaxEntity, 'ID' | 'orderItemID' | 'orderItemOptionItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>>, orderItemOptionItemDiscountTax?: Maybe<Array<(
          { __typename?: 'OrderItemOptionItemDiscountTaxEntity' }
          & Pick<OrderItemOptionItemDiscountTaxEntity, 'ID' | 'orderItemOptionItemID' | 'orderItemDiscountID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>>, orderDiscountItemOptionTax?: Maybe<Array<(
          { __typename?: 'OrderDiscountItemOptionTaxEntity' }
          & Pick<OrderDiscountItemOptionTaxEntity, 'ID' | 'orderItemOptionItemID' | 'orderDiscountItemID' | 'taxSchemeID' | 'taxSchemeDetailID' | 'taxID' | 'taxRate' | 'taxAmount'>
        )>> }
      )>> }
    )>>, orderItemSetMenuOption?: Maybe<Array<(
      { __typename?: 'OrderItemSetMenuOptionEntity' }
      & Pick<OrderItemSetMenuOptionEntity, 'setMenuOptionID'>
      & { orderItemSetMenuOptionItem?: Maybe<Array<(
        { __typename?: 'OrderItemSetMenuOptionItemEntity' }
        & Pick<OrderItemSetMenuOptionItemEntity, 'setMenuOptionItemID' | 'quantity'>
        & { setMenuOptionItem?: Maybe<(
          { __typename?: 'SetMenuOptionItemEntity' }
          & Pick<SetMenuOptionItemEntity, 'ID' | 'quantity'>
          & { menuItem?: Maybe<(
            { __typename?: 'MenuItemEntity' }
            & Pick<MenuItemEntity, 'ID' | 'name'>
          )> }
        )> }
      )>> }
    )>> }
  )> }
);

export type GetStaffQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
}>;


export type GetStaffQuery = (
  { __typename?: 'Query' }
  & { getStaff: Array<(
    { __typename?: 'StaffEntity' }
    & Pick<StaffEntity, 'ID' | 'name' | 'designation' | 'mobileNo' | 'email' | 'isActive'>
  )> }
);

export type GetOfficerQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
}>;


export type GetOfficerQuery = (
  { __typename?: 'Query' }
  & { getOfficer: Array<(
    { __typename?: 'OfficerEntity' }
    & Pick<OfficerEntity, 'ID' | 'name' | 'designation' | 'mobileNo' | 'isActive' | 'email'>
  )> }
);

export type GetTableQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outletID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetTableQuery = (
  { __typename?: 'Query' }
  & { getTable: Array<(
    { __typename?: 'TableEntity' }
    & Pick<TableEntity, 'ID' | 'tableNumber' | 'seatCount' | 'prefix' | 'outletID' | 'isActive' | 'status'>
    & { orderInfo?: Maybe<(
      { __typename?: 'OrderInfo' }
      & Pick<OrderInfo, 'totalAmount' | 'totalPax'>
    )>, reservationInfo?: Maybe<(
      { __typename?: 'ReservationInfo' }
      & Pick<ReservationInfo, 'reserved' | 'pax' | 'name' | 'time' | 'noShow'>
    )>, reservation?: Maybe<(
      { __typename?: 'ReservationEntity' }
      & Pick<ReservationEntity, 'ID' | 'name' | 'reservationDate' | 'reservationTime'>
    )>, outlet?: Maybe<(
      { __typename?: 'OutletEntity' }
      & Pick<OutletEntity, 'ID' | 'name'>
    )>, order?: Maybe<Array<(
      { __typename?: 'OrderEntity' }
      & Pick<OrderEntity, 'tableID' | 'status'>
    )>> }
  )> }
);

export type GetCurrentMealPeriodQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetCurrentMealPeriodQuery = (
  { __typename?: 'Query' }
  & { getCurrentMealPeriod: (
    { __typename?: 'MealPeriodEntity' }
    & Pick<MealPeriodEntity, 'ID' | 'name' | 'startTime' | 'endTime' | 'outletID' | 'isActive'>
  ) }
);

export type GetOutletVisitorPatronQueryVariables = Exact<{
  outletID: Scalars['String'];
}>;


export type GetOutletVisitorPatronQuery = (
  { __typename?: 'Query' }
  & { getOutletVisitorPatron: (
    { __typename?: 'PatronEntity' }
    & Pick<PatronEntity, 'ID'>
  ) }
);

export type GetTaxSchemeDetailQueryVariables = Exact<{
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  orderID: Scalars['String'];
}>;


export type GetTaxSchemeDetailQuery = (
  { __typename?: 'Query' }
  & { getTaxSchemeDetail: Array<(
    { __typename?: 'TaxSchemeDetailOutput' }
    & Pick<TaxSchemeDetailOutput, 'taxId' | 'taxRate' | 'taxCode' | 'taxType'>
  )> }
);

export type GetRoundingPolicyQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
}>;


export type GetRoundingPolicyQuery = (
  { __typename?: 'Query' }
  & { getRoundingPolicy: Array<(
    { __typename?: 'RoundingPolicyEntity' }
    & Pick<RoundingPolicyEntity, 'ID' | 'inUse' | 'isActive' | 'transactionType' | 'methodType' | 'smallestDenomination'>
  )> }
);


export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    accountID
    name
    email
    contactNo
    department
    superUser
    jobTitle
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const LoginDocument = gql`
    mutation login($loginID: String!, $password: String!) {
  login(loginID: $loginID, password: $password) {
    accessToken
    user {
      name
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginID: // value for 'loginID'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const CreatePasswordDocument = gql`
    mutation createPassword($input: passwordInfo!) {
  createPassword(input: $input)
}
    `;
export type CreatePasswordMutationFn = ApolloReactCommon.MutationFunction<CreatePasswordMutation, CreatePasswordMutationVariables>;

/**
 * __useCreatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordMutation, { data, loading, error }] = useCreatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePasswordMutation, CreatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePasswordMutation, CreatePasswordMutationVariables>(CreatePasswordDocument, baseOptions);
      }
export type CreatePasswordMutationHookResult = ReturnType<typeof useCreatePasswordMutation>;
export type CreatePasswordMutationResult = ApolloReactCommon.MutationResult<CreatePasswordMutation>;
export type CreatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePasswordMutation, CreatePasswordMutationVariables>;
export const GetScheduledDailyMenuCategoryDocument = gql`
    query getScheduledDailyMenuCategory($outletID: String, $mealPeriodID: String) {
  getOutletDailyMenuCategory(outletID: $outletID, mealPeriodID: $mealPeriodID) {
    ID
    name
    priority
    modTs
    createdTs
    outletID
    mealPeriodID
    mealPeriodID
    digitalMenuCategoryID
    outletDailyMenuItem {
      ID
      name
      description
      menuItemID
      attachments
      isOpenItem
      isOpenPrice
      isByWeight
      isSetMenu
      isChefRecommended
      uomID
      promoChecking
      outletDailyMenuCategoryID
      outletDailyPromoMenuItem {
        ID
        promoMenuItemID
        startTime
        endTime
        outletDailyPromoMenuItemPrice {
          ID
          price
          name
          description
          patronID
        }
      }
      menuItem {
        ID
        isDiscountable
        dailyAvailability {
          menuItemID
          ID
          outstandingQty
        }
        stopSales {
          ID
          isActive
          menuItemID
        }
        menuItemOptions {
          maxSelections
          menuItemID
          optionID
          menuItemOptionItem {
            optionItemID
            menuItemOptionID
            price
            optionItem {
              ID
              optionID
              name
            }
          }
          option {
            ID
            name
            optionItem {
              ID
              optionID
              name
            }
          }
        }
        setMenuOption {
          ID
          name
          maxSelections
          setMenuOptionItem {
            ID
            quantity
            menuItemID
            menuItem {
              ID
              name
            }
          }
        }
        dailyAvailability {
          ID
          outstandingQty
        }
      }
      outletDailyMenuItemPatronPrice {
        ID
        outletID
        price
        name
        patronID
        description
        patronClass
        outletDailyMenuItemID
      }
    }
  }
}
    `;

/**
 * __useGetScheduledDailyMenuCategoryQuery__
 *
 * To run a query within a React component, call `useGetScheduledDailyMenuCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledDailyMenuCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledDailyMenuCategoryQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      mealPeriodID: // value for 'mealPeriodID'
 *   },
 * });
 */
export function useGetScheduledDailyMenuCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScheduledDailyMenuCategoryQuery, GetScheduledDailyMenuCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScheduledDailyMenuCategoryQuery, GetScheduledDailyMenuCategoryQueryVariables>(GetScheduledDailyMenuCategoryDocument, baseOptions);
      }
export function useGetScheduledDailyMenuCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScheduledDailyMenuCategoryQuery, GetScheduledDailyMenuCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScheduledDailyMenuCategoryQuery, GetScheduledDailyMenuCategoryQueryVariables>(GetScheduledDailyMenuCategoryDocument, baseOptions);
        }
export type GetScheduledDailyMenuCategoryQueryHookResult = ReturnType<typeof useGetScheduledDailyMenuCategoryQuery>;
export type GetScheduledDailyMenuCategoryLazyQueryHookResult = ReturnType<typeof useGetScheduledDailyMenuCategoryLazyQuery>;
export type GetScheduledDailyMenuCategoryQueryResult = ApolloReactCommon.QueryResult<GetScheduledDailyMenuCategoryQuery, GetScheduledDailyMenuCategoryQueryVariables>;
export const GetDailyMenuItemDocument = gql`
    query getDailyMenuItem($outletDailyMenuCategoryID: String) {
  getOutletDailyMenuItem(outletDailyMenuCategoryID: $outletDailyMenuCategoryID) {
    ID
    name
    description
    defaultQuantity
    menuItemID
    attachments
    outletDailyMenuItemOption {
      ID
      outletDailyMenuItemID
      maxSelections
      name
      optionID
      outletDailyMenuItemOptionItem {
        ID
        outletDailyMenuItemOptionID
        name
        price
        optionItemID
      }
    }
  }
}
    `;

/**
 * __useGetDailyMenuItemQuery__
 *
 * To run a query within a React component, call `useGetDailyMenuItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyMenuItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyMenuItemQuery({
 *   variables: {
 *      outletDailyMenuCategoryID: // value for 'outletDailyMenuCategoryID'
 *   },
 * });
 */
export function useGetDailyMenuItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDailyMenuItemQuery, GetDailyMenuItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDailyMenuItemQuery, GetDailyMenuItemQueryVariables>(GetDailyMenuItemDocument, baseOptions);
      }
export function useGetDailyMenuItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDailyMenuItemQuery, GetDailyMenuItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDailyMenuItemQuery, GetDailyMenuItemQueryVariables>(GetDailyMenuItemDocument, baseOptions);
        }
export type GetDailyMenuItemQueryHookResult = ReturnType<typeof useGetDailyMenuItemQuery>;
export type GetDailyMenuItemLazyQueryHookResult = ReturnType<typeof useGetDailyMenuItemLazyQuery>;
export type GetDailyMenuItemQueryResult = ApolloReactCommon.QueryResult<GetDailyMenuItemQuery, GetDailyMenuItemQueryVariables>;
export const GetDailyPatronPriceDocument = gql`
    query getDailyPatronPrice($outletID: String) {
  getOutletDailyMenuItemPatronPrice(outletID: $outletID) {
    ID
    outletID
    price
    name
    description
    patronClass
    outletDailyMenuItemID
  }
}
    `;

/**
 * __useGetDailyPatronPriceQuery__
 *
 * To run a query within a React component, call `useGetDailyPatronPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyPatronPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyPatronPriceQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetDailyPatronPriceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDailyPatronPriceQuery, GetDailyPatronPriceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDailyPatronPriceQuery, GetDailyPatronPriceQueryVariables>(GetDailyPatronPriceDocument, baseOptions);
      }
export function useGetDailyPatronPriceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDailyPatronPriceQuery, GetDailyPatronPriceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDailyPatronPriceQuery, GetDailyPatronPriceQueryVariables>(GetDailyPatronPriceDocument, baseOptions);
        }
export type GetDailyPatronPriceQueryHookResult = ReturnType<typeof useGetDailyPatronPriceQuery>;
export type GetDailyPatronPriceLazyQueryHookResult = ReturnType<typeof useGetDailyPatronPriceLazyQuery>;
export type GetDailyPatronPriceQueryResult = ApolloReactCommon.QueryResult<GetDailyPatronPriceQuery, GetDailyPatronPriceQueryVariables>;
export const CreateOrderItemPatronAppDocument = gql`
    mutation createOrderItemPatronApp($orderInput: OrderItemInput!, $optionItemInput: [OrderItemOptionInput!]) {
  createOrderItemPatronApp(orderInput: $orderInput, optionItemInput: $optionItemInput)
}
    `;
export type CreateOrderItemPatronAppMutationFn = ApolloReactCommon.MutationFunction<CreateOrderItemPatronAppMutation, CreateOrderItemPatronAppMutationVariables>;

/**
 * __useCreateOrderItemPatronAppMutation__
 *
 * To run a mutation, you first call `useCreateOrderItemPatronAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderItemPatronAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderItemPatronAppMutation, { data, loading, error }] = useCreateOrderItemPatronAppMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *      optionItemInput: // value for 'optionItemInput'
 *   },
 * });
 */
export function useCreateOrderItemPatronAppMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderItemPatronAppMutation, CreateOrderItemPatronAppMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderItemPatronAppMutation, CreateOrderItemPatronAppMutationVariables>(CreateOrderItemPatronAppDocument, baseOptions);
      }
export type CreateOrderItemPatronAppMutationHookResult = ReturnType<typeof useCreateOrderItemPatronAppMutation>;
export type CreateOrderItemPatronAppMutationResult = ApolloReactCommon.MutationResult<CreateOrderItemPatronAppMutation>;
export type CreateOrderItemPatronAppMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrderItemPatronAppMutation, CreateOrderItemPatronAppMutationVariables>;
export const GetAllMealPeriodDocument = gql`
    query getAllMealPeriod {
  getAllMealPeriod
}
    `;

/**
 * __useGetAllMealPeriodQuery__
 *
 * To run a query within a React component, call `useGetAllMealPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMealPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMealPeriodQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMealPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllMealPeriodQuery, GetAllMealPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllMealPeriodQuery, GetAllMealPeriodQueryVariables>(GetAllMealPeriodDocument, baseOptions);
      }
export function useGetAllMealPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllMealPeriodQuery, GetAllMealPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllMealPeriodQuery, GetAllMealPeriodQueryVariables>(GetAllMealPeriodDocument, baseOptions);
        }
export type GetAllMealPeriodQueryHookResult = ReturnType<typeof useGetAllMealPeriodQuery>;
export type GetAllMealPeriodLazyQueryHookResult = ReturnType<typeof useGetAllMealPeriodLazyQuery>;
export type GetAllMealPeriodQueryResult = ApolloReactCommon.QueryResult<GetAllMealPeriodQuery, GetAllMealPeriodQueryVariables>;
export const GetDailyMenuCategoryDocument = gql`
    query getDailyMenuCategory($outletID: String, $mealPeriodID: String) {
  getOutletDailyMenuCategory(outletID: $outletID, mealPeriodID: $mealPeriodID) {
    ID
    name
    outletID
    mealPeriodID
    mealPeriodID
    priority
    digitalMenuCategoryID
    outletDailyMenuItem {
      ID
      outletDailyMenuCategoryID
      name
      menuItem {
        ID
        stopSales {
          ID
        }
      }
      outletDailyMenuItemPatronPrice {
        patronID
        price
      }
      outletDailyPromoMenuItem {
        ID
        startTime
        endTime
        outletDailyPromoMenuItemPrice {
          ID
          price
          patronID
        }
      }
    }
  }
}
    `;

/**
 * __useGetDailyMenuCategoryQuery__
 *
 * To run a query within a React component, call `useGetDailyMenuCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyMenuCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyMenuCategoryQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      mealPeriodID: // value for 'mealPeriodID'
 *   },
 * });
 */
export function useGetDailyMenuCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDailyMenuCategoryQuery, GetDailyMenuCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDailyMenuCategoryQuery, GetDailyMenuCategoryQueryVariables>(GetDailyMenuCategoryDocument, baseOptions);
      }
export function useGetDailyMenuCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDailyMenuCategoryQuery, GetDailyMenuCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDailyMenuCategoryQuery, GetDailyMenuCategoryQueryVariables>(GetDailyMenuCategoryDocument, baseOptions);
        }
export type GetDailyMenuCategoryQueryHookResult = ReturnType<typeof useGetDailyMenuCategoryQuery>;
export type GetDailyMenuCategoryLazyQueryHookResult = ReturnType<typeof useGetDailyMenuCategoryLazyQuery>;
export type GetDailyMenuCategoryQueryResult = ApolloReactCommon.QueryResult<GetDailyMenuCategoryQuery, GetDailyMenuCategoryQueryVariables>;
export const GetQrDocument = gql`
    query getQR($ID: String, $code: String, $outletID: String) {
  getQR(ID: $ID, code: $code, outletID: $outletID) {
    ID
    code
    createdTs
    outletID
    orderInfo
  }
}
    `;

/**
 * __useGetQrQuery__
 *
 * To run a query within a React component, call `useGetQrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQrQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      code: // value for 'code'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetQrQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetQrQuery, GetQrQueryVariables>) {
        return ApolloReactHooks.useQuery<GetQrQuery, GetQrQueryVariables>(GetQrDocument, baseOptions);
      }
export function useGetQrLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQrQuery, GetQrQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetQrQuery, GetQrQueryVariables>(GetQrDocument, baseOptions);
        }
export type GetQrQueryHookResult = ReturnType<typeof useGetQrQuery>;
export type GetQrLazyQueryHookResult = ReturnType<typeof useGetQrLazyQuery>;
export type GetQrQueryResult = ApolloReactCommon.QueryResult<GetQrQuery, GetQrQueryVariables>;
export const GetOrderDocument = gql`
    query getOrder($ID: String, $tableID: String, $mealPeriodID: String, $status: OrderStatus, $outletID: String, $serviceMode: ServiceMode) {
  getOrder(ID: $ID, tableID: $tableID, mealPeriodID: $mealPeriodID, status: $status, outletID: $outletID, serviceMode: $serviceMode) {
    ID
    outletID
    tableID
    qrID
    docNo
    serviceMode
    openedByID
    servedByIDs
    paidByID
    serviceMode
    businessDate
    pax
    mealPeriodID
    patronID
    createdTs
    totalAmount
    outstandingAmount
    registrationID
    patronAccountID
    taxSchemeInfo
    taxSchemeFooterInfo
    status
    patronName
    contactNo
    pickupTime
    orderDiscount {
      ID
      amount
      status
      reasonCode
      remark
    }
    mealPeriod {
      ID
      name
      startTime
      endTime
    }
    orderItem {
      ID
      name
      orderID
      amount
      specialRequest
      quantity
      status
      kitchenStatus
      isTakeaway
      weight
      menuItemID
      openItemName
      menuItem {
        name
        description
        attachments
      }
      orderItemOption {
        optionID
        name
        orderItemOptionItem {
          optionItemID
          optionItem {
            name
          }
        }
      }
      orderItemSetMenuOption {
        setMenuOptionID
        name
        orderItemSetMenuOptionItem {
          setMenuOptionItemID
          setMenuOptionItem {
            ID
            menuItem {
              ID
              name
            }
          }
        }
      }
      billID
      refID
    }
    bill {
      ID
      billNo
      businessDate
    }
    docNo
    table {
      ID
      name
      seatCount
      prefix
      tableNumber
      status
    }
    patron {
      patronClass
    }
    qr
  }
}
    `;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      tableID: // value for 'tableID'
 *      mealPeriodID: // value for 'mealPeriodID'
 *      status: // value for 'status'
 *      outletID: // value for 'outletID'
 *      serviceMode: // value for 'serviceMode'
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, baseOptions);
      }
export function useGetOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, baseOptions);
        }
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = ApolloReactCommon.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetAllOrderItemDocument = gql`
    query getAllOrderItem($orderID: String!) {
  getAllOrderItem(orderID: $orderID) {
    ID
    quantity
    orderID
    menuItemID
    billID
    specialRequest
    unitPrice
    taxAmount
    serviceCharge
    amount
    baseAmount
    isTakeaway
    refID
    status
    weight
    openItemName
    orderDiscount
    totalInfo
    unitPriceInfo
    orderItemDiscount {
      amount
      status
      reasonCode
      remark
    }
    orderDiscountItem {
      amount
    }
    menuItem {
      name
      isSetMenu
      isByWeight
      isOpenItem
      isOpenPrice
      isDiscountable
      description
      attachments
      uomID
      menuItemOptions {
        ID
        optionID
        maxSelections
        optionID
        menuItemOptionItem {
          ID
          menuItemOptionID
          optionItemID
          price
        }
        option {
          ID
          name
          optionItem {
            name
            ID
          }
        }
      }
      setMenuOption {
        ID
        name
        maxSelections
        setMenuOptionItem {
          ID
          quantity
          menuItemID
          menuItem {
            ID
            name
          }
        }
      }
    }
    orderItemOption {
      optionID
      orderItemOptionItem {
        optionItemID
        price
        basePrice
        unitPrice
        taxAmount
        serviceCharge
        optionItem {
          name
        }
      }
    }
    orderItemSetMenuOption {
      setMenuOptionID
      orderItemSetMenuOptionItem {
        setMenuOptionItemID
        quantity
        setMenuOptionItem {
          ID
          quantity
          menuItem {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllOrderItemQuery__
 *
 * To run a query within a React component, call `useGetAllOrderItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrderItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrderItemQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useGetAllOrderItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllOrderItemQuery, GetAllOrderItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllOrderItemQuery, GetAllOrderItemQueryVariables>(GetAllOrderItemDocument, baseOptions);
      }
export function useGetAllOrderItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllOrderItemQuery, GetAllOrderItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllOrderItemQuery, GetAllOrderItemQueryVariables>(GetAllOrderItemDocument, baseOptions);
        }
export type GetAllOrderItemQueryHookResult = ReturnType<typeof useGetAllOrderItemQuery>;
export type GetAllOrderItemLazyQueryHookResult = ReturnType<typeof useGetAllOrderItemLazyQuery>;
export type GetAllOrderItemQueryResult = ApolloReactCommon.QueryResult<GetAllOrderItemQuery, GetAllOrderItemQueryVariables>;
export const GetOutletDocument = gql`
    query getOutlet($ID: String, $name: String, $accountID: String) {
  getOutlet(ID: $ID, name: $name, accountID: $accountID) {
    ID
    isActive
    name
    registrationNo
    taxRegistrationNo
    businessDate
    contactNo
    outletCode
    currencyCode
    email
    taxSchemeID
    logo
    isInclusive
    businessDate
    openingTime
    closingTime
    autoDayEndTime
    outletChannel {
      ID
      url
      hotelID
    }
    operatingHours {
      openingTime
      closingTime
    }
    customerPolicy {
      selfOrder
      selfPayment
    }
    govTax
    serviceCharge
    tablesInfo
    address
  }
}
    `;

/**
 * __useGetOutletQuery__
 *
 * To run a query within a React component, call `useGetOutletQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetOutletQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletQuery, GetOutletQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletQuery, GetOutletQueryVariables>(GetOutletDocument, baseOptions);
      }
export function useGetOutletLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletQuery, GetOutletQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletQuery, GetOutletQueryVariables>(GetOutletDocument, baseOptions);
        }
export type GetOutletQueryHookResult = ReturnType<typeof useGetOutletQuery>;
export type GetOutletLazyQueryHookResult = ReturnType<typeof useGetOutletLazyQuery>;
export type GetOutletQueryResult = ApolloReactCommon.QueryResult<GetOutletQuery, GetOutletQueryVariables>;
export const GetBillDocument = gql`
    query getBill($ID: String, $outletID: String, $orderByDesc: String) {
  getBill(ID: $ID, outletID: $outletID, orderByDesc: $orderByDesc) {
    ID
    outletID
    outlet {
      name
      logo
      address
      contactNo
      currencyCode
      outletChannel {
        ID
        hotelID
        name
      }
    }
    createdTs
    billNo
    signature
    orderID
    status
    order {
      ID
      docNo
      patronAccountID
      patronName
      patronID
      orderDiscount {
        ID
        amount
        status
        reasonCode
        remark
      }
      table {
        ID
        tableNumber
        prefix
        status
      }
      patron {
        patronClass
      }
      serviceMode
      orderItem {
        ID
        billID
      }
    }
    payments {
      ID
      amount
      registrationID
      receivedAmount
      changeAmount
      roundingAmount
      businessDate
      cardNo
      reasonCode
      creditCardType
      paymentClass
      patronAccountID
      hotelGuestInfo
      defaultPayment {
        ID
        paymentClass
        paymentTypeInfo
      }
      status
      remark
      createdTs
    }
    orderItem {
      ID
      amount
      refID
      serviceCharge
      taxAmount
      totalInfo
      quantity
      menuItem {
        ID
        name
      }
      orderItemOption {
        ID
        name
        orderItemID
        orderItemOptionItem {
          ID
          price
          unitPrice
          optionItem {
            name
          }
        }
      }
      orderItemSetMenuOption {
        ID
        orderItemSetMenuOptionItem {
          menuItem {
            name
          }
          setMenuOptionItem {
            menuItem {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetBillQuery__
 *
 * To run a query within a React component, call `useGetBillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetBillQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillQuery, GetBillQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillQuery, GetBillQueryVariables>(GetBillDocument, baseOptions);
      }
export function useGetBillLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillQuery, GetBillQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillQuery, GetBillQueryVariables>(GetBillDocument, baseOptions);
        }
export type GetBillQueryHookResult = ReturnType<typeof useGetBillQuery>;
export type GetBillLazyQueryHookResult = ReturnType<typeof useGetBillLazyQuery>;
export type GetBillQueryResult = ApolloReactCommon.QueryResult<GetBillQuery, GetBillQueryVariables>;
export const CreateReceiptAttachmentDocument = gql`
    mutation createReceiptAttachment($billID: String!, $outletID: String!, $file: Upload!) {
  createReceiptAttachment(billID: $billID, outletID: $outletID, file: $file) {
    ID
    bucketFileName
    fileName
    fileURL
  }
}
    `;
export type CreateReceiptAttachmentMutationFn = ApolloReactCommon.MutationFunction<CreateReceiptAttachmentMutation, CreateReceiptAttachmentMutationVariables>;

/**
 * __useCreateReceiptAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateReceiptAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceiptAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceiptAttachmentMutation, { data, loading, error }] = useCreateReceiptAttachmentMutation({
 *   variables: {
 *      billID: // value for 'billID'
 *      outletID: // value for 'outletID'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateReceiptAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReceiptAttachmentMutation, CreateReceiptAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReceiptAttachmentMutation, CreateReceiptAttachmentMutationVariables>(CreateReceiptAttachmentDocument, baseOptions);
      }
export type CreateReceiptAttachmentMutationHookResult = ReturnType<typeof useCreateReceiptAttachmentMutation>;
export type CreateReceiptAttachmentMutationResult = ApolloReactCommon.MutationResult<CreateReceiptAttachmentMutation>;
export type CreateReceiptAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReceiptAttachmentMutation, CreateReceiptAttachmentMutationVariables>;
export const CreateBillAndPaymentDocument = gql`
    mutation createBillAndPayment($orderItemIDs: [String!]!, $paymentInput: PaymentInput!, $billInput: BillInput!, $isTakeaway: Boolean) {
  createBillAndPayment(orderItemIDs: $orderItemIDs, paymentInput: $paymentInput, billInput: $billInput, isTakeaway: $isTakeaway) {
    ID
  }
}
    `;
export type CreateBillAndPaymentMutationFn = ApolloReactCommon.MutationFunction<CreateBillAndPaymentMutation, CreateBillAndPaymentMutationVariables>;

/**
 * __useCreateBillAndPaymentMutation__
 *
 * To run a mutation, you first call `useCreateBillAndPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillAndPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillAndPaymentMutation, { data, loading, error }] = useCreateBillAndPaymentMutation({
 *   variables: {
 *      orderItemIDs: // value for 'orderItemIDs'
 *      paymentInput: // value for 'paymentInput'
 *      billInput: // value for 'billInput'
 *      isTakeaway: // value for 'isTakeaway'
 *   },
 * });
 */
export function useCreateBillAndPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBillAndPaymentMutation, CreateBillAndPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBillAndPaymentMutation, CreateBillAndPaymentMutationVariables>(CreateBillAndPaymentDocument, baseOptions);
      }
export type CreateBillAndPaymentMutationHookResult = ReturnType<typeof useCreateBillAndPaymentMutation>;
export type CreateBillAndPaymentMutationResult = ApolloReactCommon.MutationResult<CreateBillAndPaymentMutation>;
export type CreateBillAndPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBillAndPaymentMutation, CreateBillAndPaymentMutationVariables>;
export const GetDefaultPaymentDocument = gql`
    query getDefaultPayment($ID: String, $outletID: String, $orderByDesc: String, $orderByAsc: String) {
  getDefaultPayment(ID: $ID, outletID: $outletID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    ID
    isActive
    outletID
    paymentTypeID
    isDefault
    paymentClass
    paymentTypeInfo
  }
}
    `;

/**
 * __useGetDefaultPaymentQuery__
 *
 * To run a query within a React component, call `useGetDefaultPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultPaymentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      outletID: // value for 'outletID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetDefaultPaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDefaultPaymentQuery, GetDefaultPaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDefaultPaymentQuery, GetDefaultPaymentQueryVariables>(GetDefaultPaymentDocument, baseOptions);
      }
export function useGetDefaultPaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDefaultPaymentQuery, GetDefaultPaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDefaultPaymentQuery, GetDefaultPaymentQueryVariables>(GetDefaultPaymentDocument, baseOptions);
        }
export type GetDefaultPaymentQueryHookResult = ReturnType<typeof useGetDefaultPaymentQuery>;
export type GetDefaultPaymentLazyQueryHookResult = ReturnType<typeof useGetDefaultPaymentLazyQuery>;
export type GetDefaultPaymentQueryResult = ApolloReactCommon.QueryResult<GetDefaultPaymentQuery, GetDefaultPaymentQueryVariables>;
export const GetPatronAccountDocument = gql`
    query getPatronAccount($isMember: Boolean) {
  getPatronAccount(isMember: $isMember) {
    ID
    name
    status
    creditBalance
    hotelRoomID
    hotelRoom {
      ID
      roomNo
    }
    membershipID
    membership {
      ID
      memberNo
      description
    }
  }
}
    `;

/**
 * __useGetPatronAccountQuery__
 *
 * To run a query within a React component, call `useGetPatronAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatronAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatronAccountQuery({
 *   variables: {
 *      isMember: // value for 'isMember'
 *   },
 * });
 */
export function useGetPatronAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPatronAccountQuery, GetPatronAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPatronAccountQuery, GetPatronAccountQueryVariables>(GetPatronAccountDocument, baseOptions);
      }
export function useGetPatronAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPatronAccountQuery, GetPatronAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPatronAccountQuery, GetPatronAccountQueryVariables>(GetPatronAccountDocument, baseOptions);
        }
export type GetPatronAccountQueryHookResult = ReturnType<typeof useGetPatronAccountQuery>;
export type GetPatronAccountLazyQueryHookResult = ReturnType<typeof useGetPatronAccountLazyQuery>;
export type GetPatronAccountQueryResult = ApolloReactCommon.QueryResult<GetPatronAccountQuery, GetPatronAccountQueryVariables>;
export const UpdateOrderItemWithSetMenuOptionsDocument = gql`
    mutation updateOrderItemWithSetMenuOptions($orderItemInput: OrderItemInput!, $orderItemSetMenuOptionInput: [OrderItemSetMenuOptionInput!]) {
  updateOrderItemWithSetMenuOptions(orderItemInput: $orderItemInput, orderItemSetMenuOptionInput: $orderItemSetMenuOptionInput)
}
    `;
export type UpdateOrderItemWithSetMenuOptionsMutationFn = ApolloReactCommon.MutationFunction<UpdateOrderItemWithSetMenuOptionsMutation, UpdateOrderItemWithSetMenuOptionsMutationVariables>;

/**
 * __useUpdateOrderItemWithSetMenuOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateOrderItemWithSetMenuOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderItemWithSetMenuOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderItemWithSetMenuOptionsMutation, { data, loading, error }] = useUpdateOrderItemWithSetMenuOptionsMutation({
 *   variables: {
 *      orderItemInput: // value for 'orderItemInput'
 *      orderItemSetMenuOptionInput: // value for 'orderItemSetMenuOptionInput'
 *   },
 * });
 */
export function useUpdateOrderItemWithSetMenuOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderItemWithSetMenuOptionsMutation, UpdateOrderItemWithSetMenuOptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrderItemWithSetMenuOptionsMutation, UpdateOrderItemWithSetMenuOptionsMutationVariables>(UpdateOrderItemWithSetMenuOptionsDocument, baseOptions);
      }
export type UpdateOrderItemWithSetMenuOptionsMutationHookResult = ReturnType<typeof useUpdateOrderItemWithSetMenuOptionsMutation>;
export type UpdateOrderItemWithSetMenuOptionsMutationResult = ApolloReactCommon.MutationResult<UpdateOrderItemWithSetMenuOptionsMutation>;
export type UpdateOrderItemWithSetMenuOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrderItemWithSetMenuOptionsMutation, UpdateOrderItemWithSetMenuOptionsMutationVariables>;
export const UpdateOrderItemWithOptionsDocument = gql`
    mutation updateOrderItemWithOptions($orderItemInput: OrderItemInput!, $orderItemOptionInput: [OrderItemOptionInput!]) {
  updateOrderItemWithOptions(orderItemInput: $orderItemInput, orderItemOptionInput: $orderItemOptionInput)
}
    `;
export type UpdateOrderItemWithOptionsMutationFn = ApolloReactCommon.MutationFunction<UpdateOrderItemWithOptionsMutation, UpdateOrderItemWithOptionsMutationVariables>;

/**
 * __useUpdateOrderItemWithOptionsMutation__
 *
 * To run a mutation, you first call `useUpdateOrderItemWithOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderItemWithOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderItemWithOptionsMutation, { data, loading, error }] = useUpdateOrderItemWithOptionsMutation({
 *   variables: {
 *      orderItemInput: // value for 'orderItemInput'
 *      orderItemOptionInput: // value for 'orderItemOptionInput'
 *   },
 * });
 */
export function useUpdateOrderItemWithOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderItemWithOptionsMutation, UpdateOrderItemWithOptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrderItemWithOptionsMutation, UpdateOrderItemWithOptionsMutationVariables>(UpdateOrderItemWithOptionsDocument, baseOptions);
      }
export type UpdateOrderItemWithOptionsMutationHookResult = ReturnType<typeof useUpdateOrderItemWithOptionsMutation>;
export type UpdateOrderItemWithOptionsMutationResult = ApolloReactCommon.MutationResult<UpdateOrderItemWithOptionsMutation>;
export type UpdateOrderItemWithOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrderItemWithOptionsMutation, UpdateOrderItemWithOptionsMutationVariables>;
export const GetUomDocument = gql`
    query getUOM($ID: String) {
  getUOM(ID: $ID) {
    ID
    name
    code
    isActive
  }
}
    `;

/**
 * __useGetUomQuery__
 *
 * To run a query within a React component, call `useGetUomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetUomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomQuery, GetUomQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomQuery, GetUomQueryVariables>(GetUomDocument, baseOptions);
      }
export function useGetUomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomQuery, GetUomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomQuery, GetUomQueryVariables>(GetUomDocument, baseOptions);
        }
export type GetUomQueryHookResult = ReturnType<typeof useGetUomQuery>;
export type GetUomLazyQueryHookResult = ReturnType<typeof useGetUomLazyQuery>;
export type GetUomQueryResult = ApolloReactCommon.QueryResult<GetUomQuery, GetUomQueryVariables>;
export const GetOrderSummaryDocument = gql`
    query getOrderSummary($orderID: String, $menuItemID: String, $status: OrderItemStatus) {
  getOrderItem(orderID: $orderID, menuItemID: $menuItemID, status: $status) {
    ID
    quantity
    orderID
    menuItemID
    billID
    specialRequest
    unitPrice
    taxAmount
    serviceCharge
    amount
    baseAmount
    isTakeaway
    status
    weight
    openItemName
    totalInfo
    orderItemDiscount {
      amount
      status
      reasonCode
      remark
    }
    menuItem {
      name
      isSetMenu
      isByWeight
      isOpenItem
      isOpenPrice
      description
      attachments
      uomID
      kitchenPrinterID
      kitchenPrinter {
        ID
        name
      }
      menuItemOptions {
        ID
        optionID
        maxSelections
        optionID
        menuItemOptionItem {
          ID
          menuItemOptionID
          optionItemID
          price
        }
        option {
          ID
          name
          optionItem {
            name
            ID
          }
        }
      }
      setMenuOption {
        ID
        name
        maxSelections
        setMenuOptionItem {
          ID
          quantity
          menuItemID
          menuItem {
            ID
            name
          }
        }
      }
    }
    orderItemOption {
      optionID
      orderItemOptionItem {
        optionItemID
        price
        basePrice
        unitPrice
        taxAmount
        serviceCharge
        optionItem {
          name
        }
      }
    }
    orderItemSetMenuOption {
      setMenuOptionID
      orderItemSetMenuOptionItem {
        setMenuOptionItemID
        quantity
        setMenuOptionItem {
          ID
          quantity
          menuItem {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrderSummaryQuery__
 *
 * To run a query within a React component, call `useGetOrderSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderSummaryQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      menuItemID: // value for 'menuItemID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetOrderSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>(GetOrderSummaryDocument, baseOptions);
      }
export function useGetOrderSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>(GetOrderSummaryDocument, baseOptions);
        }
export type GetOrderSummaryQueryHookResult = ReturnType<typeof useGetOrderSummaryQuery>;
export type GetOrderSummaryLazyQueryHookResult = ReturnType<typeof useGetOrderSummaryLazyQuery>;
export type GetOrderSummaryQueryResult = ApolloReactCommon.QueryResult<GetOrderSummaryQuery, GetOrderSummaryQueryVariables>;
export const ConfirmOrderItemDocument = gql`
    mutation confirmOrderItem($orderItemIDs: [String!]!, $orderID: String!) {
  confirmOrderItem(orderItemIDs: $orderItemIDs, orderID: $orderID)
}
    `;
export type ConfirmOrderItemMutationFn = ApolloReactCommon.MutationFunction<ConfirmOrderItemMutation, ConfirmOrderItemMutationVariables>;

/**
 * __useConfirmOrderItemMutation__
 *
 * To run a mutation, you first call `useConfirmOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOrderItemMutation, { data, loading, error }] = useConfirmOrderItemMutation({
 *   variables: {
 *      orderItemIDs: // value for 'orderItemIDs'
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useConfirmOrderItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmOrderItemMutation, ConfirmOrderItemMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmOrderItemMutation, ConfirmOrderItemMutationVariables>(ConfirmOrderItemDocument, baseOptions);
      }
export type ConfirmOrderItemMutationHookResult = ReturnType<typeof useConfirmOrderItemMutation>;
export type ConfirmOrderItemMutationResult = ApolloReactCommon.MutationResult<ConfirmOrderItemMutation>;
export type ConfirmOrderItemMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmOrderItemMutation, ConfirmOrderItemMutationVariables>;
export const CreateOrderItemWithOptionsDocument = gql`
    mutation createOrderItemWithOptions($orderItemOptionInput: [OrderItemOptionInput!], $orderItemInput: OrderItemInput!) {
  createOrderItemWithOptions(orderItemOptionInput: $orderItemOptionInput, orderItemInput: $orderItemInput)
}
    `;
export type CreateOrderItemWithOptionsMutationFn = ApolloReactCommon.MutationFunction<CreateOrderItemWithOptionsMutation, CreateOrderItemWithOptionsMutationVariables>;

/**
 * __useCreateOrderItemWithOptionsMutation__
 *
 * To run a mutation, you first call `useCreateOrderItemWithOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderItemWithOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderItemWithOptionsMutation, { data, loading, error }] = useCreateOrderItemWithOptionsMutation({
 *   variables: {
 *      orderItemOptionInput: // value for 'orderItemOptionInput'
 *      orderItemInput: // value for 'orderItemInput'
 *   },
 * });
 */
export function useCreateOrderItemWithOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderItemWithOptionsMutation, CreateOrderItemWithOptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderItemWithOptionsMutation, CreateOrderItemWithOptionsMutationVariables>(CreateOrderItemWithOptionsDocument, baseOptions);
      }
export type CreateOrderItemWithOptionsMutationHookResult = ReturnType<typeof useCreateOrderItemWithOptionsMutation>;
export type CreateOrderItemWithOptionsMutationResult = ApolloReactCommon.MutationResult<CreateOrderItemWithOptionsMutation>;
export type CreateOrderItemWithOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrderItemWithOptionsMutation, CreateOrderItemWithOptionsMutationVariables>;
export const CreateOrderItemWithSetMenuOptionsDocument = gql`
    mutation createOrderItemWithSetMenuOptions($orderItemInput: OrderItemInput!, $orderItemSetMenuOptionInput: [OrderItemSetMenuOptionInput!]) {
  createOrderItemWithSetMenuOptions(orderItemInput: $orderItemInput, orderItemSetMenuOptionInput: $orderItemSetMenuOptionInput)
}
    `;
export type CreateOrderItemWithSetMenuOptionsMutationFn = ApolloReactCommon.MutationFunction<CreateOrderItemWithSetMenuOptionsMutation, CreateOrderItemWithSetMenuOptionsMutationVariables>;

/**
 * __useCreateOrderItemWithSetMenuOptionsMutation__
 *
 * To run a mutation, you first call `useCreateOrderItemWithSetMenuOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderItemWithSetMenuOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderItemWithSetMenuOptionsMutation, { data, loading, error }] = useCreateOrderItemWithSetMenuOptionsMutation({
 *   variables: {
 *      orderItemInput: // value for 'orderItemInput'
 *      orderItemSetMenuOptionInput: // value for 'orderItemSetMenuOptionInput'
 *   },
 * });
 */
export function useCreateOrderItemWithSetMenuOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderItemWithSetMenuOptionsMutation, CreateOrderItemWithSetMenuOptionsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderItemWithSetMenuOptionsMutation, CreateOrderItemWithSetMenuOptionsMutationVariables>(CreateOrderItemWithSetMenuOptionsDocument, baseOptions);
      }
export type CreateOrderItemWithSetMenuOptionsMutationHookResult = ReturnType<typeof useCreateOrderItemWithSetMenuOptionsMutation>;
export type CreateOrderItemWithSetMenuOptionsMutationResult = ApolloReactCommon.MutationResult<CreateOrderItemWithSetMenuOptionsMutation>;
export type CreateOrderItemWithSetMenuOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrderItemWithSetMenuOptionsMutation, CreateOrderItemWithSetMenuOptionsMutationVariables>;
export const GrantCustomerAccessDocument = gql`
    mutation grantCustomerAccess($accountID: String!, $outletID: String!, $qrID: String!) {
  customerAccess(accountID: $accountID, outletID: $outletID, qrID: $qrID) {
    accessToken
    customer {
      ID
      accountID
      isActive
      outletID
      orderID
      softwareCode
      superUser
    }
  }
}
    `;
export type GrantCustomerAccessMutationFn = ApolloReactCommon.MutationFunction<GrantCustomerAccessMutation, GrantCustomerAccessMutationVariables>;

/**
 * __useGrantCustomerAccessMutation__
 *
 * To run a mutation, you first call `useGrantCustomerAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGrantCustomerAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [grantCustomerAccessMutation, { data, loading, error }] = useGrantCustomerAccessMutation({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      outletID: // value for 'outletID'
 *      qrID: // value for 'qrID'
 *   },
 * });
 */
export function useGrantCustomerAccessMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GrantCustomerAccessMutation, GrantCustomerAccessMutationVariables>) {
        return ApolloReactHooks.useMutation<GrantCustomerAccessMutation, GrantCustomerAccessMutationVariables>(GrantCustomerAccessDocument, baseOptions);
      }
export type GrantCustomerAccessMutationHookResult = ReturnType<typeof useGrantCustomerAccessMutation>;
export type GrantCustomerAccessMutationResult = ApolloReactCommon.MutationResult<GrantCustomerAccessMutation>;
export type GrantCustomerAccessMutationOptions = ApolloReactCommon.BaseMutationOptions<GrantCustomerAccessMutation, GrantCustomerAccessMutationVariables>;
export const GetMenuXInhouseListDocument = gql`
    query getMenuXInhouseList($hotelID: String!, $outletID: String) {
  getMenuXInhouseList(hotelID: $hotelID, outletID: $outletID)
}
    `;

/**
 * __useGetMenuXInhouseListQuery__
 *
 * To run a query within a React component, call `useGetMenuXInhouseListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuXInhouseListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuXInhouseListQuery({
 *   variables: {
 *      hotelID: // value for 'hotelID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetMenuXInhouseListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMenuXInhouseListQuery, GetMenuXInhouseListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMenuXInhouseListQuery, GetMenuXInhouseListQueryVariables>(GetMenuXInhouseListDocument, baseOptions);
      }
export function useGetMenuXInhouseListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMenuXInhouseListQuery, GetMenuXInhouseListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMenuXInhouseListQuery, GetMenuXInhouseListQueryVariables>(GetMenuXInhouseListDocument, baseOptions);
        }
export type GetMenuXInhouseListQueryHookResult = ReturnType<typeof useGetMenuXInhouseListQuery>;
export type GetMenuXInhouseListLazyQueryHookResult = ReturnType<typeof useGetMenuXInhouseListLazyQuery>;
export type GetMenuXInhouseListQueryResult = ApolloReactCommon.QueryResult<GetMenuXInhouseListQuery, GetMenuXInhouseListQueryVariables>;
export const GetMenuXDebtorListDocument = gql`
    query getMenuXDebtorList($hotelID: String!, $outletID: String) {
  getMenuXDebtorList(hotelID: $hotelID, outletID: $outletID)
}
    `;

/**
 * __useGetMenuXDebtorListQuery__
 *
 * To run a query within a React component, call `useGetMenuXDebtorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuXDebtorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuXDebtorListQuery({
 *   variables: {
 *      hotelID: // value for 'hotelID'
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetMenuXDebtorListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMenuXDebtorListQuery, GetMenuXDebtorListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMenuXDebtorListQuery, GetMenuXDebtorListQueryVariables>(GetMenuXDebtorListDocument, baseOptions);
      }
export function useGetMenuXDebtorListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMenuXDebtorListQuery, GetMenuXDebtorListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMenuXDebtorListQuery, GetMenuXDebtorListQueryVariables>(GetMenuXDebtorListDocument, baseOptions);
        }
export type GetMenuXDebtorListQueryHookResult = ReturnType<typeof useGetMenuXDebtorListQuery>;
export type GetMenuXDebtorListLazyQueryHookResult = ReturnType<typeof useGetMenuXDebtorListLazyQuery>;
export type GetMenuXDebtorListQueryResult = ApolloReactCommon.QueryResult<GetMenuXDebtorListQuery, GetMenuXDebtorListQueryVariables>;
export const CreatePaymentByQuantityDocument = gql`
    mutation createPaymentByQuantity($paymentByQuantityInput: [PaymentByQuantityInput!]!, $billInput: BillInput!, $paymentInput: PaymentInput!, $billLedgerInput: BillLedgerInput, $scheduleBillingInput: ScheduleBillingInput) {
  paymentByQuantity(paymentByQuantityInput: $paymentByQuantityInput, billInput: $billInput, paymentInput: $paymentInput, billLedgerInput: $billLedgerInput, scheduleBillingInput: $scheduleBillingInput) {
    ID
  }
}
    `;
export type CreatePaymentByQuantityMutationFn = ApolloReactCommon.MutationFunction<CreatePaymentByQuantityMutation, CreatePaymentByQuantityMutationVariables>;

/**
 * __useCreatePaymentByQuantityMutation__
 *
 * To run a mutation, you first call `useCreatePaymentByQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentByQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentByQuantityMutation, { data, loading, error }] = useCreatePaymentByQuantityMutation({
 *   variables: {
 *      paymentByQuantityInput: // value for 'paymentByQuantityInput'
 *      billInput: // value for 'billInput'
 *      paymentInput: // value for 'paymentInput'
 *      billLedgerInput: // value for 'billLedgerInput'
 *      scheduleBillingInput: // value for 'scheduleBillingInput'
 *   },
 * });
 */
export function useCreatePaymentByQuantityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePaymentByQuantityMutation, CreatePaymentByQuantityMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePaymentByQuantityMutation, CreatePaymentByQuantityMutationVariables>(CreatePaymentByQuantityDocument, baseOptions);
      }
export type CreatePaymentByQuantityMutationHookResult = ReturnType<typeof useCreatePaymentByQuantityMutation>;
export type CreatePaymentByQuantityMutationResult = ApolloReactCommon.MutationResult<CreatePaymentByQuantityMutation>;
export type CreatePaymentByQuantityMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePaymentByQuantityMutation, CreatePaymentByQuantityMutationVariables>;
export const CreateOnlinePaymentDocument = gql`
    mutation createOnlinePayment($outletID: String!, $title: String!, $detail: String!, $payAmount: Float!, $redirectUrl: String!) {
  createOnlinePayment(outletID: $outletID, title: $title, detail: $detail, payAmount: $payAmount, redirectUrl: $redirectUrl) {
    item {
      checkoutId
      url
      qrCodeUrl
      status
    }
    code
    error {
      code
      message
      debug
    }
    paySessionNo
  }
}
    `;
export type CreateOnlinePaymentMutationFn = ApolloReactCommon.MutationFunction<CreateOnlinePaymentMutation, CreateOnlinePaymentMutationVariables>;

/**
 * __useCreateOnlinePaymentMutation__
 *
 * To run a mutation, you first call `useCreateOnlinePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnlinePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnlinePaymentMutation, { data, loading, error }] = useCreateOnlinePaymentMutation({
 *   variables: {
 *      outletID: // value for 'outletID'
 *      title: // value for 'title'
 *      detail: // value for 'detail'
 *      payAmount: // value for 'payAmount'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useCreateOnlinePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOnlinePaymentMutation, CreateOnlinePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOnlinePaymentMutation, CreateOnlinePaymentMutationVariables>(CreateOnlinePaymentDocument, baseOptions);
      }
export type CreateOnlinePaymentMutationHookResult = ReturnType<typeof useCreateOnlinePaymentMutation>;
export type CreateOnlinePaymentMutationResult = ApolloReactCommon.MutationResult<CreateOnlinePaymentMutation>;
export type CreateOnlinePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOnlinePaymentMutation, CreateOnlinePaymentMutationVariables>;
export const PostPrintDataDocument = gql`
    mutation postPrintData($printData: PrintDataPayload!) {
  postPrintData(printData: $printData)
}
    `;
export type PostPrintDataMutationFn = ApolloReactCommon.MutationFunction<PostPrintDataMutation, PostPrintDataMutationVariables>;

/**
 * __usePostPrintDataMutation__
 *
 * To run a mutation, you first call `usePostPrintDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostPrintDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postPrintDataMutation, { data, loading, error }] = usePostPrintDataMutation({
 *   variables: {
 *      printData: // value for 'printData'
 *   },
 * });
 */
export function usePostPrintDataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostPrintDataMutation, PostPrintDataMutationVariables>) {
        return ApolloReactHooks.useMutation<PostPrintDataMutation, PostPrintDataMutationVariables>(PostPrintDataDocument, baseOptions);
      }
export type PostPrintDataMutationHookResult = ReturnType<typeof usePostPrintDataMutation>;
export type PostPrintDataMutationResult = ApolloReactCommon.MutationResult<PostPrintDataMutation>;
export type PostPrintDataMutationOptions = ApolloReactCommon.BaseMutationOptions<PostPrintDataMutation, PostPrintDataMutationVariables>;
export const GetOrderItemDocument = gql`
    query getOrderItem($ID: String, $orderID: String, $billID: String, $status: OrderItemStatus) {
  getOrderItem(ID: $ID, orderID: $orderID, billID: $billID, status: $status) {
    ID
    orderID
    billID
    openItemName
    quantity
    taxAmount
    serviceCharge
    amount
    baseAmount
    kitchenStatus
    status
    menuItemID
    order {
      ID
      bill {
        billNo
      }
    }
    menuItem {
      ID
      name
      description
      isDiscountable
    }
    orderItemDiscount {
      ID
      amount
      status
      reasonCode
      remark
    }
    orderDiscount
    orderItemOption {
      ID
      orderItemOptionItem {
        ID
        price
        basePrice
        unitPrice
        taxAmount
        serviceCharge
      }
    }
    totalInfo
  }
}
    `;

/**
 * __useGetOrderItemQuery__
 *
 * To run a query within a React component, call `useGetOrderItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderID: // value for 'orderID'
 *      billID: // value for 'billID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetOrderItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderItemQuery, GetOrderItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderItemQuery, GetOrderItemQueryVariables>(GetOrderItemDocument, baseOptions);
      }
export function useGetOrderItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderItemQuery, GetOrderItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderItemQuery, GetOrderItemQueryVariables>(GetOrderItemDocument, baseOptions);
        }
export type GetOrderItemQueryHookResult = ReturnType<typeof useGetOrderItemQuery>;
export type GetOrderItemLazyQueryHookResult = ReturnType<typeof useGetOrderItemLazyQuery>;
export type GetOrderItemQueryResult = ApolloReactCommon.QueryResult<GetOrderItemQuery, GetOrderItemQueryVariables>;
export const GetBillSettlementOrderItemDocument = gql`
    query getBillSettlementOrderItem($orderID: String!) {
  getBillSettlementOrderItem(orderID: $orderID) {
    ID
    quantity
    orderID
    menuItemID
    billID
    specialRequest
    unitPrice
    taxAmount
    serviceCharge
    amount
    baseAmount
    isTakeaway
    refID
    status
    weight
    openItemName
    orderDiscount
    totalInfo
    orderItemTax {
      ID
      orderItemID
      taxSchemeID
      taxSchemeDetailID
      taxID
      taxRate
      taxAmount
      taxSchemeType
    }
    orderItemDiscountTax {
      ID
      orderItemID
      orderItemDiscountID
      taxSchemeID
      taxSchemeDetailID
      taxID
      taxRate
      taxAmount
    }
    orderDiscountItemTax {
      ID
      orderItemID
      orderDiscountItemID
      taxSchemeID
      taxSchemeDetailID
      taxID
      taxRate
      taxAmount
    }
    orderItemDiscount {
      orderItemID
      amount
      baseAmount
      serviceCharge
      taxAmount
      status
      reasonCode
      remark
    }
    orderDiscountItem {
      orderItemID
      amount
      baseAmount
      serviceCharge
      taxAmount
    }
    menuItem {
      name
      isSetMenu
      isByWeight
      isOpenItem
      isOpenPrice
      isDiscountable
      description
      attachments
      uomID
      menuItemOptions {
        ID
        optionID
        maxSelections
        optionID
        menuItemOptionItem {
          ID
          menuItemOptionID
          optionItemID
          price
        }
        option {
          ID
          name
          optionItem {
            name
            ID
          }
        }
      }
      setMenuOption {
        ID
        name
        maxSelections
        setMenuOptionItem {
          ID
          quantity
          menuItemID
          menuItem {
            ID
            name
          }
        }
      }
    }
    orderItemOption {
      optionID
      orderItemOptionItem {
        optionItemID
        price
        basePrice
        unitPrice
        taxAmount
        serviceCharge
        optionItem {
          name
        }
        orderItemOptionItemTax {
          ID
          orderItemID
          orderItemOptionItemID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
        orderItemOptionItemDiscountTax {
          ID
          orderItemOptionItemID
          orderItemDiscountID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
        orderDiscountItemOptionTax {
          ID
          orderItemOptionItemID
          orderDiscountItemID
          taxSchemeID
          taxSchemeDetailID
          taxID
          taxRate
          taxAmount
        }
        discountAmount
        discountBaseAmount
        discountTaxAmount
        discountServiceCharge
      }
    }
    orderItemSetMenuOption {
      setMenuOptionID
      orderItemSetMenuOptionItem {
        setMenuOptionItemID
        quantity
        setMenuOptionItem {
          ID
          quantity
          menuItem {
            ID
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetBillSettlementOrderItemQuery__
 *
 * To run a query within a React component, call `useGetBillSettlementOrderItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillSettlementOrderItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillSettlementOrderItemQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useGetBillSettlementOrderItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillSettlementOrderItemQuery, GetBillSettlementOrderItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillSettlementOrderItemQuery, GetBillSettlementOrderItemQueryVariables>(GetBillSettlementOrderItemDocument, baseOptions);
      }
export function useGetBillSettlementOrderItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillSettlementOrderItemQuery, GetBillSettlementOrderItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillSettlementOrderItemQuery, GetBillSettlementOrderItemQueryVariables>(GetBillSettlementOrderItemDocument, baseOptions);
        }
export type GetBillSettlementOrderItemQueryHookResult = ReturnType<typeof useGetBillSettlementOrderItemQuery>;
export type GetBillSettlementOrderItemLazyQueryHookResult = ReturnType<typeof useGetBillSettlementOrderItemLazyQuery>;
export type GetBillSettlementOrderItemQueryResult = ApolloReactCommon.QueryResult<GetBillSettlementOrderItemQuery, GetBillSettlementOrderItemQueryVariables>;
export const GetStaffDocument = gql`
    query getStaff($ID: String, $orderByDesc: String, $isActive: Boolean) {
  getStaff(ID: $ID, orderByDesc: $orderByDesc, isActive: $isActive) {
    ID
    name
    designation
    mobileNo
    email
    isActive
  }
}
    `;

/**
 * __useGetStaffQuery__
 *
 * To run a query within a React component, call `useGetStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetStaffQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStaffQuery, GetStaffQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStaffQuery, GetStaffQueryVariables>(GetStaffDocument, baseOptions);
      }
export function useGetStaffLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStaffQuery, GetStaffQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStaffQuery, GetStaffQueryVariables>(GetStaffDocument, baseOptions);
        }
export type GetStaffQueryHookResult = ReturnType<typeof useGetStaffQuery>;
export type GetStaffLazyQueryHookResult = ReturnType<typeof useGetStaffLazyQuery>;
export type GetStaffQueryResult = ApolloReactCommon.QueryResult<GetStaffQuery, GetStaffQueryVariables>;
export const GetOfficerDocument = gql`
    query getOfficer($ID: String, $orderByDesc: String, $isActive: Boolean) {
  getOfficer(ID: $ID, orderByDesc: $orderByDesc, isActive: $isActive) {
    ID
    name
    designation
    mobileNo
    isActive
    email
  }
}
    `;

/**
 * __useGetOfficerQuery__
 *
 * To run a query within a React component, call `useGetOfficerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficerQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByDesc: // value for 'orderByDesc'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetOfficerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOfficerQuery, GetOfficerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOfficerQuery, GetOfficerQueryVariables>(GetOfficerDocument, baseOptions);
      }
export function useGetOfficerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOfficerQuery, GetOfficerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOfficerQuery, GetOfficerQueryVariables>(GetOfficerDocument, baseOptions);
        }
export type GetOfficerQueryHookResult = ReturnType<typeof useGetOfficerQuery>;
export type GetOfficerLazyQueryHookResult = ReturnType<typeof useGetOfficerLazyQuery>;
export type GetOfficerQueryResult = ApolloReactCommon.QueryResult<GetOfficerQuery, GetOfficerQueryVariables>;
export const GetTableDocument = gql`
    query getTable($ID: String, $name: String, $outletID: String, $orderByAsc: String) {
  getTable(ID: $ID, name: $name, outletID: $outletID, orderByAsc: $orderByAsc) {
    ID
    tableNumber
    seatCount
    prefix
    outletID
    isActive
    status
    orderInfo {
      totalAmount
      totalPax
    }
    reservationInfo {
      reserved
      pax
      name
      time
      noShow
    }
    reservation {
      ID
      name
      reservationDate
      reservationTime
    }
    outlet {
      ID
      name
    }
    order {
      tableID
      status
    }
  }
}
    `;

/**
 * __useGetTableQuery__
 *
 * To run a query within a React component, call `useGetTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      name: // value for 'name'
 *      outletID: // value for 'outletID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTableQuery, GetTableQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTableQuery, GetTableQueryVariables>(GetTableDocument, baseOptions);
      }
export function useGetTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTableQuery, GetTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTableQuery, GetTableQueryVariables>(GetTableDocument, baseOptions);
        }
export type GetTableQueryHookResult = ReturnType<typeof useGetTableQuery>;
export type GetTableLazyQueryHookResult = ReturnType<typeof useGetTableLazyQuery>;
export type GetTableQueryResult = ApolloReactCommon.QueryResult<GetTableQuery, GetTableQueryVariables>;
export const GetCurrentMealPeriodDocument = gql`
    query getCurrentMealPeriod($outletID: String!) {
  getCurrentMealPeriod(outletID: $outletID) {
    ID
    name
    startTime
    endTime
    outletID
    isActive
  }
}
    `;

/**
 * __useGetCurrentMealPeriodQuery__
 *
 * To run a query within a React component, call `useGetCurrentMealPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentMealPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentMealPeriodQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetCurrentMealPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentMealPeriodQuery, GetCurrentMealPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentMealPeriodQuery, GetCurrentMealPeriodQueryVariables>(GetCurrentMealPeriodDocument, baseOptions);
      }
export function useGetCurrentMealPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentMealPeriodQuery, GetCurrentMealPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentMealPeriodQuery, GetCurrentMealPeriodQueryVariables>(GetCurrentMealPeriodDocument, baseOptions);
        }
export type GetCurrentMealPeriodQueryHookResult = ReturnType<typeof useGetCurrentMealPeriodQuery>;
export type GetCurrentMealPeriodLazyQueryHookResult = ReturnType<typeof useGetCurrentMealPeriodLazyQuery>;
export type GetCurrentMealPeriodQueryResult = ApolloReactCommon.QueryResult<GetCurrentMealPeriodQuery, GetCurrentMealPeriodQueryVariables>;
export const GetOutletVisitorPatronDocument = gql`
    query getOutletVisitorPatron($outletID: String!) {
  getOutletVisitorPatron(outletID: $outletID) {
    ID
  }
}
    `;

/**
 * __useGetOutletVisitorPatronQuery__
 *
 * To run a query within a React component, call `useGetOutletVisitorPatronQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutletVisitorPatronQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutletVisitorPatronQuery({
 *   variables: {
 *      outletID: // value for 'outletID'
 *   },
 * });
 */
export function useGetOutletVisitorPatronQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutletVisitorPatronQuery, GetOutletVisitorPatronQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutletVisitorPatronQuery, GetOutletVisitorPatronQueryVariables>(GetOutletVisitorPatronDocument, baseOptions);
      }
export function useGetOutletVisitorPatronLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutletVisitorPatronQuery, GetOutletVisitorPatronQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutletVisitorPatronQuery, GetOutletVisitorPatronQueryVariables>(GetOutletVisitorPatronDocument, baseOptions);
        }
export type GetOutletVisitorPatronQueryHookResult = ReturnType<typeof useGetOutletVisitorPatronQuery>;
export type GetOutletVisitorPatronLazyQueryHookResult = ReturnType<typeof useGetOutletVisitorPatronLazyQuery>;
export type GetOutletVisitorPatronQueryResult = ApolloReactCommon.QueryResult<GetOutletVisitorPatronQuery, GetOutletVisitorPatronQueryVariables>;
export const GetTaxSchemeDetailDocument = gql`
    query getTaxSchemeDetail($accountID: String!, $taxSchemeID: String!, $orderID: String!) {
  getTaxSchemeDetail(accountID: $accountID, taxSchemeID: $taxSchemeID, orderID: $orderID) {
    taxId
    taxRate
    taxCode
    taxType
  }
}
    `;

/**
 * __useGetTaxSchemeDetailQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeDetailQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      taxSchemeID: // value for 'taxSchemeID'
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useGetTaxSchemeDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>(GetTaxSchemeDetailDocument, baseOptions);
      }
export function useGetTaxSchemeDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>(GetTaxSchemeDetailDocument, baseOptions);
        }
export type GetTaxSchemeDetailQueryHookResult = ReturnType<typeof useGetTaxSchemeDetailQuery>;
export type GetTaxSchemeDetailLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeDetailLazyQuery>;
export type GetTaxSchemeDetailQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>;
export const GetRoundingPolicyDocument = gql`
    query getRoundingPolicy($ID: String, $accountID: String) {
  getRoundingPolicy(ID: $ID, accountID: $accountID) {
    ID
    inUse
    isActive
    transactionType
    methodType
    smallestDenomination
  }
}
    `;

/**
 * __useGetRoundingPolicyQuery__
 *
 * To run a query within a React component, call `useGetRoundingPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoundingPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoundingPolicyQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetRoundingPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoundingPolicyQuery, GetRoundingPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoundingPolicyQuery, GetRoundingPolicyQueryVariables>(GetRoundingPolicyDocument, baseOptions);
      }
export function useGetRoundingPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoundingPolicyQuery, GetRoundingPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoundingPolicyQuery, GetRoundingPolicyQueryVariables>(GetRoundingPolicyDocument, baseOptions);
        }
export type GetRoundingPolicyQueryHookResult = ReturnType<typeof useGetRoundingPolicyQuery>;
export type GetRoundingPolicyLazyQueryHookResult = ReturnType<typeof useGetRoundingPolicyLazyQuery>;
export type GetRoundingPolicyQueryResult = ApolloReactCommon.QueryResult<GetRoundingPolicyQuery, GetRoundingPolicyQueryVariables>;