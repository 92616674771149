import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
  },

  h1: {
    color: 'white',
    fontSize: '30px',
    fontWeight: 900,
    lineHeight: '65px',
    margin: 0,
    textShadow: '4px 5px 1px #000000',
  },
  h2: {
    color: 'white',
    fontSize: '25px',
    fontWeight: 700,
    lineHeight: '30px',
    margin: 0,
    textShadow: '4px 5px 1px #172f54',
  },
  h3: {
    color: 'white',
    //  fontSize: '210px',
    fontWeight: 400,
    // lineHeight: '210px',
    margin: 0,
  },
  backBtn: {
    color: 'white',
    backgroundColor: '#2f47ff',
    width: '100%',
    minWidth: '100px',
    maxWidth: '200px',
  },
}))
export default useStyles
