import {
	useGetOutletQuery,
	useGetTaxSchemeDetailLazyQuery,
} from 'generated/graphql';
import { amtStr } from 'helpers/numFormatter';
import React, { useEffect } from 'react';

interface Props {
	outletID;
	taxSchemeID;
	orderID;
	currencyCode;
	type;
	taxSchemeInfo;
  taxSchemeFooterInfo;
	takeaway?;
}

export enum SchemeType {
	GST = 'GST',
	ServiceCharge = 'ServiceCharge',
	SST = 'SST',
}

export const TaxSchemeComponent = (props: Props) => {
	const {
		outletID,
		taxSchemeID,
		orderID,
		currencyCode,
		type,
		taxSchemeInfo,
    taxSchemeFooterInfo,
		takeaway,
	} = props;

	const user = JSON.parse(localStorage.getItem('customerInfo'));

	const {
		loading: outletLoading,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const [
		defaultTax,
		{
			data: { getTaxSchemeDetail } = {
				getTaxSchemeDetail: null,
			},
		},
	] = useGetTaxSchemeDetailLazyQuery({
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (getOutlet?.length > 0) {
			if (taxSchemeID === null) {
				defaultTax({
					variables: {
						accountID: user?.accountID,
						taxSchemeID: getOutlet[0]?.taxSchemeID,
						orderID,
					},
				});
			} else {
				defaultTax({
					variables: {
						accountID: user?.accountID,
						taxSchemeID: taxSchemeID,
						orderID,
					},
				});
			}
		}
	}, [getOutlet]);

	const taxAmount = taxType => {
		let totalTax;
		if (taxType === SchemeType?.ServiceCharge) {
			totalTax = taxSchemeInfo?.totalSC;
		} else if (taxType === SchemeType?.SST) {
			totalTax = taxSchemeInfo?.totalSST;
		} else if (taxType === SchemeType?.GST) {
			totalTax = taxSchemeInfo?.totalGST;
		}
		return totalTax;
	};

	return (
		<>
			{getTaxSchemeDetail?.map((x, index) => {
				return (
					<>
						{type === 'detail' ? (
							takeaway ? (
								<div
									key={index}
									className="m-t-10"
									style={{ display: 'flex', width: '100%' }}
								>
									{x?.taxType !== 'ServiceCharge' ? (
										<>
											<span className="mdDesc flex-space">
												{x?.taxCode} ({x?.taxRate}%)
											</span>
											<span className="mdDesc">{`${currencyCode}${amtStr(
												Number(taxAmount(x?.taxType)),
											)}`}</span>
										</>
									) : null}
								</div>
							) : (
								<div
									key={index}
									className="m-t-10"
									style={{ display: 'flex', width: '100%' }}
								>
									<span className="mdDesc flex-space">
										{x?.taxCode} ({x?.taxRate}%)
									</span>
									<span className="mdDesc">{`${currencyCode}${amtStr(
										Number(taxAmount(x?.taxType)),
									)}`}</span>
								</div>
							)
						) : type === 'receipt' ? (
							<div
								key={index}
								className="m-t-10"
								style={{ display: 'flex', width: '100%' }}
							>
								<span
									className="flex-space mdDesc "
									style={{
										alignSelf: 'center',
										fontSize: '16px',
										color: '#212121',
									}}
								>
									{x?.taxCode} ({x?.taxRate}%)
								</span>
								<span
									className="xsTitle rightText"
									style={{ fontSize: '16px', color: '#212121' }}
								>{`${currencyCode}${amtStr(
									Number(taxAmount(x?.taxType)),
								)}`}</span>
							</div>
						) : null}
					</>
				);
			})}
		</>
	);
};
