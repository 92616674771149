import { Button, useMediaQuery } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import React from 'react';

interface PatronFooterProps {
	value: string;
	leftBtn: OptionProps;
	text: string | JSX.Element;
	style?: React.CSSProperties;
	rightBtn?: OptionProps;
}

interface OptionProps {
	onClick?: any;
	color?: 'primary' | 'yellow' | 'secondary ' | 'default' | 'inherit';
	name: string;
	disabled?: boolean;
	props?: any;
	style?: React.CSSProperties;
}

export const PatronFooter = ({
	value,
	leftBtn,
	text,
	style,
	rightBtn,
}: PatronFooterProps) => {
	const xsMobile = useMediaQuery(`(max-width:375px) `);

	return (
		<div className="footer" style={{ ...style, paddingBottom: '10px' }}>
			<div
				style={{
					float: 'left',
					marginTop: '6px',
					marginLeft: '15px',
					paddingBottom: '12px',
					// flexShrink: 0,
				}}
			>
				<Button
					startIcon={<ShoppingCartIcon style={{ fontSize: '18px' }} />}
					color={leftBtn?.color}
					onClick={leftBtn?.onClick}
					disabled={leftBtn?.disabled}
					variant="contained"
					style={{
						...leftBtn?.style,
						minWidth: '150px',
						height: '40px',
						borderRadius: '4px',
					}}
					{...leftBtn?.props}
				>
					<span style={{ fontSize: 'inherit' }}>{leftBtn?.name}</span>
				</Button>
				<div>
					<span
						className="desc"
						style={{
							width: '100%',
							position: 'absolute',
							bottom: '5px',
							paddingLeft: '16px',
							textAlign: 'left',
							left: '0px',
						}}
					>
						Subject to tax and service charge
					</span>
				</div>
			</div>

			<div style={{ float: 'right', marginTop: '6px', marginLeft: '15px' }}>
				<span
					className="footer-order-label xsTitle"
					style={{ float: 'right', padding: '6px 12px' }}
				>
					{text}
				</span>
				<span
					className="footer-order-label"
					style={{
						width: '75%',
						color: '#ff9800',
						fontSize: '20px',
						fontWeight: 'bold',
						marginTop: '-15px',
						float: 'right',
						justifyContent: 'right',
						padding: '6px 12px',
						// marginBottom: '5px',
					}}
				>
					{`${value}`}
				</span>
			</div>

			{rightBtn && (
				<div
					className="patron-footer-btn"
					style={{ float: 'right', width: xsMobile ? '75px' : '60px' }}
				>
					<Button
						color={rightBtn?.color}
						onClick={rightBtn?.onClick}
						disabled={rightBtn?.disabled}
						variant="contained"
						style={{
							minWidth: xsMobile ? '65px' : '67px',
							borderRadius: '4px',
							height: '40px',
						}}
						{...rightBtn?.props}
					>
						<span style={{ fontSize: '15px' }}>{rightBtn?.name}</span>
					</Button>
				</div>
			)}
		</div>
	);
};
