import { differenceInDays, format, parseISO } from 'date-fns';

export const formatDate = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'dd MMM yyyy') : 'NA';
};

export const convertDate = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'dd MMM yyyy, hh:mm a') : 'NA';
};

export const convertDate24Hrs = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'dd/MM/yyyy, HH:mm') : 'NA';
};

export const dateFormat = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'dd/MM/yyyy') : 'NA';
};

export const formatTime = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'hh:mm a') : 'NA';
};

export const formatTimeSecond = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'HH:mm:ss') : 'NA';
};

export const formatTimeDate = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'hh:mm a, dd MMM') : 'NA';
};

export const formatDay = (day: string) => {
	const result = parseISO(day);
	return day ? format(result, 'EEEE') : 'NA';
};

export const daysDifferences = (date: string) => {
	var DateDifferences = differenceInDays(new Date(date), new Date());
	return DateDifferences;
};
