import routes from '../Router/RootRoutes';

interface iGlobalState {
	routes: any;
	drawerOpen: boolean;
	isAuth: boolean;
	appCode: string;
}
export const GlobalInitialState: iGlobalState = {
	routes: routes,
	drawerOpen: false,
	isAuth: false,
	appCode: '',
};

export function RootReducer(state, action) {
	switch (action.type) {
		case 'routes':
			return {
				...state,
				routes: action.payload,
			};
		case 'drawerOpen':
			return { ...state, drawerOpen: action.payload };
		case 'isAuth':
			return {
				...state,
				isAuth: action.payload,
			};
		case 'appCode':
			return { ...state, mode: action.payload };
		default:
			throw new Error();
	}
}
