import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { setAccessToken } from 'AccessToken';
import {
	useGetCurrentMealPeriodLazyQuery,
	useGetDailyMenuCategoryLazyQuery,
	useGetOutletLazyQuery,
	useGetQrLazyQuery,
	useGrantCustomerAccessMutation,
} from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const QRRedirect = () => {
	useEffect(() => {
		console.log('QRRedirect');
	}, []);
	const history = useHistory();
	const { outletID, qrID, accountID }: any = useParams();
	const [qrInfo, setQrInfo] = useState(null);
	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);

	const rememberme: any = localStorage.getItem('rememberme');

	if (rememberme !== null) {
		localStorage.setItem('rememberme', rememberme);
	}

	const [
		grantCustomerAccess,
		{ loading: accessLoading },
	] = useGrantCustomerAccessMutation({
		onCompleted: ({ customerAccess }) => {
			if (customerAccess?.accessToken) {
				setAccessToken(customerAccess?.accessToken);
				sessionStorage.setItem('key', customerAccess?.accessToken);
				localStorage.setItem(
					'customerInfo',
					JSON.stringify(customerAccess.customer),
				);
				loadOutlet({
					variables: {
						ID: outletID,
					},
				});
			} else {
				throw new Error('Not authenticated');
			}
		},
	});

	const [
		loadOutlet,
		{
			called: ouletCalled,
			loading: outletLoading,
			error: outletError,
			data: { getOutlet } = { getOutlet: [] },
		},
	] = useGetOutletLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: () => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
			loadQr();
		},
	});

	const [
		loadQr,
		{
			called: qrCalled,
			loading: qrLoading,
			error: qrError,
			data: { getQR } = { getQR: [] },
		},
	] = useGetQrLazyQuery({
		fetchPolicy: 'network-only',
		variables: { ID: qrID },
		onCompleted: ({ getQR }) => {
			if (getQR.length > 0 && getQR !== undefined && getQR !== null) {
				let scannedQR = getQR[0];
				setQrInfo(scannedQR);
				// if can self order
				if (getOutlet[0]?.customerPolicy?.selfOrder) {
					// if have order but no item
					if (
						Object.keys(scannedQR?.orderInfo).length !== 0 &&
						scannedQR.orderInfo?.orderItemCount === 0
					) {
						history.push(
							`/menu/patron-app/outlet/${scannedQR?.outletID}/table/${scannedQR?.orderInfo?.tableID}/order/${scannedQR?.orderInfo?.orderID}`,
						);
						// if have order and have item
					} else if (
						Object.keys(scannedQR?.orderInfo).length !== 0 &&
						scannedQR.orderInfo?.orderItemCount !== 0
					) {
						loadCategory({
							variables: {
								mealPeriodID: currentMealPeriod?.ID,
								outletID: scannedQR?.outletID,
							},
						});
						// if have no order
					} else if (Object.keys(scannedQR?.orderInfo).length === 0) {
						history.push(`/menu/patron-app/invalid-qr`);
					}
					// if cannot self order
				} else if (getOutlet[0]?.customerPolicy?.selfOrder === false) {
          // if have order but no item
          if ( 
            Object.keys(scannedQR?.orderInfo).length !== 0 &&
              scannedQR.orderInfo?.orderItemCount === 0
            ) {
            history.push(
              `/menu/patron-app/outlet/${scannedQR?.outletID}/table/${scannedQR?.orderInfo?.tableID}/order/${scannedQR?.orderInfo?.orderID}`,
            );
            // if have order and have item
          } else if (
            Object.keys(scannedQR?.orderInfo).length !== 0 &&
						scannedQR.orderInfo?.orderItemCount !== 0
          ) {
            loadCategory({
							variables: {
								mealPeriodID: currentMealPeriod?.ID,
								outletID: scannedQR?.outletID,
							},
						});
            // if have no order
          } else if (Object.keys(scannedQR?.orderInfo).length === 0) {
            history.push(`/menu/patron-app/invalid-qr`);
          }
        }
        
        else {
					
				}
			}
		},
	});

	const [loadCategory] = useGetDailyMenuCategoryLazyQuery({
		onError: error => {
			console.log(error);
		},
		onCompleted: ({ getOutletDailyMenuCategory }) => {
			history.push(
				`/menu/patron-app/outlet/${qrInfo?.outletID}/table/${qrInfo?.orderInfo?.tableID}/order/${qrInfo?.orderInfo?.orderID}/menu-category/${getOutletDailyMenuCategory[0]?.ID}/summary`,
			);
		},
	});

	useEffect(() => {
		grantCustomerAccess({
			variables: {
				accountID: accountID,
				outletID: outletID,
				qrID: qrID,
			},
		});
	}, []);

	return (
		<>
			{accessLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
		</>
	);
};
