import {
	Checkbox,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
} from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import PickupIcon from 'assets/icons/pickup_grey.svg';
import BigNumber from 'bignumber.js';
import { OrderItemDiscountStatus } from 'generated/graphql';
import { amtStr } from 'helpers/numFormatter';
import React from 'react';
import NumberFormat from 'react-number-format';

interface OrderItemListProps {
	outletID: string;
	itemArray: any;
	negOrderItem?: any;
	leftIcon?: 'checkbox';
	leftAction?: any;
	rightIcon?: 'void' | 'checkbox' | 'quantity';
	rightAction?: any;
	selectedObj?: any; // only used to display amount field for item transfer, later might use for partial payment
	setSelectedObj?: any; // function to change selectedObj
	quantityFunc?: {
		onButtonClick?: any;
		onValueChange?: any;
	};
	currencyCode?: string;
	page?:
		| 'bill-item-discount' // done
		| 'bill-settlement' // done
		| 'confirm-order' // done
		| 'bill-listing-detail' // done
		| 'item-transfer-dialog' // done
		| 'kitchen-home'; // no need not the same
}

export const OrderItemList = (props: OrderItemListProps) => {
	const {
		outletID,
		itemArray,
		negOrderItem,
		leftIcon,
		leftAction,
		rightIcon,
		rightAction,
		selectedObj,
		setSelectedObj,
		quantityFunc,
		currencyCode = '',
		page,
	} = props;

	return (
		<>
			<List style={{ backgroundColor: 'white', color: 'black' }}>
				{page === 'item-transfer-dialog' && (
					<span className="smTitle">{`Bill Items (${itemArray?.length ??
						0})`}</span>
				)}

				{itemArray?.map((oi, index) => {
					let totalTax = 0;
					oi?.orderItemTax?.map(x => {
						totalTax += x?.taxAmount;
					});

					const baseItemAmountBill = oi?.amount + totalTax;

					const negBaseItemAmountBill =
						(negOrderItem?.[oi?.ID]?.amount ?? 0) +
						(negOrderItem?.[oi?.ID]?.taxAmount ?? 0);
					return (
						<ListItem
							style={{
								paddingLeft: page === 'item-transfer-dialog' ? '0px' : null,
								paddingRight:
									rightIcon === 'quantity' &&
									selectedObj[oi?.ID]?.showQuantity === true
										? '130px'
										: null,
							}}
						>
							{leftIcon === 'checkbox' && (
								<Checkbox
									onChange={e => {
										leftAction(
											e,
											oi?.ID,
											oi?.quantity - negOrderItem[oi?.ID] || oi?.quantity,
										);
									}}
									name="orderItemID"
									color="primary"
									style={{
										padding: '8px 5px',
										alignSelf: 'start',
									}}
									checked={selectedObj[oi?.ID]?.showQuantity}
								/>
							)}

							<ListItemText
								primary={
									<>
										<Grid container>
											<Grid item xs={8}>
												{page !== 'item-transfer-dialog' && (
													<span
														className="mdDesc"
														style={{ paddingRight: '16px' }}
													>
														{index + 1 + '.'}
													</span>
												)}

												<span className="xsTitle">
													{oi?.openItemName ?? oi.menuItem?.name}&nbsp; &nbsp;
													{'x'}
													{oi?.quantity -
														(negOrderItem?.[oi?.ID]?.quantity ?? 0)}
													{page === 'confirm-order' && oi?.isTakeaway && (
														<img
															className="list-takeaway-logo"
															src={PickupIcon}
														/>
													)}
												</span>
											</Grid>
											<Grid item xs={4}>
												<span
													className="highlight-text xsTitle right-info"
													style={{
														padding: '7px 0px 4px 4px',
													}}
												>
													{`${currencyCode}${amtStr(
														new BigNumber(baseItemAmountBill)
															.minus(negBaseItemAmountBill ?? 0)
															.toNumber(),
													)}`}
												</span>
											</Grid>
										</Grid>
									</>
								}
								secondary={
									<>
										<Grid container>
											{oi?.orderItemOption?.length
												? oi?.orderItemOption?.map(oiopt => (
														<>
															{oiopt?.orderItemOptionItem?.map(oioptitem => {
																let totalTax = 0;
																oioptitem?.orderItemOptionItemTax?.map(x => {
																	totalTax += x?.taxAmount;
																});

																const baseOptItemAmountBill =
																	oioptitem?.basePrice + totalTax;

																const negOptBaseItemAmountBill =
																	(negOrderItem?.[
																		oi?.ID
																	]?.orderItemOptions?.filter(
																		noi => noi.optionID === oiopt?.optionID,
																	)[0]?.orderItemOptionItem?.basePrice ?? 0) +
																	(negOrderItem?.[
																		oi?.ID
																	]?.orderItemOptions?.filter(
																		noi => noi.optionID === oiopt?.optionID,
																	)[0]?.orderItemOptionItem?.taxAmount ?? 0);

																return (
																	<>
																		<Grid item xs={8}>
																			<span
																				className="mdDesc"
																				style={{
																					paddingLeft:
																						page !== 'item-transfer-dialog'
																							? '26px'
																							: null,
																				}}
																			>
																				{oioptitem?.optionItem?.name}
																			</span>
																		</Grid>
																		{page !== 'bill-item-discount' && (
																			<Grid item xs={4}>
																				<span
																					className="mdDesc right-info"
																					style={{
																						marginTop: '4px',
																					}}
																				>
																					{`${currencyCode}${amtStr(
																						new BigNumber(baseOptItemAmountBill)
																							.minus(
																								negOptBaseItemAmountBill ?? 0,
																							)
																							.toNumber(),
																					)}`}
																				</span>
																			</Grid>
																		)}
																	</>
																);
															})}
														</>
												  ))
												: oi?.orderItemSetMenuOption?.map(v => (
														<>
															{v?.orderItemSetMenuOptionItem?.map(
																oisetmenuoptitem => (
																	<Grid item xs={12}>
																		<span
																			className="mdDesc"
																			style={{
																				paddingLeft:
																					page !== 'item-transfer-dialog'
																						? '26px'
																						: null,
																			}}
																		>
																			{
																				oisetmenuoptitem?.setMenuOptionItem
																					?.menuItem?.name
																			}
																		</span>
																	</Grid>
																),
															)}
														</>
												  ))}
											{/* {hasItemDisc && (
												<Grid item xs={4}>
													<span
														className="mdDesc flex-space right-info"
														style={{
															textDecoration: 'line-through ',
															marginTop: '4px',
														}}
													>
														{`${currencyCode}${amtStr(
															new BigNumber(oi?.totalInfo?.totalBaseAmount)
																.minus(negOrderItem?.[oi?.ID]?.amount ?? 0)
																.toNumber(),
														)}`}
													</span>
												</Grid>
											)} */}
										</Grid>
									</>
								}
							/>

							{rightIcon === 'checkbox' && (
								<ListItemSecondaryAction
									style={{ top: '10%', transform: 'inherit' }}
								>
									<Checkbox
										edge="end"
										value={oi?.ID}
										disabled={
											// handlePermDisabled({
											// 	outletID: outletID,
											// 	permEnum:
											// 		PosPermission.HomeTableLayoutBillItemDiscountCreateOrderItemDiscountCreate,
											// }) ||
											!oi?.menuItem?.isDiscountable
										}
										checked={
											oi?.orderItemDiscount?.filter(
												disc => disc?.status === OrderItemDiscountStatus.Active,
											)?.length !== 0
										}
										onChange={(e: any) => {
											if (oi?.menuItem?.isDiscountable) {
												rightAction(e, index, oi);
											}
										}}
										color="primary"
										inputProps={{ 'aria-label': 'secondary checkbox' }}
									/>
								</ListItemSecondaryAction>
							)}

							{rightIcon === 'void' && (
								<ListItemSecondaryAction
									style={{ top: '10%', transform: 'inherit' }}
								>
									<IconButton
										edge="end"
										aria-label="more"
										aria-controls="menu-list"
										aria-haspopup="true"
										onClick={e => {
											rightAction(e, oi?.ID, index, oi);
										}}
										// disabled={handlePermDisabled({
										// 	outletID: outletID,
										// 	permEnum:
										// 		PosPermission.HomeTableLayoutAddEditOrderItemVoidOrderItemVoid,
										// })}
									>
										<DeleteIcon fontSize="small" />
									</IconButton>
								</ListItemSecondaryAction>
							)}

							{rightIcon === 'quantity' &&
								selectedObj[oi?.ID]?.showQuantity === true && (
									<ListItemSecondaryAction
										style={{
											textAlign: 'right',
											top: '-5%',
											transform: 'inherit',
										}}
									>
										<>
											<IndeterminateCheckBoxIcon
												style={{ fontSize: '25px' }}
												className="order-minus"
												onClick={() =>
													quantityFunc?.onButtonClick(
														'reduce',
														oi?.ID,
														oi?.quantity,
													)
												}
											/>
											<NumberFormat
												customInput={TextField}
												autoComplete="off"
												isNumericString
												variant="standard"
												fullWidth={false}
												margin="dense"
												className="item-transfer-input"
												onValueChange={(value: any) => {
													const { formattedValue, floatValue } = value;

													return (
														formattedValue === '' ||
														floatValue ===
															setSelectedObj({
																...selectedObj,
																[oi?.ID]: {
																	showQuantity: true,
																	selectedQuantity: value?.floatValue,
																	maxQuantity: selectedObj[oi?.ID]?.maxQuantity,
																},
															})
													);
												}}
												isAllowed={values => {
													const { formattedValue, floatValue } = values;
													return (
														formattedValue === '' ||
														floatValue <= selectedObj[oi?.ID]?.maxQuantity
													);
												}}
												InputProps={{
													disableUnderline: true,
												}}
												style={{
													width: '20%',
													borderRadius: '8px',
													border: 'solid',
													borderColor: 'orange',
													textAlign: 'center',
													marginTop: '17px',
													marginBottom: '4px',
												}}
												value={selectedObj[oi?.ID]?.selectedQuantity}
											/>

											<AddBox
												style={{ fontSize: '25px', color: '#ff9800' }}
												className="order-add"
												onClick={() =>
													quantityFunc?.onButtonClick(
														'add',
														oi?.ID,
														oi?.quantity - negOrderItem[oi?.ID] || oi?.quantity,
													)
												}
											/>
										</>
									</ListItemSecondaryAction>
								)}
						</ListItem>
					);
				})}
			</List>
		</>
	);
};
