import React from 'react';
import { ConfirmOrder } from './ConfirmOrder';
import { InvalidQR } from './InvalidQR';
import { MenuList } from './MenuList';
import { Order } from './Order';
import { OrderSummary } from './OrderSummary';
import { Payment } from './Payment';
import { QRRedirect } from './QRRedirect';
import { Receipt } from './Receipt';

const orderRoutes = [
	{
		props: {
			exact: true,
			path: '/menu/patron-app/outlet/:outletID/qr-code/:qrID/:accountID',
		},
		component: <QRRedirect />,
	},
	{
		props: {
			exact: true,
			path: '/menu/patron-app/outlet/:outletID/table/:tableID/order/:orderID',
		},
		component: <MenuList />,
	},
	{
		props: {
			exact: true,
			path:
				'/menu/patron-app/outlet/:outletID/table/:tableID/order/:orderID/menu-category/:menuID',
		},
		component: <Order />,
	},
	{
		props: {
			exact: true,
			path:
				'/menu/patron-app/outlet/:outletID/table/:tableID/order/:orderID/menu-category/:menuID/summary',
		},
		component: <OrderSummary />,
	},
	{
		props: {
			exact: true,
			path:
				'/menu/patron-app/outlet/:outletID/table/:tableID/order/:orderID/menu-category/:menuID/summary/confirm-order',
		},
		component: <ConfirmOrder />,
	},
	{
		props: {
			exact: true,
			path:
				'/menu/patron-app/outlet/:outletID/table/:tableID/order/:orderID/menu-category/:menuID/summary/confirm-order/payment',
		},
		component: <Payment />,
	},
	{
		props: {
			exact: true,
			path:
				'/menu/patron-app/outlet/:outletID/table/:tableID/order/:orderID/menu-category/:menuID/summary/confirm-order/payment/:billID/receipt',
		},
		component: <Receipt />,
	},
	{
		props: { exact: true, path: '/menu/patron-app/invalid-qr' },
		component: <InvalidQR />,
	},
];

export default orderRoutes;
