import theme from '@ifca-root/react-component/src/assets/theme';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import { useMediaQuery } from '@material-ui/core';
import AppContext from 'containers/App/Store/AppContext';
import {
	useGetMenuXInhouseListLazyQuery,
	useGetOfficerLazyQuery,
	useGetOutletQuery,
	useGetStaffLazyQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { amtStr } from 'helpers/numFormatter';
import React, { useContext } from 'react';

interface OrderSubheaderProps {
	getOrderLoading: boolean;
	getOrder: any;
	selectedOrder: any;
	orderID: string;
	handleChangeOrder?: any;
	outletID: string;
	// subTitle?: string;
	// secSubTitle?: string;
	// simpleDescription?: string;
	// isForm?: boolean;
	// handleSubmit: any;
	// onSubmit: any;
	// formStructure: any;
	// infoLine?: string;
	// infoLineRight?: string;
	// infoLineMiddle?: any;
}

export const OrderSubheader = (props: OrderSubheaderProps) => {
	const {
		getOrderLoading,
		getOrder,
		selectedOrder,
		orderID,
		handleChangeOrder,
		outletID,
	} = props;

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadMenuXInHouseList({
				variables: {
					hotelID: getOutlet[0]?.outletChannel?.hotelID,
					outletID: outletID,
				},
			});
			loadOfficer({
				variables: {
					isActive: true,
				},
			});
			loadStaff({
				variables: {
					isActive: true,
				},
			});
		},
	});

	const [
		loadStaff,
		{ loading: getStaffLoading, data: { getStaff } = { getStaff: [] } },
	] = useGetStaffLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadOfficer,
		{ loading: getOfficerLoading, data: { getOfficer } = { getOfficer: [] } },
	] = useGetOfficerLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadMenuXInHouseList,
		{
			loading: MenuXInhouseListLoading,
			data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
		},
	] = useGetMenuXInhouseListLazyQuery({
		fetchPolicy: 'network-only',
	});

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const { globalState, dispatch }: any = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	return (
		<>
			{!getOrderLoading && (
				<DynamicSubHeader
					title={
						getOrder === undefined || getOrder?.length === 0 ? (
							''
						) : (
							<>
								<span
									className="highlight"
									style={{ color: '#ff9800', paddingRight: '4px' }}
								>
									{getOrder[0]?.table?.prefix}
									{getOrder[0]?.table?.tableNumber}
								</span>
								<span>{getOrder[0]?.docNo}</span>
							</>
						)
					}
					handleClick={e => handleClick(e, orderID, 0)} // onClick function for dropdown
					noMainheader
					rightText={
						<span>
							{getOutlet[0]?.currencyCode}
							{getOutlet[0]?.customerPolicy?.selfOrder
								? `${amtStr(getOrder[0]?.outstandingAmount)}`
								: ``}
						</span>
					}
				/>
			)}
		</>
	);
};
